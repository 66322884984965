import React, {useEffect, useRef, useState} from 'react';
import style from './style.module.less'
import topImg from '../../../../../images/member/blue-select-top.png'
import bottomImg from '../../../../../images/member/blue-select-bottom.png'

function Select(props) {

    const {
        label, options, placeholder, onChange, border = true, placement = 'bottom', value = {
            key: '',
            value: ''
        }, ...site
    } = props;

    const [isOpen, setIsOpen] = useState(false); //下拉是否打开

    const initSelectItem = {
        key: '',
        value: ''
    }
    const [selectItem, setSelectItem] = useState(initSelectItem); //当前选中

    const toggleContainer = useRef()

    useEffect(() => {
        setSelectItem(initSelectItem)
    }, [options])

    //点击打开下拉 或关闭下拉
    let onClickHandler = (e) => {
        setIsOpen(!isOpen)
    };

    //点击其他地方 判断点击是否展开selec 是否在select 内点击
    let onClickOutsideHandler = (event) => {
        if (!isOpen && !toggleContainer.current.contains(event.target)) {
            setIsOpen(false)
        }

    };

    //选中下拉内容
    let change = item => {
        if (isOpen) {
            setIsOpen(false)
            setSelectItem(item)
            onChange(item)
        }
    };

    //添加点击事件
    useEffect(() => {

        window.addEventListener("click", onClickOutsideHandler);

        return (() => {
            try {
                window.removeEventListener("click", onClickOutsideHandler);
            } catch (e) {

            }
        })

    }, [])

    useEffect(() => {
        if (value.key !== '') {
            setSelectItem({...value})
        }
    }, [value])

    return (
        <div className={style.memberSelectBox}>
            {/*是否有label*/}
            {label && <label className={style.label}>{label}:</label>}

            <div className={style.memberSelectDiv} ref={toggleContainer} onClick={onClickHandler}>
                <input
                    className={[style.selfInput, isOpen && style.inputHover, border && style.border].join(' ')}
                    title={placeholder}
                    readOnly={true}
                    value={selectItem.value ? selectItem.value : ''}
                    placeholder={placeholder}
                    {...site}
                />
                <div className={style.tagBox}>
                    <img className={style.img} src={isOpen ? topImg : bottomImg} alt=""/>
                </div>

                <div
                    className={[style.options, !isOpen && style.optionsHidden, placement === 'top' && style.optionsTop].join(' ')}
                >
                    {options &&
                        options.map((item) => {
                            return (
                                <div
                                    key={item.key}
                                    className={[style.item, selectItem.key === item.key && style.selectItem].join(' ')}
                                    title={item.value}
                                    onClick={() => {
                                        change(item)
                                    }}
                                >
                                    {item.value}
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

export default Select;