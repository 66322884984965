import {createSlice} from '@reduxjs/toolkit'

const NAMESPACE = "rightMenu"

// 右键菜单
const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    modalShowTypeScreen: {}, // 弹框显示位置
  },
  reducers: {
    setModalShowTypeScreen(state, action) {
      state.modalShowTypeScreen = action.payload
    },
  },
  extraReducers: {},
});

export const {
  setModalShowTypeScreen
} = slice.actions

export const {reducer} = slice;
