import React, {useState, useRef, useEffect} from 'react';
import style from './style.module.less'
import common from "../../../../utils/common";
import Modal from "../../../public/modal";

// 微信或支付宝支付
function PayWay(props) {
    const {
        shouldPay,
        wxOrZhi,
        memberId,
        closePayWay,
        inputVal,
        payLastList,
        payTypeListKey,
        itemId,
        payList,
        isFocus,
        isSubmit,
        error,
        setError,
    } = props

    const [popNum, setPopNum] = useState(120);      // 计时弹窗的数字
    const popNumRef = useRef(120);
    const [errorType, setErrorType] = useState(''); // 错误类型
    const [codeKey, setCodeKey] = useState('');     // 移动支付的标记
    const popContent = useRef(null); // 弹窗

    const inputCode = useRef(null)
    const timer = useRef(null)
    const countdownTimer = useRef(null)             // 弹窗倒计时计时器

    const [showTipsPop, setShowTipsPop] = useState('')

    useEffect(() => {
        if (isFocus.isFocus) {
            inputCode.current.focus()
        }
    }, [isFocus])

    let savePopNum = (t) => {
        setPopNum(t)
        popNumRef.current = t
    }

    //手机订单号 和 后台支付码, 支付成功后的方法
    const paySuccess = (money, fileKey) => {
        setError('')

        payList.payList[payTypeListKey] = {
            id: itemId,
            money: money,
            mobile_pay_code: fileKey,
        }
        payList.setPayList(JSON.parse(JSON.stringify(payList.payList)))

        payLastList.payLastList[payTypeListKey] = money
        payLastList.setPayLastList(JSON.parse(JSON.stringify(payLastList.payLastList)))
        isSubmit.current = true

        closePayWay()
    }

    //查询移动支付是否结束
    const checkCodePay = (codePayCacheKey, loadingStop) => {
        loadingStop = loadingStop || function () {
        }

        common.ajax('post', '/app.php/member-api/card/check-code-pay', {
            codePayCacheKey: codePayCacheKey,
        }).then((result) => {
            if (result.message === 'success') {
                loadingStop();

                common.toast('支付成功')

                // 关闭倒计时弹框
                closeTipsPop()

                // 支付成功后的操作
                paySuccess(shouldPay, result.fileKey)

            } else if (result.message === 'ing') {

                // 轮询
                timer.current = setTimeout(() => {
                    checkCodePay(codePayCacheKey, loadingStop)
                }, 3000)

            } else {
                loadingStop();

                // 打印错误和清除计时器
                printErrAndClearTime("轮询支付有误", 'noPoll')
            }
        }).catch((err) => {
            loadingStop();

            // 打印错误和清除计时器
            printErrAndClearTime(err.error, 'noPoll')
        })
    }

    // 倒计时计时器
    const countdown = () => {
        clearTimeout(countdownTimer.current)
        countdownTimer.current = setTimeout(() => {
            savePopNum(popNumRef.current - 1)
            if (popNumRef.current >= 0) {
                countdown()
            } else {
                // 打印错误和清除计时器
                printErrAndClearTime("", 'pollNoPay')
            }
        }, 1000)
    }

    // 打印错误和清除计时器
    const printErrAndClearTime = (error, errType) => {
        // 设置错误信息
        setErrorType(errType)
        setError(error)
        common.toast(error)

        // 停止轮询
        clearTimeout(timer.current)
        timer.current = null

        // 停止倒计时
        savePopNum(120)

        clearTimeout(countdownTimer.current)
        countdownTimer.current = null
    }

    // 清理倒计时的信息
    const clearCountDownInfo = () => {
        // 将错误信息恢复
        setErrorType('')
        setError('')
        savePopNum(120)

        // 清除轮询的API
        clearTimeout(timer.current)
        timer.current = null

        // 清除倒计时
        clearTimeout(countdownTimer.current)
        countdownTimer.current = null
    }

    // 输入框回车事件
    const submit = () => {
        setError('')

        if (inputVal.inputVal === '') {
            common.toast('请填写付款码')
            return
        }

        // 倒计时
        setShowTipsPop(true)

        savePopNum(120)
        countdown()

        let codePayCacheKey = getCodePayCacheKeyForMobilePay() // 移动支付的标记
        setCodeKey(codePayCacheKey)

        // common.loadingStart()

        let loadingStop = () => {
            // common.loadingStop()
        }

        common.ajax('post', '/app.php/member-api/card/code-pay', {
            codeType: wxOrZhi,                    //类型, 10:微信;20:支付宝
            money: shouldPay,                     //支付金额
            code: inputVal.inputVal,              //支付码
            payTypeId: itemId,                    //支付方式ID
            memberId: memberId ? memberId : 0,    //会员ID
            codePayCacheKey: codePayCacheKey,     //支付标记
        }, {
            displayError: false,
            timeout: 15000 // 移动支付这里，等待15秒
        }).then(() => {

            checkCodePay(codePayCacheKey, loadingStop)

        }).catch((err) => {
            if (typeof err.error === "undefined") {
                // 不需要轮询的情况，直接报错
                loadingStop();

                // 打印错误和清除计时器
                printErrAndClearTime("出参有误", 'noPoll')
                return;
            }

            let tempErrorData = err.error

            if ((!tempErrorData.errorCode) || (tempErrorData.errorCode !== 'ing')) {
                // 不需要轮询的情况，直接报错
                loadingStop();

                // 打印错误和清除计时器
                printErrAndClearTime(tempErrorData.error, 'noPoll')
                return;
            }

            // 轮询，检测是否支付成功
            checkCodePay(codePayCacheKey, loadingStop)

        }).finally(() => {
        })
    }

    // 移动支付的支付标记"codePayCacheKey"
    const getCodePayCacheKeyForMobilePay = () => {
        var nowTime = new Date()
        var year = nowTime.getFullYear()
        var month = nowTime.getMonth()
        month = parseInt(month) + month
        month = handleNumberShow(month)
        var day = nowTime.getDay()
        day = handleNumberShow(day)
        var hour = nowTime.getHours()
        hour = handleNumberShow(hour)
        var minute = nowTime.getMinutes()
        minute = handleNumberShow(minute)
        var second = nowTime.getSeconds()
        second = handleNumberShow(second)

        // 门店ID+ 年月日 + 随机4位数 +  + 时分秒
        // 门店ID在接口中处理
        var key = year.toString() + month.toString() + day.toString()
        var rand = random(4)
        key = key.toString() + rand.toString()
        key = key.toString() + hour.toString() + minute.toString() + second.toString()

        return key
    }

    // 处理为最小几位数展示
    const handleNumberShow = (data, number) => {
        data = data.toString()

        if (data.length >= number) {
            return data;
        }

        var temp = number - data.length
        for (var i = 0; i < temp; i++) {
            data = '0' + data
        }

        return data
    }

    // 任意位的随机数
    const random = (number) => {
        var arr = [];
        var arr1 = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

        for (var i = 0; i < number; i++) {
            var n = Math.floor(Math.random() * 10);
            arr[i] = arr1[n];

        }

        var val = arr.join("")
        return val;
    }

    // 关闭提示弹框
    const closeTipsPop = () => {
        clearCountDownInfo() // 清理数据
        setShowTipsPop(false)
    }

    return (
        <div className={style.WeChatPay}>

            {
                showTipsPop &&
                <Modal title=""
                       closable={true}
                       footer={false}
                       onCancel={() => {
                           common.confirm("请确认用户是否付款？", () => {
                               // 未付款，表示要取消支付
                               closeTipsPop()
                           }, () => {
                               // 已经付款，表示取消，不做任何操作
                           }, {
                               'okValue': '否',
                               'cancelValue': '是',
                           })
                       }}>
                    <div className={style.componentsPayWayPop}>
                        <div className={style.popContent}>
                            {errorType === 'noPoll' ? (
                                <div className={style.errorInfo}>
                                    支付失败，原因： {error}
                                </div>
                            ) : errorType === 'pollNoPay' ? (
                                <div className={style.popPollNoPay}
                                     onClick={() => {
                                         setErrorType('')
                                         setError('')

                                         // 开始轮询
                                         checkCodePay(codeKey)

                                         // 倒计时
                                         savePopNum(120)
                                         countdown()
                                     }}>
                                    扣款失败! 如果客户已扣款, 请点击我重新查询付款信息
                                </div>
                            ) : (
                                <div className={style.popNote}>正在扣款, 请不要做其他任何操作!</div>
                            )}

                            {errorType === '' ? (
                                <div className={style.popCountDown}>{popNum}</div>
                            ) : (
                                <div className={style.cancel} onClick={() => {
                                    closeTipsPop()
                                }}>取消支付</div>
                            )}
                        </div>
                    </div>
                </Modal>
            }


            <div className={style.titleDiv}>
                <div className={style.titleFontDiv}>{wxOrZhi === 10 ? '微信' : '支付宝'}付款</div>
                <img src={require('@/images/close.png')} alt="" className={style.titleCloseImg} onClick={closePayWay}/>
            </div>

            <div className={style.showInputContentDiv}>
                <div className={style.showNumberDiv}>{shouldPay}</div>
                <div className={style.showFontDiv}>付款金额</div>
                <div className={style.showInputDiv}>
                    <input ref={inputCode} className={style.showNumberInput} type="text"
                           placeholder="请扫码或者手动输入付款码"
                           value={inputVal.inputVal}
                           onChange={(e) => {
                               inputVal.setInputVal(e.target.value)
                           }}
                           onKeyDown={(e) => {
                               if (e.keyCode === 13) {
                                   submit();
                               }
                           }}/>
                </div>
            </div>

            <div className={style.tipsDiv}>
                <div className={style.tipsItemDiv}>
                    <div className={style.tipsNumDiv}>1</div>
                    <img src={require('@/images/login/code.png')} alt="" className={style.tipsImg}/>
                    <div className={style.tipsFont}>打开支付宝或微信</div>
                    <div className={style.tipsFont}>进入"付款"页面</div>
                </div>

                <div className={style.directionDiv}>
                    <img src={require('@/images/right.png')} alt="" className={style.directionImg}/>
                </div>

                <div className={style.tipsItemDiv}>
                    <div className={style.tipsNumDiv}>2</div>
                    <img src={require('@/images/pos.png')} alt="" className={style.tipsImg}/>
                    <div className={style.tipsFont}>扫描枪扫描支付</div>
                </div>
            </div>

        </div>
    );
}

export default PayWay;