import {createSlice} from '@reduxjs/toolkit'
import business from "../utils/business";
import biz from "../utils/biz";

const NAMESPACE = "handNumber"

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    groupHandIdList: [], // 和选中手牌在一个团体的手牌
  },
  reducers: {
    setGroupHandIdList(state, action) {
      state.groupHandIdList = action.payload ? [...action.payload] : []
    },
  },
  extraReducers: {},
});

export const {
  setGroupHandIdList
} = slice.actions

export const {reducer} = slice;
