import {createSlice} from '@reduxjs/toolkit'
import business from "../utils/business";
import biz from "../utils/biz";
import businessAction from "../utils/businessAction";

const NAMESPACE = "checkoutPayCard"

// 会员卡结账使用
const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    showCheckoutPayCardType: '', // 子弹框
    selectedCardList: [], // 已选的会员卡
    payCardOrderTotalFee: 0, // 订单总金额 --- 会员卡支付页的订单总金额
    payCardOrderDiscount: 0, // 优惠金额 --- 会员卡支付页的
    selectTicketList: [], // 已选的卡券 --- 会员卡

    categoryManageProjectShow: false,
    reGetCardListAction: false, // 需要重新加载会员卡数据

    memberSplitOrderPayInfo: {}, // 拆单时会员支付的信息，用来保存
  },
  reducers: {
    setShowCheckoutPayCardType(state, action) {
      state.showCheckoutPayCardType = action.payload
    },
    setSelectedCardList(state, action) {
      state.selectedCardList = action.payload
      businessAction.checkoutPayCard.selectedCardList = action.payload ? [...action.payload] : []
    },
    setSelectTicketList(state, action) {
      state.selectTicketList = action.payload
      businessAction.checkoutPayCard.card_ref_select_ticket_list = action.payload ? [...action.payload] : []
    },
    setPayCardOrderTotalFee(state, action) {
      state.payCardOrderTotalFee = action.payload
      businessAction.checkoutPayCard.orderTotalFee = action.payload
    },
    setPayCardOrderDiscount(state, action) {
      state.payCardOrderDiscount = action.payload
      businessAction.checkoutPayCard.orderDiscount = action.payload
    },
    setCategoryManageProjectShow(state, action) {
      state.categoryManageProjectShow = action.payload
    },
    setReGetCardListAction(state, action) {
      state.reGetCardListAction = action.payload
    },
    setMemberSplitOrderPayInfo(state, action) {
      state.memberSplitOrderPayInfo = action.payload
    },
  },
  extraReducers: {},
});

export const {
  setShowCheckoutPayCardType,
  setSelectedCardList,
  setSelectTicketList,
  setPayCardOrderTotalFee,
  setPayCardOrderDiscount,
  setCategoryManageProjectShow,
  setReGetCardListAction,
  setMemberSplitOrderPayInfo
} = slice.actions

export const {reducer} = slice;
