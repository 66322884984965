import {createSlice} from '@reduxjs/toolkit'

const NAMESPACE = "member"

// 查找会员使用
const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    memberShowType: '', // 展示弹框
    memberShowMemberId: 0, // 会员弹框刚打开的会员id
    memberShowBackMessage: '', // 会员弹框关掉的message， 用来协助关掉要处理的内容

    initData: {},           // 初始化数据
    memberInitData: {},     // 会员初始化数据
    memberInfo: {},         // 会员信息数据
    cardSum: {},            // 会员卡及卡券总数（各类型会员卡以及卡券对应数量）
    activeTicket: {},       // 正在操作的卡券信息 -- 转赠和延期
    activeCard: {},         // 正在操作的会员卡信息 -- 转赠和延期
  },
  reducers: {
    setInitData(state, action) {
      state.initData = action.payload
    },
    setMemberInitData(state, action) {
      state.memberInitData = action.payload
    },
    setMemberInfo(state, action) {
      state.memberInfo = action.payload
    },
    setCardSum(state, action) {
      state.cardSum = action.payload
    },
    setActiveTicket(state, action) {
      state.activeTicket = action.payload
    }
    ,
    setActiveCard(state, action) {
      state.activeCard = action.payload
    },
    setMemberShowType(state, action) {
      state.memberShowType = action.payload
    },
    setMemberShowMemberId(state, action) {
      state.memberShowMemberId = action.payload
    },
    setMemberShowBackMessage(state, action) {
      state.memberShowBackMessage = action.payload
    },
  },
  extraReducers: {},
});

export const {
  setInitData,
  setMemberInfo,
  setCardSum,
  setActiveTicket,
  setMemberInitData,
  setActiveCard,
  setMemberShowType,
  setMemberShowMemberId,
  setMemberShowBackMessage
} = slice.actions

export const {reducer} = slice;
