import business from "./business";

let voice = {
  //新消息提醒
  'newServeVideoPlay': function () {
    // 根据基础配置决定，是否要用声音提示
    // 不能声音提示，则不动
    if (parseInt(business.pcCallServiceNoticeVoice) !== business.pcCallServiceNoticeVoiceList.yes) {
      return;
    }

    var myVideo = document.getElementById("video-new-serve");

    myVideo.play();
  },
}

export default voice;
