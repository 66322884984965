import React from 'react';
import style from './style.module.less'
import Button from "../../../public/button";

function ConfirmAlert(props) {

    const {onOk, onCancel, toastTitle = '', cancelText = '否', okText = '是'} = props

    return (
        <div className={style.ConfirmAlert}>
            <div className={style.description}>
                <img src={require('@/images/member/toast.png')} className={style.toastImage} alt=""/>
                <div className={style.descriptionText}>
                    {toastTitle}
                    {/*确认作废吗？*/}
                </div>
            </div>
            <div className={style.bottomAction}>
                <Button shape={'default'} type={'cancel'} onClick={onCancel}>{cancelText}</Button>
                <Button shape={'default'} style={{marginLeft: '10px'}} type={'confirm'} onClick={onOk}>{okText}</Button>
            </div>
        </div>
    );
}

export default ConfirmAlert;