import {createSlice} from '@reduxjs/toolkit'

const NAMESPACE = "callInfo"

// 来电信息
const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    callShowType: '', // 展示的信息
    call_data:{
      'mobile': '',
      'name': '',
    }, // 呼叫信息
  },
  reducers: {
    setCallShowType(state, action) {
      state.callShowType = action.payload
    },
    setCallData(state, action) {
      state.call_data = action.payload
    },
  },
  extraReducers: {},
});

export const {
  setCallShowType,
  setCallData
} = slice.actions

export const {reducer} = slice;
