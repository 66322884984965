import React, {useState, useEffect} from 'react';
import style from './style.module.less'
import readUnunited from '../../../../../images/member/read-ununited.png'
import readTips from '../../../../../images/member/read-tips.png'
import common from "../../../../../utils/common";
import readCardUtils from "../../../../../utils/readCardUtils";

function Read(props) {
    const {nav, goRecordHome} = props

    const [haveRead, setHaveRead] = useState(true)            //判断是否读卡成功
    const [readValue, setReadValue] = useState('请将卡片置于读卡器上, 并保证设备连接正常')          //读卡后报错内容

    useEffect(() => {
        if (nav !== 'read') {
            return
        }

        handleReadCard()
    }, [nav])

    //读卡操作
    const handleReadCard = () => {
        readCardUtils.readCardForFind().then((res) => {
            // code: code   seriesnumber: seriesnumber

            common.loadingStart()
            common.ajax('get', '/app.php/member-api/member/find-by-code', {
                code: res.code,
                seriesnumber: res.seriesnumber,
            }).then((result) => {
                goRecordHome(result.id)
            }).catch((err) => {
                setReadValue(err.error)
                setHaveRead(false)
            }).finally(common.loadingStop)

        }).catch((errorData) => {
            setHaveRead(false)
            setReadValue(errorData)
        })

    }


    return (
        <div className={[style.Read, nav !== 'read' && style.noneDiv].join(' ')}>
            {
                !haveRead && <div className={style.content}>
                    <img className={style.readUnunitedImg} src={readUnunited} alt=""/>
                    <div className={style.font}>{readValue}</div>
                    <div className={style.btn} onClick={handleReadCard}>重新读卡</div>
                </div>
            }

            {
                haveRead && <div className={style.content}>
                    <img className={style.readUnunitedImg} src={readTips} alt=""/>
                    <div className={style.font}>请将会员卡放置于读卡器上</div>
                </div>
            }
        </div>
    );
}

export default Read;