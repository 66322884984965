import React, {useState, useRef, useEffect} from 'react';
import style from './style.module.less'
import MemberDetail from "../components/memberDetail";
import DetailModal from "../components/detailModal";
import Button from "../../public/button";
import cardBg from "../../../images/member/card-bg.png";
import {useDispatch, useSelector} from "react-redux";
import common from "../../../utils/common";
import print from "../../../utils/print";
import Modal from "../../public/modal";
import PayWay from "../components/payWay";
import memberUtils from "../../../utils/memberUtils";
import biz from "../../../utils/biz";

function CardRecharge(props) {
    const {setAction, onClose} = props

    const {activeCard, memberInfo} = useSelector(state => state.member)
    const dispatch = useDispatch();

    const cardBgStyle = {
        backgroundImage: "url(" + cardBg + ")",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
    }

    let [employeeGroup, setEmployeeGroup] = useState([])            //选择员工的盒子组
    let [rechargeMessage, setRechargeMessage] = useState({})        //充值信息
    let [categoryInfo, setCategoryInfo] = useState({})              //卡类信息
    let [userInfo, setUserInfo] = useState({})                      //门店信息
    let [employeeList, setEmployeeList] = useState([])              //可提成的员工列表
    let [payTypeList, setPayTypeList] = useState([])                //会员开卡充值支付方式列表
    let [rechargeMoney, setRechargeMoney] = useState('')            //充值金额
    let [realMoney, setRealMoney] = useState('')                    //实收金额
    let [tmpRealMoney, setTmpRealMoney] = useState('')              //临时实收金额
    let [price, setPrice] = useState('')                            //卡类单价
    let [overMoney, setOverMoney] = useState('')                    //充值后的余额
    let [last, setLast] = useState('')                              //分配剩余金额
    let [consumeCount, setConsumeCount] = useState('')              //消费次数
    let [overConsumeCount, setOverConsumeCount] = useState('')      //充值后的次数
    let [employeeId, setEmployeeId] = useState('0')                  //选择的员工id

    //右边的选卡支付
    let [payLastList, setPayLastList] = useState([])                //付费剩余金额
    let [lastPay, setLastPay] = useState('')                        //还需支付
    let [payList, setPayList] = useState([])                        //支付方式(要提交的数据)
    let [memberPayTypeGroup, setMemberPayTypeGroup] = useState('')  //选择的会员卡充值支付方式 (储值卡)
    let [cardTimesGroup, setCardTimesGroup] = useState('')          //选择的会员卡充值支付方式 (计次卡)

    let [wxOrZhi, setWxOrZhi] = useState(0)                         //支付方式 判断点击的是微信还是支付宝, 10:微信, 20:支付宝
    let [payWayContent, setPayWayContent] = useState(null)          //弹窗div
    let [inputVal, setInputVal] = useState('')                      //输入的条码
    let [payTypeListKey, setPayTypeListKey] = useState('')          //会员开卡充值支付方式列表的key值
    let [itemId, setItemId] = useState('')                          //传给移动之后的id
    let [isFocus, setIsFocus] = useState(false)                     //移动支付的input框自动获取焦点
    let isSubmit = useRef(false)                                    //是否提交
    let [checked, setChecked] = useState(10)                        //10:付款码, 20:手机订单号, 30:后台支付码,
    let [payWayError, setPayWayError] = useState('');

    let [storedGroup, setStoredGroup] = useState([]);               //会员卡支付方式里的储值卡付费提交数组
    let [countGroup, setCountGroup] = useState([]);                 //会员卡支付方式里的计次卡付费提交数组
    let [storedCard, setStoredCard] = useState([]);                       //会员卡支付方式里的储值卡数据
    let [countCard, setCountCard] = useState([]);                         //会员卡支付方式里的计次卡数据

    let initSelectTiket = {
        'value': '0',
        'num': '0',
    }
    let [initSelectTicketList, setInitSelectTicketList] = useState([])                  //初始的选择卡券
    let [selectTicketList, setSelectTicketList] = useState([initSelectTiket])           //选择的卡券
    let [ticketList, setTicketList] = useState([])                                      //所有卡券列表
    let [storageTicketList, setStorageTicketList] = useState([])                        //存储的卡券数据

    const [showPayWait, setShowPayWait] = useState(false)                   //展示微信或支付宝弹框

    const [expireTicketIds, setExpireTicketIds] = useState([])                          // 过期卡券或没余量的卡券IDs
    const [expireTicketList, setExpireTicketList] = useState([])                        // 过期卡券或没余量的卡券数据
    const [expireTicketInfoList, setExpireTicketInfoList] = useState({})                // 已选过期卡券信息

    // 返回页面
    const goBackAction = () => {
        switch (activeCard.card_category_type) {
            case 2: // 储值卡
                setAction('storeValue')
                break
            case 3: // 计次卡
                setAction('order')
                break
            case 5: // 专享卡
                setAction('limitProject')
                break
            default:
                break
        }
    }

    // 获取选中菜单
    const getNav = () => {
        switch (activeCard.card_category_type) {
            case 2: // 储值卡
                return 'storeValue'
            case 3: // 计次卡
                return 'order'
            case 5: // 专享卡
                return 'limitProject'
            default:
                return ''
        }
    }

    useEffect(() => {
        common.loadingStart()
        Promise.all([
            getEmployeeList(),      // 获取可提成的员工列表
            getRecharge(),          // 获取充值信息, 获取会员开卡充值支付方式
            getPayType(),           // 获取会员开卡充值支付方式
            getTicketList(),        // 获取卡券列表
        ]).finally(() => {
            common.loadingStop()
        })
    }, [])

    //根据实收金额 与 卡类单价的比较，对应展示卡券
    useEffect(() => {
        if (Number(tmpRealMoney) < Number(price)) {
            //实收金额 低于 单价
            //将默认卡券清空
            setStorageTicketList([])
        } else {
            //没有选择卡券 或 选择的卡券为默认展示数据 ，则将默认赠送卡券数据重新赋值
            if (selectTicketList.length < 1 || (selectTicketList.length === 1 && selectTicketList[0].value === '0')) {
                setStorageTicketList([...initSelectTicketList])
            }
        }
    }, [tmpRealMoney])

    //根据卡类的默认卡券，放入选中的卡券
    useEffect(() => {
        if (storageTicketList.length > 0) {
            let data = []
            let ticketIds = []
            let expireTicketList = []
            for (let i = 0; i < storageTicketList.length; i++) {
                let item = storageTicketList[i]

                let isExistBool = false;    //卡类赠送的卡券是否存在卡券列表中
                for (let j = 0; j < ticketList.length; j++) {
                    if (item.ticket_id == ticketList[j].id) {
                        isExistBool = true;
                        break
                    }
                }

                //卡券列表不存在，则放入卡券列表
                if (!isExistBool) {
                    ticketList.push({
                        'id': (item.ticket_id).toString(),
                        'title': item.ticket_title
                    })
                }

                if (!item.is_unexpired) { // 过期或无余量的卡券
                    expireTicketList[item.ticket_id] = item
                    ticketIds.push(item.ticket_id)
                }

                data.push({
                    "value": (item.ticket_id).toString(),
                    "num": item.quantity
                })
            }

            if (data.length < 1) {
                data = [initSelectTiket]    //卡类赠送的卡券全部不在卡券列表中，展示默认空值
            }

            setExpireTicketList(expireTicketList)
            setExpireTicketIds(ticketIds)

            setSelectTicketList(data)
        } else {
            setExpireTicketList([])
            setExpireTicketIds([])
            setSelectTicketList([initSelectTiket])
        }
    }, [storageTicketList, ticketList])

    //获取充值信息
    let getRecharge = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/card/get-recharge', {
                member_id: memberInfo.id,
                card_id: activeCard.id,
            }).then((res) => {
                setRechargeMessage(res)
                setCategoryInfo(res.category_info)
                setUserInfo(res.user_info)

                setRechargeMoney(paddZero(floatAdd(res.category_info.price, res.category_info.donation_amount)))
                setPrice(paddZero(res.category_info.price))
                setRealMoney(paddZero(res.category_info.price))
                setTmpRealMoney(paddZero(res.category_info.price))
                setOverMoney(paddZero(
                    floatAdd(floatAdd(res.category_info.price, res.category_info.donation_amount), res.money)
                ))
                setOverConsumeCount(paddZero(
                    floatAdd(res.category_info.consume_count, res.consume_times)
                ))
                setConsumeCount(res.category_info.consume_count)
                setLastPay(paddZero(res.category_info.price))
                setLast(paddZero(res.category_info.price))

                getTicketListByCardCategory(res.category_info.id)    // 获取卡类默认派发的卡券数据

                resolve()
            }).catch((err) => {
                reject()
            })
        })
    }

    //获取可提成的员工列表
    let getEmployeeList = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/employee/recharge-employee-list').then((res) => {
                setEmployeeList(res)
                resolve()
            }).catch((err) => {
                reject()
            })
        })
    }

    //获取卡券列表
    let getTicketList = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/ticket').then((res) => {
                setTicketList(res)
                resolve(res)
            }).catch((err) => {
                reject()
            })
        })
    }

    //获取卡类默认派发的卡券数据
    let getTicketListByCardCategory = (categoryId) => {
        common.ajax('get', '/app.php/member-api/ticket/list-for-card', {card_category_id: categoryId}).then((res) => {
            setInitSelectTicketList(res)
            setStorageTicketList(res)
        })
    }

    //获取会员开卡充值支付方式
    let getPayType = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/pay-type/recharge-v2', {member_id: memberInfo.id}).then((res) => {
                setPayTypeList(res)
                let arr = []
                res.forEach((item) => {
                    if (item.tag === 2) {
                        arr = arr.concat(item.card_list)
                    }
                })

                //储值卡
                let storedArr = []
                //计次卡
                let countArr = []

                arr.forEach((item) => {
                    if (item.type === 1) {
                        storedArr.push(item)
                    } else if (item.type === 2) {
                        countArr.push(item)
                    }
                })

                setStoredCard(JSON.parse(JSON.stringify(storedArr)))
                setCountCard(JSON.parse(JSON.stringify(countArr)))
                resolve()
            }).catch((err) => {
                reject()
            })
        })
    }

    //补零
    let paddZero = (val) => {
        if (val === '') {
            return '0.00'
        }
        val = String(val)
        if (val.indexOf('.') !== -1) {
            if (val.substring(val.indexOf('.') + 1).length < 2) {
                val += '0'
            }
        } else {
            val += '.00'
        }
        return val
    }

    //减
    let floatSub = (arg1, arg2) => {
        var r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        //动态控制精度长度
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    }

    //加
    let floatAdd = (arg1, arg2) => {
        var r1, r2, m;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        return (arg1 * m + arg2 * m) / m;
    }

    //计算分配剩余金额
    let getLastValue = () => {
        let sum = 0
        employeeGroup.forEach((item) => {
            sum += Number(item.money)
        })
        sum = floatSub(realMoney, sum)
        setLast(sum)
    }

    // 银行卡总支付金额
    let getPayLastListNum = () => {
        let sum = 0
        if (payLastList.length > 0) {
            payLastList.forEach((item) => {
                sum += Number(item)
            })
        }
        return sum
    }

    // 计算新增的储值卡的总金额
    let getStoredGroupNum = () => {
        let sum = 0
        if (storedGroup.length > 0) {
            storedGroup.forEach((item) => {
                sum += Number(item.money)
            })
        }
        return sum
    }

    // 计算新增的计次卡的总金额
    let getTimesGroupNum = () => {
        let sum = 0
        if (countGroup.length > 0) {
            countGroup.forEach((item) => {
                sum += Number(item.money)
            })
        }
        return sum
    }

    // 储值卡正输入的金额
    let getStoredMoney = () => {
        let sum = 0
        if (memberPayTypeGroup) {
            sum = +memberPayTypeGroup.money
        }
        return sum
    }

    // 计次卡正输入的金额
    let getTimesMoney = () => {
        let sum = 0
        if (cardTimesGroup) {
            sum = +cardTimesGroup.money
        }
        return sum
    }

    //去充值
    let handleSubmit = () => {

        if (Number(lastPay) !== 0) {
            common.toast('支付费用不正确')
            return
        }

        let expireTicketInfoList = []
        for (let i = 0; i < selectTicketList.length; i++) {
            let item = selectTicketList[i]
            if (item.value == 0) {
                continue //跳过默认值
            }

            if (item.num < 1) {
                common.toast('卡券数量不能小于1')
                return;
            }

            // 要进行确认提示 且 存在过期卡券，则进行提示
            if (expireTicketIds.indexOf(Number(item.value)) !== -1) {
                if (expireTicketList[item.value] !== undefined) {
                    let infoItem = expireTicketList[item.value]

                    // end_timestamp到期日期时间戳，非固定日期区间时 为空。过期了则进行提示
                    if (infoItem.end_timestamp.toString().length > 0 && infoItem.end_timestamp < biz.getNowTime()) {
                        infoItem.dateInfo = biz.handleTime(infoItem.end_timestamp)
                        expireTicketInfoList.push(infoItem)
                    }
                }
            }
        }

        // 有过期卡券
        if (expireTicketInfoList.length > 0) {
            setExpireTicketInfoList(expireTicketInfoList)
            return;
        }

        submit()
    }

    const submit = () => {
        let payListArr = []
        if (payList.length > 0) {
            payList.forEach((item) => {
                if (item) {
                    if (item.money) {
                        payListArr.push(item)
                    }
                }
            })
        }

        // 获取提成人员
        let employeeArr = getEmployee()
        if (!employeeArr) {
            return
        }

        // 储值卡要提交的值
        let storeArr = []
        storedGroup.forEach((item) => {
            storeArr.push(item)
        })

        // 计次卡要提交的值
        let countArr = []
        countGroup.forEach((item) => {
            countArr.push(item)
        })

        // 储值卡输入框有值, 并且不为0就存
        if (memberPayTypeGroup) {
            storeArr.push(memberPayTypeGroup)
        }

        // 计次卡输入框有值, 并且不为0就存
        if (cardTimesGroup) {
            countArr.push(cardTimesGroup)
        }
        let newCountArr = []
        countArr.forEach((item) => {
            newCountArr.push({card_id: item.card_id})
        })

        let tmpTicketList = []
        for (let i = 0; i < selectTicketList.length; i++) {
            let item = selectTicketList[i]
            if (item.value == 0) {
                continue //跳过默认值
            }

            if (item.num < 1) {
                common.toast('卡券数量不能小于1')
                return;
            }

            tmpTicketList.push({
                'id': item.value,
                'quantity': item.num,
            })
        }

        let data = {
            member_id: memberInfo.id,                                     //会员id
            card_id: activeCard.id,                                         //会员卡id
            data: JSON.stringify({
                commission_list: employeeArr,                        //提成人员信息
                card_data: {                                         //会员卡信息
                    validity_end: rechargeMessage.valid_end_date,    //'会员卡截止日期', 格式为"年-月-日"(如：2019-09-09)
                },
                recharge_data: {                                     //会员卡充值数据
                    money: rechargeMoney,                            //充入卡金额
                    consume_times: consumeCount,                     //'消费次数', 当 会员卡的卡类 类型为"3"时，页面传入
                },
                card_pay_list: storeArr,                             //会员卡支付方式（储值卡）
                card_times_list: newCountArr,                        //会员卡支付方式（计次卡）
                pay_list: payListArr,                                //支付方式
                ticket_list: tmpTicketList,                          //赠送卡券
            })
        }

        common.loadingStart()
        common.ajax('post', '/app.php/member-api/card/recharge-v2', data).then((res) => {
            memberUtils.fetchMemberInfo(memberInfo.id)

            common.toast(res.message)
            if (res.need_print === 1) {
                common.ajax('post', '/app.php/member-api/card/recharge-print', {
                    rechargeId: res.rechargeId
                }).then((result) => {
                    if (result.need_print === 1) {
                        print.print(result.printData, 'printWidth=' + print.printWidth, result.printNum)
                    }
                })
            }
            goBackAction()
        }).finally(common.loadingStop)
    }

    // 获取提成人员
    let getEmployee = () => {
        // employeeGroup: 是+号下的数据
        let employeeArr = employeeGroup
        let newEmployeeArr = []

        // 如果选择的分配员工和分配金额都有的话, 就加上目前选择的
        if (Number(last) && Number(employeeId)) {
            newEmployeeArr = [{employee_id: employeeId, money: last}]
            employeeArr = employeeArr.concat(newEmployeeArr)
        }

        // 如果实收金额大于0 并且 接口返回一定要分配的话则提成人员必须要有数据
        if (Number(realMoney) > 0) {
            if (rechargeMessage.card_recharge_check === 10) {
                if (employeeArr.length < 1) {
                    common.toast('提成人员不能为空')
                    return false
                }
            }
        }
        return employeeArr
    }

    // 计算还需付款金额
    let getLastPay = (val) => {

        // 银行卡总支付金额
        let sum = getPayLastListNum()

        // 储值卡新增支付的总金额
        let cardSum = getStoredGroupNum()

        // 储值卡正输入的金额
        let storedSum = getStoredMoney()

        // 计次卡新增支付的总金额
        let timesSum = getTimesGroupNum()

        // 计次卡正在输入的金额
        let timesKeepSum = getTimesMoney()

        // 支付金额全部相加
        let paySum = floatAdd(floatAdd(floatAdd(floatAdd(cardSum, sum), timesSum), timesKeepSum), storedSum)
        setLastPay(paddZero(floatSub(val, paySum)))
    }

    //输入规则判断, 保留两位小数
    let inputIsTrue = (val) => {
        if (val) {
            val = String(val)
            if (val.indexOf('.') !== -1) {
                if (val.substring(val.indexOf('.') + 1).length > 2) {
                    return false
                }
            }
        }
        return true
    }

    // 获取分配人员可分配的金额
    let getEmployeeAllocable = () => {
        let sum = 0

        if (employeeGroup.length > 0) {
            employeeGroup.forEach((item) => {
                sum += Number(item.money)
            })
            sum = floatSub(realMoney, sum)
        } else {
            sum = realMoney
        }
        if (sum <= 0) {
            return 0
        } else {
            return sum
        }
    }

    //选择卡券列表
    const showSelectTicketDiv = () => {
        // 可以修改
        if (rechargeMessage.can_update_ticket == 1) {
            return (
                selectTicketList.map((item, key) =>
                    <div key={key} className={style.consumeItem}>
                        <div className={style.select}>
                            <select value={item.value} onChange={(e) => {
                                selectTicketList[key].value = (e.target.value).toString()
                                if (selectTicketList[key].num == 0) {
                                    selectTicketList[key].num = 1
                                }
                                setSelectTicketList([...selectTicketList])

                            }}>
                                <option value="0">请选择卡券</option>
                                {ticketList.map((item, key) => {
                                    return (
                                        <option key={key} className={expireTicketIds.indexOf(Number(item.id)) !== -1 && style.optionHide} value={item.id} key={key}>{item.title}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className={style.rightAction}>
                            <div className={style.numberBox + ' ' + style.numberInputBox}>
                                <input className={style.number}
                                       value={item.num}
                                       onChange={(e) => {
                                           let val = e.target.value
                                           if (!inputIsTrue(val)) {
                                               return
                                           }

                                           selectTicketList[key].num = val
                                           setSelectTicketList([...selectTicketList])
                                       }}/>
                            </div>
                        </div>

                        {
                            selectTicketList[0].value !== "0" &&
                            <div className={style.closeBox}>
                                <img src={require('@/images/member/close.png')} className={style.closeImage}
                                     onClick={() => {
                                         let arr = selectTicketList
                                         if (arr.length <= 1) {
                                             setSelectTicketList([initSelectTiket])
                                             return
                                         }
                                         arr.splice(key, 1)
                                         setSelectTicketList([...arr])
                                     }}/>
                            </div>
                        }
                    </div>
                )
            )
        }

        // 不可以修改
        if (rechargeMessage.can_update_ticket == 2) {
            let tmpSelectTicketList = []
            for (let i = 0; i < selectTicketList.length; i++) {
                let item = selectTicketList[i]
                if (item.value < 1) {
                    continue
                }

                tmpSelectTicketList.push(item)
            }

            return (
                tmpSelectTicketList.map((item, key) =>
                    <div key={key} className={style.consumeItem}>
                        <div className={style.select}>
                            <select disabled="disabled" value={item.value}>
                                {ticketList.map((item2, key2) => {
                                    return (
                                        item.value == item2.id ?
                                            <option value={item2.id} key={key2}>{item2.title}</option> : null
                                    )
                                })}
                            </select>
                        </div>
                        <div className={style.rightAction}>
                            <div className={style.numberBox + ' ' + style.numberInputBox}>
                                <input className={style.number}
                                       value={item.num}
                                       disabled="disabled"
                                />
                            </div>
                        </div>
                    </div>
                )
            )
        }

        return ''
    }

    const closePayWay = () => {
        if (isSubmit.current) {
            let sum = 0
            payLastList.forEach((item) => {
                sum += Number(item)
            })
            if (memberPayTypeGroup) {
                sum += Number(memberPayTypeGroup.money)
            }
            lastPay = paddZero(floatSub(realMoney, sum))
            setLastPay(JSON.parse(JSON.stringify(lastPay)))
            handleSubmit()
        }

        setShowPayWait(false)
    }

    return (
        <div className={style.CardRecharge}>
            {
                expireTicketInfoList.length > 0 && <Modal
                    closable={false}
                    footer={
                        <div className={style.tipsBox}>
                            <Button type={'cancel'} shape={'default'}
                                    onClick={() => {
                                        setExpireTicketInfoList([])
                                    }}>取消</Button>
                            <Button style={{marginLeft: '10px'}}
                                    type={'confirm'}
                                    shape={'default'}
                                    onClick={() => {
                                        submit()
                                    }}>确定</Button>
                        </div>
                    }
                >
                    <div className={style.expireBox}>
                        <div className={style.title}>发放的电子券已过期</div>
                        <div className={style.content}>
                            {
                                expireTicketInfoList.map((expireTicketInfo, key) => {
                                    return (
                                        <span key={key}>"{expireTicketInfo.ticket_title}"的有效期至{expireTicketInfo.dateInfo.year+'-'+expireTicketInfo.dateInfo.month+'-'+expireTicketInfo.dateInfo.day}，</span>
                                    )
                                })
                            }
                            目前已过期，无法正常使用，您确认要发放吗？</div>
                    </div>

                </Modal>
            }

            {
                showPayWait && <Modal title=""
                                      closable={false}
                                      footer={false}>
                    <PayWay
                        shouldPay={lastPay}
                        wxOrZhi={wxOrZhi}
                        memberId={memberInfo.id}

                        payLastList={{payLastList: payLastList, setPayLastList: setPayLastList}}
                        payTypeListKey={payTypeListKey}
                        payList={{payList: payList, setPayList: setPayList}}
                        itemId={itemId}

                        closePayWay={closePayWay}
                        inputVal={{inputVal: inputVal, setInputVal: setInputVal}}
                        isFocus={{isFocus: isFocus, setIsFocus: setIsFocus}}
                        checked={{checked: checked, setChecked: setChecked}}
                        isSubmit={isSubmit} error={payWayError} setError={setPayWayError}
                    />
                </Modal>
            }

            <MemberDetail nav={getNav()} setAction={setAction}/>
            <DetailModal closable={true} title={'会员卡充值'} onBack={() => goBackAction()} footer={
                <div className={style.buttonBox}>
                    <Button type={'cancel'} shape={'default'}
                            onClick={() => {
                                goBackAction()
                            }}>取消</Button>
                    <Button style={{marginLeft: '10px'}}
                            type={'confirm'}
                            shape={'default'}
                            onClick={() => {
                                handleSubmit()
                            }}>确定</Button>
                </div>
            } onCancel={onClose}>
                <div className={style.contentBox}>
                    <div className={style.leftBox}>
                        <div className={style.commonWidth}>
                            <div className={style.cardDiv} style={cardBgStyle}>
                                <div className={style.topCardDiv}>
                                    <div className={style.cardName}>{categoryInfo.name}</div>
                                    <div className={style.cardShop}>{userInfo.name}</div>
                                </div>

                                <div className={style.cardRow}>{rechargeMessage.number}</div>
                                <div className={style.cardRow}>{categoryInfo.type === 3 ? '剩余: ' : '余额: '}
                                    <span>
                                            {categoryInfo.type === 3 ? rechargeMessage.consume_times + '次' : '￥' + (rechargeMessage.money || '')}
                                            </span></div>

                                <div className={style.bottomCardDiv}>
                                    {categoryInfo.type === 3 ?
                                        <div className={style.shop}>充值后剩余次数<span> {overConsumeCount}次</span>
                                        </div> :
                                        <div className={style.shop}>充值后余额 <span>￥{overMoney}</span></div>}
                                </div>
                            </div>

                            <div className={style.consumeItem}>
                                <div className={style.label}>
                                    充值金额
                                </div>
                                <div className={style.consumeBox}>
                                    <input className={style.price}
                                           type={'number'}
                                           value={rechargeMoney}
                                           onChange={(e) => {
                                               let val = e.target.value
                                               if (!inputIsTrue(val)) {
                                                   return
                                               }

                                               val = String(Number(val))
                                               setRechargeMoney(e.target.value)

                                               // 实收的规则, 如: 冲 1000 送 500 的规则
                                               let realVal = ''
                                               let num = Number(val)

                                               // donation 是赠送金额 + 单价
                                               let donation = Number(floatAdd(categoryInfo.price, categoryInfo.donation_amount))
                                               if ((+categoryInfo.donation_amount !== 0) && (donation !== 0)) {
                                                   if (num < donation) {
                                                       realVal = val
                                                   } else {
                                                       realVal = floatSub(num, parseInt(num / donation) * Number(categoryInfo.donation_amount))
                                                   }
                                               } else {
                                                   // 没有赠送金额, 实收就是充值金额
                                                   realVal = num
                                               }

                                               setRealMoney(realVal)

                                               let sum = floatAdd(val, rechargeMessage.money)
                                               setOverMoney(sum)
                                               getLastPay(realVal)
                                           }}
                                           onBlur={() => {
                                               let rechargeVal = paddZero(Number(rechargeMoney))
                                               let realVal = paddZero(Number(realMoney))
                                               let overVal = paddZero(Number(overMoney))
                                               setRechargeMoney(rechargeVal)
                                               setRealMoney(realVal)
                                               setTmpRealMoney(realVal)
                                               setOverMoney(overVal)

                                               // 获取分配人员可分配的金额
                                               let employeeAllocable = getEmployeeAllocable()
                                               setLast(employeeAllocable)
                                           }}/>
                                </div>
                            </div>

                            <div className={style.consumeItem}>
                                <div className={style.label}>
                                    实收金额
                                </div>
                                <div className={style.consumeBox}>
                                    <input className={style.price}
                                           type={'number'}
                                           value={realMoney}
                                           onChange={(e) => {
                                               let val = e.target.value
                                               if (!inputIsTrue(val)) {
                                                   return
                                               }
                                               val = String(Number(val))

                                               setRealMoney(e.target.value)
                                               getLastPay(val)
                                           }}
                                           onBlur={() => {
                                               setRealMoney(paddZero(Number(realMoney)))
                                               setTmpRealMoney(paddZero(Number(realMoney)))
                                               // 获取分配人员可分配的金额
                                               let employeeAllocable = getEmployeeAllocable()
                                               setLast(employeeAllocable)
                                           }}/>
                                </div>
                            </div>

                            {
                                categoryInfo.type === 3 && <div className={style.consumeItem}>
                                    <div className={style.label}>
                                        消费次数
                                    </div>
                                    <div className={style.consumeBox}>
                                        <input className={style.price}
                                               type={'number'}
                                               value={consumeCount}
                                               onChange={(e) => {
                                                   let val = e.target.value
                                                   if (!inputIsTrue(val)) {
                                                       return
                                                   }
                                                   setConsumeCount(val)
                                                   let overConsumeCountValue = floatAdd(val, rechargeMessage.consume_times)
                                                   setOverConsumeCount(overConsumeCountValue)
                                               }}
                                               onBlur={(e) => {
                                                   let val = e.target.value
                                                   setConsumeCount(String((Number(val))))
                                               }}/>
                                    </div>
                                </div>
                            }

                            <div className={style.sellEmployeeBox}>

                                {
                                    employeeGroup.map((list, key) =>
                                        <div key={key} className={style.consumeItem}>
                                            <div className={style.select}>
                                                <select value={list.employee_id}
                                                        onChange={(e) => {
                                                            let arr = employeeGroup
                                                            arr[key].employee_id = e.target.value
                                                            setEmployeeGroup(JSON.parse(JSON.stringify(arr)))
                                                        }}>
                                                    <option value="0">
                                                        请选择销售人员
                                                    </option>
                                                    {employeeList.map((item, key2) => {
                                                        return (
                                                            <option key={key + ':' + key2} value={item.employee_id}
                                                            >{item.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className={style.rightAction}>
                                                <div className={style.numberBox + ' ' + style.numberInputBox}>
                                                    <input className={style.number}
                                                           value={employeeGroup[key].money}
                                                           onChange={(e) => {
                                                               let val = e.target.value
                                                               if (!inputIsTrue(val)) {
                                                                   return
                                                               }
                                                               let arr = employeeGroup
                                                               arr[key].money = val
                                                               setEmployeeGroup(JSON.parse(JSON.stringify(arr)))
                                                               getLastValue()
                                                           }}
                                                           onBlur={() => {
                                                               let arr = employeeGroup
                                                               arr[key].money = paddZero(Number(arr[key].money))
                                                               setEmployeeGroup(JSON.parse(JSON.stringify(arr)))
                                                           }}/>
                                                </div>
                                            </div>
                                            <div className={style.closeBox}>
                                                <img src={require('@/images/member/close.png')}
                                                     className={style.closeImage}
                                                     onClick={() => {
                                                         let arr = employeeGroup
                                                         arr.splice(key, 1)
                                                         setEmployeeGroup(JSON.parse(JSON.stringify(arr)))
                                                         getLastValue()
                                                     }}/>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>


                            <div className={style.consumeItem + ' ' + style.selectEmployee}>
                                <div className={style.select}>
                                    <select
                                        value={employeeId}
                                        onChange={(e) => {
                                            setEmployeeId(e.target.value)
                                        }}
                                    >
                                        <option value="0">请选择销售人员</option>
                                        {
                                            employeeList.map((item, key) =>
                                                <option key={key} value={item.employee_id}>{item.name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className={style.rightAction}>
                                    <div className={style.numberBox + ' ' + style.numberInputBox}>
                                        <input className={style.number}
                                               value={last}
                                               onChange={(e) => {
                                                   let val = e.target.value
                                                   if (!inputIsTrue(val)) {
                                                       return
                                                   }
                                                   setLast(val)
                                               }}
                                               onFocus={() => {
                                                   let sum = getEmployeeAllocable()
                                                   setLast(sum)
                                               }}
                                               onBlur={(e) => {
                                                   let val = e.target.value
                                                   setLast(paddZero(Number(val)))
                                               }}/>
                                    </div>
                                </div>
                            </div>

                            <div className={style.addSellEmployee} onClick={() => {
                                if (!employeeId || employeeId === '0') {
                                    common.toast('请选择销售人员')
                                    return
                                }

                                if (!Number(last)) {
                                    common.toast('请输入分配金额')
                                    return
                                }

                                if (Number(last) < 0) {
                                    common.toast('分配金额不能为负数')
                                    return
                                }

                                let arr = employeeGroup.concat([{
                                    employee_id: employeeId,
                                    money: paddZero(last)
                                }])

                                let newNum = 0
                                arr.forEach((item) => {
                                    newNum += Number(item.money)
                                })
                                if (Number(realMoney) < newNum) {
                                    common.alert('分配金额不能大于实收金额')
                                    return
                                }
                                setLast(floatSub(realMoney, newNum))
                                setEmployeeGroup(arr)
                                setEmployeeId('0')

                                // setTimeout(() => {
                                //     leftBox.current.scrollTop += 49
                                // }, 10)
                            }}>
                                <div className={style.add}>+</div>
                                添加销售人员
                            </div>

                            {/*可以修改或有卡类默认的赠送卡券时，展示提示语*/}
                            {
                                (rechargeMessage.can_update_ticket == 1 || (selectTicketList.length > 0 && selectTicketList[0].value > 0)) &&
                                <div className={style.toastItem}>
                                    <img src={require('@/images/member/state.png')} className={style.toastImage}
                                         alt=""/>
                                    以下为充值自动赠送的电子券，请核对后操作
                                </div>
                            }

                            <div className={style.sellEmployeeBox}>
                                {
                                    showSelectTicketDiv()
                                }
                            </div>

                            {
                                (rechargeMessage.can_update_ticket == 1 && (selectTicketList.length > 0 && selectTicketList[0].value > 0)) &&
                                <div className={style.addSellEmployee}
                                     style={{marginTop: '10px'}}
                                     onClick={() => {
                                         selectTicketList.push(initSelectTiket)
                                         setSelectTicketList([...selectTicketList])
                                     }}>
                                    <div className={style.add}>+</div>
                                    添加卡券
                                </div>
                            }
                        </div>
                    </div>
                    <div className={style.checkoutList}>
                        {
                            payTypeList.map((item, key) => {

                                if (item.tag === 5 || item.tag === 6) {
                                    return (
                                        <div key={key} className={style.payList} onClick={() => {
                                            if (Number(lastPay) > 0) {
                                                // 获取提成人员
                                                let employeeArr = getEmployee()
                                                if (!employeeArr) {
                                                    return
                                                }

                                                if (item.tag === 5) {
                                                    setWxOrZhi(10)
                                                } else {
                                                    setWxOrZhi(20)
                                                }
                                                setInputVal('')
                                                setPayTypeListKey(key)
                                                setItemId(item.id)
                                                setIsFocus(true)
                                                setChecked(10)
                                                isSubmit.current = false
                                                setPayWayError('')
                                                setShowPayWait(true)
                                            } else {
                                                common.toast('还需支付金额不大于0')
                                            }
                                        }}>
                                            <div className={style.label}>
                                                {item.name}
                                            </div>
                                            <div className={style.priceBox}>
                                                <input style={{pointerEvents: "none"}} className={style.price}
                                                       disabled={true}
                                                       value={payLastList[key] || ''}/>
                                            </div>
                                        </div>
                                    )
                                } else if (item.tag === 2) {
                                    return ""
                                } else {
                                    return (
                                        <div key={key} className={style.payList}>
                                            <label className={style.label} htmlFor={"label" + key}>
                                                {item.name}
                                            </label>
                                            <div className={style.priceBox}>
                                                <input id={"label" + key} className={style.price}
                                                       value={payLastList[key] || ''}
                                                       onChange={(e) => {
                                                           if (payList[key]) {
                                                               let val = e.target.value
                                                               if (!inputIsTrue(val)) {
                                                                   return
                                                               }
                                                               val = String(Number(val))

                                                               let arr = payLastList
                                                               arr[key] = e.target.value
                                                               setPayLastList(JSON.parse(JSON.stringify(arr)))

                                                               // 银行卡总支付金额
                                                               let sum = getPayLastListNum()

                                                               // 储值卡新增支付的总金额
                                                               let cardSum = getStoredGroupNum()

                                                               // 储值卡正输入的金额
                                                               let storedSum = getStoredMoney()

                                                               // 计次卡新增支付的总金额
                                                               let timesSum = getTimesGroupNum()

                                                               // 计次卡正在输入的金额
                                                               let timesKeepSum = getTimesMoney()

                                                               // 支付金额全部相加
                                                               let paySum = floatAdd(floatAdd(floatAdd(floatAdd(cardSum, sum), timesSum), timesKeepSum), storedSum)

                                                               setLastPay(paddZero(floatSub(realMoney, paySum)))

                                                               payList[key].money = val
                                                               setPayList(JSON.parse(JSON.stringify(payList)))
                                                           }
                                                       }}
                                                       onFocus={() => {
                                                           let realVal = Number(realMoney)
                                                           if (realVal) {
                                                               if (!payLastList[key] || payLastList[key] === '0.00') {
                                                                   let arr = payLastList

                                                                   // 银行卡总支付金额
                                                                   let sum = getPayLastListNum()

                                                                   // 储值卡新增支付的总金额
                                                                   let cardSum = getStoredGroupNum()

                                                                   // 储值卡正输入的金额
                                                                   let storedSum = getStoredMoney()

                                                                   // 计次卡新增支付的总金额
                                                                   let timesSum = getTimesGroupNum()

                                                                   // 计次卡正在输入的金额
                                                                   let timesKeepSum = getTimesMoney()

                                                                   // 支付金额全部相加
                                                                   let paySum = floatAdd(floatAdd(floatAdd(floatAdd(sum, cardSum), storedSum), timesSum), timesKeepSum)

                                                                   if (realVal != paySum) {
                                                                       setLastPay(floatSub(realMoney, floatAdd(floatSub(realVal, paySum), paySum)))
                                                                       arr[key] = floatSub(realVal, paySum)
                                                                       setPayLastList(JSON.parse(JSON.stringify(arr)))
                                                                       payList[key] = {
                                                                           id: item.id,
                                                                           money: floatSub(realVal, paySum),
                                                                           mobile_pay_code: '',
                                                                       }
                                                                       setPayList(JSON.parse(JSON.stringify(payList)))
                                                                   } else {
                                                                       payList[key] = {
                                                                           id: item.id,
                                                                           money: '',
                                                                           mobile_pay_code: '',
                                                                       }
                                                                       setPayList(JSON.parse(JSON.stringify(payList)))
                                                                   }
                                                               }
                                                           }
                                                       }}
                                                       onBlur={() => {
                                                           if (payLastList[key]) {
                                                               let arr = payLastList
                                                               arr[key] = paddZero(Number(arr[key]))
                                                               setPayLastList(JSON.parse(JSON.stringify(arr)))
                                                           }
                                                       }}/>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }

                        {
                            storedCard.length > 0 &&
                            <div className={style.checkcardList}>
                                <div className={style.checkCardItem}>
                                    <div className={style.label}>储值卡</div>
                                    <div className={style.add} onClick={() => {
                                        // 没选储值卡不让新增一条
                                        if (!memberPayTypeGroup) {
                                            common.toast('请选择储值卡')
                                            return
                                        }

                                        // 选了储值卡金额为0不让新增一条
                                        if (memberPayTypeGroup.money === '0.00' || memberPayTypeGroup.money === '0') {
                                            common.toast('储值卡金额不能为0')
                                            return
                                        }

                                        // 选了储值卡不填金额不让新增一条
                                        if (!memberPayTypeGroup.money) {
                                            common.toast('请输入储值卡金额')
                                            return
                                        }

                                        let arr = storedGroup
                                        // 放在数组的第一个
                                        arr.splice(0, 0, memberPayTypeGroup)

                                        setStoredGroup(JSON.parse(JSON.stringify(arr)))
                                        // 清空选中的储值卡
                                        setMemberPayTypeGroup('')
                                    }}><span>+</span> 添加
                                    </div>
                                </div>

                                {
                                    storedGroup.map((list, k) =>
                                        <div key={k} className={style.checkCardItem + ' ' + style.selectCardItem}>
                                            <div className={style.select}>
                                                <select value={list.card_id} onChange={(e) => {
                                                    let arr = storedGroup
                                                    arr[k].card_id = e.target.value
                                                    setStoredGroup(JSON.parse(JSON.stringify(arr)))
                                                }}>
                                                    <option value="0">请选择会员卡</option>
                                                    {storedCard.map((item, key) => {
                                                        return (
                                                            // card_category_name: "打折加送钱", number: "202004088889", money: "5849.00"
                                                            <option value={item.id} key={key}>
                                                                {item.card_category_name + ' ('}
                                                                {item.number + ')--'}
                                                                {item.money}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className={style.rightAction}>
                                                <div className={style.numberBox + ' ' + style.numberInputBox}>
                                                    <input className={style.number}
                                                           placeholder={'请输入金额'}
                                                           value={list.money}
                                                           onChange={(e) => {

                                                               let arr = storedGroup
                                                               arr[k].money = e.target.value
                                                               setStoredGroup(JSON.parse(JSON.stringify(arr)))

                                                               // 银行卡支付总金额
                                                               let sum = getPayLastListNum()

                                                               // 储值卡新增支付的总金额
                                                               let cardSum = getStoredGroupNum()

                                                               // 储值卡正输入的金额
                                                               let storedSum = getStoredMoney()

                                                               // 计次卡新增支付的总金额
                                                               let timesSum = getTimesGroupNum()

                                                               // 计次卡正在输入的金额
                                                               let timesKeepSum = getTimesMoney()

                                                               let allSum = floatAdd(floatAdd(floatAdd(floatAdd(sum, cardSum), storedSum), timesSum), timesKeepSum)

                                                               // 还需支付 = 实收金额 - 其余的金额
                                                               setLastPay(floatSub(realMoney, allSum))
                                                           }}
                                                           onBlur={(e) => {
                                                               let val = Number(e.target.value)
                                                               if (e.target.value !== '') {
                                                                   let arr = storedGroup
                                                                   arr[k].money = paddZero(val)
                                                                   setStoredGroup(JSON.parse(JSON.stringify(arr)))
                                                               }
                                                           }}
                                                    />
                                                </div>
                                            </div>

                                            <div className={style.closeBox}>
                                                <img src={require('@/images/member/close.png')}
                                                     className={style.closeImage}
                                                     onClick={() => {
                                                         let arr = storedGroup
                                                         arr.splice(k, 1)
                                                         setStoredGroup(JSON.parse(JSON.stringify(arr)))
                                                         setLastPay(paddZero(floatAdd(lastPay, list.money)))
                                                     }}/>
                                            </div>

                                        </div>
                                    )
                                }

                                <div className={style.checkCardItem + ' ' + style.selectCardItem}>
                                    <div className={style.select}>
                                        <select value={memberPayTypeGroup.card_id || ''}
                                                onChange={(e) => {
                                                    let val = e.target.value

                                                    // 银行卡支付的总金额
                                                    let sum = getPayLastListNum()

                                                    // 储值卡新增支付的总金额
                                                    let cardSum = getStoredGroupNum()

                                                    // 计次卡新增支付的总金额
                                                    let timesSum = getTimesGroupNum()

                                                    // 计次卡正在输入的金额
                                                    let timesKeepSum = getTimesMoney()

                                                    // 支付金额全部相加
                                                    let paySum = floatAdd(floatAdd(floatAdd(cardSum, sum), timesSum), timesKeepSum)

                                                    // 不做选择计算出还需支付, 并清空储值卡后面输入的金额
                                                    if (val === '0') {
                                                        setMemberPayTypeGroup('')
                                                        setLastPay(floatSub(realMoney, paySum))
                                                        return
                                                    }

                                                    let realVal = Number(realMoney)
                                                    let memberMoney = ''

                                                    // 循环储值卡列表, 如果id和所选的值一样, 则记录该卡的金额
                                                    for (let i = 0; i < storedCard.length; i++) {
                                                        if (String(storedCard[i].id) === val) {
                                                            memberMoney = storedCard[i].money
                                                            break
                                                        }
                                                    }

                                                    let money = ''

                                                    // 如果实收金额大于银行卡已支付的金额和已选储值卡的金额之和
                                                    if (realVal > paySum) {
                                                        // 还需支付 = 实收金额 - 银行卡和储值卡的总和
                                                        let newSum = floatSub(realVal, paySum)

                                                        // 如果会员卡能支付的金额小于剩余金额, 就存卡能支付的金额, 否则存还需支付的金额
                                                        if (Number(memberMoney) <= Number(newSum)) {
                                                            money = paddZero(memberMoney)
                                                        } else {
                                                            money = paddZero(newSum)
                                                        }

                                                        // 剩余金额就是(实收金额 - (会员卡金额 + 银行卡和储值卡的总和))
                                                        setLastPay(floatSub(realMoney, floatAdd(money, paySum)))

                                                    }

                                                    // 保存为一个对象
                                                    setMemberPayTypeGroup({
                                                        ...memberPayTypeGroup,
                                                        card_id: val,
                                                        money: money
                                                    })
                                                }}>
                                            <option value="0">请选择会员卡</option>
                                            {storedCard.map((item, key) => {
                                                return (
                                                    // card_category_name: "打折加送钱", number: "202004088889", money: "5849.00"
                                                    <option value={item.id} key={key}>
                                                        {item.card_category_name + ' ('}
                                                        {item.number + ')--'}
                                                        {item.money}
                                                    </option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                    <div className={style.rightAction}>
                                        <div className={style.numberBox + ' ' + style.numberInputBox}>
                                            <input className={style.number}
                                                   placeholder={'请输入金额'}
                                                   value={memberPayTypeGroup.money || ''}
                                                   onChange={(e) => {
                                                       let val = Number(e.target.value)

                                                       // 选了会员卡才能操作
                                                       if (memberPayTypeGroup) {
                                                           let obj = memberPayTypeGroup
                                                           obj.money = e.target.value
                                                           setMemberPayTypeGroup(JSON.parse(JSON.stringify(obj)))

                                                           // 银行卡支付总金额
                                                           let sum = getPayLastListNum()

                                                           // 储值卡新增支付的总金额
                                                           let cardSum = getStoredGroupNum()

                                                           // 计次卡新增支付的总金额
                                                           let timesSum = getTimesGroupNum()

                                                           // 计次卡正在输入的金额
                                                           let timesKeepSum = getTimesMoney()

                                                           // 银行卡支付总金额 + 储值卡新增支付的总金额
                                                           let allSum = floatAdd(floatAdd(floatAdd(sum, cardSum), timesSum), timesKeepSum)

                                                           setLastPay(floatSub(realMoney, floatAdd(val, allSum)))

                                                       } else {
                                                           common.toast('请先选择会员卡')
                                                       }
                                                   }}
                                                   onBlur={(e) => {
                                                       let val = Number(e.target.value)
                                                       if (memberPayTypeGroup && (e.target.value !== '')) {
                                                           let obj = memberPayTypeGroup
                                                           obj.money = paddZero(val) + ''
                                                           setMemberPayTypeGroup(JSON.parse(JSON.stringify(obj)))
                                                       }
                                                   }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            countCard.length > 0 &&
                            <div className={style.checkcardList}>
                                <div className={style.checkCardItem}>
                                    <div className={style.label}>计次卡</div>
                                    <div className={style.add} onClick={() => {
                                        // 没选计次卡不让新增一条
                                        if (!cardTimesGroup) {
                                            common.toast('请选择计次卡')
                                            return
                                        }

                                        let arr = countGroup
                                        // 放在数组的第一个
                                        arr.splice(0, 0, cardTimesGroup)
                                        setCountGroup(JSON.parse(JSON.stringify(arr)))
                                        // 清空选中的计次卡
                                        setCardTimesGroup('')
                                    }}><span>+</span> 添加
                                    </div>
                                </div>

                                {
                                    countGroup.map((list, k) =>
                                        <div key={k} className={style.checkCardItem + ' ' + style.selectCardItem}>
                                            <div className={style.select}>
                                                <select value={list.card_id}
                                                        onChange={(e) => {
                                                            let val = e.target.value

                                                            let arr = JSON.parse(JSON.stringify(countGroup))

                                                            // 当前选择的计次卡的余额
                                                            let countMoney = ''
                                                            // 循环计次卡列表, 如果id和所选的值一样, 则记录该卡的金额
                                                            for (let i = 0; i < countCard.length; i++) {
                                                                if (String(countCard[i].id) === val) {
                                                                    countMoney = countCard[i].money
                                                                    break
                                                                }
                                                            }

                                                            arr[k].card_id = val
                                                            arr[k].money = countMoney

                                                            // 银行卡支付总金额
                                                            let sum = getPayLastListNum()

                                                            // 储值卡新增支付的总金额
                                                            let cardSum = getStoredGroupNum()

                                                            // 储值卡正在输入的金额
                                                            let storedKeepSum = getStoredMoney()

                                                            // 计次卡正在输入的金额
                                                            let timesKeepSum = getTimesMoney()

                                                            // 计次卡新增支付的总金额
                                                            let timesSum = 0
                                                            if (arr.length > 0) {
                                                                arr.forEach((item) => {
                                                                    timesSum = floatAdd(Number(item.money), timesSum)
                                                                })
                                                            }

                                                            // 所有支付方式总和
                                                            let allSum = floatAdd(floatAdd(floatAdd(sum, cardSum), timesKeepSum), timesSum)
                                                            allSum = floatAdd(allSum, storedKeepSum) // 加上正在输入的储值卡金额

                                                            // 不做选择计算出还需支付, 并清空储值卡后面输入的金额
                                                            if (val === '0') {
                                                                setCountGroup([...arr])
                                                                setLastPay(floatSub(realMoney, allSum))
                                                                return
                                                            }

                                                            // 支付总和减掉本次选卡金额，剩余的总和才是 已选好的支付金额总和
                                                            allSum = floatSub(allSum, countMoney)

                                                            let realVal = Number(realMoney)

                                                            // 还需支付 = 实收金额 - 其余金额的总和
                                                            let newSum = floatSub(realVal, allSum)

                                                            // 如果会员卡能支付的金额大于剩余金额, 就报错, 次卡只能全部支付
                                                            let money = paddZero(countMoney)
                                                            if (Number(countMoney) > Number(newSum)) {
                                                                common.toast('计次卡剩余金额大于还需支付金额')
                                                                return
                                                            }

                                                            setCountGroup([...arr])

                                                            // 剩余金额就是(实收金额 - (会员卡金额 + 银行卡和储值卡的总和))
                                                            setLastPay(floatSub(realMoney, floatAdd(money, allSum)))
                                                        }}>
                                                    <option value="0">请选择会员卡</option>
                                                    {countCard.map((item, key) => {
                                                        return (
                                                            // card_category_name: "打折加送钱", number: "202004088889", money: "5849.00"
                                                            <option value={item.id} key={key}>
                                                                {item.card_category_name + ' ('}
                                                                {item.number + ')--'}
                                                                {item.consume_times + ' ('}
                                                                {item.money + ')'}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>

                                            <div className={style.closeBox}>
                                                <img src={require('@/images/member/close.png')}
                                                     className={style.closeImage}
                                                     onClick={() => {
                                                         let arr = countGroup
                                                         arr.splice(k, 1)
                                                         setCountGroup(JSON.parse(JSON.stringify(arr)))
                                                         setLastPay(paddZero(floatAdd(lastPay, list.money)))
                                                     }}/>
                                            </div>

                                        </div>
                                    )
                                }

                                <div className={style.checkCardItem + ' ' + style.selectCardItem}>
                                    <div className={style.select}>
                                        <select value={cardTimesGroup.card_id || ''}
                                                onChange={(e) => {
                                                    let val = e.target.value

                                                    // 银行卡支付总金额
                                                    let sum = getPayLastListNum()

                                                    // 储值卡新增支付的总金额
                                                    let cardSum = getStoredGroupNum()

                                                    // 储值卡正输入的金额
                                                    let storedSum = getStoredMoney()

                                                    // 计次卡新增支付的总金额
                                                    let timesSum = getTimesGroupNum()

                                                    let allSum = floatAdd(floatAdd(floatAdd(sum, cardSum), storedSum), timesSum)

                                                    // 不做选择计算出还需支付, 并清空储值卡后面输入的金额
                                                    if (val === '0') {
                                                        setCardTimesGroup('')
                                                        setLastPay(floatSub(realMoney, allSum))
                                                        return
                                                    }

                                                    // 当前选择的计次卡的余额
                                                    let countMoney = ''
                                                    // 循环计次卡列表, 如果id和所选的值一样, 则记录该卡的金额
                                                    for (let i = 0; i < countCard.length; i++) {
                                                        if (String(countCard[i].id) === val) {
                                                            countMoney = countCard[i].money
                                                            break
                                                        }
                                                    }

                                                    let realVal = Number(realMoney)

                                                    // 还需支付 = 实收金额 - 其余金额的总和
                                                    let newSum = floatSub(realVal, allSum)

                                                    // 如果会员卡能支付的金额大于剩余金额, 就报错, 次卡只能全部支付
                                                    let money = paddZero(countMoney)
                                                    if (Number(countMoney) > Number(newSum)) {
                                                        common.toast('计次卡剩余金额大于还需支付金额')
                                                        return
                                                    }

                                                    // 剩余金额就是(实收金额 - (会员卡金额 + 银行卡和储值卡的总和))
                                                    setLastPay(floatSub(realMoney, floatAdd(money, allSum)))

                                                    setCardTimesGroup({
                                                        ...cardTimesGroup,
                                                        card_id: val,
                                                        money: money
                                                    })
                                                }}>
                                            <option value="0">请选择会员卡</option>
                                            {countCard.map((item, key) => {
                                                return (
                                                    // card_category_name: "打折加送钱", number: "202004088889", money: "5849.00"
                                                    <option value={item.id} key={key}>
                                                        {item.card_category_name + ' ('}
                                                        {item.number + ')--'}
                                                        {item.consume_times + ' ('}
                                                        {item.money + ')'}
                                                    </option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                    {/*<div className={style.rightAction}>*/}
                                    {/*    <div className={style.numberBox + ' '+ style.numberInputBox}>*/}
                                    {/*        <input className={style.number}*/}
                                    {/*               placeholder={'请输入金额'}*/}
                                    {/*               value={memberPayTypeGroup.money || ''}*/}
                                    {/*               onChange={(e) => {*/}
                                    {/*                   let val = Number(e.target.value)*/}

                                    {/*                   // 选了会员卡才能操作*/}
                                    {/*                   if (memberPayTypeGroup) {*/}
                                    {/*                       let obj = memberPayTypeGroup*/}
                                    {/*                       obj.money = e.target.value*/}
                                    {/*                       setMemberPayTypeGroup(JSON.parse(JSON.stringify(obj)))*/}

                                    {/*                       // 银行卡支付总金额*/}
                                    {/*                       let sum = getPayLastListNum()*/}

                                    {/*                       // 储值卡新增支付的总金额*/}
                                    {/*                       let cardSum = getStoredGroupNum()*/}

                                    {/*                       // 计次卡新增支付的总金额*/}
                                    {/*                       let timesSum = getTimesGroupNum()*/}

                                    {/*                       // 计次卡正在输入的金额*/}
                                    {/*                       let timesKeepSum = getTimesMoney()*/}

                                    {/*                       // 银行卡支付总金额 + 储值卡新增支付的总金额*/}
                                    {/*                       let allSum = floatAdd(floatAdd(floatAdd(sum, cardSum), timesSum), timesKeepSum)*/}

                                    {/*                       setLastPay(floatSub(realMoney, floatAdd(val, allSum)))*/}

                                    {/*                   } else {*/}
                                    {/*                       common.toast('请先选择会员卡')*/}
                                    {/*                   }*/}
                                    {/*               }}*/}
                                    {/*               onBlur={(e) => {*/}
                                    {/*                   let val = Number(e.target.value)*/}
                                    {/*                   if (memberPayTypeGroup && (e.target.value !== '')) {*/}
                                    {/*                       let obj = memberPayTypeGroup*/}
                                    {/*                       obj.money = paddZero(val) + ''*/}
                                    {/*                       setMemberPayTypeGroup(JSON.parse(JSON.stringify(obj)))*/}
                                    {/*                   }*/}
                                    {/*               }}/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        }

                        <div className={style.checkoutBottom}>
                            <div className={style.checkoutBox}>
                                <div className={style.checkoutRemark}>
                                    <div className={style.leftRemark}>
                                        还需支付
                                    </div>
                                    <div className={style.priceRemark}>
                                        ￥{lastPay || '0.00'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </DetailModal>
        </div>
    );
}

export default CardRecharge;