import React, {useState} from 'react';
import style from './style.module.less'
import MemberDetail from "../components/memberDetail";
import DetailModal from "../components/detailModal";
import Button from "../../public/button";
import couponBg from "../../../images/member/coupon.png";
import {useSelector} from "react-redux";
import common from "../../../utils/common";

function TransferCoupon(props) {
    const {setAction, onClose} = props

    const {activeTicket, memberInfo} = useSelector(state => state.member)

    const couponBgStyle = {
        backgroundImage: "url(" + couponBg + ")",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
    }

    let [phone, setPhone] = useState('')  // 手机号
    let [resultMessage, setResultMessage] = useState('')  // 正确信息
    let [errorMessage, setErrorMessage] = useState('')  // 报错信息
    let [remark, setRemark] = useState('')  // 备注

    // 提交
    let handleSubmit = () => {

        if (phone === '') {
            common.toast('请输入手机号')
            return
        }
        if (phone.length !== 11) {
            common.toast('请输入正确的手机号')
            return
        }
        if (phone === memberInfo.mobile) {
            common.toast('不能转赠给自己')
            return
        }

        common.loadingStart()
        common.ajax('post', '/app.php/member-api/ticket-detail/transfer', {
            member_id: memberInfo.id,     // 会员id
            ticket_detail_id: activeTicket.id,       // 卡券详情id
            new_mobile: phone,    // 新会员手机号
            new_member_id: resultMessage.id || '', // 新会员id(可为空)
            remark: remark, // 备注信息
        }).then((res) => {
            common.toast(res.message)
            setAction('ticket')
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={style.TransferCoupon}>
            <MemberDetail nav={'ticket'} setAction={setAction}/>
            <DetailModal closable={true} title={'卡券转赠'} onBack={() => setAction('ticket')} footer={
                <div className={style.buttonBox}>
                    <Button type={'cancel'} shape={'default'}
                            onClick={() => {
                                setAction('ticket')
                            }}>取消</Button>
                    <Button style={{marginLeft: '10px'}}
                            type={'confirm'}
                            shape={'default'}
                            onClick={() => {
                                handleSubmit()
                            }}>确定</Button>
                </div>
            } onCancel={onClose}>
                <div className={style.contentBox}>
                    <div className={style.couponDiv} style={couponBgStyle}>
                        <div className={style.topCouponDiv}>
                            <div className={style.couponName}>{activeTicket.name}</div>
                            <div className={style.couponMoney}>{activeTicket.money_str ?
                                <span>￥</span> : ''}{activeTicket.money_str}{activeTicket.project_str}</div>
                        </div>

                        <div className={style.bottomCouponDiv}>
                            <div className={style.number}>{activeTicket.valid_str}</div>
                            <div className={style.shop}>{activeTicket.user_name}</div>
                        </div>
                    </div>

                    <div className={style.inputDiv}>
                        <input type="text" placeholder={'请输入接受此卡券的会员手机号码'}
                               value={phone}
                               onChange={(e) => {
                                   let val = common.trimBlank(e.target.value)
                                   setPhone(val)
                                   if (val.length === 11) {
                                       common.loadingStart()
                                       common.ajax('get', '/app.php/member-api/member/find-member-by-mobile', {
                                           mobile: val,
                                       }).then((res) => {
                                           setResultMessage(res)
                                           setErrorMessage('')
                                       }).catch((err) => {
                                           // setErrorMessage(err.error)
                                           // setResultMessage('')
                                       }).finally(() => {
                                           common.loadingStop()
                                       })
                                   } else {
                                       // setResultMessage('')
                                       // setErrorMessage('')
                                   }
                               }}/>
                    </div>

                    <div className={style.textareaDiv}>
                        <textarea className={style.rightTextarea} name="" cols="30" rows="10" placeholder="请输入备注"
                                  value={remark}
                                  onChange={(e) => {
                                      setRemark(e.target.value)
                                  }}
                        />
                    </div>
                </div>


            </DetailModal>
        </div>
    );
}

export default TransferCoupon;