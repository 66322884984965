import common from "./common";
import biz from "./biz";

let indexedDb = {}

indexedDb.indexedDb = '';
indexedDb.businessCacheValidTime = 5 * 60; // 业务数据有效时间：5分钟

// 对应数据使用在IndexedDB中的storeName(可理解为表名)
indexedDb.indexedDBStoreNameList = {
  'config': 'config',
  'shift': 'shift',
  'star_level': 'star_level',
  'project_type': 'project_type',
  'room_type': 'room_type',
  'room_area': 'room_area',
  'pay_type_type': 'pay_type_type',
  'pay_type': 'pay_type',
  'employee_tag': 'employee_tag',
  'bill_type': 'bill_type',
  'code': 'code',
  'hand_number': 'hand_number',
  'project': 'project',
  'room': 'room',
  'room_trends': 'room_trends',
  'reserve': 'reserve',
  'service_type': 'service_type',
  'front_remind': 'front_remind',
  'employee': 'employee',
  'employee_trends': 'employee_trends',
  'customer_booking': 'customer_booking',
  'user': 'user',
  'user_booking_online': 'user_booking_online',
  'bill': 'bill',
  'store_wait_serve_booking': 'store_wait_serve_booking',
  'call_serve': 'call_serve',
  'appoint_order': 'appoint_order',
  'consumes_no_service_end': 'consumes_no_service_end',
  'room_open': 'room_open',
  'employee_booking': 'employee_booking',
  'phrase': 'phrase',
  'setting': 'setting',
  'manual_price_plan': 'manual_price_plan',
}

indexedDb.handleIndexedDB = () => {

  return new Promise((resolve, reject) => {

    try {
      var indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.msIndexedDB;
      if (!indexedDB) {
        alert("你的浏览器不支持IndexedDB，请使用Chrome浏览器");
        reject()
      }

      var version = 7; // 20241209 最新版

      var request = indexedDB.open('kushu_front_dev', version);

      request.onerror = function () {
        alert("浏览器版本过低，请使用chrome浏览器哦");
        console.log("IndexedDB Error");

        reject("IndexedDB Error")
      };

      // 新创建数据库时触发
      request.onupgradeneeded = function (event) {
        var db = event.target.result;

        // 基础数据，给"name"加索引
        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.config)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.config, {keyPath: 'name'}); // 配置
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.shift)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.shift, {autoIncrement: true}); // 班次
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.star_level)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.star_level, {autoIncrement: true}); // 星级
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.project_type)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.project_type, {autoIncrement: true}); // 项目类型
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.room_type)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.room_type, {autoIncrement: true}); // 房间类型
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.room_area)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.room_area, {autoIncrement: true}); // 房间区域类型
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.pay_type_type)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.pay_type_type, {autoIncrement: true}); // 支付方式类型
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.pay_type)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.pay_type, {autoIncrement: true}); // 支付方式
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.employee_tag)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.employee_tag, {autoIncrement: true}); // 技师标签
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.bill_type)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.bill_type, {autoIncrement: true}); // 账单类型
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.code)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.code, {autoIncrement: true}); // 实体卡
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.hand_number)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.hand_number, {autoIncrement: true}); // 手牌
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.project)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.project, {autoIncrement: true}); // 项目
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.room)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.room, {autoIncrement: true}); // 房间-基础数据
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.room_trends)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.room_trends, {autoIncrement: true}); // 房间-状态
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.reserve)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.reserve, {autoIncrement: true}); // 技师可服务时间
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.service_type)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.service_type, {autoIncrement: true}); // 服务类型
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.front_remind)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.front_remind, {autoIncrement: true}); // 收银提醒规则
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.employee)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.employee, {autoIncrement: true}); // 员工
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.employee_trends)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.employee_trends, {autoIncrement: true}); // 员工动态数据
        }

        // 业务数据
        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.customer_booking)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.customer_booking, {autoIncrement: true}); // 在店预约数据
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.user_booking_online)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.user_booking_online, {autoIncrement: true}); // 在线预约
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.bill)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.bill, {autoIncrement: true}); // 未结账的账单
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.store_wait_serve_booking)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.store_wait_serve_booking, {autoIncrement: true}); // 预约客户信息
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.call_serve)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.call_serve, {autoIncrement: true}); // 待处理的呼叫服务信息
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.appoint_order)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.appoint_order, {autoIncrement: true}); // 排钟数据
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.consumes_no_service_end)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.consumes_no_service_end, {autoIncrement: true}); // 未到钟的消费
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.room_open)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.room_open, {autoIncrement: true}); // 开房记录
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.employee_booking)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.employee_booking, {autoIncrement: true}); // 技师预约记录
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.phrase)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.phrase, {autoIncrement: true}); // 常用语
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.setting)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.setting, {autoIncrement: true}); // 设置的别名
        }

        if (!db.objectStoreNames.contains(indexedDb.indexedDBStoreNameList.manual_price_plan)) {
          db.createObjectStore(indexedDb.indexedDBStoreNameList.manual_price_plan, {autoIncrement: true}); // 手动的优惠方案
        }

      }

      // 数据库打开成功
      request.onsuccess = function (event) {
        indexedDb.indexedDb = request.result;

        resolve();
      }
    } catch (e) {
      reject('IndexedDB Full Error')
    }

  });

}

// 将数据放入config表
indexedDb.putIntoConfigByIndexedDB = (name, value, action) => {
  // 更新
  try {
    action = action || 0; // 是否执行

    // 不执行，不放入缓存，则不动
    if (!action) {
      return;
    }

    var storeName = indexedDb.indexedDBStoreNameList.config
    var nowTime = biz.getNowTime();
    indexedDb.indexedDb.transaction([storeName], 'readwrite').objectStore(storeName).put({
      name: name,
      value: value,
      'cache_time': nowTime
    });
  } catch (e) {
    console.log("IndexedDB：Config中数据更新失败:" + name)
  }
}

// 从config表中获取数据
indexedDb.getOneFromConfigByNameByIndexedDB = (name) => {
  return new Promise((resolve, reject) => {
    try {
      var storeName = indexedDb.indexedDBStoreNameList.config
      let request = indexedDb.indexedDb.transaction([storeName], 'readonly').objectStore(storeName).get(name);

      request.onsuccess = function (e) {
        let result = e.target.result;

        if (result) {
          resolve({'name': name, data: result.value});
        } else {
          reject("没有缓存数据:" + name);
        }
      }

      // 缓存数据失败，直接返回
      request.onerror = function () {
        reject("获取数据失败:" + name)
      };
    } catch (e) {
      console.log("IndexedDB：从config中获取数据失败:" + name)
      reject("IndexedDB：从config中获取数据失败:" + name)
    }
  });
}

// 将数据放入基础表 tableName: 表名；data：数组
indexedDb.putAllIntoForStoreNameByIndexedDB = (storeName, data, action) => {
  try {
    action = action || 0; // 是否执行

    // 不执行，不放入缓存，则不动
    if (!action) {
      return;
    }

    var objectStore = indexedDb.indexedDb.transaction([storeName], 'readwrite').objectStore(storeName)

    // 将数据表中的数据都清空
    objectStore.clear();

    // 重新放入

    // 第一行放入缓存时间
    var nowTime = biz.getNowTime();
    objectStore.add({'cache_time': nowTime})

    // 批量将数据放入
    for (var i = 0; i < data.length; i++) {
      objectStore.add(data[i])
    }
  } catch (e) {
    console.log(e)
    console.log("IndexedDB：将数据放入基础表中失败:" + storeName)
  }
}

// 从基础表中获取数据  storeName: 表名，alias: 别名，当没有别名的时候，是以表名返回作为name的
indexedDb.getListForStoreNameByIndexedDb = (storeName, alias, validTime) => {
  alias = alias || storeName
  validTime = validTime || false;

  return new Promise((resolve, reject) => {
    try {

      var objectStore = indexedDb.indexedDb.transaction([storeName], 'readonly').objectStore(storeName)

      var request = objectStore.getAll();

      request.onsuccess = function (e) {
        var list = e.target.result;

        if (!list) {
          // 没有缓存数据
          reject("IndexedDB NO DATA");
          return;
        }

        // 判断过期时间，如果时间不在有效期内，则不使用缓存
        if (validTime) {
          var nowTime = biz.getNowTime();
          if (typeof list[0] === "undefined") {
            reject("IndexedDB NO DATA");
            return;
          }

          if ((!list[0]) || (list[0] == undefined)) {
            reject("IndexedDB NO DATA");
            return;
          }

          if (typeof list[0]['cache_time'] === "undefined") {
            reject("IndexedDB NO DATA");
            return;
          }

          var cacheTime = list[0]['cache_time']

          if (!cacheTime) {
            reject("IndexedDB NO DATA");
            return;
          }

          var tempTime = parseInt(nowTime) - parseInt(cacheTime);

          // 缓存时间距离当前时间超过 有效期 则不使用缓存，视为无效
          if (parseInt(tempTime) > parseInt(validTime)) {
            reject("IndexedDB NO DATA");
            return;
          }
        }

        // 重新组装数据，第一行是过期时间
        var data = [];
        for (var i = 1; i < list.length; i++) {
          data.push(list[i])
        }

        resolve({'name': alias, 'data': data});
        return;
      }

      // 缓存数据失败，直接返回
      request.onerror = function () {
        reject("Indexed Error")
        return;
      };
    } catch (e) {
      console.log("IndexedDB：从基础表中获取失败:" + storeName)
      reject("Indexed GET ERROR:" + storeName)
      return;
    }
  })
}


export default indexedDb;



