import React, {useRef, useState, useEffect} from 'react';
import style from "./style.module.less";
import Button from "../../../public/button";
import common from "../../../../utils/common";
import PayWay from "../../components/payWay";
import Modal from "../../../public/modal";
import readCardUtils from "../../../../utils/readCardUtils";
import print from "../../../../utils/print";
import {useDispatch, useSelector} from "react-redux";
import memberUtils from "../../../../utils/memberUtils";
import biz from "../../../../utils/biz";

function CardCreate(props) {
    const {
        setAction,
        goRecordHome,
        memberId,
        cardCategoryId,
        fastCreateCard,
        employeeList,
        ticketList,
        defaultSelectTicketList,
        payTypeList,
        memberPayTypeList,
        onClose,
        goOpenCard
    } = props

    const {memberInfo} = useSelector(state => state.member)
    const dispatch = useDispatch();

    const [employeeGroup, setEmployeeGroup] = useState([])                  //选择员工的盒子组
    const [employeeId, setEmployeeId] = useState('0')                        //选择的员工id
    const [last, setLast] = useState('')                                    //剩余金额
    const [createCard, setCreateCard] = useState({})                        //开卡信息
    const [memberPayTypeGroup, setMemberPayTypeGroup] = useState('')        //选择的会员卡充值支付方式 (储值卡)
    const [cardTimesGroup, setCardTimesGroup] = useState('')                //选择的会员卡充值支付方式 (计次卡)
    const [issueMoney, setIssueMoney] = useState('')                        //发卡金额
    const [realMoney, setRealMoney] = useState('')                          //实收金额
    const [tmpRealMoney, setTmpRealMoney] = useState('')                    //临时实收金额
    const [price, setPrice] = useState('')                                  //卡类单价
    const [consumeCount, setConsumeCount] = useState('')                    //消费次数
    const [validEndStr, setValidEndStr] = useState('')                      //有效期
    const [icSeriesNumber, setIcSeriesNumber] = useState('')                //No: 卡面号
    const [icFaceNumber, setIcFaceNumber] = useState('')                //No: 卡面号
    const [icCode, setIcCode] = useState('')                                //No: 卡编号
    const [isCheck, setIsCheck] = useState(false)                           //是否点击了发放实体卡

    //右边的选卡支付
    const [payLastList, setPayLastList] = useState([])                      //付费剩余金额
    const [lastPay, setLastPay] = useState('')                              //还需支付
    const [payList, setPayList] = useState([])                              //支付方式(要提交的数据)
    const [wxOrZhi, setWxOrZhi] = useState(0)                               //支付方式 判断点击的是微信还是支付宝, 5:微信, 6:支付宝

    const [inputVal, setInputVal] = useState('')                            //输入的条码
    const [payTypeListKey, setPayTypeListKey] = useState('')                //会员开卡充值支付方式列表的key值
    const [itemId, setItemId] = useState('')                                //传给移动之后的id
    const [isFocus, setIsFocus] = useState(false)                           //移动支付的input框自动获取焦点
    const [checked, setChecked] = useState(10)                              //10:付款码, 20:手机订单号, 30:后台支付码,
    const isSubmit = useRef(false)                                          //是否提交
    const [payWayError, setPayWayError] = useState('');

    const [storedGroup, setStoredGroup] = useState([]);                     //会员卡支付方式里的储值卡付费提交数组
    const [countGroup, setCountGroup] = useState([]);                       //会员卡支付方式里的计次卡付费提交数组
    const [storedCard, setStoredCard] = useState([]);                       //会员卡支付方式里的储值卡数据
    const [countCard, setCountCard] = useState([]);                         //会员卡支付方式里的计次卡数据
    const [showPayWait, setShowPayWait] = useState(false)                   //展示微信或支付宝弹框

    const initSelectTicket = {
        'value': '0',
        'num': '0',
    }
    const [selectTicketList, setSelectTicketList] = useState([initSelectTicket])                  //选择的卡券
    const [storageTicketList, setStorageTicketList] = useState([])                               //存储的卡券数据

    const [expireTicketIds, setExpireTicketIds] = useState([])                          // 过期卡券或没余量的卡券IDs
    const [expireTicketList, setExpireTicketList] = useState([])                        // 过期卡券或没余量的卡券数据
    const [expireTicketInfoList, setExpireTicketInfoList] = useState({})                // 已选过期卡券信息

    useEffect(() => {
        setEmployeeGroup([])
        setEmployeeId('0')
        setLast('')
        setIsCheck(false)
        setPayLastList([])
        setPayList([])
        setMemberPayTypeGroup('')
        setCardTimesGroup('')
        setIcSeriesNumber('')
        setIcFaceNumber('')
        setIcCode('')
        setStoredGroup([])
        setCountGroup([])
        //获取开卡信息
        common.loadingStart()
        Promise.all([
            getCreateCard(),           //开卡信息
            getStoredAndCountCard(),   //获取会员卡支付方式里的储值卡和计次卡
        ]).finally(common.loadingStop)

        setStorageTicketList([...defaultSelectTicketList])
    }, [cardCategoryId.cardCategoryId])

    useEffect(() => {
        let ticketIds = []
        let expireTicketList = []
        for (let i = 0; i < defaultSelectTicketList.length; i++) {
            let item = defaultSelectTicketList[i]

            if (item.is_unexpired) { // 未过期且有余量的卡券跳过
                continue
            }

            // 过期 或 无余量 的卡券数据
            expireTicketList[item.ticket_id] = item
            ticketIds.push(item.ticket_id)
        }

        setExpireTicketList(expireTicketList)
        setExpireTicketIds(ticketIds)
    }, [defaultSelectTicketList])

    // useEffect(() => {
    //     // 组件移除时的操作
    //     return () => {
    //         setShowPayWait(false)
    //     }
    // }, [])

    // 会员卡变化及时更新
    useEffect(() => {
        getStoredAndCountCard()
    }, [memberPayTypeList])

    //根据实收金额 与 卡类单价的比较，对应展示卡券
    useEffect(() => {
        if (Number(tmpRealMoney) < Number(price)) {
            //实收金额 低于 单价
            //将默认卡券清空
            setStorageTicketList([])
        } else {
            if (selectTicketList.length < 1 || (selectTicketList.length === 1 && selectTicketList[0].value === '0')) {
                //没有选择卡券 或 选择的卡券为默认展示数据 ，则将默认赠送卡券数据重新赋值
                setStorageTicketList([...defaultSelectTicketList])
            }
        }
    }, [tmpRealMoney])

    useEffect(() => {
        if (storageTicketList.length > 0) {
            let data = []
            for (let i = 0; i < storageTicketList.length; i++) {
                let item = storageTicketList[i]

                let isExistBool = false;    //卡类赠送的卡券是否存在卡券列表中
                for (let j = 0; j < ticketList.length; j++) {
                    if (item.ticket_id.toString() === ticketList[j].id.toString()) {
                        isExistBool = true;
                        break
                    }
                }

                //卡券列表不存在，则放入卡券列表
                if (!isExistBool) {
                    ticketList.push({
                        'id': (item.ticket_id).toString(),
                        'title': item.ticket_title
                    })
                }

                data.push({
                    "value": (item.ticket_id).toString(),
                    "num": item.quantity
                })
            }

            if (data.length < 1) {
                data = [initSelectTicket]    //卡类赠送的卡券全部不在卡券列表中，展示默认空值
            }

            setSelectTicketList(data)
        } else {
            setSelectTicketList([initSelectTicket])
        }
    }, [storageTicketList])

    // 读取实体卡操作
    const readCardHandle = () => {
        readCardUtils.readCardForAction(cardCategoryId.cardCategoryId).then((res) => {
            // icCode: 卡编号    icSeriesNumber: 卡序列号   icFaceNumber: 卡面号
            setIcFaceNumber(res.icFaceNumber)
            setIcSeriesNumber(res.icSeriesNumber)
            setIcCode(res.icCode)
        })
    }

    //开卡信息
    const getCreateCard = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/card/get-create-card', {
                card_category_id: cardCategoryId.cardCategoryId
            }).then((res) => {
                setCreateCard(res)

                setIssueMoney(paddZero(floatAdd(res.price, res.donation_amount)))
                setPrice(paddZero(res.price))
                setRealMoney(paddZero(res.price))
                setTmpRealMoney(paddZero(res.price))
                setConsumeCount(res.consume_count)
                setValidEndStr(res.valid_end_str)
                setLastPay(paddZero(res.price))
                setLast(paddZero(res.price))
                if (fastCreateCard === 20) {
                    setIsCheck(true)
                }
                if (res.has_card_entity == 10) {
                    setIsCheck(true)
                    readCardHandle()
                }

                resolve()
            }).catch((err) => {
                reject()
            })
        })
    }

    //获取会员卡支付方式里的储值卡和计次卡
    const getStoredAndCountCard = () => {
        //储值卡
        let storedArr = []
        //计次卡
        let countArr = []
        memberPayTypeList.forEach((item) => {
            if (item.type === 1) {
                storedArr.push(item)
            } else if (item.type === 2) {
                countArr.push(item)
            }
        })
        setStoredCard(storedArr)
        setCountCard(countArr)
    }

    //补零
    const paddZero = (val) => {
        if (val === '') {
            return '0.00'
        }
        val = String(val)
        if (val.indexOf('.') !== -1) {
            if (val.substring(val.indexOf('.') + 1).length < 2) {
                val += '0'
            }
        } else {
            val += '.00'
        }
        return val
    }

    //计算还需付款金额
    const getLastPay = (val) => {

        // 银行卡总支付金额
        let sum = getPayLastListNum()

        // 储值卡新增支付的总金额
        let cardSum = getStoredGroupNum()

        // 储值卡正输入的金额
        let storedSum = getStoredMoney()

        // 计次卡新增支付的总金额
        let timesSum = getTimesGroupNum()

        // 计次卡正在输入的金额
        let timesKeepSum = getTimesMoney()

        // 支付金额全部相加
        let paySum = floatAdd(floatAdd(floatAdd(floatAdd(cardSum, sum), timesSum), timesKeepSum), storedSum)
        setLastPay(paddZero(floatSub(val, paySum)))
    }

    //加
    const floatAdd = (arg1, arg2) => {
        var r1, r2, m;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        return (arg1 * m + arg2 * m) / m;
    }

    //减
    const floatSub = (arg1, arg2) => {
        var r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        //动态控制精度长度
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    }

    //计算分配剩余金额
    const getLastValue = () => {
        let sum = 0
        employeeGroup.forEach((item) => {
            sum += Number(item.money)
        })
        sum = floatSub(realMoney, sum)
        setLast(sum)
    }

    //输入规则判断, 保留两位小数
    const inputIsTrue = (val) => {
        if (val) {
            val = String(val)
            if (val.indexOf('.') !== -1) {
                if (val.substring(val.indexOf('.') + 1).length > 2) {
                    return false
                }
            }
        }
        return true
    }

    // 银行卡总支付金额
    const getPayLastListNum = () => {
        let sum = 0
        if (payLastList.length > 0) {
            payLastList.forEach((item) => {
                sum += Number(item)
            })
        }
        return sum
    }

    // 计算新增的储值卡的总金额
    const getStoredGroupNum = () => {
        let sum = 0
        if (storedGroup.length > 0) {
            storedGroup.forEach((item) => {
                sum += Number(item.money)
            })
        }
        return sum
    }

    // 计算新增的计次卡的总金额
    const getTimesGroupNum = () => {
        let sum = 0
        if (countGroup.length > 0) {
            countGroup.forEach((item) => {
                sum += Number(item.money)
            })
        }
        return sum
    }

    // 储值卡正输入的金额
    const getStoredMoney = () => {
        let sum = 0
        if (memberPayTypeGroup) {
            sum = +memberPayTypeGroup.money
        }
        return sum
    }

    // 计次卡正输入的金额
    const getTimesMoney = () => {
        let sum = 0
        if (cardTimesGroup) {
            sum = +cardTimesGroup.money
        }
        return sum
    }

    // 获取提成人员
    const checkAndGetEmployeeBeforeSubmit = () => {
        if (fastCreateCard === 20) {
            if (!isCheck) {
                common.alert('快速开卡，只能开实体卡')
                return false
            }
        }

        if (isCheck) {
            if (!icCode || !icSeriesNumber) {
                common.alert('请读取实体卡')
                return false
            }
        }

        // employeeGroup: 是+号下的数据
        let employeeArr = employeeGroup
        let newEmployeeArr = []

        // 如果选择的分配员工和分配金额都有的话, 就加上目前选择的
        if (Number(last) && Number(employeeId)) {
            newEmployeeArr = [{employee_id: employeeId, money: last}]
            employeeArr = employeeArr.concat(newEmployeeArr)
        }

        // 如果实收金额大于0 并且 接口返回一定要分配的话则提成人员必须要有数据
        if (Number(realMoney) > 0) {
            if (createCard.card_create_check === 10) {
                if (employeeArr.length < 1) {
                    common.alert('提成人员不能为空')
                    return false
                }
            }
        }
        return employeeArr
    }

    //提交
    const handleSubmit = (lastPay) => {
        if (Number(lastPay) !== 0) {
            common.alert('支付费用不正确')
            return
        }

        let expireTicketInfoList = []
        for (let i = 0; i < selectTicketList.length; i++) {
            let item = selectTicketList[i]
            if (item.value == 0) {
                continue //跳过默认值
            }

            if (item.num < 1) {
                common.alert('卡券数量不能小于1')
                return;
            }

            // 要进行确认提示 且 存在过期卡券，则进行提示
            if (expireTicketIds.indexOf(Number(item.value)) !== -1) {
                if (expireTicketList[item.value] !== undefined) {
                    let infoItem = expireTicketList[item.value]

                    // end_timestamp到期日期时间戳，非固定日期区间时 为空。过期了则进行提示
                    if (infoItem.end_timestamp.toString().length > 0 && infoItem.end_timestamp < biz.getNowTime()) {
                        infoItem.dateInfo = biz.handleTime(infoItem.end_timestamp)
                        expireTicketInfoList.push(infoItem)
                    }
                }
            }
        }

        // 有过期卡券
        if (expireTicketInfoList.length > 0) {
            setExpireTicketInfoList(expireTicketInfoList)
            return;
        }

        submit()
    }

    const submit = () => {
        // 获取提成人员
        let employeeArr = checkAndGetEmployeeBeforeSubmit()

        if (!employeeArr) {
            return
        }

        // 支付方式
        let payListArr = []
        payList.forEach((item) => {
            if (item) {
                if (item.money) {
                    payListArr.push(item)
                }
            }
        })

        // 储值卡要提交的值
        let storeArr = []
        storedGroup.forEach((item) => {
            storeArr.push(item)
        })

        // 计次卡要提交的值
        let countArr = []
        countGroup.forEach((item) => {
            countArr.push(item)
        })

        // 储值卡输入框有值, 并且不为0就存
        if (memberPayTypeGroup) {
            storeArr.push(memberPayTypeGroup)
        }

        // 计次卡输入框有值, 并且不为0就存
        if (cardTimesGroup) {
            countArr.push(cardTimesGroup)
        }
        let newCountArr = []
        countArr.forEach((item) => {
            newCountArr.push({card_id: item.card_id})
        })

        let tmpTicketList = []
        for (let i = 0; i < selectTicketList.length; i++) {
            let item = selectTicketList[i]
            if (item.value == 0) {
                continue //跳过默认值
            }

            if (item.num < 1) {
                common.alert('卡券数量不能小于1')
                return;
            }

            tmpTicketList.push({
                'id': item.value,
                'quantity': item.num,
            })
        }

        let data = {
            member_id: memberId ? memberId : '',            //会员id
            fast_create_card: fastCreateCard,               //是否快速开卡（10:否 20:是）
            data: JSON.stringify({
                commission_list: employeeArr,               //提成人员信息
                paymentMoney: realMoney,                    //实收金额
                card_data: {                                //会员卡信息
                    card_category_id: cardCategoryId.cardCategoryId,       //卡类ID
                    money: issueMoney,                      //冲入卡金额
                    consume_times: consumeCount,            //消费次数  根据卡类类型，当为3时，必传
                    validity_end: validEndStr,              //有效期截止日期  格式为：年-月-日，如：2019-09-09
                    issue: isCheck ? 20 : 10,               //是否有实物卡（10:否 20:有）
                    ic_code: icCode,                        //IC卡编码（非必传，有实物卡则必传）
                    ic_series_number: icSeriesNumber,       //IC卡面号（非必传，有实物卡则必传）
                },
                card_pay_list: storeArr,                    //会员卡支付方式(储值卡)
                card_times_list: newCountArr,               //会员卡支付方式(计次卡)
                pay_list: payListArr,                       //支付方式
                ticket_list: tmpTicketList,                 //赠送卡券
            })
        }

        common.loadingStart()
        common.ajax('post', '/app.php/member-api/card/create-card-v2', data).then((res) => {

            common.toast(res.message)

            // 需要打印就去打印
            if (res.need_print === 1) {
                common.ajax('post', '/app.php/member-api/card/recharge-print', {
                    rechargeId: res.rechargeId
                }).then((result) => {
                    if (result.need_print === 1) {
                        print.print(result.printData, 'printWidth=' + print.printWidth, result.printNum)
                    }
                })
            }

            // 去跳转
            if (fastCreateCard === 20) {
                // 快速开卡进入的，返回会员开卡页
                goOpenCard()
            } else {
                memberUtils.fetchMemberInfo(memberInfo.id) // 会员办卡后，重新加载会员信息

                if (createCard.type === '储值卡') {
                    setAction('storeValue')
                } else if (createCard.type === '计次卡') {
                    setAction('order')
                } else {
                    setAction('limitProject')
                }
            }
        }).finally(common.loadingStop)
    }

    // 获取分配人员可分配的金额
    const getEmployeeAllocable = () => {
        let sum = 0
        if (employeeGroup.length > 0) {
            employeeGroup.forEach((item) => {
                sum += Number(item.money)
            })
            sum = floatSub(realMoney, sum)
        } else {
            sum = realMoney
        }
        if (sum <= 0) {
            return 0
        } else {
            return sum
        }
    }

    //选择卡券列表
    const showSelectTicketDiv = () => {
        // 可以修改
        if (createCard.can_update_ticket == 1) {
            return (
                selectTicketList.map((item, key) =>
                    <div key={key} className={style.consumeItem}>
                        <div className={style.select}>
                            <select value={item.value} onChange={(e) => {
                                selectTicketList[key].value = (e.target.value).toString()
                                if (selectTicketList[key].num == 0) {
                                    selectTicketList[key].num = 1
                                }
                                setSelectTicketList([...selectTicketList])

                            }}>
                                <option value="0">请选择卡券</option>
                                {ticketList.map((item2, key2) => {
                                    return (
                                        <option key={key2} className={expireTicketIds.indexOf(Number(item2.id)) !== -1 && style.optionHide} value={item2.id} key={key}>{item2.title}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className={style.rightAction}>
                            <div className={style.numberBox + ' ' + style.numberInputBox}>
                                <input className={style.number}
                                       value={item.num}
                                       onChange={(e) => {
                                           let val = e.target.value
                                           if (!inputIsTrue(val)) {
                                               return
                                           }

                                           selectTicketList[key].num = val
                                           setSelectTicketList([...selectTicketList])
                                       }}/>
                            </div>
                        </div>

                        {
                            selectTicketList[0].value !== "0" &&
                            <div className={style.closeBox}>
                                <img src={require('@/images/member/close.png')} className={style.closeImage}
                                     onClick={() => {
                                         let arr = selectTicketList
                                         if (arr.length <= 1) {
                                             setSelectTicketList([initSelectTicket])
                                             return
                                         }
                                         arr.splice(key, 1)
                                         setSelectTicketList([...arr])
                                     }}/>
                            </div>
                        }
                    </div>
                )
            )
        }

        // 不可以修改
        if (createCard.can_update_ticket == 2) {
            let tmpSelectTicketList = []
            for (let i = 0; i < selectTicketList.length; i++) {
                let item = selectTicketList[i]
                if (item.value < 1) {
                    continue
                }

                tmpSelectTicketList.push(item)
            }

            return (
                tmpSelectTicketList.map((item, key) =>
                    <div key={key} className={style.consumeItem}>
                        <div className={style.select}>
                            <select disabled="disabled" value={item.value}>
                                {ticketList.map((item2, key2) => {
                                    return (
                                        item.value == item2.id ?
                                            <option className={expireTicketIds.indexOf(Number(item2.id)) !== -1 && style.optionHide} value={item2.id} key={key2}>{item2.title}</option> : null
                                    )
                                })}
                            </select>
                        </div>
                        <div className={style.rightAction}>
                            <div className={style.numberBox + ' ' + style.numberInputBox}>
                                <input className={style.number}
                                       value={item.num}
                                       disabled="disabled"
                                />
                            </div>
                        </div>
                    </div>
                )
            )
        }

        return ''
    }

    // 关闭微信或支付宝支付弹层需要处理的事情
    const closePayWay = () => {
        if (isSubmit.current) {
            let sum = 0
            payLastList.forEach((item) => {
                sum += Number(item)
            })
            if (memberPayTypeGroup) {
                sum += Number(memberPayTypeGroup.money)
            }
            let lastPay = paddZero(floatSub(realMoney, sum))
            setLastPay(JSON.parse(JSON.stringify(lastPay)))

            handleSubmit(lastPay)
        }

        setShowPayWait(false)
    }

    return (
        <div className={style.CardCreate}>

            {
                expireTicketInfoList.length > 0 && <Modal
                    closable={false}
                    footer={
                        <div className={style.tipsBox}>
                            <Button type={'cancel'} shape={'default'}
                                    onClick={() => {
                                        setExpireTicketInfoList([])
                                    }}>取消</Button>
                            <Button style={{marginLeft: '10px'}}
                                    type={'confirm'}
                                    shape={'default'}
                                    onClick={() => {
                                        submit()
                                    }}>确定</Button>
                        </div>
                    }
                >
                    <div className={style.expireBox}>
                        <div className={style.title}>发放的电子券已过期</div>
                        <div className={style.content}>
                            {
                                expireTicketInfoList.map((expireTicketInfo, key) => {
                                    return (
                                        <span key={key}>"{expireTicketInfo.ticket_title}"的有效期至{expireTicketInfo.dateInfo.year+'-'+expireTicketInfo.dateInfo.month+'-'+expireTicketInfo.dateInfo.day}，</span>
                                    )
                                })
                            }
                            目前已过期，无法正常使用，您确认要发放吗？</div>
                    </div>

                </Modal>
            }


            {
                showPayWait && <Modal title=""
                                      closable={false}
                                      footer={false}>
                    <PayWay
                        shouldPay={lastPay}
                        wxOrZhi={wxOrZhi}
                        memberId={memberId}

                        payLastList={{payLastList: payLastList, setPayLastList: setPayLastList}}
                        payTypeListKey={payTypeListKey}
                        payList={{payList: payList, setPayList: setPayList}}
                        itemId={itemId}

                        closePayWay={closePayWay}
                        inputVal={{inputVal: inputVal, setInputVal: setInputVal}}
                        isFocus={{isFocus: isFocus, setIsFocus: setIsFocus}}
                        checked={{checked: checked, setChecked: setChecked}}
                        isSubmit={isSubmit} error={payWayError} setError={setPayWayError}
                        onOk={() => {
                        }}/>
                </Modal>
            }

            <div className={style.headerTitle}>
                <img src={require('@/images/member/left.png')} onClick={goRecordHome} className={style.leftImage}
                     alt=""/>
                会员办卡
                <div className={style.closeImage}>
                    <img src={require('@/images/member/close.png')} onClick={onClose} className={style.closeImage}
                         alt=""/>
                </div>
            </div>
            <div className={style.content}>
                <div className={style.consumeList}>
                    <div className={style.cardTitle}>
                        {createCard.nane}
                    </div>
                    <div className={style.consumeItem}>
                        <div className={style.label}>
                            No: {icFaceNumber}
                        </div>
                        <div className={style.consumeBox}>
                            <input type="checkbox"
                                   className={style.checkbox}
                                   checked={isCheck ? 'checked' : ''}
                                   onChange={() => {
                                       if (isCheck) {
                                           setIcFaceNumber('')
                                           setIcSeriesNumber('')
                                           setIcCode('')
                                       } else {
                                           readCardHandle()
                                       }
                                       setIsCheck(!isCheck)
                                   }}/>
                            <div className={style.checkboxTitle}>发实物卡</div>
                        </div>
                    </div>
                    <div className={style.consumeItem}>
                        <div className={style.label}>
                            发卡金额
                        </div>
                        <div className={style.consumeBox}>
                            <input className={style.price}
                                   type={'number'}
                                   value={issueMoney}
                                   onChange={(e) => {
                                       let val = e.target.value
                                       if (!inputIsTrue(val)) {
                                           return
                                       }

                                       val = String(Number(val))

                                       setIssueMoney(e.target.value)

                                       //冲 1000 送 500 的规则
                                       let realVal = ''
                                       let num = Number(val)

                                       // donation: 是赠送金额 + 单价
                                       let donation = Number(floatAdd(createCard.price, createCard.donation_amount))
                                       if ((+createCard.donation_amount !== 0) && (donation !== 0)) {
                                           if (num < donation) {
                                               realVal = val
                                           } else {
                                               realVal = floatSub(num, parseInt(num / donation) * Number(createCard.donation_amount))
                                           }
                                       } else {
                                           // 没有赠送金额, 实收就是充值金额
                                           realVal = num
                                       }

                                       setRealMoney(realVal)

                                       getLastPay(realVal)
                                   }}
                                   onBlur={() => {
                                       let issueVal = paddZero(Number(issueMoney))
                                       let realVal = paddZero(realMoney)
                                       setIssueMoney(issueVal)
                                       setRealMoney(realVal)
                                       setTmpRealMoney(realVal)

                                       // 获取分配人员可分配的金额
                                       let employeeAllocable = getEmployeeAllocable()
                                       setLast(employeeAllocable)
                                   }}/>
                        </div>
                    </div>
                    <div className={style.consumeItem}>
                        <div className={style.label}>
                            实收金额
                        </div>
                        <div className={style.consumeBox}>
                            <input className={style.price}
                                   type={'number'}
                                   value={realMoney}
                                   onChange={(e) => {
                                       let val = e.target.value
                                       if (!inputIsTrue(val)) {
                                           return
                                       }
                                       val = String(Number(val))
                                       setRealMoney(e.target.value)
                                       getLastPay(val)
                                   }}
                                   onBlur={() => {
                                       setRealMoney(paddZero(Number(realMoney)))
                                       setTmpRealMoney(paddZero(Number(realMoney)))

                                       // 获取分配人员可分配的金额
                                       let employeeAllocable = getEmployeeAllocable()
                                       setLast(employeeAllocable)
                                   }}/>
                        </div>
                    </div>

                    {(createCard.type === '计次卡') ? (
                        <div className={style.consumeItem}>
                            <div className={style.label}>
                                消费次数
                            </div>
                            <div className={style.consumeBox}>
                                <input className={style.price}
                                       type={'number'}
                                       value={consumeCount}
                                       onChange={(e) => {
                                           setConsumeCount(e.target.value)
                                       }}/>
                            </div>
                        </div>
                    ) : ''}

                    <div className={style.consumeItem}>
                        <div className={style.label}>
                            有效期限
                        </div>
                        <div className={style.consumeBox}>
                            <input type={"date"} className={style.price}
                                   value={validEndStr}
                                   onChange={(e) => {
                                       setValidEndStr(e.target.value)
                                   }}/>
                        </div>
                    </div>

                    <div className={style.sellEmployeeBox}>

                        {
                            employeeGroup.map((list, key) =>
                                <div key={key} className={style.consumeItem}>
                                    <div className={style.select}>
                                        <select value={list.employee_id}
                                                onChange={(e) => {
                                                    let arr = employeeGroup
                                                    arr[key].employee_id = e.target.value
                                                    setEmployeeGroup(JSON.parse(JSON.stringify(arr)))
                                                }}>
                                            <option value="0">
                                                请选择销售人员
                                            </option>
                                            {employeeList.map((item, key2) => {
                                                return (
                                                    <option key={key + ':' + key2} value={item.employee_id}
                                                    >{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className={style.rightAction}>
                                        <div className={style.numberBox + ' ' + style.numberInputBox}>
                                            <input className={style.number}
                                                   value={employeeGroup[key].money}
                                                   onChange={(e) => {
                                                       let val = e.target.value
                                                       if (!inputIsTrue(val)) {
                                                           return
                                                       }
                                                       let arr = employeeGroup
                                                       arr[key].money = val
                                                       setEmployeeGroup(JSON.parse(JSON.stringify(arr)))
                                                       getLastValue()
                                                   }}
                                                   onBlur={() => {
                                                       let arr = employeeGroup
                                                       arr[key].money = paddZero(Number(arr[key].money))
                                                       setEmployeeGroup(JSON.parse(JSON.stringify(arr)))
                                                   }}/>
                                        </div>
                                    </div>
                                    <div className={style.closeBox}>
                                        <img src={require('@/images/member/close.png')} className={style.closeImage}
                                             onClick={() => {
                                                 let arr = employeeGroup
                                                 arr.splice(key, 1)
                                                 setEmployeeGroup(JSON.parse(JSON.stringify(arr)))
                                                 getLastValue()
                                             }}/>
                                    </div>
                                </div>
                            )
                        }

                    </div>


                    <div className={style.consumeItem + ' ' + style.selectEmployee}>
                        <div className={style.select}>
                            <select
                                value={employeeId}
                                onChange={(e) => {
                                    setEmployeeId(e.target.value)
                                }}
                            >
                                <option value="0">请选择销售人员</option>
                                {
                                    employeeList.map((item, key) =>
                                        <option key={key} value={item.employee_id}>{item.name}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className={style.rightAction}>
                            <div className={style.numberBox + ' ' + style.numberInputBox}>
                                <input className={style.number}
                                       value={last}
                                       onChange={(e) => {
                                           let val = e.target.value
                                           if (!inputIsTrue(val)) {
                                               return
                                           }
                                           setLast(val)
                                       }}
                                       onFocus={() => {
                                           let sum = getEmployeeAllocable()
                                           setLast(sum)
                                       }}
                                       onBlur={(e) => {
                                           let val = e.target.value
                                           setLast(paddZero(Number(val)))
                                       }}/>
                            </div>
                        </div>
                    </div>

                    <div className={style.addSellEmployee} onClick={() => {
                        if (!employeeId || employeeId === '0') {
                            common.toast('请选择销售人员')
                            return
                        }

                        if (!Number(last)) {
                            common.toast('请输入分配金额')
                            return
                        }

                        if (Number(last) < 0) {
                            common.toast('分配金额不能为负数')
                            return
                        }

                        let arr = employeeGroup.concat([{
                            employee_id: employeeId,
                            money: paddZero(last)
                        }])

                        let newNum = 0
                        arr.forEach((item) => {
                            newNum += Number(item.money)
                        })
                        if (Number(realMoney) < newNum) {
                            common.alert('分配金额不能大于实收金额')
                            return
                        }
                        setLast(floatSub(realMoney, newNum))
                        setEmployeeGroup(arr)
                        setEmployeeId('0')

                        // setTimeout(() => {
                        //     leftBox.current.scrollTop += 49
                        // }, 10)
                    }}>
                        <div className={style.add}>+</div>
                        添加销售人员
                    </div>

                    {/*可以修改或有卡类默认的赠送卡券时，展示提示语*/}
                    {
                        (createCard.can_update_ticket == 1 || (selectTicketList.length > 0 && selectTicketList[0].value > 0)) &&
                        <div className={style.toastItem}>
                            <img src={require('@/images/member/state.png')} className={style.toastImage} alt=""/>
                            以下为开卡自动赠送的电子券，请核对后操作
                        </div>
                    }

                    <div className={style.sellEmployeeBox}>
                        {
                            showSelectTicketDiv()
                        }
                    </div>

                    {
                        (createCard.can_update_ticket == 1 && (selectTicketList.length > 0 && selectTicketList[0].value > 0)) &&
                        <div className={style.addSellEmployee}
                             style={{marginTop: '10px'}}
                             onClick={() => {
                                 selectTicketList.push(initSelectTicket)
                                 setSelectTicketList([...selectTicketList])
                             }}>
                            <div className={style.add}>+</div>
                            添加卡券
                        </div>
                    }

                </div>
                <div className={style.checkoutList}>
                    {
                        payTypeList.map((item, key) => {

                            if (item.tag === 5 || item.tag === 6) {
                                return (
                                    <div key={key} className={style.payList} onClick={() => {
                                        if (Number(lastPay) > 0) {
                                            // 获取提成人员
                                            let employeeArr = checkAndGetEmployeeBeforeSubmit()
                                            if (!employeeArr) {
                                                return
                                            }

                                            if (!payLastList[key]) {
                                                if (item.tag === 5) {
                                                    setWxOrZhi(10)
                                                } else {
                                                    setWxOrZhi(20)
                                                }
                                                setInputVal('')
                                                setPayTypeListKey(key)
                                                setItemId(item.id)
                                                setIsFocus(true)
                                                setChecked(10)
                                                isSubmit.current = false
                                                setPayWayError('')
                                                setShowPayWait(true)
                                            }
                                        } else {
                                            common.toast('支付金额已满，请核对支付方式')
                                        }
                                    }}>
                                        <div className={style.label}>
                                            {item.name}
                                        </div>
                                        <div className={style.priceBox}>
                                            <input style={{pointerEvents: "none"}} className={style.price} value={payLastList[key] || ''} disabled={true}/>
                                        </div>
                                    </div>
                                )
                            } else if (item.tag === 2) {
                                return ""
                            } else {
                                return (
                                    <div key={key} className={style.payList}>
                                        <label className={style.label} for={"label" + key}>
                                            {item.name}
                                        </label>
                                        <div className={style.priceBox}>
                                            <input id={"label" + key} className={style.price}
                                                   value={payLastList[key] || ''}
                                                   onChange={(e) => {
                                                       if (payList[key]) {

                                                           let val = e.target.value

                                                           // 最多两位小数
                                                           if (!inputIsTrue(val)) {
                                                               return
                                                           }
                                                           val = String(Number(val))

                                                           let arr = payLastList
                                                           arr[key] = e.target.value
                                                           setPayLastList(JSON.parse(JSON.stringify(arr)))

                                                           // 银行卡总支付金额
                                                           let sum = getPayLastListNum()

                                                           // 储值卡新增支付的总金额
                                                           let cardSum = getStoredGroupNum()

                                                           // 储值卡正输入的金额
                                                           let storedSum = getStoredMoney()

                                                           // 计次卡新增支付的总金额
                                                           let timesSum = getTimesGroupNum()

                                                           // 计次卡正在输入的金额
                                                           let timesKeepSum = getTimesMoney()

                                                           // 支付金额全部相加
                                                           let paySum = floatAdd(floatAdd(floatAdd(floatAdd(cardSum, sum), timesSum), timesKeepSum), storedSum)

                                                           setLastPay(paddZero(floatSub(realMoney, paySum)))

                                                           payList[key].money = val
                                                           setPayList(JSON.parse(JSON.stringify(payList)))
                                                       }
                                                   }}
                                                   onFocus={() => {
                                                       let realVal = Number(realMoney)
                                                       if (realVal) {
                                                           if (!payLastList[key] || payLastList[key] === '0.00') {
                                                               let arr = payLastList

                                                               // 银行卡总支付金额
                                                               let sum = getPayLastListNum()

                                                               // 储值卡新增支付的总金额
                                                               let cardSum = getStoredGroupNum()

                                                               // 储值卡正输入的金额
                                                               let storedSum = getStoredMoney()

                                                               // 计次卡新增支付的总金额
                                                               let timesSum = getTimesGroupNum()

                                                               // 计次卡正在输入的金额
                                                               let timesKeepSum = getTimesMoney()

                                                               // 支付金额全部相加
                                                               let paySum = floatAdd(floatAdd(floatAdd(floatAdd(sum, cardSum), storedSum), timesSum), timesKeepSum)

                                                               if (realVal != paySum) {
                                                                   setLastPay(floatSub(realMoney, floatAdd(floatSub(realVal, paySum), paySum)))
                                                                   arr[key] = floatSub(realVal, paySum)
                                                                   setPayLastList(JSON.parse(JSON.stringify(arr)))
                                                                   payList[key] = {
                                                                       id: item.id,
                                                                       money: floatSub(realVal, paySum),
                                                                       mobile_pay_code: '',
                                                                   }
                                                                   setPayList(JSON.parse(JSON.stringify(payList)))
                                                               } else {
                                                                   payList[key] = {
                                                                       id: item.id,
                                                                       money: '',
                                                                       mobile_pay_code: '',
                                                                   }
                                                                   setPayList(JSON.parse(JSON.stringify(payList)))
                                                               }
                                                           }
                                                       }
                                                   }}
                                                   onBlur={() => {
                                                       if (payLastList[key]) {
                                                           let arr = payLastList
                                                           arr[key] = paddZero(Number(arr[key]))
                                                           setPayLastList(JSON.parse(JSON.stringify(arr)))
                                                       }
                                                   }}/>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }

                    {/*<div className={style.payList}>*/}
                    {/*    <div className={style.label}>*/}
                    {/*        抵扣券*/}
                    {/*    </div>*/}
                    {/*    <div className={style.priceBox}>*/}
                    {/*        <input className={style.price} placeholder={'0.00'} readOnly={true}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={style.payList}>*/}
                    {/*    <div className={style.label}>*/}
                    {/*        免单*/}
                    {/*    </div>*/}
                    {/*    <div className={style.priceBox}>*/}
                    {/*        <input className={style.price} placeholder={'0.00'} readOnly={true}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={style.payList}>*/}
                    {/*    <div className={style.label}>*/}
                    {/*        支付宝*/}
                    {/*    </div>*/}
                    {/*    <div className={style.priceBox}>*/}
                    {/*        <input className={style.price} placeholder={'0.00'} readOnly={true}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={style.payList}>*/}
                    {/*    <div className={style.label}>*/}
                    {/*        支付宝支付*/}
                    {/*    </div>*/}
                    {/*    <div className={style.priceBox}>*/}
                    {/*        <input className={style.price} value={'1288.00'} placeholder={'0.00'} readOnly={true}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={style.payList}>*/}
                    {/*    <div className={style.label}>*/}
                    {/*        内部*/}
                    {/*    </div>*/}
                    {/*    <div className={style.priceBox}>*/}
                    {/*        <input className={style.price} placeholder={'0.00'} readOnly={true}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={style.payList}>*/}
                    {/*    <div className={style.label}>*/}
                    {/*        银行卡*/}
                    {/*    </div>*/}
                    {/*    <div className={style.priceBox}>*/}
                    {/*        <input className={style.price} placeholder={'0.00'} readOnly={true}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={style.payList}>*/}
                    {/*    <div className={style.label}>*/}
                    {/*        更多*/}
                    {/*        <img src={require('@/images/member/bottom.png')} className={style.bottomImage} alt=""/>*/}
                    {/*    </div>*/}
                    {/*    <div className={style.priceBox}>*/}
                    {/*        <input className={style.price} placeholder={'0.00'} readOnly={true}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={style.checkoutBottom}>
                        <div className={style.checkoutBox}>
                            {
                                storedCard.length > 0 &&
                                <>
                                    <div className={style.checkoutTitle}>
                                        储值卡
                                    </div>
                                    <div className={style.selectBox}>
                                        <div className={style.select}>
                                            <select
                                                value={memberPayTypeGroup.card_id || ''}
                                                onChange={(e) => {
                                                    let val = e.target.value

                                                    // 银行卡支付的总金额
                                                    let sum = getPayLastListNum()

                                                    // 储值卡新增支付的总金额
                                                    let cardSum = getStoredGroupNum()

                                                    // 计次卡新增支付的总金额
                                                    let timesSum = getTimesGroupNum()

                                                    // 计次卡正在输入的金额
                                                    let timesKeepSum = getTimesMoney()

                                                    // 支付金额全部相加
                                                    let paySum = floatAdd(floatAdd(floatAdd(cardSum, sum), timesSum), timesKeepSum)

                                                    // 不做选择计算出还需支付, 并清空储值卡后面输入的金额
                                                    if (val === '0') {
                                                        setMemberPayTypeGroup('')
                                                        setLastPay(floatSub(realMoney, paySum))
                                                        return
                                                    }

                                                    let realVal = Number(realMoney)
                                                    let memberMoney = ''

                                                    // 循环储值卡列表, 如果id和所选的值一样, 则记录该卡的金额
                                                    for (let i = 0; i < storedCard.length; i++) {
                                                        if (String(storedCard[i].id) === val) {
                                                            memberMoney = storedCard[i].money
                                                            break
                                                        }
                                                    }

                                                    let money = ''

                                                    // 如果实收金额大于银行卡已支付的金额和已选储值卡的金额之和
                                                    if (realVal > paySum) {
                                                        // 还需支付 = 实收金额 - 银行卡和储值卡的总和
                                                        let newSum = floatSub(realVal, paySum)

                                                        // 如果会员卡能支付的金额小于剩余金额, 就存卡能支付的金额, 否则存还需支付的金额
                                                        if (Number(memberMoney) <= Number(newSum)) {
                                                            money = paddZero(memberMoney)
                                                        } else {
                                                            money = paddZero(newSum)
                                                        }

                                                        // 剩余金额就是(实收金额 - (会员卡金额 + 银行卡和储值卡的总和))
                                                        setLastPay(floatSub(realMoney, floatAdd(money, paySum)))

                                                    }

                                                    // 保存为一个对象
                                                    setMemberPayTypeGroup({
                                                        ...memberPayTypeGroup,
                                                        card_id: val,
                                                        money: money
                                                    })
                                                }}>
                                                <option value="0">选择会员卡</option>

                                                {storedCard.map((item, key) => {
                                                    return (
                                                        // card_category_name: "打折加送钱", number: "202004088889", money: "5849.00"
                                                        <option value={item.id} key={key}>
                                                            {item.card_category_name + ' ('}
                                                            {item.number + ')--'}
                                                            {item.money}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className={style.selectTitle}>
                                            <div className={style.priceBox}>
                                                <input className={style.price} placeholder={'金额'}
                                                       type={'number'}
                                                       onChange={(e) => {
                                                           let val = Number(e.target.value)

                                                           // 选了会员卡才能操作
                                                           if (memberPayTypeGroup) {
                                                               let obj = memberPayTypeGroup
                                                               obj.money = e.target.value
                                                               setMemberPayTypeGroup(JSON.parse(JSON.stringify(obj)))

                                                               // 银行卡支付总金额
                                                               let sum = getPayLastListNum()

                                                               // 储值卡新增支付的总金额
                                                               let cardSum = getStoredGroupNum()

                                                               // 计次卡新增支付的总金额
                                                               let timesSum = getTimesGroupNum()

                                                               // 计次卡正在输入的金额
                                                               let timesKeepSum = getTimesMoney()

                                                               // 银行卡支付总金额 + 储值卡新增支付的总金额
                                                               let allSum = floatAdd(floatAdd(floatAdd(sum, cardSum), timesSum), timesKeepSum)

                                                               setLastPay(floatSub(realMoney, floatAdd(val, allSum)))

                                                           } else {
                                                               common.toast('请先选择会员卡')
                                                           }
                                                       }}
                                                       onBlur={(e) => {
                                                           let val = Number(e.target.value)
                                                           if (memberPayTypeGroup && (e.target.value !== '')) {
                                                               let obj = memberPayTypeGroup
                                                               obj.money = paddZero(val) + ''
                                                               setMemberPayTypeGroup(JSON.parse(JSON.stringify(obj)))
                                                           }
                                                       }}/>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                countCard.length > 0 &&
                                <>
                                    <div className={style.checkoutTitle} style={{marginTop: '10px'}}>
                                        计次卡
                                    </div>
                                    <div className={style.selectBox}>
                                        <div className={style.select}>
                                            <select
                                                value={cardTimesGroup.card_id || ''}
                                                onChange={(e) => {
                                                    let val = e.target.value

                                                    // 银行卡支付总金额
                                                    let sum = getPayLastListNum()

                                                    // 储值卡新增支付的总金额
                                                    let cardSum = getStoredGroupNum()

                                                    // 储值卡正输入的金额
                                                    let storedSum = getStoredMoney()

                                                    // 计次卡新增支付的总金额
                                                    let timesSum = getTimesGroupNum()

                                                    let allSum = floatAdd(floatAdd(floatAdd(sum, cardSum), storedSum), timesSum)

                                                    // 不做选择计算出还需支付, 并清空储值卡后面输入的金额
                                                    if (val === '0') {
                                                        setCardTimesGroup('')
                                                        setLastPay(floatSub(realMoney, allSum))
                                                        return
                                                    }

                                                    // 当前选择的计次卡的余额
                                                    let countMoney = ''
                                                    // 循环计次卡列表, 如果id和所选的值一样, 则记录该卡的金额
                                                    for (let i = 0; i < countCard.length; i++) {
                                                        if (countCard[i].id.toString() === val.toString()) {
                                                            countMoney = countCard[i].money
                                                            break
                                                        }
                                                    }

                                                    let realVal = Number(realMoney)

                                                    // 还需支付 = 实收金额 - 其余金额的总和
                                                    let newSum = floatSub(realVal, allSum)

                                                    // 如果会员卡能支付的金额大于剩余金额, 就报错, 次卡只能全部支付
                                                    let money = paddZero(countMoney)
                                                    if (Number(countMoney) > Number(newSum)) {
                                                        common.toast('计次卡剩余金额大于还需支付金额')
                                                        return
                                                    }

                                                    // 剩余金额就是(实收金额 - (会员卡金额 + 银行卡和储值卡的总和))
                                                    setLastPay(floatSub(realMoney, floatAdd(money, allSum)))
                                                    setCardTimesGroup({
                                                        ...cardTimesGroup,
                                                        card_id: val,
                                                        money: money
                                                    })
                                                }}>
                                                <option value="0">选择会员卡</option>

                                                {countCard.map((item, key) => {
                                                    return (
                                                        // card_category_name: "打折加送钱", number: "202004088889", money: "5849.00"
                                                        <option value={item.id} key={key}>
                                                            {item.card_category_name + ' ('}
                                                            {item.number + ')--'}
                                                            {item.consume_times + ' ('}
                                                            {item.money + ')'}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {/*<div className={style.selectTitle}>*/}
                                        {/*    <div className={style.priceBox}>*/}
                                        {/*        */}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </>
                            }

                            <div className={style.checkoutRemark}>
                                <div className={style.leftRemark}>
                                    还需支付
                                </div>
                                <div className={style.priceRemark}>
                                    ￥{lastPay || '0.00'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.bottomAction}>
                <div className={style.readCardBox}>
                    {
                        isCheck && <Button shape={'default'} type={'cancel'} hover={'default'} onClick={readCardHandle}>
                            <div className={style.readCard}>
                                <img src={require('@/images/member/readCard.png')} className={style.readCardImage} alt=""/>
                                读卡
                            </div>
                        </Button>
                    }

                </div>
                <Button shape={'default'} type={'cancel'} onClick={() => {
                    if (fastCreateCard === 20) {
                        goOpenCard()
                    } else {
                        goRecordHome()
                    }
                }}>取消</Button>
                <Button shape={'default'} style={{marginLeft: '10px'}} type={'confirm'}
                        onClick={() => handleSubmit(lastPay)}>确认</Button>
            </div>
        </div>
    );
}

export default CardCreate;