import React, {useState, useEffect} from 'react';
import style from './style.module.less'
import MemberDetail from "../components/memberDetail";
import DetailModal from "../components/detailModal";
import Button from "../../public/button";
import couponBg from "../../../images/member/coupon.png";
import {useSelector} from "react-redux";
import common from "../../../utils/common";

function PutOffCard(props) {
    const {setAction, onClose} = props
    const {activeCard, memberInfo} = useSelector(state => state.member)

    const couponBgStyle = {
        backgroundImage: "url(" + couponBg + ")",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
    }

    let [validMessage, setValidMessage] = useState({})  // 延期卡的信息
    let [validTime, setValidTime] = useState('')  // 延期的时间

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/app.php/member-api/card/info-for-valid', {
            member_id: memberInfo.id,
            card_id: activeCard.id,
        }).then((res) => {
            setValidMessage(res)
            setValidTime(res.valid_end_date)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    // 返回页面
    const goBackAction = () => {
        switch (activeCard.card_category_type) {
            case 2: // 储值卡
                setAction('storeValue')
                break
            case 3: // 计次卡
                setAction('order')
                break
            case 5: // 专享卡
                setAction('limitProject')
                break
            default:
                break
        }
    }

    // 去延期
    const handleSubmit = () => {
        if (!validTime) {
            common.toast('请选择到期时间')
            return
        }

        common.loadingStart()
        common.ajax('post', '/app.php/member-api/card/update-valid', {
            member_id: memberInfo.id,
            card_id: activeCard.id,
            date: validTime,
        }).then((res) => {
            common.toast(res.message)
            goBackAction()
        }).finally(common.loadingStop)
    }

    // 获取选中菜单
    const getNav = () => {
        switch (activeCard.card_category_type) {
            case 2: // 储值卡
                return 'storeValue'
            case 3: // 计次卡
                return 'order'
            case 5: // 专享卡
                return 'limitProject'
            default:
                return ''
        }
    }

    return (
        <div className={style.PutOffCard}>
            <MemberDetail nav={getNav()} setAction={setAction}/>
            <DetailModal closable={true} title={'会员卡延期'} onBack={() => goBackAction()} footer={
                <div className={style.buttonBox}>
                    <Button type={'cancel'} shape={'default'}
                            onClick={() => {
                                goBackAction()
                            }}>取消</Button>
                    <Button style={{marginLeft: '10px'}}
                            type={'confirm'}
                            shape={'default'}
                            onClick={() => {
                                handleSubmit()
                            }}>确定</Button>
                </div>
            } onCancel={onClose}>
                <div className={style.contentBox}>
                    <div className={style.couponDiv} style={couponBgStyle}>
                        <div className={style.topCouponDiv}>
                            <div className={style.couponName}>{validMessage.card_category_name}</div>
                            <div className={style.couponMoney}> {validMessage.mone &&
                                <span>￥</span>}{validMessage.money}</div>
                        </div>

                        <div className={style.bottomCouponDiv}>
                            <div>{validMessage.number && 'No.'}{validMessage.number}</div>
                            <div>{validMessage.user_name}</div>
                        </div>
                    </div>

                    <div className={style.cardInputDiv}>
                        <div>到期时间</div>
                        <div className={style.inputDateDiv}>
                            <input type="date" value={validTime}
                                   onChange={(e) => {
                                       setValidTime(e.target.value)
                                   }}/>
                        </div>
                    </div>

                </div>


            </DetailModal>
        </div>
    );
}

export default PutOffCard;