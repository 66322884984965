import React, {useEffect, useState} from 'react';
import style from './style.module.less'
import Button from "../../../../public/button";
import common from "../../../../../utils/common";

function Find(props) {

    const {nav, goRecordHome} = props

    const [selectMessage, setSelectMessage] = useState({
        card_number: '',                        //查询的卡号
        name: '',                               //查询的姓名
        mobile: '',                             //查询的手机号
    })
    const [query, setQuery] = useState({})
    const [mobileList, setMobileList] = useState([])            //storage里的手机号
    const [list, setList] = useState([])          //查询到的数据
    let [mobileDivFocus, setMobileDivFocus] = useState(false) //判断手机号输入框聚焦

    useEffect(() => {
        if (selectMessage.mobile.length === 11) {
            setQuery(selectMessage)
        }
    }, [selectMessage.mobile])

    useEffect(() => {
        setList([])

        if (query.card_number || query.name || query.mobile) {
            selectHandle()
        }
    }, [query])

    const selectHandle = () => {
        common.loadingStart()
        common.ajax('get', '/app.php/member-api/member/find', {
            card_number: query.card_number,
            name: query.name,
            mobile: query.mobile,
        }).then((res) => {
            let arr = []
            for (let i = 0; i < res.length; i++) {
                let item = res[i]
                item.isShow = false // 是否显示完整手机
                item.mobile = handPhoneFormat(item.mobile)
                item.preMobile = item.mobile // 原加密手机号
                arr.push(item)
            }
            setList(arr)

            // 只有进行完整手机号搜索的，才将手机号和名称保留在前端
            if (query.mobile && query.mobile.length === 11) {
                common.saveMobile(query.mobile, arr[0].name)
                getStorageMobile()
            }
        }).catch((err) => {
            if (query.mobile && query.mobile.length === 11) {
                common.saveMobile(query.mobile, '')
                getStorageMobile()
            }
        }).finally(common.loadingStop)
    }

    //处理号码格式
    const handPhoneFormat = (phone) => {
        phone = phone + ''
        if (phone.length === 11) {
            return phone.replace(/^(.{3})(.{4})(.*)$/, '$1 $2 $3');
        }

        if (phone.length === 8) {
            return phone.replace(/(.{4})(?=.)/g, '$1 ');
        }
        return phone;
    }

    // 获取储存的手机号
    const getStorageMobile = () => {
        let arr = JSON.parse(localStorage.getItem('mobileList')) || []
        setMobileList(arr)
    }

    useEffect(() => {
        getStorageMobile()
    }, [])

    useEffect(() => {
        if (selectMessage.mobile.length === 11) {
            setQuery(selectMessage)
            setMobileDivFocus(false)
        }
    }, [selectMessage.mobile])

    const getAllMobile = (id) => {
        common.loadingStart()
        common.ajax('get', '/app.php/member-api/member/copy-mobile', {
            id: id
        }).then((res) => {

            let arr = []
            for (let i = 0; i < list.length; i++) {
                let item = list[i]

                item.isShow = false
                item.mobile = item.preMobile // 加密手机号
                if (item.id.toString() === id.toString()) {
                    item.isShow = true
                    item.mobile = handPhoneFormat(res)
                }

                arr.push(item)
            }

            setList(arr)
        }).catch((err) => {
        }).finally(common.loadingStop)
    }

    return (
        <div className={[style.Find, nav !== 'find' && style.noneDiv].join(' ')}>
            <div className={style.searchInputBox}>
                <input type="text" placeholder={'请输入卡号'} className={style.input}
                       value={selectMessage.card_number}
                       onChange={(e) => {
                           setSelectMessage({...selectMessage, card_number: e.target.value})
                       }}
                       onKeyDown={(e) => {
                           if (e.keyCode === 13) {
                               setQuery(selectMessage)
                           }
                       }}
                />
                <input type="text" placeholder={'请输入姓名'} className={style.input}
                       value={selectMessage.name}
                       onChange={(e) => {
                           setSelectMessage({...selectMessage, name: e.target.value})
                       }}
                       onKeyDown={(e) => {
                           if (e.keyCode === 13) {
                               setQuery(selectMessage)
                           }
                       }}/>

                <div className={style.mobileInput}>
                    <input type="text" placeholder={'请输入手机号'} className={style.input}
                           value={selectMessage.mobile}
                           onChange={(e) => {
                               let val = e.target.value
                               if (val.length > 11) {
                                   return
                               }
                               val = val.replace(/\D/g, '')
                               setSelectMessage({...selectMessage, mobile: val})
                           }}
                           onKeyDown={(e) => {
                               if (e.keyCode === 13) {
                                   setQuery(selectMessage)
                               }
                           }}
                           onFocus={() => {
                               setMobileDivFocus(true)
                           }}
                           onBlur={() => {
                               setTimeout(() => {
                                   setMobileDivFocus(false)
                               }, 200)
                           }}/>

                    {
                        (mobileList.length > 0) &&
                        <div className={[style.selectBox, !mobileDivFocus && style.noneDiv].join(' ')}>
                            <div className={style.selectItem}>
                                {
                                    mobileList.map((item, key) =>
                                        <div key={key} className={style.text} onClick={(e) => {
                                            let obj = {...selectMessage, mobile: item.mobile}
                                            setSelectMessage(obj)
                                            setQuery(obj)
                                        }}>
                                            <div>{handPhoneFormat(item.mobile)}</div>
                                            <div className={style.textRight}>{item.name}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }

                </div>
                <Button type={'confirm'} shape={'default'}
                        onClick={() => {
                            setQuery(selectMessage)
                        }}
                >搜索</Button>
            </div>
            <div className={style.content}>
                {
                    list.length > 0 ?
                        <div className={style.tableBox}>
                            <table>
                                <thead>
                                <tr>
                                    <th style={{width: '170px'}}>姓名</th>
                                    <th style={{width: '200px'}}>电话</th>
                                    <th style={{width: '210px'}}>开户门店</th>
                                    <th className={style.textRight}>操作</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    list.map((item, index) =>
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td className={style.mobileDiv}>{item.mobile} {
                                                (item.mobile && !item.isShow) &&
                                                <div className={style.showMobileFont} onClick={() => {
                                                    if (item.isShow) {
                                                        return
                                                    }

                                                    getAllMobile(item.id)
                                                }}>全显手机号</div>
                                            }</td>
                                            <td>{item.user_name}</td>
                                            <td className={style.textRight + ' ' + style.find}><span onClick={() => {
                                                goRecordHome(item.id)
                                            }}>查看</span></td>
                                        </tr>
                                    )
                                }

                                {/*<tr>*/}
                                {/*    <td>黄先生</td>*/}
                                {/*    <td>*/}
                                {/*        <input type="text" className={style.tableInput} placeholder={'请输入手机号...'}/>*/}
                                {/*    </td>*/}
                                {/*    <td>海上半岛模拟店</td>*/}
                                {/*    <td className={style.textRight + ' ' + style.find}>查看</td>*/}
                                {/*</tr>*/}
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className={style.noData}>暂无会员数据</div>
                }
            </div>
        </div>
    );
}

export default Find;