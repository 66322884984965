import React from 'react';
import Close from '../../../images/close.png'
import style from './style.module.less'

function Modal(props) {

    const {title = '', closable = true, footer, children, onCancel,onBgCb = () => {}, ...rest} = props

    return (
        <div className={style.Modal} onClick={onBgCb}>
            <div className={style.modalContent} {...rest}>
                {
                    (closable || title !== '') && <div className={style.title}>
                        {title}
                        {
                            closable && <div className={style.closeImage} onClick={onCancel}>
                                <img className={style.closeImg} src={Close} alt=""/>
                            </div>
                        }

                    </div>
                }

                <div className={style.content}>
                    {children}
                </div>
                {footer && <div className={style.action}>
                    {footer}
                </div>}

            </div>
        </div>
    );
}

export default Modal;