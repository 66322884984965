import React, {useEffect, useState, useRef} from 'react';
import style from './style.module.less'
import CouponCard from "../couponCard";
import EmptyCardCreate from "./emptyCardCreate";
import CardCreate from "./cardCreate";
import common from "../../../utils/common";
import {useSelector} from "react-redux";

function IssuanceCardCoupon(props) {
    const {memberInfo} = useSelector(state => state.member)

    const {fastCreateCard, setAction, goRecordHome, onClose, goOpenCard} = props

    const [navType, setNavType] = useState(0)                         //选中的卡类型id
    const [cardCategoryId, setCardCategoryId] = useState('')          //选中的卡类id
    const [cardTypeList, setCardTypeList] = useState([])              //卡类型列表
    const [cardTypeChildList, setCardTypeChildList] = useState([])    //卡类型子列表
    const [cardAllList, setCardAllList] = useState([])                //卡类型所有列表
    const [cardList, setCardList] = useState([])                      //卡类型下的列表
    // let [init, setInit] = useState(true)                            //判断是否是第一次进入页面
    const [employeeList, setEmployeeList] = useState([])              //可提成的员工列表
    const [payTypeList, setPayTypeList] = useState([])                //会员开卡充值支付方式列表
    const [memberPayTypeList, setMemberPayTypeList] = useState([])    //会员开卡充值支付方式(会员卡列表)列表

    const [ticketList, setTicketList] = useState([])                  //所有卡券列表
    const [selectTicketList, setSelectTicketList] = useState([])                  //所有卡券列表

    const continueShow = useRef(false)

    useEffect(() => {
        // 10:普通办卡 20：快速办卡
        if (fastCreateCard === 10 || fastCreateCard === 20) {
            //卡类的所有类型, 获取可提成的员工列表, 获取会员开卡充值支付方式
            common.loadingStart()
            Promise.all([getCardCategoryType(), getEmployeeList(), getPayType(), getTicketList()]).finally(common.loadingStop)
        } else {
            common.alert('参数错误')
        }

        return () => {
            if (continueShow.current === false) {
                let closeCb = props.closeCb || function () {
                }
                closeCb();
            }

        }
    }, [])

    //卡类的所有类型
    const getCardCategoryType = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/card-category').then((res) => {
                if (res.length > 0) {
                    sortSmallToBig(res)
                }
                setCardAllList(res)
                setCardList(res)
                let cardTypeArr = []
                res.forEach((item) => {
                    cardTypeArr.push(item.card_type)
                })

                common.ajax('get', '/app.php/member-api/card-category/card-type').then((result) => {
                    let arr = []
                    let arrSon = []
                    result.forEach((item) => {
                        if (cardTypeArr.indexOf(item.id) !== -1) {
                            if (item.pid === 0) {
                                arr.push(item)
                            } else {
                                arrSon.push(item)
                            }
                        }
                    })
                    for (let i = 0; i < arr.length; i++) {
                        for (let j = 0; j < arrSon.length; j++) {
                            if (arr[i].id === arrSon[j].pid) {
                                arr[i].haveSon = 1
                            }
                        }
                    }
                    setCardTypeList(arr)
                    setCardTypeChildList(arrSon)

                })
                resolve()
            }).catch((error) => {
                reject()
            })
        })

    }

    //获取卡类型列表数据
    const getCardType = (id) => {
        let idArr = []
        let arr = []
        cardTypeChildList.forEach((item) => {
            if (item.pid === id) {
                idArr.push(item.id)
            }
        })
        idArr.push(id)
        idArr.forEach((idItem) => {
            cardAllList.forEach((item) => {
                if (item.card_type === idItem) {
                    arr.push(item)
                }
            })
        })
        if (arr.length > 0) {
            sortSmallToBig(arr)
        }
        setCardList(arr)
    }

    // 从小到大排序
    const sortSmallToBig = (arr) => {
        arr.sort((a, b) => {
            return Number(a.price) - Number(b.price)
        })
    }

    //获取可提成的员工列表
    const getEmployeeList = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/employee/recharge-employee-list').then((res) => {
                setEmployeeList(res)
                resolve()
            }).catch((err) => {
                reject()
            })
        })
    }

    //获取卡券列表
    const getTicketList = () => {
        return new Promise((resolve, reject) => {
            setTicketList([]); // 先清空

            common.ajax('get', '/app.php/member-api/ticket').then((res) => {
                setTicketList(res)
                resolve()
            }).catch((err) => {
                reject()
            })
        })
    }

    //获取卡类默认派发的卡券数据
    const getTicketListByCardCategory = (categoryId) => {
        setSelectTicketList([]); // 先清空

        common.ajax('get', '/app.php/member-api/ticket/list-for-card', {card_category_id: categoryId}).then((res) => {
            setSelectTicketList(res)
        })
    }

    //获取会员开卡充值支付方式
    const getPayType = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/pay-type/recharge-v2', {member_id: memberInfo.id || ''}).then((res) => {
                setPayTypeList(res)
                let arr = []
                res.forEach((item) => {
                    if (item.tag === 2) {
                        arr = arr.concat(item.card_list)
                    }
                })
                setMemberPayTypeList(JSON.parse(JSON.stringify(arr)))

                resolve()
            }).catch((err) => {
                reject()
            })
        })
    }

    const [more, setMore] = useState(false)

    return (
        <div className={style.issuanceCardCoupon}>
            <div className={style.leftBox}>
                <div className={style.headerMenu}>
                    <div className={style.menuList}>
                        {/*<div className={style.menuItem}>*/}
                        {/*    通用*/}
                        {/*</div>*/}
                        {
                            cardTypeList.map((item, index) => {

                                if (!more && index > 5) {
                                    return
                                }

                                return (
                                    <div key={index}
                                         className={style.menuItem + ' ' + (item.id === navType && style.act)}
                                         onClick={() => {
                                             getCardType(item.id)
                                             setNavType(item.id)
                                             setCardCategoryId('')
                                         }}>
                                        {item.value}

                                        {item.haveSon &&
                                            <img src={require('@/images/member/bottom.png')}
                                                 className={style.bottomImage}
                                                 alt=""/>}

                                        {item.haveSon && <div className={style.selectBox}>
                                            {
                                                cardTypeChildList.map((list, key) =>
                                                    <div key={key} className={style.selectItem}>
                                                        <div className={style.text} onClick={(e) => {
                                                            e.stopPropagation()
                                                            getCardType(list.id)
                                                            setNavType(list.pid)
                                                            setCardCategoryId('')
                                                        }}>
                                                            {list.value}
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            {/*<div className={style.selectItem}>*/}
                                            {/*    <div className={style.text}>*/}
                                            {/*        计次卡*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className={style.selectItem}>*/}
                                            {/*    <div className={style.text}>*/}
                                            {/*        储值卡储值卡储值卡储值卡*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className={style.selectItem}>*/}
                                            {/*    <div className={style.text}>*/}
                                            {/*        计次卡*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>}
                                    </div>
                                )
                            })
                        }
                    </div>

                    {
                        cardTypeList.length > 4 &&
                        <div className={style.selectRight} onClick={() => {
                            setMore(!more)
                        }}>
                            {more ? <img src={require('@/images/member/selectTop.png')} className={style.bottomImage}
                                         alt=""/>
                                :
                                <img src={require('@/images/member/selectBottom.png')} className={style.bottomImage}
                                     alt=""/>}
                        </div>
                    }

                </div>
                <div className={style.cardList}>
                    {
                        cardList.map((item, index) => {
                            return (
                                <div key={index}
                                     className={style.cardItem + ' ' + (cardCategoryId === item.id && style.act)}
                                     onClick={() => {
                                         setCardCategoryId(item.id)
                                         getTicketListByCardCategory(item.id)
                                     }}>
                                    <CouponCard item={item}></CouponCard>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            {
                cardCategoryId ?
                    <CardCreate memberId={memberInfo.id}
                                onClose={onClose}
                                cardCategoryId={{cardCategoryId: cardCategoryId, setCardCategoryId: setCardCategoryId}}
                                fastCreateCard={fastCreateCard}
                                goOpenCard={goOpenCard}
                                employeeList={employeeList}
                                ticketList={ticketList}
                                defaultSelectTicketList={selectTicketList}
                                payTypeList={payTypeList}
                                setAction={setAction} goRecordHome={() => goRecordHome(memberInfo.id)}
                                memberPayTypeList={memberPayTypeList}/> : <EmptyCardCreate
                        goOpenCard={goOpenCard} fastCreateCard={fastCreateCard} onClose={onClose} goRecordHome={() => goRecordHome(memberInfo.id)}/>
            }
        </div>
    );
}

export default IssuanceCardCoupon;