import React from 'react';
import style from './style.module.less'
import addressImg from "../../../../../images/member/address.png";
import shopImg from "../../../../../images/member/shop.png";
import userImg from "../../../../../images/member/user.png";

function MemberConsumeRecord(props) {
    const {orderList} = props

    return (
        <div className={style.MemberConsumeRecord}>
            {
                orderList.map((item, index) =>
                    <div key={index} className={style.recordItem}>
                        <div className={style.timeLineBox}>
                            <div className={style.circle}></div>
                        </div>
                        <div className={style.recordInfo}>
                            <div className={style.recordHeader}>
                                <div className={style.time}>{item.finish_at}</div>
                                <div className={style.orderNumber}>订单编号：{item.order_number}</div>
                            </div>

                            {
                                item.consume_list.map((list, key) =>
                                    <div key={key} className={style.recordProject}>
                                        <div className={style.imgDiv}>
                                            <img src={list.project_img_url} className={style.projectImg} />
                                        </div>
                                        <div className={style.projectDiv}>
                                            <div className={style.row}>
                                                <div className={style.projectName}> {list.project_name} <span className={style.qty}>x{list.quantity}</span></div>
                                                <div className={style.moneyDiv}>￥{list.price}</div>
                                            </div>
                                            <div className={style.row}>
                                                <div className={style.infoBox}>
                                                    <div className={style.infoItem}>
                                                        <img className={style.infoImg} src={addressImg} alt=""/> {list.user_name}
                                                    </div>
                                                    {
                                                        list.room_number && <div className={style.infoItem}>
                                                            <img className={style.infoImg} src={shopImg} alt=""/> {list.room_number}
                                                        </div>
                                                    }

                                                    {
                                                        list.work_list.length > 0 && <div className={style.infoItem}>
                                                            <img className={style.infoImg} src={userImg} alt=""/> {list.work_list.map((order, key2) => {
                                                            return (
                                                                <span key={key2}>
                                                                        {order.employee_number}&nbsp;{order.employee_name}
                                                                    </span>
                                                            )
                                                        })}
                                                        </div>
                                                    }

                                                </div>
                                                <div className={style.typeBtn}>{list.clock_type_str}</div>
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>

                                )
                            }

                            <div className={style.recordBottomDiv}>
                                <div className={style.accountBox}>
                                    <div className={style.subtotal}>
                                        <div className={style.subtotalItem}><span>应收</span>￥{Number(item.pre_money) - Number(item.discount_money)}</div>
                                        <div className={style.subtotalItem}><span>优惠</span>￥{item.discount_money}</div>
                                    </div>
                                    <div className={style.total}>支付金额 <span>￥{item.total_fee}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            }
        </div>
    );
}

export default MemberConsumeRecord;