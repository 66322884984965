import React, {useState} from 'react';
import style from './style.module.less'
import MemberDetail from "../components/memberDetail";
import closeImg from "../../../images/close.png";
import ticketBg from "../../../images/member/ticket.png";
import CardItem from './components/cardItem'
import Ticket from './components/coupon'

function Card(props) {
    const {setAction, nav, onClose} = props

    const ticketBgStyle = {
        // backgroundImage: "url(" + ticketBg + ")",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
    }

    return (
        <div className={style.Card}>
            <MemberDetail nav={nav} setAction={setAction}/>
            <div className={style.content}>
                <div className={style.title}>
                    <div className={style.icon}></div>
                    <div className={style.titleNav}>
                        <div className={[style.navItem, nav === 'storeValue' && style.active].join(' ')} onClick={() => {
                            setAction('storeValue')
                        }}>储值卡
                        </div>
                        <div className={[style.navItem, nav === 'order' && style.active].join(' ')} onClick={() => {
                            setAction('order')
                        }}>计次卡
                        </div>
                        <div className={[style.navItem, nav === 'limitProject' && style.active].join(' ')} onClick={() => {
                            setAction('limitProject')
                        }}>专享卡
                        </div>
                        <div className={[style.navItem, nav === 'ticket' && style.active].join(' ')} onClick={() => {
                            setAction('ticket')
                        }}>卡券
                        </div>
                    </div>
                    <div className={style.icon}>
                        <img onClick={onClose} src={closeImg} alt=""/>
                    </div>
                </div>

                {
                    nav === 'storeValue' && <CardItem setAction={setAction} type={10} ticketBgStyle={ticketBgStyle}></CardItem>
                }

                {
                    nav === 'order' && <CardItem setAction={setAction} type={20} ticketBgStyle={ticketBgStyle}></CardItem>
                }

                {
                    nav === 'limitProject' && <CardItem setAction={setAction} type={30} ticketBgStyle={ticketBgStyle}></CardItem>
                }

                {
                    nav === 'ticket' && <Ticket ticketBgStyle={ticketBgStyle} setAction={setAction}></Ticket>
                }

            </div>
        </div>
    );
}

export default Card;