import React, {useState, useEffect} from 'react';
import style from './style.module.less'
import MemberDetail from "../components/memberDetail";
import DetailModal from "../components/detailModal";
import Button from "../../public/button";
import tipsImg from '../../../images/plaint-act.png'
import readCardImg from '../../../images/member/read-card.png'
import {useDispatch, useSelector} from "react-redux";
import common from "../../../utils/common";
import readCardUtils from "../../../utils/readCardUtils";
import memberUtils from "../../../utils/memberUtils";

function ReplaceCard(props) {
    const {setAction, onClose} = props
    const dispatch = useDispatch();

    const {activeCard, memberInfo} = useSelector(state => state.member)
    const [cardReissueMessage, setCardReissueMessage] = useState({})     //补卡换卡信息
    const [ic, setIc] = useState({
        ic_code: '',                //IC卡编码
        ic_series_number: '',       //IC卡序列号
        ic_face_number: '',         //IC卡面号
    })

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/app.php/member-api/card/get-card-reissue', {card_id: activeCard.id}).then((res) => {
            setCardReissueMessage(res)
        }).finally(common.loadingStop)
    }, [])

    //提交数据
    const handleSubmit = () => {
        if (ic.ic_code === '' || ic.ic_series_number === '') {
            common.toast('请读卡')
            return
        }
        let data = {
            member_id: memberInfo.id,                      //会员id
            card_id: activeCard.id,                          //会员卡id
            ic_code: ic.ic_code,                      //IC卡编码
            ic_series_number: ic.ic_series_number,    //IC卡序列号
        }

        common.loadingStart()
        common.ajax('post', '/app.php/member-api/card/card-reissue', data).then((res) => {
            memberUtils.fetchMemberInfo(memberInfo.id)

            common.toast("换卡成功")
            setTimeout(() => {
                goBackAction()
            }, 1000)
        }).finally(common.loadingStop)
    }

    // 返回页面
    const goBackAction = () => {
        switch (activeCard.card_category_type) {
            case 2: // 储值卡
                setAction('storeValue')
                break
            case 3: // 计次卡
                setAction('order')
                break
            case 5: // 专享卡
                setAction('limitProject')
                break
            default:
                break
        }
    }

    // 获取选中菜单
    const getNav = () => {
        switch (activeCard.card_category_type) {
            case 2: // 储值卡
                return 'storeValue'
            case 3: // 计次卡
                return 'order'
            case 5: // 专享卡
                return 'limitProject'
            default:
                return ''
        }
    }

    return (
        <div className={style.ReplaceCard}>
            <MemberDetail nav={getNav()} setAction={setAction}/>
            <DetailModal closable={true} title={'补卡换卡'} onBack={() => goBackAction()} footer={
                <div className={style.buttonBox}>
                    <Button type={'cancel'} shape={'default'}
                            onClick={() => {
                                goBackAction()
                            }}>取消</Button>
                    <Button style={{marginLeft: '10px'}}
                            type={'confirm'}
                            shape={'default'}
                            onClick={() => {
                                handleSubmit()
                            }}>确定</Button>
                </div>
            } onCancel={onClose}>
                <div className={style.contentBox}>
                    <div className={style.tipsDiv}>
                        <img className={style.tipsImg} src={tipsImg} alt=""/>
                        1、进行补卡操作后原会员卡不可以再使用；2、原会员卡卡余额消费信息将转移至新卡内
                    </div>

                    <div className={style.contentMiddle}>
                        <div className={style.row}>
                            <div className={style.rowTitle}>卡类型</div>
                            <div className={style.rowText}>{cardReissueMessage.card_category_name}</div>
                        </div>
                        <div className={style.row}>
                            <div className={style.rowTitle}>原卡号</div>
                            <div className={style.rowText}>{cardReissueMessage.ic_face_number && 'No.' +cardReissueMessage.ic_face_number}</div>

                        </div>
                        <div className={style.row + ' ' + style.border}>
                            <div className={style.rowTitle}>卡内余额</div>
                            <div className={style.rowText}>{cardReissueMessage.money && '￥' +cardReissueMessage.money}</div>
                        </div>
                        <div className={style.newCardBox}>
                            <div className={style.rowTitle}>新卡号</div>
                            {
                                ic.ic_face_number && <div className={style.rowText}>No.{ic.ic_face_number}</div>
                            }
                        </div>
                        <div className={style.readBox}>
                            <img src={readCardImg} className={style.readCardImg} alt=""/>
                            <div className={style.tipsContent}>请放置会员卡后点击读卡</div>
                            <div className={style.readBtn} onClick={() => {
                                readCardUtils.readCardForAction(activeCard.id).then((res) => {
                                    // icCode: 卡编号    icSeriesNumber: 卡序列号   icFaceNumber: 卡面号
                                    setIc({
                                        ...ic,
                                        ic_code: res.icCode,
                                        ic_series_number: res.icSeriesNumber,
                                        ic_face_number: res.icFaceNumber,
                                    })
                                }).catch((errorData) => {
                                    common.toast(errorData)
                                })
                            }}>读卡</div>
                        </div>
                    </div>
                </div>
            </DetailModal>
        </div>
    );
}

export default ReplaceCard;