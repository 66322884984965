import React, {useState, useRef, useEffect} from 'react';
import style from './style.module.less'
import Button from "../../public/button";
import common from "../../../utils/common";
import Close from "../../../images/close.png";

function OpenCard(props) {
    const {onClose, setAction, setFastCreateCard, goRecordHome, setCreateMobile} = props
    const [moblieVal, setMoblieVal] = useState('')        //手机号展示
    const moblieNum = useRef('')                          //手机号提交的数据
    const autoFocus = useRef(null)                        //手机输入框

    const focusTimmer = useRef('')

    useEffect(() => {

        // 卸载
        return () => {
            clearTimeout(focusTimmer.current)
            focusTimmer.current = ''
            autoFocus.current = null;
        }
    }, [])

    useEffect(() => {
        //手机输入框自动聚焦
        if (!autoFocus.current) {
            return;
        }

        focusTimmer.current = setTimeout(() => {
            autoFocus.current.focus()
        }, 500)

    }, [autoFocus.current])

    //去搜索
    const searchHandle = () => {

        if (!moblieNum.current) {
            common.toast('请输入手机号')
            return
        }

        common.loadingStart()
        common.ajax('get', '/app.php/member-api/member/find', {
            mobile: moblieNum.current
        }, {displayError: false}).then((res) => {
            if (moblieNum.current && moblieNum.current.length === 11) { // 在需要放入 历史记录的时候，是完整手机号，不是带星号的手机号
                common.saveMobile(moblieNum.current, res[0].name)
            }
            if (res.length > 0) {
                goRecordHome(res[0].id)
            }
        }).catch((res) => {
            common.saveMobile(moblieNum.current, '')

            if (res.error === '无对应会员') {
                common.confirm('无对应会员, 是否新开会员?', () => {
                    setCreateMobile(moblieNum.current)
                    setAction('create')
                })
            } else {
                common.toast(res.error)
            }
        }).finally(common.loadingStop)
    }

    return (
        <div className={style.OpenCard}>
            <div className={style.title}>
                会员开卡
                <div className={style.closeImage} onClick={onClose}>
                    <img src={Close} alt=""/>
                </div>
            </div>

            <div className={style.headerInput}>
                <input type="text" placeholder={'请输入手机号'} className={style.inputBox} value={moblieVal}
                       ref={autoFocus}
                       onChange={(e) => {
                           let str = ''
                           let value = e.target.value
                           value = value.replace(/\D/g, '')
                           if (value.length >= 12) {
                               return
                           }
                           moblieNum.current = value
                           for (let i = 0; i < moblieNum.current.length; i++) {
                               if (i === 2 || i === 6) {
                                   str += moblieNum.current[i] + ' '
                               } else {
                                   str += moblieNum.current[i]
                               }
                           }
                           setMoblieVal(str)
                       }}
                       onKeyDown={(e) => {
                           let str = ''
                           if (e.keyCode === 8) {
                               if (moblieVal[moblieVal.length - 1] === ' ') {
                                   for (let i = 0; i < moblieVal.length - 1; i++) {
                                       str += moblieVal[i]
                                   }
                                   setMoblieVal(str)
                               }
                           }
                           if (e.keyCode === 13) {
                               searchHandle()
                           }
                       }}/>
                <div className={style.openButton} onClick={() => {
                    setFastCreateCard(20)
                    setAction('apply')
                }}>
                    <img src={require('@/images/member/card.png')} className={style.cardImage} alt=""/>
                    <div>免信息快速开卡</div>
                    <img src={require('@/images/member/right.png')} className={style.rightImage} alt=""/>
                </div>
            </div>
            <div className={style.description}>
                <div className={style.descriptionText}>
                     会员办卡须知：
                </div>
                <div className={style.descriptionText}>
                    1、输入手机号 > 确定 > 系统会自动查询是否为老会员；
                </div>
                <div className={style.descriptionText}>
                    2、老会员办卡可直接选择 "我要办卡" 即可,无需录入会员基本信息；
                </div>
                <div className={style.descriptionText}>
                    3、新会员开户输入手机号后需完善会员资料；
                </div>
                <div className={style.descriptionText}>
                    4、免信息快速开卡,不需要录入会员信息,可直接办卡,但注意此会员将无法进行无卡消费.
                </div>
            </div>
            <div className={style.bottomAction}>
                <Button shape={'default'} type={'cancel'} onClick={onClose}>取消</Button>
                <Button shape={'default'} style={{marginLeft: '10px'}} type={'confirm'} onClick={searchHandle}>确认</Button>
            </div>
        </div>
    );
}

export default OpenCard;