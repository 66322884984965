import {createSlice} from '@reduxjs/toolkit'
import business from "../utils/business";
import biz from "../utils/biz";
import businessAction from "../utils/businessAction";

const NAMESPACE = "checkoutPay"

// 结账支付页使用
const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    showCheckoutPayType: '', // 子弹框
    order: '', // 用来计算的订单信息
    existPaymentList: [], // 已存在的支付方式
  },
  reducers: {
    setShowCheckoutPayType(state, action) {
      state.showCheckoutPayType = action.payload
    },
    setOrder(state, action) {
      state.order = action.payload
      businessAction.checkoutPay.order = action.payload ? {...action.payload} : action.payload
    },
    setExistPaymentList(state, action) {
      state.existPaymentList = action.payload
      businessAction.checkoutPay.exist_payments = action.payload ? [...action.payload] : action.payload
    },
  },
  extraReducers: {},
});

export const {
  setShowCheckoutPayType,
  setOrder,
  setExistPaymentList
} = slice.actions

export const {reducer} = slice;
