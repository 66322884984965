import React, {useState, useEffect} from 'react';
import style from './style.module.less'
import MemberDetail from "../components/memberDetail";
import closeImg from "../../../images/close.png";
import MemberConsumeRecord from "./components/consume";
import MemberRechargeRecord from "./components/recharge";
import common from "../../../utils/common";
import {useSelector} from "react-redux";

function ConsumeRecord(props) {
    const {setAction, onClose} = props

    const {memberInfo} = useSelector(state => state.member)

    const [whichRecord, setWhichRecord] = useState(true)                  //true:消费记录, false:充值记录
    const [orderList, setOrderList] = useState([])                        //消费记录数据
    const [rechargeRecordList, setRechargeRecordList] = useState([])      //充值记录数据

    useEffect(() => {

        //拉取消费记录, 获取充值记录
        common.loadingStart()
        Promise.all([getOrder(), getRechargeRecord()]).finally(common.loadingStop)

    }, [])

    //获取消费记录
    const getOrder = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/member/order', {
                id: memberInfo.id
            }).then((res) => {
                setOrderList(res)
                resolve()
            }).catch((err) => {
                reject()
            })
        })
    }

    //获取充值记录
    const getRechargeRecord = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/card/recharge-record', {
                member_id: memberInfo.id
            }).then((res) => {
                setRechargeRecordList(res)
                resolve()
            }).catch((err) => {
                reject()
            })
        })
    }

    return (
        <div className={style.ConsumeRecord}>
            <MemberDetail setAction={setAction}/>
            <div className={style.content}>
                <div className={style.title}>
                    <div className={style.icon}></div>
                    <div className={style.titleNav}>
                        <div className={[style.navItem, whichRecord && style.active].join(' ')} onClick={() => {
                            setWhichRecord(true)
                        }}>消费记录
                        </div>
                        <div className={[style.navItem, !whichRecord && style.active].join(' ')} onClick={() => {
                            setWhichRecord(false)
                        }}>充值记录
                        </div>
                    </div>
                    <div className={style.icon}>
                        <img src={closeImg} onClick={onClose} alt=""/>
                    </div>
                </div>

                {
                    whichRecord ?
                        <MemberConsumeRecord orderList={orderList}></MemberConsumeRecord> :
                        <MemberRechargeRecord rechargeRecordList={rechargeRecordList}></MemberRechargeRecord>
                }
            </div>
        </div>
    );
}

export default ConsumeRecord;