import React, {useState} from 'react';
import style from './style.module.less'
import MemberDetail from "../components/memberDetail";
import DetailModal from "../components/detailModal";
import Button from "../../public/button";
import couponBg from "../../../images/member/coupon.png";
import {useSelector} from "react-redux";
import common from "../../../utils/common";

function PutOffTicket(props) {
    const {setAction, onClose} = props

    const {activeTicket, memberInfo} = useSelector(state => state.member)

    const couponBgStyle = {
        backgroundImage: "url(" + couponBg + ")",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
    }

    let [validTime, setValidTime] = useState('')  // 延期的时间

    // 去延期
    let handleSubmit = () => {
        if (!validTime) {
            common.toast('请选择延期时间')
            return
        }

        common.loadingStart()
        common.ajax('post', '/app.php/member-api/ticket-detail/update-valid', {
            member_id: memberInfo.id,
            ticket_detail_id: activeTicket.id,
            date: validTime,
        }).then((res) => {
            common.toast(res)
            setAction('ticket')
        }).finally(common.loadingStop)
    }

    return (
        <div className={style.PutOffTicket}>
            <MemberDetail nav={'ticket'} setAction={setAction}/>
            <DetailModal closable={true} title={'卡券延期'} onBack={() => setAction('ticket')} footer={
                <div className={style.buttonBox}>
                    <Button type={'cancel'} shape={'default'}
                            onClick={() => {
                                setAction('ticket')
                            }}>取消</Button>
                    <Button style={{marginLeft: '10px'}}
                            type={'confirm'}
                            shape={'default'}
                            onClick={() => {
                                handleSubmit()
                            }}>确定</Button>
                </div>
            } onCancel={onClose}>
                <div className={style.contentBox}>
                    <div className={style.couponDiv} style={couponBgStyle}>
                        <div className={style.topCouponDiv}>
                            <div className={style.couponName}>{activeTicket.name}</div>
                            <div className={style.couponMoney}>{activeTicket.money_str ?
                                <span>￥</span> : ''}{activeTicket.money_str}{activeTicket.project_str}</div>
                        </div>

                        <div className={style.bottomCouponDiv}>
                            <div className={style.number}>{activeTicket.valid_str}</div>
                            <div className={style.shop}>{activeTicket.user_name}</div>
                        </div>
                    </div>

                    <div className={style.cardInputDiv}>
                        <div>到期时间</div>
                        <div className={style.inputDateDiv}>
                            <input type="date" value={validTime}
                                   onChange={(e) => {
                                       setValidTime(e.target.value)
                                   }}/>
                        </div>
                    </div>

                </div>


            </DetailModal>
        </div>
    );
}

export default PutOffTicket;