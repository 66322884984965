import common from "./common";

let readCardUtils = {
  
}

// 读卡->读出卡号 --- 提供给通过卡号搜索会员使用的读卡
// 用法 common.readCardForFind().then((data) => { 读卡后的操作 }).catch((data) => { data:报错信息 })
// code: code
// seriesnumber: seriesnumber
readCardUtils.readCardForFind = () => {

  return new Promise((resolve, reject) => {
    common.loadingStart()

    readCardUtils.read().then((data) => {
      resolve(data)
      return;
    }).catch((error) => {
      common.toast(error)
      reject(error)
      return;
    }).finally(() => {
      common.loadingStop();
    })

  })
}

// 读卡->读出卡号 --- 提供给会员操作时的读卡: 补卡换卡、开卡
// cardCategoryId 卡类ID
// 用法 common.readCardForAction().then((data) => { 读卡后操作 }).catch((data) => { data:报错信息 })
// icCode: 卡编号
// icSeriesNumber: 卡序列号
// icFaceNumber: 卡面号
readCardUtils.readCardForAction = (cardCategoryId) => {
  return new Promise((resolve, reject) => {

    if(!cardCategoryId) {
      common.toast("请传入卡类ID");
      reject("请传入卡类ID");
      return;
    }

    common.loadingStart();

    readCardUtils.readCardActionForAction(cardCategoryId).then((data) => {
      resolve(data)
      return;
    }).catch((error) => {
      common.toast(error)
      reject(error)
      return;
    }).finally(() => {
      common.loadingStop();
    })
  })
}

readCardUtils.readCardActionForAction = (cardCategoryId) => {
  return new Promise((resolve, reject) => {

    // 会员卡初始化
    readCardUtils.cardInit().then(() => {

      // 读卡
      return readCardUtils.read()

    }).then((data) => {

      // 读卡成功

      var code = data.code;
      var seriesnumber = data.seriesnumber;

      // 获取卡面号
      readCardUtils.faceNumberByCode(code, seriesnumber).then((tempData) => {
        // 获取到卡面号，直接返回
        resolve(tempData)
        return;
      }).catch((tempErrorData) => {
        // 没有获取到卡面号，判断要不要制作卡片

        // 非"noCard"报错，不需要制作卡片
        if(tempErrorData != 'noCard') {
          reject(tempErrorData);
          return;
        }

        // 判断code是否可以进行制作

        readCardUtils.checkCodeCanCreateCard(code, seriesnumber).then(() => {

          //读取卡片的卡面号
          readCardUtils.readFaceNumber().then((faceNumber) => {


            common.ajax('post', '/app.php/member-api/card/make-card', {
              'ic_face_number': faceNumber,
              'card_category_id': cardCategoryId,
              'ic_series_number': seriesnumber
            }).then((res) => {

              var writeData = "?series_number=" + encodeURIComponent(res['ic_series_number']) + "&code=" + encodeURIComponent(res['ic_code']) + "&face_number=" + encodeURIComponent(res['ic_face_number']);

              //写卡
              readCardUtils.writeCard(writeData).then(() => {
                resolve({
                  "icCode": res['ic_code'],
                  "icSeriesNumber": res['ic_series_number'],
                  "icFaceNumber": res['ic_face_number']
                })
                return;
              }).catch((tempErrorData) => {
                reject(tempErrorData);
                return;
              })

            }).catch((err) => {
              reject(err.error)
              return;
            })


          }).catch((tempErrorData) => {
            reject(tempErrorData);
            return;
          })

        }).catch((tempErrorData) => {
          reject(tempErrorData);
          return;
        })

      })

    }).catch((tempError) => {
      reject(tempError)
      return;
    })

  })
}

// 读卡
readCardUtils.read = () => {
  return new Promise((resolve, reject) => {
    common.jQueryAjax({
      url: common.localExeUrl + "/read",
      type: "POST",
      dataType: 'json',
      data: {},
      success: function (result) {
        var code = result.code;
        var seriesnumber = result.series_number;
        var error = result.error;
        if (error.length !== 0) {
          if (error === 'nocard') {
            reject("读卡器连接成功但未读到卡");
            return;
          } else {
            reject(error);
            return;
          }
        }

        resolve({
          'code': code,
          'seriesnumber': seriesnumber,
        })

        return;
      },
      error: function () {
        reject("读卡器未连接");
        return;
      }
    });
  })
}

// 会员卡判断是否初始化，然后进行初始化的操作
readCardUtils.cardInit = () => {
  //判断该卡是否已经初始化密码过
  return new Promise((resolve, reject) => {
    var tempIsInitResult = false;
    window.$.ajax({
      url: common.localExeUrl + "/cardIsInit",
      type: "POST",
      dataType: 'json',
      data: {},
      async: false,
      success: function (result) {
        tempIsInitResult = result;
      }
    });

    // 当接口不存在，直接返回进行下一步
    if(tempIsInitResult === false ){
      resolve()
      return;
    }

    //当该接口存在的时候
    //1：已初化   0 未初始化 2读卡器未连接  3未放卡 4未知卡片(不是空卡，也不是我们初始化的卡)

    //未初始化
    if (tempIsInitResult === '0') {
      //当值为0的时候，表示未初始化，则需要初始化卡片

      //初始化卡片
      var tempInitResult = '';
      window.$.ajax({
        url: common.localExeUrl + "/cardInit",
        type: "POST",
        dataType: 'json',
        data: {},
        async: false,
        success: function (result) {
          tempInitResult = result;
        }
      });

      if (!tempInitResult) {
        reject("读卡器未连接");
        return;
      }

      if (tempInitResult != 'OK') {
        reject(tempInitResult);
        return;
      }

    } else if (tempIsInitResult === '1') {
      //已初始化 什么都不用做
    } else if (tempIsInitResult === '2') {
      reject("读卡器未连接");
      return;
    } else if (tempIsInitResult === '3') {
      reject("请放卡");
      return;
    } else if (tempIsInitResult === '4') {
      reject("未知卡片");
      return;
    } else {
      reject("读卡器判断初始化有问题");
      return;
    }

    resolve()
    return;
  })
}

// 根据code获取卡面号
readCardUtils.faceNumberByCode = (code, seriesnumber) => {
  return new Promise((resolve, reject) => {
    common.ajax('get', '/app.php/member-api/card/face-number-by-code', {
      'code': code,
      'seriesnumber': seriesnumber
    }, {'displayError': false}).then((res) => {
      resolve({
        "icCode": code,
        "icSeriesNumber": seriesnumber,
        "icFaceNumber": res
      })
      return;
    }).catch((err) => {
      reject((typeof err.error !== "undefined") ? err.error : '获取实体卡信息失败');
      return;
    })
  })
}

// 判断code可不可以进行卡片制作
readCardUtils.checkCodeCanCreateCard = (code, seriesnumber) => {
  return new Promise((resolve, reject) => {
    common.ajax('get', '/app.php/member-api/card/find-by-code', {
      'code': code,
      'seriesnumber': seriesnumber
    }).then((res) => {

      if(res.init) {
        // 初始化过
        reject(res.message);
        return;
      }

      resolve()
      return;

    }).catch((err) => {
      reject(err.error);
      return;
    })
  })
}

// 读取卡片的卡面号
readCardUtils.readFaceNumber = () => {
  return new Promise((resolve, reject) => {
    //读取卡片的卡面号
    window.$.ajax({
      url: common.localExeUrl + "/faceNumber",
      type: "POST",
      dataType: 'json',
      success: function (faceNumberResult) {

        //判断是否有错误信息
        if (faceNumberResult.error.length != 0) {
          reject(faceNumberResult.error);
          return;
        } else {

          //读出卡面号
          var faceNumber = faceNumberResult.face_number;

          //20180614  处理判断卡面号
          faceNumber = readCardUtils.getAndHandlerFaceNumber(faceNumber);

          if (!faceNumber) {
            reject('');
            return;
          }

          resolve(faceNumber);
          return;
        }
      },
      error: function () {
        reject("读卡出错");
        return;
      }
    });
  })
}

// 写卡
readCardUtils.writeCard = (writeData) => {
  //写卡
  return new Promise((resolve, reject) => {
    window.$.ajax({
      url: common.localExeUrl + "/write" + writeData,
      type: "POST",
      dataType: 'json',
      success: function (info) {
        if (info !== "success") {
          reject(info)
          return;
        } else {

          resolve()
          return;
        }
      },
      error: function () {
        reject("读卡器故障")
        return;
      }
    });
  })
}

// 处理卡面号-提供给卡操作的读卡(common.readCardForAction)使用
readCardUtils.getAndHandlerFaceNumber = (faceNumber) => {

  //有f的是酷鼠系统制作的会员卡
  //没有f的，检测A(a，不区分大小写)，为卡制作方写好的会员卡

  //1、检测是否有f，存在，有的话，截取f前面的为卡面号

  //2、当没有f存在的时候，检测是否有A(a)存在，没有的话， 则报错
  //有的话，则截取A(a)前面的字符串为卡面号，且判断卡面号的长度不能超过8位

  //3、判断卡面号是否为数字

  //处理该卡面号 检测是否有字母“f”存在，如果存在则截取“f”之前的数据，如果没有则截取前8位数据

  var tempFNum = faceNumber.indexOf('f');
  if (tempFNum > 0) {
    //“f”存在 取“f”之前的数据
    faceNumber = faceNumber.substring(0, tempFNum);

  } else {

    //检测是否有A(或a)出现，并且获取位置
    var tempANum = faceNumber.indexOf('A');

    //当A未出现，获取a的位置
    if (tempANum < 0) {
      tempANum = faceNumber.indexOf('a');
    }

    //当A(a)没有出现时，报错
    if (tempANum < 0) {
      common.toast('会员卡出错，无卡面号结束符出现，请联系系统管理员');
      return false;
    }

    //获取A(a)前面的字符串
    faceNumber = faceNumber.substring(0, tempANum);

    //判断卡面号的长度是否超过8
    if (faceNumber.length > 8) {
      common.alert('会员卡出错，卡面号长度不允许超过8位');
      return false;
    }
  }

  //判断卡面号是否存在
  if ((!faceNumber) || (faceNumber.length <= 0)) {
    common.alert('请检查会员卡，未检测到卡面号');
    return false;
  }

  //判断卡面号是否为4到20位的数字
  var reg = /^\d{4,20}$/;
  if ((!reg.test(faceNumber))) {
    common.alert('卡面号只能为4到20位的数字');
    return false;
  }

  //判断取出的卡面号是否都为0，如果都为0表示该卡不能操作
  if (/^0+$/.test(faceNumber)) {
    common.toast('此卡未制卡');
    return false;
  }

  return faceNumber;
}

export default readCardUtils