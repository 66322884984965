import common from "./common";
import {setMemberInfo} from "../reducers/member";

let memberUtils = {

}

memberUtils.fetchMemberInfo = (id) => {
    common.ajax('get', '/app.php/member-api/member/info', {id: id}).then((res) => {
        common.dispatch(setMemberInfo(res))     // 放入会员信息
    })
}


export default memberUtils;