import {createSlice} from '@reduxjs/toolkit'

const NAMESPACE = "device"

// 智能控制
const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    deviceScene: '', // 当前页面选择的总控场景  standard: 标准; sleep: 睡眠; quiet: 安静; clean: 打扫 leave: 离开
    isSetConfig:false, //是否为联动配置
    configChange: 0,       // 配置修改 每次改变
  },
  reducers: {
    setDeviceScene(state, action) {
      state.deviceScene = action.payload
    },
    setIsSetConfig(state, action) {
      state.isSetConfig = action.payload
    },
    setChangeConfigChange(state, action) {
      state.configChange = action.payload
    },
  },
  extraReducers: {},
});

export const {
  setDeviceScene,
  setIsSetConfig,
  setChangeConfigChange
} = slice.actions

export const {reducer} = slice;
