import React, {useState, useEffect} from 'react';
import style from './style.module.less'
import MemberDetail from "../components/memberDetail";
import DetailModal from "../components/detailModal";
import Button from "../../public/button";
import couponBg from "../../../images/member/coupon.png";
import plaintIcon from "../../../images/plaint.png";
import {useDispatch, useSelector} from "react-redux";
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import memberUtils from "../../../utils/memberUtils";

function CardBack(props) {
    const {setAction, onClose} = props

    const {activeCard, memberInfo} = useSelector(state => state.member)
    const dispatch = useDispatch();

    const couponBgStyle = {
        backgroundImage: "url(" + couponBg + ")",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
    }

    const [showBackPay, setShowBackPay] = useState(false)     // 是否展示退卡方式

    const [cardBackMessage, setCardBackMessage] = useState({})     //退卡销户信息
    const [backPayTypeList, setBackPayTypeList] = useState([])     //退卡支付方式列表

    const [submitMessage, setSubmitMessage] = useState({           //提交的数据
        deduct: '',             //	扣除费用
        actual: '',             //	实退金额
        pay_type: '0',          //	退款方式
        description: '',        //	备注
        actual_two: '',         //	实退金额 - 第二种方式
        pay_type_two: '0',      //	退款方式 - 第二种方式
    })

    useEffect(() => {
        common.loadingStart()
        Promise.all([getCardBack(), getBackPayType()]).finally(common.loadingStop)

    }, [])

    //获取退卡销户信息
    const getCardBack = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/card/get-card-back', {card_id: activeCard.id}).then((res) => {
                setCardBackMessage(res)
                setSubmitMessage({...submitMessage, deduct: res.deduct, actual: res.actual})
                resolve()
            }).catch((err) => {
                reject()
            })
        })

    }

    //获取退卡的支付方式
    const getBackPayType = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/card/back-pay-type-list').then((res) => {
                setBackPayTypeList(res)
                resolve()
            }).catch((err) => {
                reject()
            })
        })
    }

    const handleSubmit = () => {
        /**
         *
         * 1.退卡的时候，再来两行（退款方式、实退金额）
         *   可以混合退卡、也可以单个退卡
         *
         * 2.已填退卡方式，实退金额不大于0，提示：请正确填写实退金额（必须是扣除金额大于0时，才可判断）
         *
         * 3.退卡方式没选，不用管实退金额
         */

        if (submitMessage.pay_type === '0' && submitMessage.pay_type_two === '0') {
            common.toast('请选择退款方式')
            return
        }

        if (submitMessage.pay_type == submitMessage.pay_type_two) {
            common.toast('两种退款方式不能相同')
            return
        }

        if (Number(submitMessage.deduct) < 0) {
            common.toast('扣除费用不能为负数')
            return
        }

        let payTypeList = []
        let tmpActual = 0

        if (submitMessage.pay_type !== '0') {
            if (Number(submitMessage.actual) < 0) {
                common.toast('请正确填写实退金额')
                return
            }

            payTypeList.push({
                'pay_type': submitMessage.pay_type,
                'actual': submitMessage.actual,
            })

            tmpActual = submitMessage.actual
        }

        if (submitMessage.pay_type_two !== '0') {
            if (Number(submitMessage.actual_two) < 0 || submitMessage.actual_two.toString().length < 1) {
                common.toast('请正确填写实退金额')
                return
            }

            if (Number(submitMessage.actual_two) > 0) {
                payTypeList.push({
                    'pay_type': submitMessage.pay_type_two,
                    'actual': submitMessage.actual_two,
                })
            }

            tmpActual = new BigNumber(tmpActual).plus(new BigNumber(submitMessage.actual_two)).toFixed(2)
        }

        let tmpMoney = new BigNumber(submitMessage.deduct).plus(new BigNumber(tmpActual))

        if (Number(tmpMoney) !== Number(cardBackMessage.money)) {
            common.toast('扣除金额加实退金额不等于当前余额')
            return
        }

        if (Number(cardBackMessage.money) > 0 && payTypeList.length === 0) {
            common.toast("请选择退款方式");
            return;
        }

        common.confirm('确定退卡吗?', () => {
            let data = {
                member_id: memberInfo.id,
                card_id: activeCard.id,
                deduct: submitMessage.deduct,
                pay_type_list: JSON.stringify(payTypeList),
                description: submitMessage.description,
            }
            common.loadingStart()
            common.ajax('post', '/app.php/member-api/card/card-back-v2', data).then((res) => {
                memberUtils.fetchMemberInfo(memberInfo.id)

                common.toast('退卡成功!')
                goBackAction()
            }).catch((err) => {
            }).finally(common.loadingStop)
        })
    }

    // 补零
    const paddZero = (val) => {
        if (val === '') {
            return '0.00'
        }
        val = String(val)
        if (val.indexOf('.') !== -1) {
            if (val.substring(val.indexOf('.') + 1).length < 2) {
                val += '0'
            }
        } else {
            val += '.00'
        }
        return val
    }

    // 返回页面
    const goBackAction = () => {
        switch (activeCard.card_category_type) {
            case 2: // 储值卡
                setAction('storeValue')
                break
            case 3: // 计次卡
                setAction('order')
                break
            case 5: // 专享卡
                setAction('limitProject')
                break
            default:
                break
        }
    }

    // 获取选中菜单
    const getNav = () => {
        switch (activeCard.card_category_type) {
            case 2: // 储值卡
                return 'storeValue'
            case 3: // 计次卡
                return 'order'
            case 5: // 专享卡
                return 'limitProject'
            default:
                return ''
        }
    }

    return (
        <div className={style.CardBack}>
            <MemberDetail nav={getNav()} setAction={setAction}/>
            <DetailModal closable={true} title={'会员退卡'} onBack={() => goBackAction()} footer={
                <div className={style.buttonBox}>
                    <Button type={'cancel'} shape={'default'}
                            onClick={() => {
                                goBackAction()
                            }}>取消</Button>
                    <Button style={{marginLeft: '10px'}}
                            type={'confirm'}
                            shape={'default'}
                            onClick={() => {
                                handleSubmit()
                            }}>确定</Button>
                </div>
            } onCancel={onClose}>
                <div className={style.contentBox}>
                    <div className={style.couponDiv} style={couponBgStyle}>
                        <div className={style.topCouponDiv}>
                            <div className={style.couponName}>{activeCard.name}</div>
                            <div className={style.couponMoney}>{activeCard.card_category_type === 3 ?
                                <span>{activeCard.consume_times}次</span> : <span>￥{activeCard.money}</span>}</div>
                        </div>

                        <div className={style.bottomCouponDiv}>
                            <div>No.{activeCard.number}</div>
                            <div>{activeCard.user_name}</div>
                        </div>
                    </div>

                    <div className={style.couponInputDiv}>
                        <div className={style.label}>扣除金额
                            <div className={style.plaintBox}>
                                <img className={style.plaintIcon} src={plaintIcon} alt=""/>
                                <div className={style.plaintTips}>
                                    指在退卡时要从会员卡中直接减去的部分,如赠送的金额或者有的退卡时是不退钱的.那么你可以把不退的部分直接扣除去掉,这样他不会对今天的现金流产生影响
                                </div>
                            </div>
                        </div>
                        <div className={style.couponMoney}>￥
                            <input type="text" className={style.moneyInput} value={submitMessage.deduct}
                                   onChange={(e) => {
                                       let val = Number(e.target.value)
                                       if (val < 0) {
                                           return
                                       }
                                       val = String(val)
                                       if (val.indexOf('.') !== -1) {
                                           if (val.substring(val.indexOf('.') + 1).length > 2) {
                                               return
                                           }
                                       }

                                       let actual = new BigNumber(cardBackMessage.money).minus(new BigNumber(val)).toFixed(2)
                                       if (actual === 'NaN') {
                                           actual = ''
                                       }

                                       setSubmitMessage({
                                           ...submitMessage,
                                           deduct: e.target.value,
                                           actual: actual
                                       })
                                   }}
                                   onBlur={() => {
                                       let deduct = paddZero(Number(submitMessage.deduct))
                                       setSubmitMessage({
                                           ...submitMessage,
                                           deduct: deduct,
                                       })
                                   }}/></div>
                    </div>
                    <div className={style.couponInputDiv}>
                        <div className={style.label}>退款方式

                            <div className={style.plaintBox}>
                                <img className={style.plaintIcon} src={plaintIcon} alt=""/>
                                <div className={style.plaintTips}>
                                    指您需要退款部分是按哪个支付方式退还给客户,您选择了哪个支付方式,那么今天的营业额中都会相应的减去这个部分的资金流水,所以一定要注意您在发卡时选择的支付方式需要与退卡时保持一致.有多个支付方式退款时请点击下边的增加退款支付方式按钮
                                </div>
                            </div>
                        </div>
                        <div className={style.couponSelect}>
                            <select value={submitMessage.pay_type}
                                    onChange={(e) => {
                                        setSubmitMessage({...submitMessage, pay_type: e.target.value})
                                    }}>
                                <option value='0'>请选择</option>
                                {backPayTypeList.map((item, key) => {
                                    return (
                                        <option value={item.id} key={key}>{item.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={style.couponInputDiv}>
                        <div className={style.label}>实退金额
                            <div className={style.plaintBox}>
                                <img className={style.plaintIcon} src={plaintIcon} alt=""/>
                                <div className={style.plaintTips}>
                                    当您输入了扣除费用系统会自动计算出您实际退给客户的金额,此金额会在你今天的资金流水中减去
                                </div>
                            </div>
                        </div>
                        <div className={style.couponMoney}>￥
                            <input type="text" className={style.moneyInput} value={submitMessage.actual}
                                   onChange={(e) => {
                                       let val = Number(e.target.value)
                                       if (val < 0) {
                                           return
                                       }
                                       val = String(val)
                                       if (val.indexOf('.') !== -1) {
                                           if (val.substring(val.indexOf('.') + 1).length > 2) {
                                               return
                                           }
                                       }
                                       setSubmitMessage({...submitMessage, actual: e.target.value})
                                   }}
                                   onBlur={() => {
                                       let actual = paddZero(Number(submitMessage.actual))
                                       setSubmitMessage({
                                           ...submitMessage,
                                           actual: actual,
                                       })
                                   }}/>
                        </div>
                    </div>

                    {
                        !showBackPay && <div className={style.addPayBtn} onClick={() => setShowBackPay(true)}>
                            + 增加退款支付方式
                        </div>
                    }


                    <div style={{display: !showBackPay ? 'none' : 'block'}}>
                        <div className={style.couponInputDiv}>
                            <div className={style.label}>退款方式
                                <div className={style.plaintBox}>
                                    <img className={style.plaintIcon} src={plaintIcon} alt=""/>
                                    <div className={style.plaintTips}>
                                        指您需要退款部分是按哪个支付方式退还给客户,您选择了哪个支付方式,那么今天的营业额中都会相应的减去这个部分的资金流水,所以一定要注意您在发卡时选择的支付方式需要与退卡时保持一致.有多个支付方式退款时请点击下边的增加退款支付方式按钮
                                    </div>
                                </div>
                            </div>

                            <div className={style.couponSelect}>
                                <select value={submitMessage.pay_type_two}
                                        onChange={(e) => {
                                            setSubmitMessage({...submitMessage, pay_type_two: e.target.value})
                                        }}>
                                    <option value='0'>请选择</option>
                                    {backPayTypeList.map((item, key) => {
                                        return (
                                            <option value={item.id} key={key}>{item.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className={style.couponInputDiv + ' ' + style.noBorder}>
                            <div className={style.label}>实退金额
                                <div className={style.plaintBox}>
                                    <img className={style.plaintIcon} src={plaintIcon} alt=""/>
                                    <div className={style.plaintTips}>
                                        当您输入了扣除费用系统会自动计算出您实际退给客户的金额,此金额会在你今天的资金流水中减去
                                    </div>
                                </div>
                            </div>

                            <div className={style.couponMoney}>{submitMessage.actual_two.length > 0 && '￥'}
                                <input type="text" className={style.moneyInput} value={submitMessage.actual_two}
                                       onChange={(e) => {
                                           let val = Number(e.target.value)
                                           if (val < 0) {
                                               return
                                           }
                                           val = String(val)
                                           if (val.indexOf('.') !== -1) {
                                               if (val.substring(val.indexOf('.') + 1).length > 2) {
                                                   return
                                               }
                                           }
                                           setSubmitMessage({...submitMessage, actual_two: e.target.value})
                                       }}
                                       onBlur={() => {
                                           let actual = paddZero(Number(submitMessage.actual_two))
                                           setSubmitMessage({
                                               ...submitMessage,
                                               actual_two: actual,
                                           })
                                       }}/>
                            </div>
                        </div>
                    </div>

                    <div className={style.textareaDiv}>
                        <textarea className={style.rightTextarea} name="" cols="30" rows="10" placeholder="请输入备注"
                                  value={submitMessage.description}
                                  onChange={(e) => {
                                      setSubmitMessage({...submitMessage, description: e.target.value})

                                  }}
                        />
                    </div>
                </div>


            </DetailModal>
        </div>
    );
}

export default CardBack;