import React from 'react';
import style from './style.module.less'
import Close from "../../../../images/close.png";
import left from "../../../../images/member/left.png";

function DetailModal(props) {
    const {title = '', closable = true, footer, children, onCancel, onBack} = props

    return (
        <div className={style.DetailModal}>
            {
                (closable || title !== '') && <div className={style.title}>
                    <img className={style.leftImg} src={left} onClick={onBack} alt=""/>

                    {title}
                    {
                        closable && <div className={style.closeImage} onClick={onCancel}>
                            <img src={Close} alt=""/>
                        </div>
                    }

                </div>
            }

            <div className={style.content}>
                {children}
            </div>
            {footer && <div className={style.action}>
                {footer}
            </div>}

        </div>
    );
}

export default DetailModal;