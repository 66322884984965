import {createSlice} from '@reduxjs/toolkit'
import business from "../utils/business";
import biz from "../utils/biz";
import businessAction from "../utils/businessAction";

const NAMESPACE = "checkoutPayMeituan"

// 会员卡结账使用
const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    showMeituanType: "",
  },
  reducers: {
    setShowMeituanType(state, action) {
      state.showMeituanType = action.payload
    },

  },
  extraReducers: {},
});

export const {
  setShowMeituanType,
} = slice.actions

export const {reducer} = slice;
