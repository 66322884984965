import React, {useEffect, useState, useRef} from 'react';
import style from './style.module.less'
import bottomImg from "../../../../../images/member/blue-select-bottom.png";
import selectedImg from "../../../../../images/member/selected.png";
import selectTopImg from "../../../../../images/member/selectTop.png";
import selectBottomImg from "../../../../../images/member/selectBottom.png";
import {useDispatch, useSelector} from "react-redux";
import common from "../../../../../utils/common";
import {setCardSum, setActiveTicket} from "../../../../../reducers/member";
import Modal from "../../../../public/modal";
import ConfirmAlert from "../../../components/confirmAlert";

function Coupon(props) {
    const {
        ticketBgStyle,
        setAction
    } = props

    const dispatch = useDispatch();

    const {memberInfo} = useSelector(state => state.member)

    let [ticketCount, setTicketCount] = useState([])          //卡券的统计
    let [ticketCountActive, setTicketCountActive] = useState('')//选中的卡券类型
    let [remarkTimes, setRemarkTimes] = useState([])          //备注的计时器
    let [remarkList, setRemarkList] = useState([])            //备注信息
    let [loading, setLoading] = useState(false)               //页面加载中

    let [expiredTicketList, setExpiredTicketList] = useState([])      // 过期卡券
    let [expiredLoading, setExpiredLoading] = useState(false)         //加载显示
    let [over, setOver] = useState(false)                             //没有更多数据显示
    let [none, setNone] = useState(false)                             //暂无数据显示
    let [pagination, setPagination] = useState({currentPage: 1})
    let [pageCount, setPageCount] = useState(0)                        //总页数

    let [showTicketExtension, setShowTicketExtension] = useState(false)       // 展示卡券延期页面
    let [ticketExtensionData, setTicketExtensionData] = useState({})       // 卡券延期数据

    let [ticketList, setTicketList] = useState([])            // 卡券列表
    let [ticketListAll, setTicketListAll] = useState([])      // 卡券列表全部

    const [showRowNav, setShowRowNav] = useState(false)       // 是否展示第二行卡券分类名

    const [actInvalidData, setActInvalidData] = useState({})     // 作废数据
    const [showConfirm, setShowConfirm] = useState(false)     // 是否展示确认框


    useEffect(() => {
        setShowTicketExtension(false)

        //切换卡类时, 滚动条置顶
        // 初始化过期卡券数据
        let bool = false
        setTicketCountActive('')
        setExpiredTicketList([])
        setExpiredLoading(bool)
        setOver(bool)
        setNone(bool)
        setPagination({currentPage: 1})
        setPageCount(0)
        setTicketExtensionData({})

        //会员卡券详情列表
        getTicketList()
    }, [])

    //会员卡券详情列表
    let getTicketList = () => {
        common.loadingStart()
        setLoading(true)
        Promise.all([ticketDetail(), fetchCardSum()]).finally(() => {
            common.loadingStop()
            setLoading(false)
        })
    }

    useEffect(() => {
        // 非过期卡券
        if (ticketCountActive !== -1) {
            return
        }

        let arr = []
        for (let i = 0; i < expiredTicketList.length; i++) {
            arr.push(expiredTicketList[i].remark)
        }
        setRemarkList(arr)
    }, [expiredTicketList, ticketCountActive])

    //会员卡券详情列表
    const ticketDetail = () => {
        setRemarkList([])
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/ticket-detail/v2', {
                member_id: memberInfo.id,
            }).then((res) => {

                // 将项目拆成数组
                let tmpTicketDetailList = []
                let arr = []
                for (let i = 0; i < res.ticketDetailList.length; i++) {
                    let item = res.ticketDetailList[i]

                    item.project_list = []
                    if (item.project_str.length > 0) {
                        item.project_list = item.project_str.split(',')
                    }

                    tmpTicketDetailList.push(item)

                    arr.push(item.remark)
                }

                setTicketListAll(tmpTicketDetailList)
                setTicketList(tmpTicketDetailList)
                setRemarkList(arr)

                let newArr = []

                newArr.push({
                    'id': -1,
                    'name': '过期',
                    'quantity': 0,
                })

                for (let index in res.ticketList) {
                    if (res.ticketList[index].quantity != 0) {
                        newArr.push(res.ticketList[index])
                    }
                }

                setTicketCount(newArr)

                resolve()
            }).catch((err) => {
                reject()
            })
        })
    }

    //更改卡券备注
    const changeTicketRemark = (itemId, value) => {
        common.ajax('post', '/app.php/member-api/ticket-detail/update-remark', {
            member_id: memberInfo.id,
            ticket_detail_id: itemId,
            remark: value,
        })
    }

    //搜索分类卡券
    const selectTicket = (val) => {

        // 选中 过期的卡券 分类
        if (val === -1) {
            selectExpiredTicket()
        } else {
            setExpiredTicketList([])    // 清空过期的卡券
        }

        //切换卡类时, 滚动条置顶
        let arr = []
        let newArr = []
        ticketListAll.forEach((item) => {
            if (item.ticket_id == val) {
                arr.push(item)
                newArr.push(item.remark)
            }
        })
        setTicketList(arr)
        setRemarkList(newArr)
        setTicketCountActive(val)
    }

    //删除卡券
    const removeTicketList = (key, id, ticketId) => {
        let arr = ticketList
        let arrRemark = remarkList
        arr.splice(key, 1)
        arrRemark.splice(key, 1)
        setTicketList(JSON.parse(JSON.stringify(arr)))
        setRemarkList(JSON.parse(JSON.stringify(arrRemark)))
        let newArr = []
        ticketListAll.forEach((list) => {
            if (list.id !== id) {
                newArr.push(list)
            }
        })
        setTicketListAll(JSON.parse(JSON.stringify(newArr)))

        ticketCount.forEach((list) => {
            if (list.id === ticketId) {
                list.quantity--
            }
        })
    }

    // 选 过期的卡券 分类，进行拿第一页数据
    const selectExpiredTicket = () => {
        let bool = false
        setExpiredLoading(bool)
        setOver(bool)
        setNone(bool)

        let nextPage = 1    //第一页
        setPagination({...pagination, currentPage: nextPage})
        getExpiredTicketList('', nextPage, bool, bool, bool)
    }

    // 获取已过期卡券数据（未使用）
    const getExpiredTicketList = (replaceData, nextPage, newLoading, newNone, newOver) => {
        replaceData = replaceData || false
        nextPage = nextPage || 1       //因为pagination.currentPage赋值有延迟问题，翻页时采用传值

        newLoading = newLoading || false    //加载中
        newNone = newNone || false          //没有数据
        newOver = newOver || false          //已全部加载

        if (newLoading || newNone || newOver) {
            return
        }

        setExpiredLoading(true)
        common.ajax("get", "/app.php/member-api/ticket-detail/expired", {
            member_id: memberInfo.id,
            page: nextPage,
        }).then((res) => {
            setPageCount(res.page.pageCount)

            let infoList = []

            if (res.list.length > 0) {
                for (let i = 0; i < res.list.length; i++) {
                    let item = res.list[i]

                    item.project_list = []
                    if (item.project_str.length > 0) {
                        item.project_list = item.project_str.split(',')
                    }

                    infoList.push(item)
                }
            }

            if (!replaceData) {
                setExpiredTicketList(infoList)

                //重新加载数据
                if (infoList.length < 1) {
                    setOver(false)
                    setNone(true)   //没有数据，开启"暂无"提示
                    return
                }
            } else {
                //翻页
                for (let i = 0; i < infoList.length; i++) {
                    expiredTicketList.push(infoList[i])
                }
            }

            //当前页是最后一页
            let bool = nextPage >= res.page.pageCount;
            setOver(bool)
        }).catch((a) => {
            setNone(false)
            setExpiredLoading(false)
            setOver(false)
            setExpiredTicketList([])
        }).finally(() => {
            setExpiredLoading(false)
        })
    }

    const nextTicket = () => {
        if (none || expiredLoading || over) {
            return
        }

        // 当前页面大于等于总页码数
        if (pagination.currentPage >= pageCount) {
            return
        }

        let nextPage = pagination.currentPage + 1
        setPagination({...pagination, currentPage: nextPage})
        getExpiredTicketList(true, nextPage, loading, none, over)
    }

    // 获取最新卡券数
    const fetchCardSum = () => {
        common.ajax('get', '/app.php/member-api/member/sum', {id: memberInfo.id}).then((res) => {
            dispatch(setCardSum(res))        // 放入会员卡及卡券总数
        })
    }

    // 作废卡券
    const invalid = () => {
        if (actInvalidData.key === undefined) {
            common.toast('作废数据缺失')
            return
        }

        let item = actInvalidData.item
        let key = actInvalidData.key

        common.loadingStart()
        common.ajax('post', '/app.php/member-api/ticket-detail/invalid', {
            member_id: memberInfo.id,
            ticket_detail_id: item.id,
        }).then(() => {
            removeTicketList(key, item.id, item.ticket_id)
            fetchCardSum()
            common.toast('作废成功')
            setShowConfirm(false)
            setActInvalidData({})
        }).finally(common.loadingStop)
    }

    const showTicketItem = (item, key) => {
        return (
            <div key={key} className={style.itemDiv}
                 style={ticketBgStyle}>
                <div className={style.bgTopBox}>

                    <div className={style.ticketNameBox}>
                        <div className={style.ticketName}>{item.name}
                        </div>
                        {
                            item.money_str &&
                            <div className={style.moneyFont}><span>￥</span>{item.money_str}</div>
                        }

                    </div>

                    <div className={style.row}>
                        <div className={style.shop}>{item.user_name}</div>
                    </div>

                    <div className={style.row}>
                        <div
                            className={style.category}>限品类：{item.project_list.length > 0 ? item.project_list[0] : '不限'}</div>

                        <img className={style.bottomImg} src={bottomImg} alt=""/>

                        {
                            item.project_list.length > 0 &&
                            <div className={style.selectBox}>

                                {
                                    item.project_list.map((item, index) =>
                                        <div key={index} className={style.selectItem}>
                                            <div className={style.text}>
                                                <div>{item}</div>
                                            </div>
                                        </div>
                                    )
                                }

                                {/*<div className={'selectItem'}>*/}
                                {/*    <div className={'text'}>*/}
                                {/*        <div>三星团购全身精油开背三星团购全身精油开背</div>*/}
                                {/*        <img src={selectedImg} alt=""/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        }

                    </div>

                    <div className={style.row}>
                        <div className={style.number}>使用期限：{item.valid_str}</div>
                    </div>

                </div>


                <div className={style.bgBottomBox}>

                    <div className={style.remark}>
                        <textarea className={style.remarkInput} placeholder={'请输入备注信息'}
                                  value={remarkList[key] || ''}
                                  onChange={(e) => {
                                      let value = e.target.value
                                      remarkList[key] = value

                                      if (ticketCountActive !== -1) {
                                          ticketList[key].remark = value
                                      } else {
                                          expiredTicketList[key].remark = value // 过期卡券
                                      }

                                      clearTimeout(remarkTimes[key])
                                      remarkTimes[key] = setTimeout(() => {
                                          changeTicketRemark(item.id, value)
                                      }, 1000)
                                      setRemarkTimes(JSON.parse(JSON.stringify(remarkTimes)))
                                  }}></textarea>
                    </div>

                    <div className={style.btnBox}>
                        {
                            ticketCountActive !== -1 ? <>
                                <div className={style.btnItem} onClick={() => {
                                    setActInvalidData({
                                        item: item, key: key
                                    })
                                    setShowConfirm(true)
                                }}>作废
                                </div>
                                <div className={style.btnItem + ' ' + style.noBorder} onClick={() => {
                                    dispatch(setActiveTicket(item))
                                    setAction('transferCoupon')
                                }}>转赠
                                </div>
                            </> : <span className={style.btnItem + ' ' + style.noBorder} onClick={() => {
                                dispatch(setActiveTicket(item))
                                setAction('putOffTicket')
                            }}>延期</span>
                        }

                    </div>
                </div>

            </div>
        )
    }

    return (
        <div className={style.Coupon}>

            {
                showConfirm && <Modal title=""
                                      closable={false}
                                      footer={false}>
                    <ConfirmAlert toastTitle={'确认作废吗'}
                                  onCancel={() => {
                                      setShowConfirm(false)
                                      setActInvalidData({})
                                  }}
                                  onOk={() => invalid()}
                    />
                </Modal>
            }

            <div className={style.couponNav}>
                <div className={style.couponContentNav}>
                    {
                        ticketCount.map((item, key) => {
                                if (key > 6) {
                                    return ""
                                }

                                return (
                                    <div key={key}
                                         className={[style.couponNavItem, ticketCountActive === item.id && style.active].join(' ')}
                                         onClick={() => {
                                             selectTicket(item.id)
                                         }}>{item.name}{item.quantity ? '(' + item.quantity + ')' : ''}</div>
                                )
                            }
                        )
                    }
                </div>

                {
                    (ticketCount.length > 6 && !showRowNav) ?
                        <img className={style.selectImg} src={selectBottomImg} alt="" onClick={() => setShowRowNav(true)}/>
                        : <div className={style.selectImg}></div>
                }

            </div>

            {
                (ticketCount.length > 6 && showRowNav) && <div className={style.couponNav}>
                    <div className={style.couponContentNav}>
                        {
                            ticketCount.map((item, key) => {
                                    if (key < 7) {
                                        return ""
                                    }

                                    return (
                                        <div key={key}
                                             className={[style.couponNavItem, ticketCountActive === item.id && style.active].join(' ')}
                                             onClick={() => {
                                                 selectTicket(item.id)
                                             }}>{item.name}{item.quantity ? '(' + item.quantity + ')' : ''}</div>
                                    )
                                }
                            )
                        }
                    </div>

                    <img className={style.selectImg} src={selectTopImg} alt="" onClick={() => setShowRowNav(false)}/>
                </div>
            }


            <div className={style.couponContent}>
                {
                    ticketCountActive !== -1 ?
                        ticketList.map((item, key) =>
                            showTicketItem(item, key)
                        ) :
                        expiredTicketList.map((item, key) =>
                            showTicketItem(item, key)
                        )
                }

                {expiredTicketList.length > 0 && pagination.currentPage < pageCount ?
                    <div className={style.nextBtnBox}>
                        <div className={style.nextBtnDiv} onClick={() => {
                            nextTicket()
                        }}>点击加载更多
                        </div>
                    </div> : null}

                {
                    ticketCountActive !== -1 && (
                        ticketList.length === 0 && (
                            loading ?
                                <div className={style.loadingFont}>加载中...</div> :
                                <div className={style.loadingFont}>暂无数据</div>
                        )
                    )
                }

                {
                    ticketCountActive === -1 && expiredTicketList.length < 1 ?
                        <div className={style.loadingFont}>暂无数据</div> : null
                }
            </div>
        </div>

    );
}

export default Coupon;