import React, {useState} from 'react';
import Modal from "../../components/public/modal";
import Home from '../../components/member/home'

function Member(props) {

    const [show, setShow] = useState(true) // 是否展示会员弹框

    return (
    <div className={'Member'}>

        {
            show && <Modal
                closable={false}
                title={''}
            >
                <Home isOpenCard={true} onClose={() => {
                    setShow(false)
                }}></Home>

            </Modal>
        }


        {
            !show && <div style={{ width: "100%", height: "100%", textAlign: 'center', marginTop: '100px', fontSize: '20px'}}>新版会员弹框已关闭</div>
        }
    </div>
  );
}

export default Member;