import React from 'react';
import style from './style.module.less'
import addressImg from "../../../../../images/member/address.png";

function MemberRechargeRecord(props) {
    const {rechargeRecordList} = props

    return (
        <div className={style.MemberRechargeRecord}>
            {
                rechargeRecordList.map((item, key) => {
                    if (item.type === 10 || item.type === 20) {
                        return (
                            // 会员充值 和 开卡
                            <div
                                key={key}
                                className={[style.recordItem, item.type === 10 ? style.createCard : style.rechargeCard].join(' ')}>
                                <div className={style.timeLineBox}>
                                    <div className={style.circle}></div>
                                </div>
                                <div className={style.recordInfo}>
                                    <div className={style.recordHeader}>
                                        <div className={style.time}>{item.created_at}</div>
                                        <div
                                            className={style.orderNumber}>{item.type === 10 ? '会员开卡' : '会员充值'}</div>
                                    </div>

                                    <div className={style.tableBox}>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>卡类型</th>
                                                <th>卡号</th>
                                                <th>充值前金额</th>
                                                <th>充值金额</th>
                                                <th>充值后金额</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            <tr>
                                                <td>{item.card_category_name}</td>
                                                <td>{item.card_number}</td>
                                                <td>￥{item.before_money}</td>
                                                <td>￥{item.money}</td>
                                                <td>￥{item.after_money}</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <div className={style.tableBottom}>
                                            {(item.commission_list.length > 0) ? (
                                                <div className={style.salesPersonnelDiv}>
                                                    <div className={style.salesPersonnelTitle}>销售人员:</div>
                                                    <div className={style.salesPersonnelContent}>
                                                        {item.commission_list.map((list, k) => {
                                                            return (
                                                                <div className={style.salesPersonnelContentList}
                                                                     key={k}>
                                                                    {list.employee_str}
                                                                    <span>-</span>
                                                                    <span
                                                                        className={style.listMoney}>{list.money}元</span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            ) : <div></div>}
                                            <div className={style.infoItem}>
                                                <img className={style.infoImg} src={addressImg}
                                                     alt=""/> {item.user_name}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    } else if (item.type === 30) {
                        return (
                            // 会员消费
                            <div className={style.recordItem}>
                                <div className={style.timeLineBox}>
                                    <div className={style.circle}></div>
                                </div>
                                <div className={style.recordInfo}>
                                    <div className={style.recordHeader}>
                                        <div className={style.time}>{item.created_at}</div>
                                        <div className={style.orderNumber}>会员消费</div>
                                    </div>

                                    <div className={style.tableBox}>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>卡类型</th>
                                                <th>卡号</th>
                                                <th>操作门店</th>
                                                <th>消费金额</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            <tr>
                                                <td>{item.card_category_name}</td>
                                                <td>{item.card_number}</td>
                                                <td>{item.user_name}</td>
                                                <td>￥{item.money}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        )
                    } else if (item.type === 40) {
                        return (
                            // 会员退卡
                            <div className={style.recordItem + ' ' + style.backCard}>
                                <div className={style.timeLineBox}>
                                    <div className={style.circle}></div>
                                </div>
                                <div className={style.recordInfo}>
                                    <div className={style.recordHeader}>
                                        <div className={style.time}>{item.created_at}</div>
                                        <div className={style.orderNumber}>会员退卡</div>
                                    </div>

                                    <div className={style.tableBox}>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>卡类型</th>
                                                <th>卡号</th>
                                                <th>操作门店</th>
                                                <th>退卡扣款</th>
                                                <th>退卡金额</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            <tr>
                                                <td>{item.card_category_name}</td>
                                                <td>{item.card_number}</td>
                                                <td>{item.user_name}</td>
                                                <td>￥{item.refund_deduct_money}</td>
                                                <td>￥{item.money}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        )
                    } else if (item.type === 80) {
                        return (
                            // 会员并卡
                            <div className={style.recordItem + ' ' + style.mergeCard}>
                                <div className={style.timeLineBox}>
                                    <div className={style.circle}></div>
                                </div>
                                <div className={style.recordInfo}>
                                    <div className={style.recordHeader}>
                                        <div className={style.time}>{item.created_at}</div>
                                        <div className={style.orderNumber}>会员并卡</div>
                                    </div>

                                    <div className={style.tableBox}>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>卡类型</th>
                                                <th>卡号</th>
                                                <th>操作金额</th>
                                                <th>卡内金额</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            <tr>
                                                <td>
                                                    <div className={style.mergeDiv}>{item.card_category_name}
                                                        <div
                                                            className={style.operate}>{item.merge_type == 10 ? '转出' : '转入'}</div>
                                                    </div>
                                                </td>
                                                <td>{item.card_number}</td>
                                                <td>{item.money}</td>
                                                <td>{item.after_money}</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <div className={style.tableBottom}>
                                            <div></div>
                                            <div className={style.infoItem}>
                                                <img className={style.infoImg} src={addressImg}
                                                     alt=""/> {item.user_name}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    }

                })
            }

        </div>
    );
}

export default MemberRechargeRecord;