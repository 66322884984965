import React, {useState} from 'react';
import cutImg from '../../../../../images/member/cut.png'
import actCutImg from '../../../../../images/member/act-cut.png'
import addImg from '../../../../../images/member/add.png'
import actAddImg from '../../../../../images/member/act-add.png'
import style from './style.module.less'

function OperateQty(props) {
    const {num, handleAdd, handleMinus} = props

    const [tmpCutImg, setTmpCutImg] = useState(cutImg)
    const [tmpAddImg, setTmpAddImg] = useState(addImg)

    const onMouseLeave = (type) => {
        if (type === 'cut') {
            setTmpCutImg(cutImg)
            return
        }

        setTmpAddImg(addImg)
    }

    const onMouseEnter = (type) => {
        if (type === 'cut') {
            setTmpCutImg(actCutImg)
            return
        }

        setTmpAddImg(actAddImg)
    }

    // const handleAdd = () => {
    //     setNum(num + 1)
    // }
    //
    // const handleMinus = () => {
    //     if (num < 1) {
    //         return
    //     }
    //
    //     setNum(num - 1)
    // }

    return (
        <div className={style.rightAction}>
            <div className={style.actionBtn}>
                <img onClick={handleMinus} onMouseEnter={() => {
                    onMouseEnter('cut')
                }} onMouseLeave={() => {
                    onMouseLeave('cut')
                }} src={tmpCutImg} className={style.actionImage} alt=""/>
            </div>
            <div className={style.numberBox}>
                <input className={style.number} value={num} readOnly={true}/>
            </div>
            <div className={style.actionBtn}>
                <img onMouseEnter={() => {
                    onMouseEnter('add')
                }} onMouseLeave={() => {
                    onMouseLeave('add')
                }} onClick={handleAdd} src={tmpAddImg} className={style.actionImage} alt=""/>
            </div>
        </div>
    );
}

export default OperateQty;