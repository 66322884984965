import React, {useEffect, useRef, useState} from 'react';
import style from './style.module.less'
import defaultAvatar from '../../../images/member/default-avatar.png'
import uploadImg from '../../../images/member/upload-img.png'
import checkboxImg from '../../../images/member/checkbox.png'
import checkboxActImg from '../../../images/member/checkbox-act.png'
import Button from "../../public/button";
import common from "../../../utils/common";
import Close from "../../../images/close.png";
import {useDispatch, useSelector} from "react-redux";
import {setMemberInitData} from "../../../reducers/member";

function MemberCreate(props) {
    const dispatch = useDispatch();

    const {memberInfo} = useSelector(state => state.member)

    const {type, mobile, title, goRecordHome, onClose, goOpenCard} = props

    const imgRef = useRef(null)

    let [submitInfo, setSubmitInfo] = useState({                 //要提交的数据
        name: '',                       //会员姓名
        mobile: '',                     //会员手机号
        birthday: '',                   //生日(格式为：年-月-日)
        sex: 1,                         //性别(1: 男; 2: 女)
        id_card: '',                    //身份证号码
        postcode: '',                   //邮编
        home_phone: '',                 //住宅电话
        qq: '',                         //QQ
        email: '',                      //电子邮件
        house_address: '',              //家庭住址
        company_address: '',            //公司住址
        send_consume: 10,               //消费是否发送短信(10: 是; 20: 否)
        send_recharge: 10,              //充值是否发送短信(10: 是; 20: 否)
        send_create: 10,                //开卡是否发送短信(10: 是; 20: 否)
        send_message: 10,               //允许发送营销短信(10: 是; 20: 否)
        send_booking: 10,               //允许发送预约短信(10: 是; 20: 否)
        pre_pwd: '',                    //原密码
        pwd: '',                        //密码
        confirm_pwd: '',                //确认密码
    })

    const [initMemberMessage, setInitMemberMessage] = useState({})  //新开会员 或 编辑会员信息 时的 配置初始化
    const [coverImg, setCoverImg] = useState({})   // 头像数据

    useEffect(() => {
        if (!type) {
            return;
        }

        if (type === 'update' && (memberInfo === undefined || memberInfo.id === undefined)) {
            common.toast('缺少会员参数')
            return;
        }

        common.loadingStart()
        Promise.all([
            initMember(),               //新开会员 或 编辑会员信息 时的 配置初始化
            isUpdataOrNew()             //判断是 编辑会员 或 新开会员
        ]).finally(common.loadingStop)
    }, [type])

    //判断是 编辑会员 或 新开会员
    let isUpdataOrNew = () => {
        return new Promise((resolve, reject) => {
            //是编辑就获取会员信息
            if (type === 'update') {
                getInfo().then(() => {
                    resolve()
                }).catch((res) => {
                    reject(res)
                })
                return
            }

            resolve()
        })

    }

    //新开会员 或 编辑会员信息 时的 配置初始化
    const initMember = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/init/member').then((res) => {
                setInitMemberMessage(res)
                dispatch(setMemberInitData(res))

                if (type === 'create') {
                    // 默认数据
                    setSubmitInfo({
                        ...submitInfo,
                        'pwd': res.default_pwd,
                        'confirm_pwd': res.default_pwd,
                        'mobile': mobile,
                    })
                }

                resolve()
            }).catch((err) => {
                reject(err.error)
            })
        })
    }

    //获取会员信息
    const getInfo = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/member/info', {id: memberInfo.id}).then((res) => {
                setSubmitInfo({
                    ...submitInfo,
                    name: res.name,
                    mobile: res.mobile,
                    birthday: res.birthday === '-' ? '' : res.birthday,
                    sex: res.sex,
                    id_card: res.id_card,
                    postcode: res.postcode,
                    home_phone: res.home_phone,
                    qq: res.qq,
                    email: res.email,
                    house_address: res.house_address,
                    company_address: res.company_address,
                    send_consume: res.send_consume,
                    send_recharge: res.send_recharge,
                    send_create: res.send_create,
                    send_message: res.send_message,
                    send_booking: res.send_booking,
                })

                setCoverImg({...coverImg, url: res.avatar})
                resolve()
            }).catch((err) => {
                reject(err.error)
            })
        })
    }

    // 保存
    const handleSave = () => {
        if (!submitInfo.name) {
            common.toast('姓名不能为空')
            return
        }
        if (!submitInfo.mobile) {
            common.toast('手机号不能为空')
            return
        }
        if (submitInfo.mobile.length !== 11) {
            common.toast('手机号格式无效')
            return
        }

        // 可以管理密码
        if (initMemberMessage.manage_pwd === 10) {
            if (submitInfo.pwd !== submitInfo.confirm_pwd) {
                common.toast('密码与重复密码不一致')
                return
            }
        }

        if (type === 'update') {
            //修改会员信息
            updateHandle()
        } else if (type === 'create') {
            //创建会员
            createHandle()
        }
    }


    //修改会员信息
    const updateHandle = () => {
        if (memberInfo.id === undefined) {
            common.toast('缺少ID参数')
            return
        }

        common.loadingStart()
        common.ajax('post', '/app.php/member-api/member/update', {
            id: memberInfo.id,
            data: JSON.stringify({
                name: submitInfo.name,
                mobile: submitInfo.mobile,
                birthday: submitInfo.birthday,
                sex: submitInfo.sex,
                id_card: submitInfo.id_card,
                postcode: submitInfo.postcode,
                home_phone: submitInfo.home_phone,
                qq: submitInfo.qq,
                email: submitInfo.email,
                house_address: submitInfo.house_address,
                company_address: submitInfo.company_address,
            }),
            pre_pwd: submitInfo.pre_pwd,
            pwd: submitInfo.pwd,
            send_consume: submitInfo.send_consume,
            send_recharge: submitInfo.send_recharge,
            send_create: submitInfo.send_create,
            send_message: submitInfo.send_message,
            send_booking: submitInfo.send_booking,
            fileKey: coverImg.fileKey ? coverImg.fileKey : '',
        }).then((res) => {
            common.toast(res)
            setTimeout(() => {
                goRecordHome(memberInfo.id)
            }, 1000)
        }).finally(common.loadingStop)
    }

    //创建会员
    const createHandle = () => {
        common.loadingStart()
        common.ajax('post', '/app.php/member-api/member/create', {
            data: JSON.stringify({
                name: submitInfo.name,
                mobile: submitInfo.mobile,
                birthday: submitInfo.birthday,
                sex: submitInfo.sex,
                id_card: submitInfo.id_card,
                postcode: submitInfo.postcode,
                home_phone: submitInfo.home_phone,
                qq: submitInfo.qq,
                email: submitInfo.email,
                house_address: submitInfo.house_address,
                company_address: submitInfo.company_address,
            }),
            pwd: submitInfo.pwd || initMemberMessage.default_pwd,
            send_consume: submitInfo.send_consume,
            send_recharge: submitInfo.send_recharge,
            send_create: submitInfo.send_create,
            send_message: submitInfo.send_message,
            send_booking: submitInfo.send_booking,
            fileKey: coverImg.fileKey ? coverImg.fileKey : '',
        }).then((res) => {
            common.toast('操作成功')
            setTimeout(() => {
                //两秒后查询该手机号, 并跳转至详情页
                common.loadingStart()
                common.ajax('get', '/app.php/member-api/member/find', {
                    mobile: submitInfo.mobile
                }).then((res) => {
                    if (res.length > 0) {
                        goRecordHome(res[0].id)
                    }
                }).catch((err) => {
                    common.alert(err.error)
                }).finally(common.loadingStop)
            }, 1000)
        }).finally(common.loadingStop)
    }

    // 上传头像
    let uploadAvatar = (files) => {
        let form = new FormData();
        form.append('files', files, files.name)

        common.loadingStart()
        common.ajax('post', '/app.php/member-api/member/upload', form, {'contentType': 'multipart/form-data'}).then((res) => {
            let pic = res[0]
            let item = {
                uid: Date.now(),    // 当前时间戳当循环中的key使用
                fileKey: pic.fileKey,
                url: pic.url,
                status: 'done',
            }

            setCoverImg(item)
        }).finally(() => {
            common.loadingStop()
        })
    }

    const onCancle = () => {
        // 返回消费记录未展开页
        if(type === 'update') {
            goRecordHome(memberInfo.id)
            return
        }

        goOpenCard()
    }

    return (
        <div className={style.MemberCreate}>
            <div className={style.memberTitle}>
                {title}
                <div className={style.closeImage} onClick={onClose}>
                    <img src={Close} alt=""/>
                </div>
            </div>

            <div className={style.content}>
                <div className={style.left}>
                    <div className={style.avatarBox}>
                        <img className={style.avatarImg} src={coverImg.url ? coverImg.url : defaultAvatar} alt=""/>

                        {/*<div className={'update-pen-div'}>*/}
                        {/*    <img className={'update-pen'} src={updatePen} alt=""/>*/}
                        {/*</div>*/}
                    </div>

                    <div className={style.uploadDiv} onClick={() => imgRef.current.click()}>
                        <img className={style.uploadImg} src={uploadImg} alt=""/>
                        上传图片

                        <input ref={imgRef} style={{display: 'none'}} type="file" value={''} onChange={(e) => {
                            uploadAvatar(e.target.files[0])
                        }}/>
                    </div>
                </div>
                <div className={style.right}>
                    <div className={style.row}>
                        <div className={style.col}>
                            <div className={style.label + ' ' + style.required}>姓名</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="text" placeholder="请输入姓名"
                                       value={submitInfo.name} onChange={(e) => {
                                    setSubmitInfo({...submitInfo, name: e.target.value})
                                }}/>
                            </div>
                        </div>

                        <div className={style.col}>
                            <div className={style.label}>性别</div>
                            <div className={style.rightDiv}>
                                <label className={style.radioWrapper} onClick={() => {
                                    setSubmitInfo({...submitInfo, sex: 1})
                                }}>
                                <span className={[style.antRadio, submitInfo.sex === 1 && style.antRadioChecked].join(' ')}>
                                    <input className={style.antRadioInput} type="radio" value="apple"/>
                                        <div className={style.antRadioInner}></div>
                                </span>
                                    <span> 男 </span>
                                </label>
                                <label
                                    onClick={() => {
                                        setSubmitInfo({...submitInfo, sex: 2})
                                    }}
                                    className={style.radioWrapper}>
                                <span className={[style.antRadio, submitInfo.sex === 2 && style.antRadioChecked].join(' ')}>
                                    <input className={style.antRadioInput} type="radio" value="apple"/>
                                        <div className={style.antRadioInner}></div>
                                </span>
                                    <span> 女 </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className={style.row}>
                        <div className={style.col}>
                            <div className={style.label + ' ' + style.required}>手机号码</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="text" placeholder="请输入手机号码"
                                       value={submitInfo.mobile}
                                       onChange={(e) => {
                                           setSubmitInfo({...submitInfo, mobile: common.trimBlank(e.target.value)})
                                       }}/>
                            </div>
                        </div>

                        <div className={style.col}>
                            <div className={style.label}>QQ</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="text" placeholder="请输入QQ"
                                       value={submitInfo.qq}
                                       onChange={(e) => {
                                           setSubmitInfo({...submitInfo, qq: e.target.value})
                                       }}/>
                            </div>
                        </div>
                    </div>

                    <div className={style.row}>
                        <div className={style.col}>
                            <div className={style.label}>身份证号</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="text" placeholder="请输入身份证号"
                                       value={submitInfo.id_card}
                                       onChange={(e) => {
                                           setSubmitInfo({...submitInfo, id_card: e.target.value})
                                       }}/>
                            </div>
                        </div>

                        <div className={style.col}>
                            <div className={style.label}>住宅电话</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="text" placeholder="请输入住宅电话"
                                       value={submitInfo.home_phone}
                                       onChange={(e) => {
                                           setSubmitInfo({...submitInfo, home_phone: e.target.value})
                                       }}/>
                            </div>
                        </div>
                    </div>

                    <div className={style.row}>
                        <div className={style.col}>
                            <div className={style.label}>家庭住址</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="text" placeholder="请输入家庭住址"
                                       value={submitInfo.house_address}
                                       onChange={(e) => {
                                           setSubmitInfo({...submitInfo, house_address: e.target.value})
                                       }}/>
                            </div>
                        </div>

                        <div className={style.col}>
                            <div className={style.label}>出生日期</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="date" placeholder="请输入出生日期"
                                       value={submitInfo.birthday}
                                       onChange={(e) => {
                                           setSubmitInfo({...submitInfo, birthday: e.target.value})
                                       }}/>
                            </div>
                        </div>
                    </div>

                    <div className={style.row}>
                        <div className={style.col}>
                            <div className={style.label}>邮编</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="text" placeholder="请输入邮编"
                                       value={submitInfo.postcode}
                                       onChange={(e) => {
                                           setSubmitInfo({...submitInfo, postcode: e.target.value})
                                       }}/>
                            </div>
                        </div>

                        <div className={style.col}>
                            <div className={style.label}>电子邮箱</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="text" placeholder="请输入电子邮箱"
                                       value={submitInfo.email}
                                       onChange={(e) => {
                                           setSubmitInfo({...submitInfo, email: e.target.value})
                                       }}/>
                            </div>
                        </div>
                    </div>

                    <div className={style.row}>
                        <div className={style.col}>
                            <div className={style.label}>公司住址</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="text" placeholder="请输入公司住址"
                                       value={submitInfo.company_address}
                                       onChange={(e) => {
                                           setSubmitInfo({...submitInfo, company_address: e.target.value})
                                       }}/>
                            </div>
                        </div>

                        {
                            initMemberMessage.manage_pwd === 10 ?
                                (
                                    type === 'update' ?
                                        <div className={style.col}>
                                            <div className={style.label}>原密码</div>
                                            <div className={style.rightDiv}>
                                                <input className={style.rightInput} type="password"
                                                       placeholder="请输入原密码"
                                                       value={submitInfo.pre_pwd}
                                                       onChange={(e) => {
                                                           setSubmitInfo({...submitInfo, pre_pwd: e.target.value})
                                                       }}/>
                                            </div>
                                        </div> :
                                        <div className={style.col}>
                                            <div className={style.label}>密码</div>
                                            <div className={style.rightDiv}>
                                                <input className={style.rightInput} type="password" placeholder="请输入密码"
                                                       value={submitInfo.pwd}
                                                       onChange={(e) => {
                                                           setSubmitInfo({...submitInfo, pwd: e.target.value})
                                                       }}/>
                                            </div>
                                        </div>
                                ) :
                                <div className={style.col}></div>
                        }
                    </div>

                    {
                        initMemberMessage.manage_pwd === 10 && (
                            type === 'update' ? <div className={style.row}>
                                <div className={style.col}>
                                    <div className={style.label}>密码</div>
                                    <div className={style.rightDiv}>
                                        <input className={style.rightInput} type="password" placeholder="请输入密码"
                                               value={submitInfo.pwd}
                                               onChange={(e) => {
                                                   setSubmitInfo({...submitInfo, pwd: e.target.value})
                                               }}/>
                                    </div>
                                </div>

                                <div className={style.col}>
                                    <div className={style.label}>重复密码</div>
                                    <div className={style.rightDiv}>
                                        <input className={style.rightInput} type="password" placeholder="请输入重复密码"
                                               value={submitInfo.confirm_pwd}
                                               onChange={(e) => {
                                                   setSubmitInfo({...submitInfo, confirm_pwd: e.target.value})
                                               }}/>
                                    </div>
                                </div>
                            </div> : <div className={style.row}>
                                <div className={style.col}>
                                    <div className={style.label}>重复密码</div>
                                    <div className={style.rightDiv}>
                                        <input className={style.rightInput} type="password" placeholder="请输入重复密码"
                                               value={submitInfo.confirm_pwd}
                                               onChange={(e) => {
                                                   setSubmitInfo({...submitInfo, confirm_pwd: e.target.value})
                                               }}/>
                                    </div>
                                </div>

                                <div className={style.col}></div>
                            </div>
                        )
                    }


                    {/*<div className={style.row}>*/}
                    {/*    <div className={style.col}>*/}
                    {/*        <div className={style.label}>备注信息</div>*/}
                    {/*        <div className={style.rightDiv}>*/}

                    {/*        <textarea className="right-textarea" name="" cols="30" rows="10" placeholder="请输入备注"*/}
                    {/*                  onChange={(e) => {*/}
                    {/*                  }}*/}
                    {/*        />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {
                        initMemberMessage.manage_message === 10 &&
                        <div className={style.row}>
                            <div className={style.col}>
                                <div className={style.label}>信息提醒</div>
                                <div className={style.rightDiv}>
                                    <label
                                        className={style.checkboxWrapper} onClick={(e) => {
                                        let val = submitInfo.send_consume === 10 ? 20 : 10
                                        setSubmitInfo({...submitInfo, send_consume: val})
                                    }}><span
                                        className={style.antCheckbox}>
                               <img src={submitInfo.send_consume === 10 ? checkboxActImg : checkboxImg}
                                    className={style.checkboxImg} alt=""/>
                               </span><span>允许发送消费信息</span>
                                    </label>
                                    <label
                                        className={style.checkboxWrapper} onClick={(e) => {
                                        let val = submitInfo.send_recharge === 10 ? 20 : 10
                                        setSubmitInfo({...submitInfo, send_recharge: val})
                                    }}><span
                                        className={style.antCheckbox}>
                               <img src={submitInfo.send_recharge === 10 ? checkboxActImg : checkboxImg}
                                    className={style.checkboxImg} alt=""/>
                               </span><span>允许发送充值短信</span>
                                    </label>
                                    <label
                                        className={style.checkboxWrapper} onClick={(e) => {
                                        let val = submitInfo.send_create === 10 ? 20 : 10
                                        setSubmitInfo({...submitInfo, send_create: val})
                                    }}><span
                                        className={style.antCheckbox}>
                                      <img src={submitInfo.send_create === 10 ? checkboxActImg : checkboxImg}
                                           className={style.checkboxImg}
                                           alt=""/></span><span>允许发送办卡提醒</span>
                                    </label>
                                    <label
                                        className={style.checkboxWrapper} onClick={(e) => {
                                        let val = submitInfo.send_message === 10 ? 20 : 10
                                        setSubmitInfo({...submitInfo, send_message: val})
                                    }}><span
                                        className={style.antCheckbox}>
                                      <img src={submitInfo.send_message === 10 ? checkboxActImg : checkboxImg}
                                           className={style.checkboxImg}
                                           alt=""/></span><span>允许发送营销短信</span>
                                    </label>
                                    <label
                                        className={style.checkboxWrapper} onClick={(e) => {
                                        let val = submitInfo.send_booking === 10 ? 20 : 10
                                        setSubmitInfo({...submitInfo, send_booking: val})
                                    }}><span
                                        className={style.antCheckbox}>
                                      <img src={submitInfo.send_booking === 10 ? checkboxActImg : checkboxImg}
                                           className={style.checkboxImg}
                                           alt=""/></span><span>允许发送预约短信</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className={style.bottomDiv}>
                <Button type={'cancel'} shape={'default'}
                        onClick={() => {
                            onCancle()
                        }}
                >取消</Button>
                <Button style={{marginLeft: '10px'}}
                        type={'confirm'}
                        shape={'default'}
                        onClick={handleSave}
                >确定</Button>
            </div>
        </div>
    )
}

export default MemberCreate;