import React, {useState} from 'react';
import style from './style.module.less'
import MemberDetail from "../components/memberDetail";
import DetailModal from "../components/detailModal";
import Button from "../../public/button";
import {useSelector} from "react-redux";
import common from "../../../utils/common";

function UpdatePassword(props) {
    const {setAction, goRecordHome, onClose} = props

    const {memberInfo} = useSelector(state => state.member)

    const [submitInfo, setSubmitInfo] = useState({                 //要提交的数据
        pre_pwd: '',                    //原密码
        pwd: '',                        //密码
        confirm_pwd: '',                //重复密码
    })

    //提交数据
    const submitHandle = () => {
        if(submitInfo.pwd.length < 6) {
            common.toast('新密码不能小于6位')
            return
        }

        if(submitInfo.pwd !== submitInfo.confirm_pwd) {
            common.toast('重复密码不一致')
            return
        }

        common.loadingStart()
        common.ajax('post', '/app.php/member-api/member/update-password', {
            id: memberInfo.id,
            pre_pwd: submitInfo.pre_pwd,
            pwd: submitInfo.pwd,
            confirm_pwd: submitInfo.confirm_pwd,
        }).then((res) => {
            common.toast(res)
            goRecordHome(memberInfo.id)
        }).finally(common.loadingStop)
    }

    return (
        <div className={style.UpdatePassword}>
            <MemberDetail setAction={setAction}/>
            <DetailModal closable={true} onBack={() => goRecordHome(memberInfo.id)} title={'修改密码'} onCancel={onClose} footer={
                <div className={style.buttonBox}>
                    <Button type={'cancel'} shape={'default'}
                            onClick={() => {
                                goRecordHome(memberInfo.id)
                            }}>取消</Button>
                    <Button style={{marginLeft: '10px'}}
                            type={'confirm'}
                            shape={'default'}
                            onClick={() => {
                                submitHandle()
                            }}>确定</Button>
                </div>
            }>
                <div className={style.contentBox}>
                    <div className={style.contentMiddle}>
                        <div className={style.row}>
                            <div className={style.rowTitle}>姓名</div>
                            <div className={style.rowText}>{memberInfo.name}</div>
                        </div>
                        <div className={style.row}>
                            <div className={style.rowTitle}>手机号</div>
                            <div className={style.rowText}>{memberInfo.mobile}</div>
                        </div>

                        <div className={style.border}></div>

                        <div className={style.inputRow}>
                            <div className={style.label}>原密码</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="password" placeholder="请输入"
                                       autoComplete="new-password"
                                       value={submitInfo.pre_pwd}
                                       onChange={(e) => {
                                           setSubmitInfo({...submitInfo, pre_pwd: e.target.value})
                                       }}/>
                            </div>
                        </div>

                        <div className={style.inputRow}>
                            <div className={style.label}>新密码</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="password" placeholder="请输入"
                                       autoComplete="new-password"
                                       value={submitInfo.pwd}
                                       onChange={(e) => {
                                           setSubmitInfo({...submitInfo, pwd: e.target.value})
                                       }}/>
                            </div>
                        </div>

                        <div className={style.inputRow}>
                            <div className={style.label}>重复密码</div>
                            <div className={style.rightDiv}>
                                <input className={style.rightInput} type="password" placeholder="请输入"
                                       autoComplete="new-password"
                                       value={submitInfo.confirm_pwd}
                                       onChange={(e) => {
                                           setSubmitInfo({...submitInfo, confirm_pwd: e.target.value})
                                       }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </DetailModal>
        </div>
    );
}

export default UpdatePassword;