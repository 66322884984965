import React from 'react';
import style from './style.module.less'

function CouponCard(props) {
    const {item} = props

    return (
        <div className={style.CouponCard}>
            <div className={style.title}>
                {item.name}
            </div>
            {/*<div className={style.description}>*/}
            {/*    限消费品项：无限制*/}
            {/*</div>*/}
            <div className={style.description}>
                有效期：{item.valid_str}
            </div>
            <div className={style.bottomRemark}>

                <div className={style.leftRemark}>
                    {(item.type === 2 || item.type === 5) ?
                        ('优惠比例: ' + item.discount + '%') :
                        ('消费次数: ' + item.consume_count + '次')}
                </div>
                <div className={style.priceRemark}>
                    ￥{item.price}
                </div>
            </div>
        </div>
    );
}

export default CouponCard;