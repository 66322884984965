import {createSlice} from '@reduxjs/toolkit'
import business from "../utils/business";
import biz from "../utils/biz";
import businessAction from "../utils/businessAction";

const NAMESPACE = "checkoutContent"

// 结账的消费名字使用
const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    showCheckoutContentType: '', // 子弹框
    activeServiceType: '',
    preActiveServiceType: '',
    checkoutBills: [],
  },
  reducers: {
    setShowCheckoutContentType(state, action) {
      state.showCheckoutContentType = action.payload
    },
    setActiveServiceType(state, action) {
      state.activeServiceType = action.payload
    },
    setPreActiveServiceType(state, action) {
      state.preActiveServiceType = action.payload
    },
    setCheckoutBills(state, action) {
      state.checkoutBills = action.payload
      businessAction.checkoutContent.bills = [...action.payload]
    }
  },
  extraReducers: {},
});

export const {
  setShowCheckoutContentType,
  setActiveServiceType,
  setPreActiveServiceType,
  setCheckoutBills,
} = slice.actions

export const {reducer} = slice;
