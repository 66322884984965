import React from 'react';
import './style.less'
import classNames from "classnames";

function Button(props) {

    const {type = 'primary', shape = 'round', disabled, children, onClick, focus = false, block = false, hover = '', ...rest} = props

    let classes = classNames({
        'ssm-btn': true,
        'ssm-btn-block': block,

        'ssm-btn-focus': focus,
        'ssm-btn-hover': hover === 'default',
        "ssm-btn-checkout-hover": hover === 'checkout',
        "ssm-btn-checkout-bottom-hover": hover === 'checkoutBottom',
        "ssm-btn-checkout-cancel-hover":hover === 'checkoutCancel',

        'ssm-btn-default': type === 'default',
        'ssm-btn-gray': type === 'gray',
        'ssm-btn-orange': type === 'orange',
        'ssm-btn-success': type === 'success',
        'ssm-btn-cancel': type === 'cancel',
        'ssm-btn-confirm': type === 'confirm',

        'ssm-btn-shape-round': shape === 'round',
        'ssm-btn-shape-default': shape === 'default',

        'ssm-btn-disabled': disabled,
    })

    return (
        <button onClick={onClick} className={classes} {...rest}>{children}</button>
    )
}

export default Button;