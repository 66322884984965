import {combineReducers} from 'redux'
import {configureStore} from '@reduxjs/toolkit'

import {reducer as appReducer} from './reducers/app'
import {reducer as selectContentReducer} from './reducers/selectContent'
import {reducer as checkoutContentReducer} from './reducers/checkoutContent'
import {reducer as checkoutPayReducer} from './reducers/checkoutPay'
import {reducer as checkoutPayCardReducer} from './reducers/checkoutPayCard'
import {reducer as doorClockReducer} from './reducers/doorClock'
import {reducer as memberReducer} from './reducers/member'
import {reducer as rightMenuReducer} from './reducers/rightMenu'
import {reducer as roomReducer} from './reducers/room'
import {reducer as handNumberReducer} from './reducers/handNumber'
import {reducer as callInfoReducer} from './reducers/callInfo'
import {reducer as deviceReducer} from './reducers/device'
import {reducer as checkoutPayMeituanReducer} from './reducers/checkoutPayMeituan'

const reducers = combineReducers({
    app: appReducer,
    selectContent: selectContentReducer,
    checkoutContent: checkoutContentReducer,
    checkoutPay: checkoutPayReducer,
    checkoutPayCard: checkoutPayCardReducer,
    doorClock: doorClockReducer,
    member: memberReducer,
    rightMenu: rightMenuReducer,
    room: roomReducer,
    handNumber: handNumberReducer,
    callInfo: callInfoReducer,
    device: deviceReducer,
    checkoutPayMeituan: checkoutPayMeituanReducer,
})

const promiseMiddleware = ({dispatch, getState}) => next => (action) => {

    // eg. home/productListApi/fulfilled
    const matches = /(.*)\/(pending|fulfilled|rejected)/.exec(action.type)
    if (matches) {
        const [, apiName, requestState] = matches
        dispatch({type: `LOADING_${apiName}_${requestState.toUpperCase()}`});
    }

    return next(action)
}

const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(promiseMiddleware),
});

export default store
