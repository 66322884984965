import common from "./common";
import business from "./business";
import biz from "./biz";

let print = {
  printWidth: 500,

  //打印
  // printContent: 打印的内容,JSON字符串
  // printParams: 对应的参数，数组
  'print': function (printContent, printParams, num, printResult) {

    //打印结果默认值数据
    printResult = printResult || {'error': ''};

    //打印次数小于0时，停止打印
    if (parseInt(num) <= 0) {
      return printResult;
    }

    // 打印小票
    print.printToMorePage(printContent, printParams, '');

    //打印次数减一
    num = parseInt(num) - parseInt(1);

    print.print(printContent, printParams, num, printResult);
  },

  //打印呼叫服务内容的数据
  'printCallServe': function (callServeData) {
    //打印数据
    var printData = [];

    var i;

    var businessTime = business.businessDate.replace(/-/g, '/');
    businessTime = new Date(businessTime);
    businessTime = businessTime.getTime();
    businessTime = businessTime / 1000;

    for (i = 0; i < callServeData.length; i++) {
      if (parseInt(callServeData[i]['status']) === business.callServeStatusList.delete) {
        continue;
      }

      //排除不在刚下单和待处理中的数据
      if (parseInt(callServeData[i]['status']) !== business.callServeStatusList.call && parseInt(callServeData[i]['status']) !== business.callServeStatusList.pending) {
        continue;
      }

      //排除已经打印过的数据
      if (callServeData[i]['print_at'] > 0) {
        continue;
      }

      //只打印当前营业日的数据
      if (callServeData[i]['created_at'] < businessTime) {
        continue;
      }

      printData.push(callServeData[i]);
    }

    //待处理的呼叫服务信息
    var url = common.apiUrl('call-serve/print-data')

    // todo: 20220124 暂时不启用边缘的呼叫该功能，未审核测试，不能保证
    // if (biz.sortVersion(business.offlineVersion, '20211129') !== 1) {
    //   url = common.apiUrl('FINANCE/call-serve/print-data')
    // }

    for (var j = 0; j < printData.length; j++) {
      print.printAndUpdateByUrlAndId(url, printData[j]['id']);
    }
  },

  //打印且更新打印时间  给出更新的URL
  'printAndUpdateByUrlAndId': function (url, id) {
    common.axios('get', url, {
      'id': id
    }).then((resultData) => {

      if (resultData['message'] === 'success') {

        var secondPrintArr = resultData['data'];

        //打印第二个小票
        var printResult = {'error': ''};

        //打印小票
        print.print(secondPrintArr, ['printerName=print3', 'printWidth=' + print.printWidth], 1, printResult);
      }

    }).catch((errorObj) => {
      // 失败
      common.toast(errorObj.error);
    }).finally(() => {

    })
  },

  // 消费转移的打印小票: consumeIds:json字符串
  'printForTransfer': function (transferIds) {
    common.axios('post', common.apiUrl("FINANCE/consume/print-for-transfer"), {
      "transferIds": transferIds
    }).then((resultData) => {
      if (!resultData) {
        return;
      }

      // 将打印的内容，JSON解析后，再encode，因为java返回的和PHP的不太一样，要处理成一样的
      try {
        resultData = biz.jsonEncode(JSON.parse(resultData));
      } catch (e) {

      }

      var print2Result = {'error': ''};

      // 鞋吧小票
      print.print(resultData, ['printerName=print2', 'printWidth=' + print.printWidth], 1, print2Result);

      if (print2Result.error) {
        common.toast("消费转移小票打印失败:" + print2Result.error);
      }

    }).catch((errorObj) => {
      common.toast('消费转移打印小票: 打印失败:' + errorObj.error);
    })
  },


  // 打印小票 --- 当内容超过
  // data: json字符串，数组组成的json字符串
  // printParams：数组， ["printWidth=xx", "print=xxx", ...]
  'printToMorePage': function (data, printParams, qrcode) {
    // 将大于的数据，分为40行为一段的纸
    qrcode = qrcode || ''

    // var contentArr = [];
    var onePageRows = 50; // 一张小票的行数：50行: 他们测试的50行的项目，会无法打印，加上标题和维度，暂时定为50行超过则换下一张纸

    try {
      data = JSON.parse(data)
    } catch (e) {
      common.toast("打印数据格式有误");
      return;
    }

    var pages = Math.ceil(data.length / onePageRows); // 一共几张小票

    // 组装打印的参数数据

    for (let i = 1; i <= pages; i++) {
      // 每张小票打印

      let tempContent = [] // 打印的内容

      // 第几行到第几行
      tempContent = data.slice((i - 1) * onePageRows, i * onePageRows);

      tempContent = biz.jsonEncode(tempContent)

      let tempPrintData = [...printParams]
      tempPrintData.push('json=' + encodeURIComponent(tempContent))

      tempPrintData = tempPrintData.join('&')
      if (tempPrintData) {
        tempPrintData = '?' + tempPrintData
      }

      //将数据传到程序上进行打印
      var temp = ''

      var ajaxData = {}

      if (i === pages) {
        // 最后一页，把二维码加上
        if (qrcode) {
          ajaxData['qrcode'] = qrcode
        }
      }

      // 打印
      common.jQueryAjax({
        url: business.print + tempPrintData,
        type: "POST",
        dataType: 'json',
        async: false,
        data: ajaxData,
        success: function (result) {
          temp = result;
        },
        error: function () {
          // common.toast("打印程序没打开");
          common.toast("打印程序未打开，请在电脑桌面上双击打开名叫[读卡打印]的程序");
        }
      });

      if (!temp) {
        // common.toast("打印程序没打开[" + i + "]");
        common.toast("打印程序未打开，请在电脑桌面上双击打开名叫[读卡打印]的程序[" + i + "]");
        break;
        return;
      }

      if (temp !== 'success') {
        common.toast("打印失败:" + temp);
        break;
        return;
      }

      // 打印成功，打印下一张，如果是最后一张了，则直接提示，打印成功
      if (i < pages) {
        common.toast("打印中...")
      } else {
        common.toast("打印成功");
      }

      // 延时500毫秒
    }

  },

  // 删除消费 的打印小票: consumeIds:json字符串
  'printForConsumeDelete': function (consumeIds) {
    common.axios('post', common.apiUrl("FINANCE/print/consume-cancel"), {
      "consumeIds": consumeIds, // 消费ids  json字符串
      "type": 20, // 删除消费
    }).then((resultData) => {
      if (!resultData) {
        return;
      }

      // 将打印的内容，JSON解析后，再encode，因为java返回的和PHP的不太一样，要处理成一样的
      try {
        resultData = biz.jsonEncode(JSON.parse(resultData));
      } catch (e) {

      }

      var printResult = {'error': ''};

      //打印小票
      print.print(resultData, ['printWidth=' + print.printWidth], 1, printResult);

      if (printResult.error) {
        common.toast("删除消费小票打印失败:" + printResult.error);
      }

    }).catch((errorObj) => {
      common.toast('删除消费打印小票: 打印失败:' + errorObj.error);
    })
  },

  // 退单 的打印小票: consumeIds:json字符串
  'printForBillBack': function (consumeIds) {
    common.axios('post', common.apiUrl("FINANCE/print/consume-cancel"), {
      "consumeIds": consumeIds, // 消费ids  json字符串
      "type": 10, // 退单
    }).then((resultData) => {
      if (!resultData) {
        return;
      }

      // 将打印的内容，JSON解析后，再encode，因为java返回的和PHP的不太一样，要处理成一样的
      try {
        resultData = biz.jsonEncode(JSON.parse(resultData));
      } catch (e) {

      }

      var printResult = {'error': ''};

      //打印小票
      print.print(resultData, ['printWidth=' + print.printWidth], 1, printResult);

      if (printResult.error) {
        common.toast("退单小票打印失败:" + printResult.error);
      }

    }).catch((errorObj) => {
      common.toast('退单打印小票: 打印失败:' + errorObj.error);
    })
  },

  // 消费转移 的打印小票: ids:json字符串
  'printForConsumeTransfer': function (ids) {
    common.axios('post', common.apiUrl("FINANCE/print/bill-transfer"), {
      "ids": ids, // 消费转移表的ids  json字符串
    }).then((resultData) => {
      if (!resultData) {
        return;
      }

      // 将打印的内容，JSON解析后，再encode，因为java返回的和PHP的不太一样，要处理成一样的
      try {
        resultData = biz.jsonEncode(JSON.parse(resultData));
      } catch (e) {

      }

      var printResult = {'error': ''};

      //打印小票
      print.print(resultData, ['printWidth=' + print.printWidth], 1, printResult);

      if (printResult.error) {
        common.toast("消费转移小票打印失败:" + printResult.error);
      }

    }).catch((errorObj) => {
      common.toast('消费转移打印小票: 打印失败:' + errorObj.error);
    })
  },


}

export default print;
