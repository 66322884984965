import React, {useState, useEffect, useRef} from 'react';
import style from'./style.module.less'
import bottomImg from "../../../../../images/member/blue-select-bottom.png";
import selectedImg from "../../../../../images/member/selected.png";
import common from "../../../../../utils/common";
import {useDispatch, useSelector} from "react-redux";
import {setCardSum, setActiveCard} from "../../../../../reducers/member";
import Modal from "../../../../public/modal";
import ConfirmAlert from "../../../components/confirmAlert";
import Button from "../../../../public/button";
import checkboxImg from '../../../../../images/member/checkbox.png'
import checkboxActImg from '../../../../../images/member/checkbox-act.png'
import memberUtils from "../../../../../utils/memberUtils";

function CardItem(props) {
    const {type, ticketBgStyle, setAction} = props // type: 10: 储值卡；20: 计次卡; 30: 专享卡
    const dispatch = useDispatch();

    const {memberInfo} = useSelector(state => state.member)

    const whichMerge = useRef(0)                                // 确定点击多卡合并的是哪个
    const [mergeCardList, setMergeCardList] = useState([])      // 被合并的卡类id列表
    const [merge, setMerge] = useState(false)                   // 是否展示合并

    const [cardList, setCardList] = useState([])              // 储值卡 或 计次卡列表
    const [loading, setLoading] = useState(false)               // 页面加载中
    const [remarkList, setRemarkList] = useState([])            // 备注信息
    const [remarkTimes, setRemarkTimes] = useState([])          // 备注的计时器
    const [showConfirm, setShowConfirm] = useState(false)        // 是否展示确认框
    const [activeCard, setTmpActiveCard] = useState({})          // 当前操作的卡

    useEffect(() => {
        pageFetchData()
    }, [type])

    const pageFetchData = () => {
        //获取储值卡 或 计次卡列表
        common.loadingStart()
        setLoading(true)
        Promise.all([fetchCardSum(), init()]).finally(() => {
                common.loadingStop()
                setLoading(false)
            }
        )
    }

    //获取储值卡 或 计次卡列表
    const init = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/card', {
                member_id: memberInfo.id,
                type: type
            }).then((res) => {
                setCardList(res)
                let arr = []
                for (let i = 0; i < res.length; i++) {
                    arr.push(res[i].remark)
                }
                setRemarkList(arr)
                resolve()
            }).catch((err) => {
                reject()
            })
        })
    }

    // 获取最新卡券数
    const fetchCardSum = () => {
        common.ajax('get', '/app.php/member-api/member/sum', {id: memberInfo.id}).then((res) => {
            dispatch(setCardSum(res))        // 放入会员卡及卡券总数
        })
    }

    //更改卡类备注
    const changeUpdateRemark = (itemId, value) => {
        common.ajax('post', '/app.php/member-api/card/update-remark', {
            member_id: memberInfo.id,
            card_id: itemId,
            remark: value,
        })
    }

    // 获取提示语
    const getConfirmMsg = () => {
        switch (showConfirm) {
            case 10:
                return '确认启用吗'
            case 20:
                return '确认禁用吗'
            case 30:
                return '确认合并吗'
            default:
                return ''
        }
    }

    // 启用
    const handleNormal = () => {
        common.loadingStart()
        common.ajax('post', '/app.php/member-api/card/normal', {
            member_id: memberInfo.id,
            card_id: activeCard.id,
        }).then((res) => {
            pageFetchData()
            common.toast(res.message)
            setTmpActiveCard({})
        }).finally(common.loadingStop)
    }

    // 禁用
    const handleInvalid = () => {
        common.loadingStart()
        common.ajax('post', '/app.php/member-api/card/invalid', {
            member_id: memberInfo.id,
            card_id: activeCard.id,
        }).then((res) => {
            pageFetchData()
            common.toast(res)
            setTmpActiveCard({})
        }).finally(common.loadingStop)
    }

    // 执行多卡合并
    const handleMerge = () => {
        let arr = []
        mergeCardList.forEach((item) => {
            if (item) {
                arr.push(item)
            }
        })
        if (arr.length < 1) {
            common.toast('至少选择一个合并卡')
            return
        }

        let data = {
            member_id: memberInfo.id,
            card_id: whichMerge.current,
            card_ids_list: JSON.stringify(arr),
        }
        common.loadingStart()
        common.ajax('post', '/app.php/member-api/card/card-merge', data).then((res) => {
            memberUtils.fetchMemberInfo(memberInfo.id)

            common.toast(res)
            setMerge(!(merge))
            whichMerge.current = 0
            setMergeCardList([])
            pageFetchData()
        }).finally(common.loadingStop)
    }

    return (
        <div className={style.CardItem}>
            {
                showConfirm && <Modal title=""
                                      closable={false}
                                      footer={false}>
                    <ConfirmAlert toastTitle={getConfirmMsg()}
                                  onCancel={() => {
                                      setShowConfirm('')
                                  }}
                                  onOk={() => {
                                      switch (showConfirm) {
                                          case 10: // 启用
                                              handleNormal()
                                              break
                                          case 20: // 禁用
                                              handleInvalid()
                                              break
                                          case 30: // 合并
                                              handleMerge()
                                              break
                                      }

                                      setShowConfirm('')
                                  }}
                    />
                </Modal>
            }

            {
                cardList.length === 0 && (
                    loading ?
                        <div className={style.loadingFont}>加载中...</div> :
                        <div className={style.loadingFont}>暂无数据</div>
                )
            }

            <div className={style.cardItemContent}>
                {
                    cardList.map((item, key) =>
                        <div key={key}
                             className={[style.itemDiv, ((whichMerge.current === item.id || mergeCardList[key] === item.id) && merge) && style.actMerge].join(' ')}
                             style={ticketBgStyle}>

                            <div className={style.bgTopBox}>
                                <div className={[style.ticketNameBox, type !== 10 && style.bottomBorder].join(' ')}>
                                    {
                                        merge && item.can_merge === 10 && <img
                                            src={(whichMerge.current === item.id || mergeCardList[key] === item.id) ? checkboxActImg : checkboxImg}
                                            className={style.ticketCheckImg} alt=""
                                            onClick={() => {
                                                // 主合并，不允许取消选中
                                                if (whichMerge.current === item.id) {
                                                    return
                                                }

                                                let arr = mergeCardList
                                                if (arr[key] !== item.id) {
                                                    arr[key] = item.id
                                                    setMergeCardList(JSON.parse(JSON.stringify(arr)))
                                                } else {
                                                    arr[key] = 0
                                                    setMergeCardList(JSON.parse(JSON.stringify(arr)))
                                                }
                                            }}/>
                                    }

                                    <div className={style.ticketName}>{item.name} {item.is_normal === 20 &&
                                        <span className={style.freeze}>冻结</span>}
                                    </div>
                                    {
                                        item.card_category_type == 5 &&
                                        <div className={style.moneyFont}><span>￥</span>{item.money}</div>
                                    }

                                    {
                                        item.card_category_type == 3 &&
                                        <div className={style.count}><span>剩</span>{item.consume_times}次</div>
                                    }
                                </div>

                                {
                                    item.card_category_type == 2 && <div className={style.money}>￥{item.money}</div>
                                }

                                <div className={style.row}>
                                    <div className={style.number}>No.{item.number}</div>
                                    <div className={style.shop}>{item.user_name}</div>
                                </div>

                                {
                                    (item.card_category_type == 2 || item.card_category_type == 5) &&
                                    <div className={style.row}>
                                        <div className={style.number}>折扣率：{item.discount}%</div>
                                    </div>
                                }

                                {/*{*/}
                                {/*    (item.card_category_type == 2 || item.card_category_type == 3) &&*/}
                                {/*    <div className={'row jsfs'}>*/}

                                {/*        <div*/}
                                {/*            className={'category'}>限品类：{(item.relate_project_list_use && item.relate_project_list_use.length > 0) ? item.relate_project_list_use[0].name : '不限'}</div>*/}

                                {/*        <img className={'bottomImg'} src={bottomImg} alt=""/>*/}

                                {/*        {*/}
                                {/*            (item.relate_project_list_use && item.relate_project_list_use.length > 0) &&*/}
                                {/*            <div className={'selectBox'}>*/}

                                {/*                {*/}
                                {/*                    item.relate_project_list_use.map((item2, index) =>*/}
                                {/*                        <div key={index} className={'selectItem'}>*/}
                                {/*                            <div className={'text'}>*/}
                                {/*                                <div>{item2.name}</div>*/}
                                {/*                            </div>*/}
                                {/*                        </div>*/}
                                {/*                    )*/}
                                {/*                }*/}

                                {/*                /!*<div className={'selectItem'}>*!/*/}
                                {/*                /!*    <div className={'text'}>*!/*/}
                                {/*                /!*        <div>三星团购全身精油开背三星团购全身精油开背</div>*!/*/}
                                {/*                /!*        <img src={selectedImg} alt=""/>*!/*/}
                                {/*                /!*    </div>*!/*/}
                                {/*                /!*</div>*!/*/}
                                {/*            </div>*/}
                                {/*        }*/}

                                {/*    </div>*/}
                                {/*}*/}

                                <div className={style.row}>
                                    <div className={style.number}>使用期限：{item.valid_str}</div>
                                </div>

                            </div>

                            <div className={style.bgBottomBox}>

                                <div className={style.remark}>
                                <textarea type="text" className={style.remarkInput} placeholder={'请输入备注信息'}
                                          value={remarkList[key] || ''}
                                          onChange={(e) => {
                                              let value = e.target.value
                                              remarkList[key] = value
                                              clearTimeout(remarkTimes[key])
                                              remarkTimes[key] = setTimeout(() => {
                                                  changeUpdateRemark(item.id, value)
                                              }, 1000)
                                              setRemarkTimes(JSON.parse(JSON.stringify(remarkTimes)))
                                          }}/>
                                </div>

                                <div className={style.btnBox}>
                                    {
                                        item.can_recharge === 10 && <div className={style.btnItem} onClick={() => {
                                            if (item.can_recharge !== 10) {
                                                return;
                                            }
                                            dispatch(setActiveCard(item))
                                            setAction('cardRecharge')
                                        }}>充值</div>
                                    }
                                    {
                                        item.is_normal === 10 && <div className={style.btnItem} onClick={() => {
                                            setShowConfirm(20)
                                            setTmpActiveCard(item)
                                        }}>禁用</div>
                                    }

                                    {
                                        item.is_normal === 20 && <div className={style.btnItem} onClick={() => {
                                            setShowConfirm(10)
                                            setTmpActiveCard(item)
                                        }}>启用</div>
                                    }

                                    <div className={style.btnItem} onClick={() => {
                                        dispatch(setActiveCard(item))
                                        setAction('replaceCard')
                                    }}>补卡换卡
                                    </div>
                                    {
                                        item.can_refund === 10 && <div className={style.btnItem} onClick={() => {
                                            dispatch(setActiveCard(item))
                                            setAction('cardBack')
                                        }}>退卡销户</div>
                                    }

                                    <div className={style.btnItem} onClick={() => {
                                        dispatch(setActiveCard(item))
                                        setAction('putOffCard')
                                    }}>延期
                                    </div>
                                    <div className={[style.btnItem, item.can_merge === 10 && style.noBorder].join(' ')}
                                         onClick={() => {
                                             dispatch(setActiveCard(item))
                                             setAction('transferCard')
                                         }}>转赠
                                    </div>

                                    {
                                        (item.can_merge === 10) && <div className={style.btnItem + ' ' + style.noBorder}
                                                                        onClick={() => {
                                                                            setMerge(!(merge))
                                                                            whichMerge.current = item.id
                                                                            setMergeCardList([])
                                                                            setTmpActiveCard(item)
                                                                        }}>多卡合并</div>
                                    }

                                </div>
                            </div>

                        </div>
                    )
                }
            </div>

            {
                merge && <div className={style.cardItemBottom}>
                    <div className={style.buttonBox}>
                        <Button type={'cancel'} shape={'default'}
                                onClick={() => {
                                    setMerge(false)
                                    whichMerge.current = 0
                                    setMergeCardList([])
                                }}>取消</Button>
                        <Button style={{marginLeft: '10px'}}
                                type={'confirm'}
                                shape={'default'}
                                onClick={() => {
                                    setShowConfirm(30)
                                }}>确定</Button>
                    </div>
                </div>
            }
        </div>

    );
}

export default CardItem;