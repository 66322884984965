import {createSlice} from '@reduxjs/toolkit'
import business from "../utils/business";
import biz from "../utils/biz";

const NAMESPACE = "doorClock"

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    doorClockType: '',
    doorClockHandNumberList: [],
  },
  reducers: {
    setDoorClockType(state, action) {
      state.doorClockType = action.payload
    },
    setDoorClockHandNumberList(state, action) {
      state.doorClockHandNumberList = action.payload
    },
  },
  extraReducers: {},
});

export const {
  setDoorClockType,
  setDoorClockHandNumberList
} = slice.actions

export const {reducer} = slice;
