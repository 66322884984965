import React, {useEffect, useState} from 'react';
import style from './index.module.less'
import Avatar from '../../images/avatar.png'
import {useNavigate} from "react-router-dom";
import common from "../../utils/common";
import Modal from "../../components/public/modal";
import EditSite from '../../components/editSite'
import Close from "../../images/close.png";
import Button from "../../components/public/button";
import biz from "../../utils/biz";
import {useDispatch} from "react-redux";

function Site(props) {

    const [employeeInfo, setEmployeeInfo] = useState({})                                // 技师数据
    const [userInfo, setUserInfo] = useState({})                                        // 门店数据
    const [oemInfo, setOemInfo] = useState({})                                          // oem数据
    const [webList, setWebList] = useState([])                                          // 常用网站数据
    const [messageList, setMessageList] = useState([])                                  // 系统消息数据
    const [statisticsInfo, setStatisticsInfo] = useState({                              // 统计数据
        'money': 0,             // 资金流水
        'customer': 0,          // 客流量
        'consume_money': 0,     // 品项销售
        'recharge_money': 0,    // 预收金额
    })
    const [storeSupport, setStoreSupport] = useState({})                                 // 管理平台数据
    const [mobileSupport, setMobileSupport] = useState({})                               // 移动营销数据
    const [checkManage, setCheckManage] = useState(false)                                // 是否超管 true:是 false:否
    const [checkFront, setCheckFront] = useState(false)                                  // 检测该门店的超管是否有进入门店的权限 true:是 false:否
    const [showMember, setShowMember] = useState(false)                                  // 是否展示客户联系 true:是 false:否
    const [showMessage, setShowMessage] = useState(false)                                // 是否展示短信营销 true:是 false:否
    const [messageNotice, setMessageNotice] = useState('')                               // 短信提示信息

    const [webSiteUpdateShow, setWebSiteUpdateShow] = useState(false)                    // 是否打开编辑常用网站弹窗

    const [messageActive, setMessageActive] = useState('system')                               // 系统消息数据

    const [showTips, setShowTips] = useState(false)                               // 确认提示

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if(common.navigate == null) {
            common.navigate = navigate
        }

        common.dispatch = dispatch
    }, [])

    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/account/home", {}, {"appointNeedRetry": "no"}).then(res => {
            setEmployeeInfo(res.employeeInfo)            // 技师数据
            setUserInfo(res.userInfo)                    // 门店数据
            biz.saveUserInfo(res.userInfo)                    // 门店数据
            setOemInfo(res.oemInfo)                      // oem数据

            setStatisticsInfo({
                'money': res.money,                      // 资金流水
                'customer': res.customer,                // 客流量
                'consume_money': res.consume_money,      // 品项销售
                'recharge_money': res.recharge_money,    // 预收金额
            })

            setWebList(res.websiteList)                  // 常用网站数据
            setStoreSupport(res.store_support)           // 管理平台数据
            setMobileSupport(res.mobile_support)         // 移动营销数据

            setCheckManage(res.check_manage)             // 是否超管 true:是 false:否
            setCheckFront(res.check_front)               // 检测该门店的超管是否有进入门店的权限 true:是 false:否
            setShowMember(res.show_member)               // 是否展示客户联系 true:是 false:否
            setShowMessage(res.show_message)             // 是否展示短信营销 true:是 false:否

            setMessageList(res.system_message_list)      // 系统消息数据

            setMessageNotice(res.message_notice)         // 短信提示信息
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    // 是否展示收银
    let showCashier = () => {
        if (checkManage) {  // 是否管理员
            // 超管允许进收银
            if (checkFront) {
                return true
            }

            return false
        }

        // 非管理员，直接展示收银
        return true
    }

    // 获取常用网站数据
    let websiteOk = () => {
      common.loadingStart()
      common.ajax('get', common.apiUrl('/app.php/service-pad-api/website'), {}, {"appointNeedRetry": "no"}).then((res) => {
          // 常用网站数据
          setWebList(res)
      }).finally(() => common.loadingStop())
    }

    return (
        <div className={style.Site}>
            <div className={style.nav}>
                <div className={style.logo}>
                    <img src={require('@/images/home/logo.png')} alt=""/>
                </div>
                <div className={style.userInfo}>
                    <div className={style.avatar}>
                        <img src={userInfo.cover_url ? userInfo.cover_url : Avatar} alt=""/>
                    </div>
                    <div className={style.name}>
                        {employeeInfo.nickname ? employeeInfo.nickname : ''}
                    </div>
                    <div className={style.name}>
                        <a style={{"color": "#1890ff", "marginLeft": "10px", cursor: "pointer"}} onClick={() => {
                            common.confirm("确认退出登录吗？", () => {
                                common.logout()
                            })

                            return false;
                        }}>退出</a>
                    </div>
                </div>
            </div>
            <div className={style.content}>
                <div className={style.header}>
                    <div className={style.user}>
                        <div className={style.userInfo}>
                            <div className={style.avatar}>
                                <img src={userInfo.cover_url ? userInfo.cover_url : Avatar} alt=""/>
                            </div>
                            <div className={style.info}>
                                <div className={style.name}>
                                    <span className={style.brand}>{userInfo.brand ? userInfo.brand : '-'}</span>
                                    <span className={style.tag}>{userInfo.name ? userInfo.name : '-'}</span>
                                </div>
                                <div className={style.addressInfo}>
                                    <div>
                                        <img src={require('@/images/home/telephone.png')} alt=""/>
                                        <span>{userInfo.phone ? userInfo.phone : '-'}</span>
                                    </div>
                                    <div>
                                        <img src={require('@/images/home/address.png')} alt=""/>
                                        <span>{userInfo.address ? userInfo.address : '-'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.topAction}>
                            <div className={style.item}>
                                <img src={require('@/images/home/back1.png')} alt="" className={style.backImage}/>
                                <span className={style.price}><span>¥</span>{statisticsInfo.money}</span>
                                <span className={style.title}>资金流水</span>
                            </div>
                            <div className={style.item}>
                                <img src={require('@/images/home/back2.png')} alt="" className={style.backImage}/>
                                <span className={style.price}><span>¥</span>{statisticsInfo.consume_money}</span>
                                <span className={style.title}>品项销售</span>
                            </div>
                            <div className={style.item}>
                                <img src={require('@/images/home/back3.png')} alt="" className={style.backImage}/>
                                <span className={style.price}><span>¥</span>{statisticsInfo.recharge_money}</span>
                                <span className={style.title}>预收金额</span>
                            </div>
                            <div className={style.item}>
                                <img src={require('@/images/home/back4.png')} alt="" className={style.backImage}/>
                                <span className={style.price}>{statisticsInfo.customer}</span>
                                <span className={style.title}>客流量</span>
                            </div>
                        </div>
                    </div>
                    <div className={style.contact}>
                        <div className={style.headerTitle}>
                            <div className={style.title}>
                                <img src={require('@/images/home/mobile.png')} alt=""/>
                                {oemInfo.phone ? oemInfo.phone : '-'}
                            </div>
                            <div className={style.bottomAction}>
                                <img src={require('@/images/home/wechat.png')} alt=""/>
                                客服
                            </div>
                        </div>
                        <div className={style.contactList}>
                            <div className={style.item} onClick={() => {
                                common.redirectToAccount()
                            }}>
                                <div className={style.imageBox}>
                                    <img src={require('@/images/home/manage.png')} alt=""/>
                                </div>
                                <span className={style.title}>管理平台</span>
                                <span className={style.time}>{storeSupport.expire ? storeSupport.expire : ''}</span>
                                <span className={style.remark}>{storeSupport.notice ? storeSupport.notice : ''}</span>
                            </div>
                            <div className={style.item} onClick={() => {
                                common.redirectToAccount()
                            }}>
                                <div className={style.imageBox}>
                                    <img src={require('@/images/home/mobile-marketing.png')} alt=""/>
                                </div>
                                <span className={style.title}>移动营销</span>
                                <span className={style.time}>{mobileSupport.expire ? mobileSupport.expire : ''}</span>
                                <span className={style.remark}>{mobileSupport.notice ? mobileSupport.notice : ''}</span>
                            </div>
                            <div className={style.item} onClick={() => {
                                common.redirectToAccount()
                            }}>
                                <div className={style.imageBox}>
                                    <img src={require('@/images/home/note.png')} alt=""/>
                                </div>
                                <span className={style.title}>短信</span>
                                <span className={style.time}>{userInfo.sms_count ? userInfo.sms_count : ''}条</span>
                                <span className={style.remark}>{messageNotice}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.main}>
                    <div className={style.action}>
                        {
                            checkManage ? <div className={style.item} onClick={() => {
                                common.redirectToAccount()
                            }}>
                                <img src={require('@/images/home/tag7.png')} alt=""/>
                                <span>账户中心</span>
                            </div> : null
                        }

                        {
                            showCashier() ? <div className={style.item} onClick={() => {
                                navigate('/home')
                            }}>
                                <img src={require('@/images/home/tag1.png')} alt=""/>
                                <span>前台收银</span>
                            </div> : null
                        }

                        {
                            showMember ? <div className={style.item} onClick={() => {
                                common.redirectToUrl("app.php/member/member")
                            }}>
                                <img src={require('@/images/home/tag2.png')} alt=""/>
                                <span>客户关系</span>
                            </div> : null
                        }

                        <div className={style.item} onClick={() => {
                            common.redirectToUrl("wechat/official/view")
                        }}>
                            <img src={require('@/images/home/tag3.png')} alt=""/>
                            <span>微信营销</span>
                        </div>

                        {
                            showMessage ? <div className={style.item} onClick={() => {
                                common.redirectToUrl("app.php/member/message/index")
                                // setShowTips(true)
                            }}>
                                <img src={require('@/images/home/tag4.png')} alt=""/>
                                <span>短信营销</span>
                            </div> : null
                        }

                        <div className={style.item} onClick={() => {
                            common.redirectToUrl('app.php/report/home')
                        }}>
                            <img src={require('@/images/home/tag5.png')} alt=""/>
                            <span>运营分析</span>
                        </div>
                        {/*<div className={style.item}>*/}
                        {/*    <img src={require('@/images/home/tag6.png')} alt=""/>*/}
                        {/*    <span>资产管理</span>*/}
                        {/*</div>*/}
                        {/*<div className={style.item}>*/}
                        {/*    <img src={require('@/images/home/tag7.png')} alt=""/>*/}
                        {/*    <span>报表中心</span>*/}
                        {/*</div>*/}
                        <div className={style.item} onClick={() => {
                            common.redirectToV4ManageUrl('/project')
                        }}>
                            <img src={require('@/images/home/tag8.png')} alt=""/>
                            <span>品项管理</span>
                        </div>
                        {/*<div className={style.item}>*/}
                        {/*    <img src={require('@/images/home/tag9.png')} alt=""/>*/}
                        {/*    <span>基础价格</span>*/}
                        {/*</div>*/}
                        {/*<div className={style.item}>*/}
                        {/*    <img src={require('@/images/home/tag10.png')} alt=""/>*/}
                        {/*    <span>提成方案</span>*/}
                        {/*</div>*/}
                        {/*<div className={style.item}>*/}
                        {/*    <img src={require('@/images/home/tag11.png')} alt=""/>*/}
                        {/*    <span>卡类管理</span>*/}
                        {/*</div>*/}
                        {/*<div className={style.item}>*/}
                        {/*    <img src={require('@/images/home/tag12.png')} alt=""/>*/}
                        {/*    <span>档案管理</span>*/}
                        {/*</div>*/}
                    </div>
                    <div className={style.rightContent}>
                        <div className={style.top}>
                            <div className={style.headerTitle}>
                                <div className={style.title}>
                                    <img src={require('@/images/home/network.png')} alt=""/>
                                    常用网站
                                </div>
                                <div className={style.bottomAction} style={{cursor:"pointer"}} onClick={() => setWebSiteUpdateShow(true)}>
                                    <img src={require('@/images/home/edit.png')} alt=""/>
                                    编辑
                                </div>
                            </div>
                            <div className={style.appList}>
                                {
                                    webList.map((item, key) =>
                                        <div key={key} className={style.item} onClick={() => {
                                            window.open(item.url, "_blank")
                                        }}>
                                            <div className={style.colorItem} style={{background: item.color ? item.color : '#DF342E'}}></div>
                                            {/*<img src={item.cover_url ? item.cover_url : ''} alt=""/>*/}
                                            <span className={style.title}>{item.name}</span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className={style.bottom}>
                            <div className={style.tabBar}>
                                <div className={style.item}>
                                    <span className={messageActive === 'system' ? style.act : ''} onClick={() => setMessageActive('system')}>系统消息</span>
                                </div>
                                <div className={style.item}>
                                    <span className={messageActive === 'help' ? style.act : ''} onClick={() => setMessageActive('help')}>帮助</span>
                                </div>
                            </div>

                            <ul className={style.infoList}>
                                {
                                    messageActive === 'system' && messageList.map((item, key) =>
                                        <li key={key}>
                                            <div className={style.text}>
                                                <div className={style.name}>
                                                    {item.type_name}
                                                    {
                                                        key < 3 && <span className={style.tag}/>
                                                    }
                                                </div>
                                                <div className={style.remark}>
                                                    {item.message_for_type}
                                                </div>
                                            </div>
                                            <div className={style.time}>
                                                {item.date}
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <div className={style.bottom}>
                版权©{common.oemInfo.copyrightBeginTime} - {common.oemInfo.copyrightEndTime} {oemInfo.company ? oemInfo.company : ''}，所有版权保留
                版本2.1
            </div>

            {/*  编辑常用网站弹窗  */}
            {
                webSiteUpdateShow &&
                <Modal title="编辑常用网站"
                       onCancel={() => setWebSiteUpdateShow(false)}>
                    <EditSite
                        webSiteList={webList}
                        onOk={() => {
                            websiteOk()
                        }}/>
                </Modal>
            }


            {
                showTips && <Modal title="" closable={false}>
                    <div className={style.tipsBox}>
                        <div className={style.closeImage} onClick={() => {
                            setShowTips(false)
                        }}>
                            <img className={style.closeImg} src={Close} alt=""/>
                        </div>

                        <div className={style.contentBoxDiv}>
                            <img src={require('@/images/waring.png')} className={style.waringImg} alt=""/>
                            <div className={style.title}>温馨提示</div>
                            <div className={style.tipsDiv}>注意!根据要求，三季度将开展垃圾信息专项整治工作,发送成功率极低,建议近期不要发送营销类信息.</div>
                        </div>

                        <div className={style.footer}>
                            <Button type={'cancel'} shape={'default'}
                                    onClick={() => {
                                        setShowTips(false)
                                    }}>否</Button>
                            <Button style={{marginLeft: '10px'}}
                                    type={'confirm'}
                                    shape={'default'}
                                    onClick={() => {
                                        common.redirectToUrl("app.php/member/message/index")
                                    }}>我已知晓</Button>
                        </div>
                    </div>
                </Modal>
            }

        </div>
    );
}

export default Site;