import React, {useEffect, useState} from 'react';
import common from "../../../utils/common";
import {setMemberInfo, setCardSum, setMemberInitData, setInitData} from "../../../reducers/member";
import {useDispatch} from "react-redux";
import MemberFind from '../findMember'
import MemberCreate from '../create'
import RecordHome from '../detail'
import IssuanceCardCoupon from '../issuanceCardCoupon'
import ConsumeRecord from '../consumeRecord'
import SendCoupon from '../sendCoupon'
import Ticket from '../card'
import StoreValueCard from '../card'
import OrderCard from '../card'
import LimitProjectCard from '../card'
import UpdatePassword from '../updatePassword'
import TransferCoupon from '../transferCoupon'
import PutOffTicket from '../putOffTicket'
import PutOffCard from '../putOffCard'
import TransferCard from '../transferCard'
import CardBack from '../cardBack'
import ReplaceCard from '../replaceCard'
import CardRecharge from '../cardRecharge'
import OpenCard from '../openCard'
import style from './style.module.less'

function MemberHome(props) {
    const {onHide, memberId = 0, isOpenCard = false} = props

    const dispatch = useDispatch();

    const [action, setAction] = useState('find')
    const [fastCreateCard, setFastCreateCard] = useState(10) // 是否快速开卡（10:否 20:是）

    const [createMobile, setCreateMobile] = useState('')     // 新开会员所使用的手机号

    useEffect(() => {

        return () => {
            // 页面结束
            dispatch(setMemberInfo({}))     // 放入会员信息
            dispatch(setCardSum({}))        // 放入会员卡及卡券总数
            dispatch(setMemberInitData({})) // 放入会员初始化数据
            dispatch(setInitData({})) // 放入初始化数据
            setAction('')
        }
    }, [])

    useEffect(() => {
        if (memberId > 0) {
            goRecordHome(memberId)
        }
    }, [memberId])

    // isOpenCard 为 true，打开会员开卡页面
    useEffect(() => {
        if (!isOpenCard) {
            return
        }

        goOpenCard()
    }, [isOpenCard])

    // 去消费记录未展开页面，需要获取会员数据以及会员卡数量数据
    const goRecordHome = (id) => {
        common.loadingStart()
        Promise.all([
            fetchMemberInfo(id),               // 获取会员信息
            fetchMemberCardSum(id),            // 获取会员卡及卡券总数
            fetchInitMember(),                 // 获取会员初始化数据
            fetchInit()                        // 获取初始化配置
        ]).then((arr) => {
            dispatch(setMemberInfo(arr[0]))     // 放入会员信息
            dispatch(setCardSum(arr[1]))        // 放入会员卡及卡券总数
            dispatch(setMemberInitData(arr[2])) // 放入会员初始化数据
            dispatch(setInitData(arr[3])) // 放入初始化数据
            setAction('recordHome')
        }).finally(common.loadingStop)
    }

    // 获取会员信息
    const fetchMemberInfo = (id) => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/member/info', {id: id}).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err.error)
            })
        })
    }

    // 获取会员卡及卡券总数
    const fetchMemberCardSum = (id) => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/member/sum', {id: id}).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err.error)
            })
        })
    }

    // 获取会员初始化数据
    const fetchInitMember = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/init/member').then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err.error)
            })
        })
    }

    // 获取初始化数据
    const fetchInit = () => {
        return new Promise((resolve, reject) => {
            common.ajax('get', '/app.php/member-api/init').then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err.error)
            })
        })
    }

    // 去会员开卡页面
    const goOpenCard = () => {
        setFastCreateCard(10) // 默认不是快速开卡
        setAction('openCard')
    }

    // 展示对应页面
    const showPage = () => {
        switch (action) {
            case 'find':    // 查找会员
                return <MemberFind goRecordHome={goRecordHome} onClose={onHide}></MemberFind>
            case 'recordHome':    // 消费记录未展开
                return <RecordHome setAction={setAction} onClose={onHide}></RecordHome>
            case 'create':  // 新开会员
                return <MemberCreate goOpenCard={goOpenCard} goRecordHome={goRecordHome} title={'新开会员'} type={'create'} mobile={createMobile} onClose={onHide}></MemberCreate>
            case 'update':  // 编辑会员
                return <MemberCreate title={'编辑会员'} type={'update'} goRecordHome={goRecordHome}
                                     onClose={onHide}></MemberCreate>
            case 'updatePassword':  // 改密
                return <UpdatePassword setAction={setAction} goRecordHome={goRecordHome}
                                       onClose={onHide}></UpdatePassword>
            case 'apply':  // 办卡
                return <IssuanceCardCoupon onClose={onHide} fastCreateCard={fastCreateCard} setAction={setAction}
                                           goOpenCard={goOpenCard} goRecordHome={goRecordHome}></IssuanceCardCoupon>
            case 'consumeRecord':  // 消费记录
                return <ConsumeRecord onClose={onHide} setAction={setAction}></ConsumeRecord>
            case 'sendTicket':  // 发放电子券
                return <SendCoupon onClose={onHide} setAction={setAction} goRecordHome={goRecordHome}></SendCoupon>
            case 'ticket':  // 卡券列表
                return <Ticket onClose={onHide} nav={'ticket'} setAction={setAction}></Ticket>
            case 'storeValue':  // 储值卡列表
                return <StoreValueCard onClose={onHide} nav={'storeValue'} setAction={setAction}
                                       goRecordHome={goRecordHome}></StoreValueCard>
            case 'order':  // 计次卡列表
                return <OrderCard onClose={onHide} nav={'order'} setAction={setAction}
                                  goRecordHome={goRecordHome}></OrderCard>
            case 'limitProject':  // 专享卡列表
                return <LimitProjectCard onClose={onHide} nav={'limitProject'} setAction={setAction}
                                         goRecordHome={goRecordHome}></LimitProjectCard>
            case 'transferCoupon':  // 转赠卡券
                return <TransferCoupon onClose={onHide} setAction={setAction}></TransferCoupon>
            case 'putOffTicket':  // 卡券延期
                return <PutOffTicket onClose={onHide} setAction={setAction}></PutOffTicket>
            case 'putOffCard':  // 会员卡延期
                return <PutOffCard onClose={onHide} setAction={setAction}></PutOffCard>
            case 'transferCard':  // 会员卡转赠
                return <TransferCard onClose={onHide} setAction={setAction}></TransferCard>
            case 'cardBack':  // 会员卡退卡
                return <CardBack onClose={onHide} setAction={setAction}></CardBack>
            case 'replaceCard':  // 会员补卡换卡
                return <ReplaceCard onClose={onHide} setAction={setAction}></ReplaceCard>
            case 'cardRecharge':  // 会员卡充值
                return <CardRecharge onClose={onHide} setAction={setAction}></CardRecharge>
            case 'openCard':  // 会员开卡
                return <OpenCard setCreateMobile={setCreateMobile} onClose={onHide} setAction={setAction} setFastCreateCard={setFastCreateCard} goRecordHome={goRecordHome}></OpenCard>
            default:
                return ""
        }
    }

    return (
        <div className={style.MemberHome}>
            {showPage()}
        </div>
    );
}

export default MemberHome;