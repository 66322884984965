import React, {useRef, useState} from 'react';
import './style.less'
import common from "../../utils/common";

//编辑常用网站
function EditSite(props) {
    const {onOk, webSiteList} = props

    const imgRef = useRef(null)

    const [activeItem, setActiveItem] = useState({})        // 选中的网站
    const [imgUrl, setImgUrl] = useState("")                // 图片url
    const [data, setData] = useState({                      // 表单数据
        'name': '',         // 网站名
        'url': '',          // 网站地址
        'fileKey': '',      // 网站图fileKey
        'color': '#DF342E',         // 颜色
    })
    const [handleType, setHandleType] = useState(null)      // 操作类型 10:新增 20:编辑

    const colorList = [
        {color: '#1FAF8A'},
        {color: '#2CC861'},
        {color: '#2DBAB7'},
        {color: '#2B82D6'},
        {color: '#EDBA2A'},
        {color: '#DF342E'},
        {color: '#DE691E'},
    ]

    // 保存
    let save = () => {
        if (data.name.length < 1) {
            common.toast('网站名不能为空')
            return
        }

        if (data.url.length < 1) {
            common.toast('网站地址不能为空')
            return
        }

        if (!handleType) {
            common.toast('操作类型异常')
            return
        }

        // 编辑
        if (handleType === 20) {
            common.loadingStart()
            common.axios("post", common.apiUrl("/app.php/service-pad-api/website/update"), {
                'name': data.name,
                'url': data.url,
                'color': data.color,
                'id': activeItem.id,
            }).then((res) => {
                common.toast(res)
                onOk()
            }).catch((errorObj) => {
                // 失败
                common.toast(errorObj.error);
            }).finally(() => common.loadingStop())

            return;
        }

        // 新增
        common.toast('新增')
        common.loadingStart()
        common.axios("post", common.apiUrl("/app.php/service-pad-api/website/create"), {
            'name': data.name,
            'url': data.url,
            'color': data.color,
        }).then((res) => {
            common.toast('新增成功')
            onOk()
        }).catch((errorObj) => {
            // 失败
            common.toast(errorObj.error);
        }).finally(() => common.loadingStop())
    }

    // 删除网站
    let deleteWeb = () => {
        if (activeItem.id === undefined) {
            common.toast('请选需要删除的网站')
            return
        }

        common.confirm("确定删除？", () => {
            common.loadingStart()
            common.axios("post", common.apiUrl("/app.php/service-pad-api/website/delete"), {id: activeItem.id}).then((res) => {
                common.toast(res)
                setActiveItem({})
                setData({
                    'name': '',
                    'url': '',
                    'color': '#DF342E',
                })
                onOk()
            }).catch((errorObj) => {
                // 失败
                common.toast(errorObj.error);
            }).finally(() => common.loadingStop())
        })
    }

    return (
        <div className="EditSite">
            {/*<div className="title-div">*/}
            {/*    <div className="title-font-div">编辑常用网站</div>*/}
            {/*    <img src={require('@/images/close.png')} className="title-close-img" alt=""/>*/}
            {/*</div>*/}

            <div className="content-box-div">
                <div className="menu-div">
                    <div className={'menu-item-content-div'}>
                        {
                            webSiteList.map((item, key) =>
                                <div key={key}
                                     className={activeItem.id && item.id === activeItem.id ? 'menu-item-div menu-active' : 'menu-item-div'}
                                     onClick={() => {
                                         setActiveItem(item)
                                         setData({
                                             'name': item.name,
                                             'url': item.url,
                                             'color': item.color ? item.color : '#DF342E',
                                             'fileKey': "",
                                         })
                                         setImgUrl(item.cover_url)
                                         setHandleType(20)
                                     }}>
                                    <div className="menu-img-div">
                                        <div className={'color-item'} style={{background: item.color ? item.color : '#DF342E'}}></div>
                                    </div>
                                    <div className="menu-content-div">
                                        <div className="menu-title-div">{item.name}</div>
                                        <div className="menu-url-div">{item.url}</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className={'create-btn-box'}>
                        <div className={'create-btn'} onClick={() => {
                            setActiveItem({})
                            setData({
                                'name': '',
                                'url': '',
                                'color': '#DF342E',
                            })
                            setImgUrl("")
                            setHandleType(10)
                        }}>新增
                        </div>
                    </div>

                </div>

                {
                    handleType && <div className="right-content-div">
                        <div className="form-content-box-div">

                            <div className="flex-row-div">
                                <div className="left-div">网站名</div>
                                <div className="right-div">
                                    <input className="right-input" type="text" placeholder="请输入网站名" onChange={(e) => {
                                        setData({...data, 'name': e.target.value})
                                    }} value={data.name}/>
                                </div>
                            </div>

                            <div className="flex-row-div">
                                <div className="left-div">网站地址</div>
                                <div className="right-div">
                                    <input className="right-input" type="text" value={data.url} onChange={(e) => {
                                        setData({...data, 'url': e.target.value})
                                    }} placeholder="请输入网站地址"/>
                                </div>
                            </div>

                            <div className="flex-row-div">
                                <div className="left-div">网站颜色</div>
                                <div className="colorRight">

                                    <div className={'itemList'}>
                                        {
                                            colorList.map((item, index) => {
                                                return (
                                                    <div className={item.color === data.color ? 'item act' : 'item'}
                                                         style={{background: item.color}} key={index} onClick={(e) => {
                                                        setData({...data, 'color': item.color})
                                                    }}>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className={'selectColor'}>
                                        <div className={'act'} style={{background: data.color}}>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="bottom-box-div">
                            <div className="btn-div cancel-btn" onClick={deleteWeb}>删除</div>
                            <div className="btn-div submit-btn" onClick={save}>保存</div>
                        </div>
                    </div>
                }


            </div>
        </div>
    );
}

export default EditSite;