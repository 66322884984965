import {createSlice} from '@reduxjs/toolkit'
import business from "../utils/business";
import biz from "../utils/biz";

const NAMESPACE = "selectContent"

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    consumeList: [],
    showSelectContentType: 'hand',
    billRemark: '',
  },
  reducers: {
    setConsumeList(state, action) {
      state.consumeList = action.payload
    },
    setShowSelectContentType(state, action) {
      state.showSelectContentType = action.payload
    },
    setBillRemark(state, action) {
      state.billRemark = action.payload
    },
  },
  extraReducers: {},
});

export const {
  setConsumeList,
  setShowSelectContentType,
  setBillRemark
} = slice.actions

export const {reducer} = slice;
