import React from 'react';
import style from './style.module.less'
import homeImg from '../../../../images/member/home.png'
import updateImg from '../../../../images/member/update-pen.png'
import defaultImg from '../../../../images/member/default-avatar.png'
import sexMan from '../../../../images/member/sex-man.png'
import sexWoMan from '../../../../images/member/sex-woman.png'
import rightImg from '../../../../images/member/right-img.png'
import {useSelector} from "react-redux";
import common from "../../../../utils/common";

function MemberDetail(props) {
    const {memberInfo, cardSum, memberInitData, initData} = useSelector(state => state.member)

    const {nav, setAction} = props

    const getSexImg = () => {
        switch (memberInfo.sex) {
            case 1:
                return <img src={sexMan} className={style.manImg} alt=""/>
            case 2:
                return <img src={sexWoMan} className={style.manImg} alt=""/>
            default:
                return ''
        }
    }

    //处理号码格式
    const handPhoneFormat = (phone) => {
        phone = phone + ''
        if (phone.length === 11) {
            return phone.replace(/(\d{3})(\d{4})/, "$1 $2 ");
        }

        if (phone.length === 8) {
            return phone.replace(/(\d{4})(\d{4})/, "$1 $2 ");
        }
        return phone;
    }

    // 复制手机号
    const copyText = (text) => {
        let oInput = document.createElement('input');
        oInput.value = text;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        oInput.className = 'oInput';
        oInput.style.display = 'none';
        common.toast('手机号已复制成功')
    }

    return (
        <div className={style.MemberDetail}>
            <div className={style.topBox}>
                <div className={style.topHeader}>
                    <div className={style.titleDiv}>
                        <div className={style.titleFontLeft}>
                            <img src={homeImg} className={style.home} alt=""/>
                            {memberInfo.user_name}
                        </div>
                        <div className={style.updateBox}>
                            {
                                memberInitData.can_update_pwd == 10 && <span onClick={() => {
                                    setAction('updatePassword')
                                }}>
                                <img src={updateImg} className={style.titleImgRight} alt=""/>改密
                            </span>
                            }

                            <span onClick={() => {
                                setAction('update')
                            }}>
                            <img src={updateImg} className={style.titleImgRight} alt=""/>编辑
                        </span>
                        </div>
                    </div>
                    <div className={style.headerImgDiv}>
                        <img src={memberInfo.avatar ? memberInfo.avatar : defaultImg} className={style.avatarImg} alt=""/>
                        <div className={style.sexDiv}>
                            {getSexImg()}
                        </div>
                    </div>

                    <div className={style.headerName}>{memberInfo.name}</div>
                    {/*<div className={'tag-box'}>*/}
                    {/*    <div className={'tag-item'}>会员</div>*/}
                    {/*    <div className={'tag-item'}>其他</div>*/}
                    {/*    <div className={'tag-item'}>常来</div>*/}
                    {/*    <input type="text" className={'tag-input'} placeholder={'输入标签'}/>*/}
                    {/*    <div className={'tag-item add'}>+</div>*/}
                    {/*</div>*/}

                    <div className={style.headerMessage}>
                        <div className={style.headerMessageList}>
                            <div className={style.headerMessageTitle}>
                                电话
                            </div>
                            <div className={style.headerMessageValue}>
                                <span>{handPhoneFormat(memberInfo.mobile)}</span>
                                <span className={style.copyDiv} onClick={() => {
                                    copyText(memberInfo.mobile)
                                }}>复制</span></div>
                        </div>
                        <div className={style.headerMessageList}>
                            <div className={style.headerMessageTitle}>生日
                            </div>
                            <div
                                className={style.headerMessageValue}>{memberInfo.birthday ? memberInfo.birthday : '-'}</div>
                        </div>
                    </div>
                </div>

                <div className={style.moneyIntegral}>
                    <div className={style.moneyBox}>
                        <div className={style.money}>{memberInfo.money}</div>
                        <div className={style.font}>账户余额</div>
                    </div>
                    <div className={style.moneyBox}>
                        <div className={style.money}>{memberInfo.point}</div>
                        <div className={style.font}>账户积分</div>
                    </div>
                </div>
            </div>

            <div className={style.ticketBox}>
                <div className={[style.ticketItem, nav === 'storeValue' && style.active].join(' ')} onClick={() => {
                    setAction('storeValue')
                }}>
                    <span className={style.font}>储值卡</span>
                    <div className={style.num}>{cardSum.store_card_count === 0 ? '' : cardSum.store_card_count} <img
                        className={style.rightImg} src={rightImg} alt=""/></div>
                </div>
                <div className={[style.ticketItem, nav === 'order' && style.active].join(' ')} onClick={() => {
                    setAction('order')
                }}>
                    <span className={style.font}>计次卡</span>
                    <div className={style.num}>{cardSum.times_card_count === 0 ? '' : cardSum.times_card_count} <img
                        className={style.rightImg} src={rightImg} alt=""/></div>
                </div>
                <div className={[style.ticketItem, nav === 'limitProject' && style.active].join(' ')} onClick={() => {
                    setAction('limitProject')
                }}>
                    <span className={style.font}>专享卡</span>
                    <div
                        className={style.num}>{cardSum.limit_project_card_count === 0 ? '' : cardSum.limit_project_card_count}
                        <img className={style.rightImg} src={rightImg} alt=""/></div>
                </div>
                <div className={[style.ticketItem, nav === 'ticket' && style.active].join(' ')}
                     onClick={() => {
                         setAction('ticket')
                     }}>
                    <span className={style.font}>卡券</span>
                    <div className={style.num}>{cardSum.ticket_count === 0 ? '' : cardSum.ticket_count} <img
                        className={style.rightImg} src={rightImg} alt=""/></div>
                </div>
            </div>

            <div className={style.bottomBox}>
                <div className={style.bottomBtn} onClick={() => setAction('apply')}>办卡</div>
                {
                    initData.can_send_ticket.toString() === "10" &&
                    <div className={style.bottomBtn} onClick={() => setAction('sendTicket')}>发放电子券</div>
                }

                <div className={style.bottomBtn} onClick={() => {
                    if (initData.can_card_show_consume.toString() !== "10") {
                        common.toast('出错了请联系技术人员')
                        return
                    }

                    setAction('consumeRecord')
                }}>查消费记录</div>
            </div>
        </div>
    );
}

export default MemberDetail;