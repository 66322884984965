import React from 'react';
import style from'./style.module.less'
import MemberDetail from "../components/memberDetail";
import closeImg from "../../../images/close.png";
import findImg from "../../../images/member/find.png";
import {useSelector} from "react-redux";
import common from "../../../utils/common";

function Detail(props) {
    const {setAction, onClose} = props
    const {initData} = useSelector(state => state.member)

    return (
        <div className={style.Detail}>
            <MemberDetail setAction={setAction}/>
            <div className={style.content}>
                <div className={style.title}>
                    <div className={style.icon}></div>
                    <div className={style.titleContent}></div>
                    <div className={style.icon}>
                        <img src={closeImg} onClick={onClose}/>
                    </div>
                </div>

                <div className={style.contentBox}>
                    <img className={style.findImg} src={findImg} alt=""/>
                    <div className={style.findBtn} onClick={() => {
                        if (initData.can_card_show_consume.toString() !== "10") {
                            common.toast('出错了请联系技术人员')
                            return
                        }

                        setAction('consumeRecord')
                    }}>查看消费记录
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Detail;