import React, {Suspense} from "react";
import {
    unstable_HistoryRouter as HistoryRouter,
    Routes,
    Route,
} from "react-router-dom"
import './App.css';
import NProgress from "./components/public/nprogress";
import Site from "./pages/site";
import Member from "./pages/member";
import {createHashHistory} from 'history';

import { Buffer } from 'buffer';
import process from 'process';

window.Buffer = Buffer;
window.process = process;

const Home = React.lazy(() => import("./pages/home"));
const Login = React.lazy(() => import("./pages/login"));


function NotFound(props) {
    return <>NotFound</>
}


function App(props) {

    const history = createHashHistory()

    return (<>

        <div className={'App'}>
            {/*<Router basename='/v2/'>*/}
            <HistoryRouter history={history}>
                <Suspense fallback={<NProgress></NProgress>}>
                    <Routes>
                        <Route path={'/home'} element={<Home/>}/>
                        <Route path={'/login'} element={<Login/>}/>
                        <Route path={'/member'} element={<Member/>}/>
                        <Route path={'/'} element={<Site/>}/>
                        {/* NotFound */}
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </Suspense>
            </HistoryRouter>
        </div>
    </>);
}


export default App