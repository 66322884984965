import React from 'react';
import style from "./style.module.less";
import Button from "../../../public/button";
import common from "../../../../utils/common";

function EmptyCardCreate(props) {
    const {onClose, goRecordHome, fastCreateCard, goOpenCard} = props

    return (
        <div className={style.EmptyCardCreate}>
            <div className={style.headerTitle}>
                <img onClick={goRecordHome} src={require('@/images/member/left.png')} className={style.leftImage} alt=""/>
                会员办卡
                <div className={style.closeImage}>
                    <img onClick={onClose} src={require('@/images/member/close.png')} className={style.closeImage} alt=""/>
                </div>
            </div>
            <div className={style.content}>
                <div className={style.description}>
                    <img src={require('@/images/member/toast.png')} className={style.toastImage} alt=""/>
                    <div className={style.descriptionText}>
                        请在左侧选择需要办理的卡
                    </div>
                </div>
            </div>
            <div className={style.bottomAction}>
                <Button shape={'default'} type={'cancel'} onClick={() => {
                    if (fastCreateCard === 20) {
                        goOpenCard()
                    } else {
                        goRecordHome()
                    }
                }}>取消</Button>
                <Button shape={'default'} style={{marginLeft: '10px'}} type={'confirm'} onClick={() => {
                    common.toast('请先选择左侧需要办理的卡')
                }}>确认</Button>
            </div>
        </div>
    );
}

export default EmptyCardCreate;