import React, {useState, useEffect} from 'react';
import style from './style.module.less'
import MemberDetail from "../components/memberDetail";
import DetailModal from "../components/detailModal";
import Button from "../../public/button";
import Select from "./components/select";
import OperateQty from "./components/operateQty";
import {useDispatch, useSelector} from "react-redux";
import common from "../../../utils/common";
import {setCardSum} from "../../../reducers/member";

function SendCoupon(props) {
    const {setAction, goRecordHome, onClose} = props

    const dispatch = useDispatch();
    const {memberInfo} = useSelector(state => state.member)

    const [ticketList, setTicketList] = useState([])     // 卡券列表
    const [quantity, setQuantity] = useState([])         // 数量
    const [remark, setRemark] = useState('')             // 备注
    const [ticketData, setTicketData] = useState([])     // 发放卡券列表

    useEffect(() => {
        fetchTicketData()
    }, [])

    // 获取卡券数据
    const fetchTicketData = () => {
        common.loadingStart()
        common.ajax('get', '/app.php/member-api/ticket').then((res) => {
            let arr = [{key: '0', value: '请选择券类型'}]
            for (let i = 0; i < res.length; i++) {
                let item = res[i]
                arr.push({
                    key: item.id,
                    value: item.title,
                })
            }
            setTicketList(arr)
        }).finally(common.loadingStop)
    }

    //派发处理
    const sendHandle = () => {
        let arr = []
        if (ticketData.length === 0) {
            common.toast('请选择卡券')
            return
        }
        for (let i = 0; i < ticketData.length; i++) {
            if (ticketData[i] && (ticketData[i].ticket_id !== '0')) {
                arr.push(ticketData[i])
            }
        }

        if (arr.length === 0) {
            common.toast('请选择卡券')
            return
        }
        for (let i = 0; i < arr.length; i++) {
            if ((!arr[i].ticket_id)) {
                common.toast('请选择卡券')
                return
            }
            if (!arr[i].quantity) {
                common.toast('请选择数量')
                return
            }
            if (Number(arr[i].quantity) <= 0) {
                common.toast('数量必须大于0')
                return
            }
        }

        common.loadingStart()
        common.ajax('post', '/app.php/member-api/ticket/send', {
            member_id: memberInfo.id,
            data: JSON.stringify({
                ticket_data: arr,
                remark: remark,
            })
        }).then((res) => {
            setTicketData([])
            setRemark('')
            setQuantity([])
            setRemark('')

            fetchTicketData()   // 重新获取卡券下拉数据

            common.toast(res)
            common.ajax('get', '/app.php/member-api/member/sum', {id: memberInfo.id}).then((res) => {
                dispatch(setCardSum(res))        // 放入会员卡及卡券总数
            })
        }).finally(common.loadingStop)
    }


    return (
        <div className={style.SendCoupon}>
            <MemberDetail setAction={setAction}/>
            <DetailModal closable={true} title={'发放电子券'} onBack={() => goRecordHome(memberInfo.id)} footer={
                <div className={style.buttonBox}>
                    <Button type={'cancel'} shape={'default'}
                            onClick={() => {
                                goRecordHome(memberInfo.id)
                            }}>取消</Button>
                    <Button style={{marginLeft: '10px'}}
                            type={'confirm'}
                            shape={'default'}
                            onClick={sendHandle}>确定</Button>
                </div>
            } onCancel={onClose}>
                <div className={style.contentBox}>

                    {
                        [1, 2, 3, 4, 5].map((list, key) => {
                            return (
                                <div key={key} className={style.selectBoxRow}>
                                    <div className={style.selectDiv}>
                                        <Select
                                            options={ticketList}
                                            placeholder="请选择卡券类型"
                                            onChange={(item) => {
                                                let arr = ticketData
                                                arr[key] = {
                                                    ticket_id: item.key,
                                                    quantity: quantity[key],
                                                }
                                                setTicketData(arr)
                                            }}/>
                                    </div>
                                    <OperateQty
                                        num={quantity[key] || 0}
                                        handleAdd={() => {
                                            let qty = quantity[key] || 0
                                            ++qty

                                            if (ticketData[key]) {
                                                ticketData[key].quantity = qty
                                            }

                                            quantity[key] = qty
                                            setQuantity(JSON.parse(JSON.stringify(quantity)))
                                        }}
                                        handleMinus={() => {
                                            let qty = quantity[key] || 0
                                            if(qty <= 0) {
                                                return
                                            }

                                            --qty

                                            if (ticketData[key]) {
                                                ticketData[key].quantity = qty
                                            }

                                            quantity[key] = qty
                                            setQuantity(JSON.parse(JSON.stringify(quantity)))
                                        }}
                                    />
                                </div>
                            )
                        })
                    }

                    <div className={style.textareaDiv}>
                        <textarea className={style.rightTextarea} name="" cols="30" rows="10" placeholder="请输入备注信息"
                                  value={remark}
                                  onChange={(e) => {
                                      setRemark(e.target.value)
                                  }}
                        />
                    </div>
                </div>


            </DetailModal>
        </div>
    );
}

export default SendCoupon;