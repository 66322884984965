import React, {useState} from 'react';
import style from './style.module.less'
import Close from "../../../images/close.png";
import Find from './components/find'
import Read from "./components/read";

function FindMember(props) {
    const {goRecordHome, onClose} = props

    const [nav, setNav] = useState('find')

    return (
        <div className={style.FindMember}>
            <div className={style.title}>
                <div></div>
                <div className={style.titleNav}>
                    <div className={[style.navItem, nav === 'find' && style.active].join(' ')} onClick={() => {
                        setNav('find')
                    }}>查询
                    </div>
                    <div className={[style.navItem, nav === 'read' && style.active].join(' ')} onClick={() => {
                        setNav('read')
                    }}>读卡
                    </div>
                </div>
                <div className={style.closeImage} onClick={onClose}>
                    <img src={Close} alt=""/>
                </div>
            </div>

            <Find nav={nav} goRecordHome={goRecordHome}></Find>

            <Read nav={nav} goRecordHome={goRecordHome}></Read>
        </div>
    );
}

export default FindMember;