import {createSlice} from '@reduxjs/toolkit'
import business from "../utils/business";
import biz from "../utils/biz";

const NAMESPACE = "app"


const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    test: "test",

    serviceFeeShow: false, // 默认不展示服务到期提示
    storeDue: '', // 店务管家到期时间

    employeeInfo: '', // 店务管家到期时间

    beginBillType: 1,// 默认手牌
    beginNumberTitle: '手牌', // 开单模式的那个编号标题，手牌、房间
    projectList: [], //项目数据
    projectObjById: {}, // 项目数据对象，以项目ID为key，项目数据为value的对象，如：{1001: xxx, 1002: xxx}
    projectObjByNumber: {},

    handNumberList: [], //手牌数据
    handNumberObjById: {}, // 根据手牌ID处理的手牌对象
    handNumberObjByNumber: {}, //手牌数据对象，以手牌号为key，手牌数据为value的对象，如：{1001: xxx, 1002: xxx}
    handNumberObjByCode: {}, // 手牌，用code作为key

    roomList: [],//房间数据
    roomObjById: {}, // 根据id处理的对象
    roomObjByNumber: {}, // 根据房间编号处理的对象

    employeeList: [], //技师数据
    employeeObjByEmployeeId: {},//技师对象数据，以技师Id为key，技师数据为value的对象，如：{1001: xxx, 1002: xxx}
    employeeObjByNumber: {},//技师对象数据，以技师工号为key，技师数据为value的对象，如：{1001: xxx, 1002: xxx}
    handleEmployeeObjByEmployeeId: {}, // 处理过后的员工信息  忙碌的技师信息
    codeList: [], //实体卡数据
    tagProjectList: [], //标签项目对应数据

    serveBillList: [], //未结账的账单数据
    billObjByHandId: {}, // 手牌为key的服务中的账单
    billObjById: {}, // 账单ID为key的服务中的账单
    billListGroupBatchId: {}, // 批次ID为key的服务中的账单
    billListGroupRoomId: {}, // 房间id为key的服务中的账单
    roomIdsGroupBatch: {}, // 按照批次分组的账单里的房间ids
    batchIdsGroupRoom: {}, // 按照房间分组的批次ids

    billAllConsumeList: [], // 账单里的所有的消费数据
    billAllConsumeObjById: {}, // 根据消费id为key的  账单里所有的消费数据
    billAllConsumeListGroupEmployeeId: {}, // 根据技师分组的，账单里的所有消费
    allConsumeListGroupRoomId: {}, // 根据房间分组的，账单里的所有消费
    allConsumeRoomIds: [], // 所有消费里涉及到的房间
    allConsumeRoomObjByRoomId: {}, // 根据房间分组的  所有消费

    noEndConsumeListGroupEmployeeId: {}, // 根据技师分组的，未落钟的消费

    roomOpenList: [], //开房使用中的数据
    roomOpenListGroupByRoomId: {}, // 根据房间分组的开房数据，房间ID为key，开房数据组装成数组作为value

    callServeList: [], //未处理的呼叫服务信息

    appointOrderList: [], //待处理的排钟数据
    appointOrderListByEmployeeId: {}, // 根据技师ID分组的数据
    appointOrderListByRoomId: {}, // 根据房间ID分组的数据

    noEndConsumeList: [], //未到钟的消费数据
    employeeTagList: [], //技师标签
    projectTypeList: [],//项目分类标签
    roomAreaList: [],//房间区域
    roomTypeList: [],//房间类型
    bookingInfoList: [],//预约信息
    userInfo: {}, // 门店信息
    ipadCode: '', // 客显通讯码
    reportList: [], // 门店自己的扩展的报表 --- 不启用
    customerBooking: [], // 预约客户信息  在店预约
    userBookingOnlineData: [], // 在线预约的数据

    shiftList: [], // 班次
    shiftObjById: {}, //  id为key 班次
    employeeStarLevelList: [], // 技师星级
    payTypeTypeList: [], // 支付方式的类型
    payTypes: [], // 支付方式
    billTypeList: [], // 账单类型

    frontWaitRemindData: [],
    employeeBookingList: [], // 技师预约数据

    codeObjByCode: {},
    codeObjByDoorClock: {},
    pcAppointShowTagProject: 1,
    appointWait: "no", // 排钟排起(yes支持; no不支持)
    roomShowMoney: 1, // 房间显示金额(1全部;2未结;3已结)

    phraseList: [], // 常用语
    manualPricePlanList: [], // 手动执行的优惠方案

    nowTime: 0,

    mainNowType: '', // 中间目前选择的内容
    modalShowType: '', // 弹框显示的内容
    rightMenuType: '', // 右键菜单类型

    financeRoomContent: 3,
    mainActiveObj: false, // 中间内容的选中项
    mainRightShowType: 'consume', // 右边选中的类型

    employeeShowPercentage: 100, // 底部技师展示的比例
    frontRoomShowPercentage: 100, // 房间显示的比例
    systemUseType: '1', // 系统使用模式
    roomShowHandSetting: 2, // 房间是否显示手牌
    roomShowPageV4Finance: 1, // 房间展示是否分页[新版收银]
    busyEmployeeShowClock: 2, // 服务中的技师是否显示钟类型[1是;2否]
    memberSplitOrder: 2, // 会员拆单是否自动填写手机号和验证[1是;2否]
    callServeRoomShow: 2, // 房间有服务是否闪烁，默认不闪
    frontBottomEmployeeAddClockFlicker: 2, // 底部技师有加钟是否闪烁，默认不闪

    employeeClockType: 2, //技师排钟方式 默认为 轮次打尾牌
    frontEmployeeShow: 1, // 技师展示，默认是下移
    storeWaitServeBookingList: [], // 在店预约的

    bottomShowTagObj: {}, // 底部技师显示的标签数据{id: {"name": "xxx", "sort": xxx, "show": "xxx"}, ...}
  },
  reducers: {
    testAction(state, action) {
      state.test = action.payload
    },
    setServiceFeeShow(state, action) {
      state.serviceFeeShow = action.payload
      business.serviceDue.show_value = action.payload
    },
    setEmployeeInfo(state, action) {
      state.employeeInfo = action.payload
    },
    setStoreDue(state, action) {
      state.storeDue = action.payload
      business.serviceDue.store_due = action.payload
    },
    setBeginBillType(state, action) {
      state.beginBillType = action.payload
      business.begin_bill_type = action.payload

      if(business.begin_bill_type == business.beginBillTypeList.room) {
        state.beginNumberTitle = '房间'
      } else {
        state.beginNumberTitle = '手牌'
      }
    },
    setProjectList(state, action) {
      state.projectList = action.payload
      business.projects = action.payload

      var arr = [];

      let projects = business.projects

      for (var i = 0; i < projects.length; i++) {
        if (projects[i]['status'] == business.projectStatus.status_yes) {
          arr.push(projects[i]);
        }
      }

      arr.sort(function (a, b) {
        return a.number - b.number;
      });

      business.projectNormalList = arr;
    },
    setProjectObjById(state, action) {
      state.projectObjById = action.payload
      business.projectObjById = action.payload
    },
    setProjectObjByNumber(state, action) {
      state.projectObjByNumber = action.payload
      business.projectObjByNumber = action.payload
    },
    setHandNumberList(state, action) {
      state.handNumberList = action.payload
      business.handNumberList = action.payload
      business.hand_numbers = action.payload

      let normalArr = [];
      for (let i = 0; i < business.hand_numbers.length; i++) {
        if (business.hand_numbers[i]['status'] != business.handNumberStatus.status_delete) {
          continue;
        }

        normalArr.push(business.hand_numbers[i])
      }
      business.normal_hand_numbers = normalArr
    },
    setHandNumberObjById(state, action) {
      state.handNumberObjById = action.payload
      business.handNumberObjById = action.payload
    },
    setHandNumberObjByNumber(state, action) {
      state.handNumberObjByNumber = action.payload
      business.handNumberObjByNumber = action.payload
    },
    setHandNumberObjByCode(state, action) {
      state.handNumberObjByCode = action.payload
      business.handNumberObjByCode = action.payload
    },
    setRoomList(state, action) {
      state.roomList = action.payload
      business.rooms = action.payload

      var arr = [];

      for (var i = 0; i < business.rooms.length; i++) {
        if (business.rooms[i]['status'] == business.roomStatus.status_yes) {
          arr.push(business.rooms[i]);
        }
      }

      business.roomNormalList = arr
    },
    setRoomObjById(state, action) {
      state.roomObjById = action.payload
      business.roomObjById = action.payload
    },
    setRoomObjByNumber(state, action) {
      state.roomObjByNumber = action.payload
      business.roomObjByNumber = action.payload
    },
    setEmployeeList(state, action) {
      state.employeeList = action.payload
      business.employees = action.payload

      // 营销人员
      business.sellEmployees = business.functions.handleAndGetSellEmployeeList(business.employees);

      // 状态正常的技师
      business.normalEmployeeTypeEmployeeList = business.handleNormalEmployeeTypeList()
    },
    setEmployeeObjByEmployeeId(state, action) {
      state.employeeObjByEmployeeId = action.payload
      business.employeeObjByEmployeeId = action.payload
    },
    setEmployeeObjByNumber(state, action) {
      state.employeeObjByNumber = action.payload
      business.employeeObjByNumber = action.payload
    },
    setHandleEmployeeObjByEmployeeId(state, action) {
      let obj = action.payload ? action.payload : {}
      state.handleEmployeeObjByEmployeeId = obj
      business.handleEmployeeObjByEmployeeId = obj
    },
    setCodeList(state, action) {
      state.codeList = action.payload
      business.codeList = action.payload
    },
    setTagProjectList(state, action) {
      state.tagProjectList = action.payload
      business.tag_project_list = action.payload;
    },
    setServeBillList(state, action) {
      state.serveBillList = action.payload
      business.bills = action.payload
    },
    setBillObjByHandId(state, action) {
      state.billObjByHandId = action.payload
      business.billObjByHandId = action.payload
    },
    setBillObjById(state, action) {
      state.billObjById = action.payload
      business.billObjById = action.payload
    },
    setBillListGroupBatchId(state, action) {
      state.billListGroupBatchId = action.payload
      business.billListGroupBatchId = action.payload
    },
    setBillListGroupRoomId(state, action) {
      state.billListGroupRoomId = action.payload
      business.billListGroupRoomId = action.payload
    },
    setRoomIdsGroupBatch(state, action) {
      state.roomIdsGroupBatch = action.payload
      business.roomIdsGroupBatch = action.payload
    },
    setBatchIdsGroupRoom(state, action) {
      state.batchIdsGroupRoom = action.payload
      business.batchIdsGroupRoom = action.payload
    },
    setBillAllConsumeList(state, action) {
      state.billAllConsumeList = action.payload
      business.billAllConsumeList = action.payload
    },
    setBillAllConsumeObjById(state, action) {
      state.billAllConsumeObjById = action.payload
      business.billAllConsumeObjById = action.payload
    },
    setBillAllConsumeListGroupEmployeeId(state, action) {
      state.billAllConsumeListGroupEmployeeId = action.payload
      business.billAllConsumeListGroupEmployeeId = action.payload
    },
    setAllConsumeListGroupRoomId(state, action) {
      state.allConsumeListGroupRoomId = action.payload
      business.allConsumeListGroupRoomId = action.payload
    },
    setAllConsumeRoomIds(state, action) {
      state.allConsumeRoomIds = action.payload
      business.allConsumeRoomIds = action.payload
    },
    setAllConsumeRoomObjByRoomId(state, action) {
      state.allConsumeRoomObjByRoomId = action.payload
      business.allConsumeRoomObjByRoomId = action.payload
    },
    setNoEndConsumeListGroupEmployeeId(state, action) {
      state.noEndConsumeListGroupEmployeeId = action.payload
      business.noEndConsumeListGroupEmployeeId = action.payload
    },
    setRoomOpenList(state, action) {
      state.roomOpenList = action.payload
      business.roomOpenList = action.payload ? [...action.payload]: []
    },
    setRoomOpenListGroupByRoomId(state, action) {
      state.roomOpenListGroupByRoomId = action.payload
      business.roomOpenListGroupByRoomId = action.payload
    },
    setCallServeList(state, action) {
      let arr = action.payload ? [...action.payload] : []

      if(arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          let item = arr[i]
          let checkPending = false
          if (item['status'] == business.callServeStatusList.call || item['status'] == business.callServeStatusList.pending) {
            checkPending = true;
          } else {
            checkPending = false;
          }

          item = {
            ...item,
            'check_pending': checkPending
          }

          arr[i] = item
        }
      }

      state.callServeList = arr
      business.call_serve = arr
    },
    setAppointOrderList(state, action) {
      state.appointOrderList = action.payload
      business.appointOrder = action.payload
    },
    setAppointOrderListByEmployeeId(state, action) {
      state.appointOrderListByEmployeeId = action.payload
      business.appointOrderListByEmployeeId = action.payload
    },
    setAppointOrderListByRoomId(state, action) {
      state.appointOrderListByRoomId = action.payload
      business.appointOrderListByRoomId = action.payload
    },
    setNoEndConsumeList(state, action) {
      state.noEndConsumeList = action.payload
      business.consumesNoServiceEnd = action.payload
    },
    setEmployeeTagList(state, action) {
      state.employeeTagList = action.payload
      business.employee_tag_list = action.payload
    },
    setProjectTypeList(state, action) {
      state.projectTypeList = action.payload
      business.project_type_list = action.payload
    },
    setRoomAreaList(state, action) {
      state.roomAreaList = action.payload
      business.room_area_list = action.payload
    },
    setRoomTypeList(state, action) {
      state.roomTypeList = action.payload
      business.room_type_list = action.payload
    },
    setBookingInfoList(state, action) {
      state.bookingInfoList = action.payload
      business.bookingInfoList = action.payload
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload
      business.userInfo = action.payload
    },
    setIpadCode(state, action) {
      state.ipadCode = action.payload
      business.ipadCode = action.payload
    },
    setReportList(state, action) {
      state.reportList = action.payload
      business.reportList = action.payload
    },
    setCustomerBooking(state, action) {
      state.customerBooking = action.payload
      business.customerBooking = action.payload
    },
    setUserBookingOnlineData(state, action) {
      state.userBookingOnlineData = action.payload
      business.userBookingOnlineData = action.payload
    },
    setShiftList(state, action) {
      state.shiftList = action.payload
      business.shiftList = action.payload
    },
    setShiftObjById(state, action) {
      state.shiftObjById = action.payload
      business.shiftObjById = action.payload
    },
    setEmployeeStarLevelList(state, action) {
      state.employeeStarLevelList = action.payload
      business.employee_star_level_list = action.payload
    },
    setPayTypeTypeList(state, action) {
      state.payTypeTypeList = action.payload
      business.pay_type_type_list = action.payload
    },
    setPayTypes(state, action) {
      state.payTypes = action.payload
      business.pay_types = action.payload
    },
    setBillTypeList(state, action) {
      state.billTypeList = action.payload
      business.billTypeList = action.payload
    },
    setFrontWaitRemindData(state, action) {
      state.frontWaitRemindData = action.payload
    },
    setEmployeeBookingList(state, action) {
      state.employeeBookingList = action.payload
      business.employeeBookingList = action.payload
    },
    setCodeObjByCode(state, action) {
      state.codeObjByCode = action.payload
      business.codeObjByCode = action.payload
    },
    setCodeObjByDoorClock(state, action) {
      state.codeObjByDoorClock = action.payload
      business.codeObjByDoorClock = action.payload
    },
    setPhraseList(state, action) {
      state.phraseList = action.payload
      business.phraseList = action.payload ? [...action.payload] : [];
    },
    setNowTime(state, action) {
      state.nowTime = action.payload
      business.nowTime = action.payload
    },
    setMainNowType(state, action) {
      state.mainNowType = action.payload
      business.mainNowType = action.payload
    },
    setModalShowType(state, action) {
      state.modalShowType = action.payload
      business.modalShowType = action.payload
    },
    setRightMenuType(state, action) {
      state.rightMenuType = action.payload
      business.rightMenuType = action.payload
    },
    setFinanceRoomContent(state, action) {
      state.financeRoomContent = action.payload
      business.financeRoomContent = action.payload
    },
    setMainActiveObj(state, action) {
      state.mainActiveObj = action.payload
      business.mainActiveObj = action.payload ? {...action.payload} : false;
    },
    setMainRightShowType(state, action) {
      state.mainRightShowType = action.payload
    },
    setEmployeeShowPercentage(state, action) {
      state.employeeShowPercentage = action.payload
      business.employeeShowPercentage = action.payload
    },
    setFrontRoomShowPercentage(state, action) {
      state.frontRoomShowPercentage = action.payload
      business.frontRoomShowPercentage = action.payload
    },
    setPcAppointShowTagProject(state, action) {
      state.pcAppointShowTagProject = action.payload
      business.pcAppointShowTagProject = action.payload
    },
    setAppointWait(state, action) {
      state.appointWait = action.payload
      business.appointWait = action.payload
    },
    setRoomShowMoney(state, action) {
      state.roomShowMoney = action.payload
      business.roomShowMoney = action.payload
    },
    setManualPricePlanList(state, action) {
      state.manualPricePlanList = action.payload
      business.manualPricePlanList = action.payload
    },
    setSystemUseType(state, action) {
      state.systemUseType = action.payload
      business.systemUseType = action.payload
    },
    setRoomShowHandSetting(state, action) {
      state.roomShowHandSetting = action.payload
      business.roomShowHandSetting = action.payload
    },
    setRoomShowPageV4Finance(state, action) {
      state.roomShowPageV4Finance = action.payload
    },
    setBusyEmployeeShowClock(state, action) {
      state.busyEmployeeShowClock = action.payload
    },
    setMemberSplitOrder(state, action) {
      state.memberSplitOrder = action.payload
    },
    setCallServeRoomShow(state, action) {
      state.callServeRoomShow = action.payload
      business.callServeRoomShow = action.payload
    },
    setFrontBottomEmployeeAddClockFlicker(state, action) {
      state.frontBottomEmployeeAddClockFlicker = action.payload
      business.frontBottomEmployeeAddClockFlicker = action.payload
    },
    setEmployeeClockType(state, action) {
      state.employeeClockType = action.payload
      business.employee_clock_type = action.payload
    },
    setFrontEmployeeShow(state, action) {
      state.frontEmployeeShow = action.payload
      business.frontEmployeeShow = action.payload
    },
    setStoreWaitServeBookingList(state, action) {
      state.storeWaitServeBookingList = action.payload ? [...action.payload]: []
      business.store_wait_serve_booking_list = state.storeWaitServeBookingList
    },
    setBottomShowTagObj(state, action) {
      state.bottomShowTagObj = action.payload
    },
  },
  extraReducers: {},
});

export const {
  testAction,
  setServiceFeeShow,
  setStoreDue,
  setBeginBillType,
  setProjectList,
  setProjectObjById,
  setProjectObjByNumber,
  setHandNumberList,
  setHandNumberObjById,
  setHandNumberObjByNumber,
  setHandNumberObjByCode,
  setRoomList,
  setRoomObjById,
  setRoomObjByNumber,
  setEmployeeList,
  setEmployeeObjByEmployeeId,
  setEmployeeObjByNumber,
  setHandleEmployeeObjByEmployeeId,
  setCodeList,
  setTagProjectList,
  setServeBillList,
  setBillObjByHandId,
  setBillObjById,
  setBillListGroupBatchId,
  setBillListGroupRoomId,
  setRoomIdsGroupBatch,
  setBatchIdsGroupRoom,
  setBillAllConsumeList,
  setBillAllConsumeObjById,
  setBillAllConsumeListGroupEmployeeId,
  setAllConsumeListGroupRoomId,
  setAllConsumeRoomIds,
  setAllConsumeRoomObjByRoomId,
  setNoEndConsumeListGroupEmployeeId,
  setRoomOpenList,
  setRoomOpenListGroupByRoomId,
  setCallServeList,
  setAppointOrderList,
  setAppointOrderListByEmployeeId,
  setAppointOrderListByRoomId,
  setNoEndConsumeList,
  setEmployeeTagList,
  setProjectTypeList,
  setRoomAreaList,
  setRoomTypeList,
  setBookingInfoList,
  setUserInfo,
  setIpadCode,
  setReportList,
  setCustomerBooking,
  setUserBookingOnlineData,
  setShiftList,
  setShiftObjById,
  setEmployeeStarLevelList,
  setPayTypeTypeList,
  setPayTypes,
  setBillTypeList,
  setFrontWaitRemindData,
  setEmployeeBookingList,
  setCodeObjByCode,
  setCodeObjByDoorClock,
  setNowTime,
  setMainNowType,
  setModalShowType,
  setRightMenuType,
  setPhraseList,
  setFinanceRoomContent,
  setMainActiveObj,
  setMainRightShowType,
  setEmployeeShowPercentage,
  setFrontRoomShowPercentage,
  setPcAppointShowTagProject,
  setAppointWait,
  setRoomShowMoney,
  setManualPricePlanList,
  setSystemUseType,
  setRoomShowHandSetting,
  setRoomShowPageV4Finance,
  setBusyEmployeeShowClock,
  setMemberSplitOrder,
  setCallServeRoomShow,
  setFrontBottomEmployeeAddClockFlicker,
  setEmployeeClockType,
  setFrontEmployeeShow,
  setEmployeeInfo,
  setStoreWaitServeBookingList,
  setBottomShowTagObj
} = slice.actions

export const {reducer} = slice;
