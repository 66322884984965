import {createSlice} from '@reduxjs/toolkit'
import business from "../utils/business";
import biz from "../utils/biz";

const NAMESPACE = "room"

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    roomData: [],   //房间列表
    selectedRoom: {}, //选中房间
    groupRoomIdList: [], // 和选中房间在一个团体的房间
    envChange: 0,       // 环境改变次数 每次改变
  },
  reducers: {
    setRoomData(state, action) {
      state.roomData = action.payload
    },
    setSelectedRoom(state, action) {
      state.selectedRoom = action.payload

      //选中房间后将环境变化次数置为0
      state.envChange = 0
    },
    setChangeEnv(state, action) {
      state.envChange = action.payload + 1
    },
    setGroupRoomIdList(state, action) {
      state.groupRoomIdList = action.payload ? [...action.payload] : []
    },
  },
  extraReducers: {},
});

export const {
  setRoomData,
  setSelectedRoom,
  setGroupRoomIdList,
  setChangeEnv
} = slice.actions

export const {reducer} = slice;
