import React, {useState} from 'react';
import style from './style.module.less'
import MemberDetail from "../components/memberDetail";
import DetailModal from "../components/detailModal";
import Button from "../../public/button";
import couponBg from "../../../images/member/card-bg.png";
import {useDispatch, useSelector} from "react-redux";
import common from "../../../utils/common";
import memberUtils from "../../../utils/memberUtils";

function TransferCard(props) {
    const {setAction, onClose} = props

    const {activeCard, memberInfo} = useSelector(state => state.member)
    const dispatch = useDispatch();

    const couponBgStyle = {
        backgroundImage: "url(" + couponBg + ")",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
    }

    const [phone, setPhone] = useState('')  // 手机号
    const [resultMessage, setResultMessage] = useState('')  // 正确信息
    const [remark, setRemark] = useState('')  // 备注

    // 提交
    const handleSubmit = () => {

        if (phone === '') {
            common.toast('请输入手机号')
            return
        }
        if (phone.length !== 11) {
            common.toast('请输入正确的手机号')
            return
        }
        if (phone === memberInfo.mobile) {
            common.toast('不能转赠给自己')
            return
        }

        common.loadingStart()
        common.ajax('post', '/app.php/member-api/card/card-transfer', {
            member_id: memberInfo.id,     // 会员id
            card_id: activeCard.id,       // 会员卡id
            new_mobile: phone,    // 新会员手机号
            new_member_id: resultMessage.id || '', // 新会员id(可为空)
            remark: remark, // 备注信息
        }).then((res) => {
            memberUtils.fetchMemberInfo(memberInfo.id)

            common.toast(res.message)
            goBackAction()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 返回页面
    const goBackAction = () => {
        switch (activeCard.card_category_type) {
            case 2: // 储值卡
                setAction('storeValue')
                break
            case 3: // 计次卡
                setAction('order')
                break
            case 5: // 专享卡
                setAction('limitProject')
                break
            default:
                break
        }
    }

    // 获取选中菜单
    const getNav = () => {
        switch (activeCard.card_category_type) {
            case 2: // 储值卡
                return 'storeValue'
            case 3: // 计次卡
                return 'order'
            case 5: // 专享卡
                return 'limitProject'
            default:
                return ''
        }
    }

    return (
        <div className={style.TransferCard}>
            <MemberDetail nav={getNav()} setAction={setAction}/>
            <DetailModal closable={true} title={'会员卡转赠'} onBack={() => goBackAction()} footer={
                <div className={style.buttonBox}>
                    <Button type={'cancel'} shape={'default'}
                            onClick={() => {
                                goBackAction()
                            }}>取消</Button>
                    <Button style={{marginLeft: '10px'}}
                            type={'confirm'}
                            shape={'default'}
                            onClick={() => {
                                handleSubmit()
                            }}>确定</Button>
                </div>
            } onCancel={onClose}>
                <div className={style.contentBox}>
                    <div className={style.couponDiv} style={couponBgStyle}>
                        <div className={style.topCouponDiv}>
                            <div className={style.couponName}>{activeCard.name}</div>
                            <div className={style.couponShop}>{activeCard.user_name}</div>
                        </div>

                        <div className={style.couponRow}>{activeCard.number}</div>
                        <div className={style.couponRow}>有效期：{activeCard.valid_str}</div>

                        <div className={style.bottomCouponDiv}>
                            {activeCard.card_category_type === 3 ?
                                <div className={style.shop}>当前次数<span> {activeCard.consume_times}次</span> </div> : <div className={style.shop}>当前余额 <span>￥{activeCard.money}</span></div>}
                        </div>
                    </div>

                    <div className={style.inputDiv}>
                        <input type="text" placeholder={'请输入接收此卡的会员手机号码'}
                               value={phone}
                               onChange={(e) => {
                                   let val = common.trimBlank(e.target.value)
                                   setPhone(val)
                                   if (val.length === 11) {
                                       common.loadingStart()
                                       common.ajax('get', '/app.php/member-api/member/find-member-by-mobile', {
                                           mobile: val,
                                       }).then((res) => {
                                           setResultMessage(res)
                                       }).finally(() => {
                                           common.loadingStop()
                                       })
                                   }
                               }}/>
                    </div>

                    <div className={style.textareaDiv}>
                        <textarea className={style.rightTextarea} name="" cols="30" rows="10" placeholder="请输入备注信息"
                                  value={remark}
                                  onChange={(e) => {
                                      setRemark(e.target.value)
                                  }}
                        />
                    </div>
                </div>


            </DetailModal>
        </div>
    );
}

export default TransferCard;