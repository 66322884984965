import common from "./common";
import biz from "./biz";
import noticeMessage from "./noticeMessage"
import print from "./print";
import mqttClient from "./mqttClient";

import {
  setModalShowType,
  setRightMenuType,
  setServiceFeeShow
} from "../reducers/app";

import {
  setModalShowTypeScreen
} from "../reducers/rightMenu";

import {
  setDoorClockType,
  setDoorClockHandNumberList
} from "../reducers/doorClock";
import {setMemberShowMemberId, setMemberShowType, setMemberShowBackMessage} from "../reducers/member";


let business = {
  serviceTel: '400-870-2711',
  baseUrl: "components/",
  print: "http://" + "127.0.0.1:2048/print", //打印
  readCard: "http://" + "127.0.0.1:2048/read", //读卡
  call: "http://" + "127.0.0.1:2048/call", //获取来电显示
  clientCode: "http://" + "127.0.0.1:2048/clientCode", //登录机制
  clientLocalInfo: "http://" + "127.0.0.1:2048/localInfo", //EXE获取localInfo数据
  readRf008: "http://" + "127.0.0.1:2048/readRF008", // 读取门柜锁数据
  setRF008: "http://" + "127.0.0.1:2048/setRF008", // 开启门柜锁
  clearRF008: "http://" + "127.0.0.1:2048/clearRF008", // 关闭门柜锁
  clearRF008Qrcode: "http://" + "127.0.0.1:2048/clearRF008Qrcode", // 关闭门柜锁-带有二维码
  qrcodeRF008: "http://" + "127.0.0.1:2048/qrcode", // 帝派鞋柜，根据手牌卡号，打印二维码
  doorLockBUrl: 'http://127.0.0.1:8081/', // 爱莎门锁地址
  setRoomNameToHand: "http://" + "127.0.0.1:2048/setRoomName", // 将 房间号设置到 手牌里

  setRF003: "http://" + "127.0.0.1:2048/setRF003", // 发牌-爱莎v2
  clearRF003: "http://" + "127.0.0.1:2048/clearRF003", // 消牌-爱莎v2
}

business.call_serve = [] //待处理的呼叫服务信息
business.projectObjById = {}
business.handNumberList = []


business.beginBillTypeList = {
  'handNumber': 1,
  'room': 2
};

// 服务方式
business.service_type_list = {
  store: 10, // 店内
  door: 20, // 上门
}

// 会员是否启用密码
business.can_member_use_pwd = false; // 默认不启用

// 开单选项目
business.front_begin_bill_with_project = 1; // 默认为1
business.frontBeginBillWithProjectList = {
  yes: 1,
  no: 2,
}

// 中间的技师状态
business.front_default_employee_show = 1; // 默认为1
business.frontDefaultEmployeeShowList = {
  normal: 1, // 正常展示
  clock: 2, // 根据轮次排钟展示
}

// 是否只显示有预约的技师
business.front_only_show_booking_employee = false; // 默认为2
business.frontOnlyShowBookingEmployeeList = {
  yes: 1,
  no: 2,
}

// 收银 手牌/房间状态 是否展示预约信息
business.front_hand_and_room_show_appoint_info = 2; // 默认为2
business.frontHandAndRoomShowAppointInfoList = {
  yes: 1,
  no: 2,
}

// 中间房间显示的比例
business.frontRoomShowPercentage = 100; // 默认为100

// 技师显示比例
business.employeeShowPercentage = 100; // 技师显示比例

//验证卡券类型
business.checkTicketList = {
  'code': 'code',
  'ticket': 'ticket_detail_id',
};

//卡券类型
business.ticketStatusList = {
  notused: 1,  //未使用
  used: 2, //已使用
  invalid: 3 //作废
};

// 卡券类型
business.ticketTypeList = {
  money: 10, // 代金券
  project: 20, // 项目抵扣
  discount: 30, // 打折券
}

// 卡券的折扣范围
business.ticketDiscountRangeList = {
  all: 10, // 整单
  one: 20, // 单项
}

business.cloudServiceExpire = false; //默认不提醒

//收银房间、底部技师界面  值类型
business.frontRoomAndBottomEmployeeList = {
  v1: 1, //旧版
  v2: 2, //2018新版
  v3: 3, //201911新版
};

//服务收费类型
business.cloudServiceTypeList = {
  store: 1,  //店务管家
  mobile: 2  //移动营销
};

// 项目是否为小项目
business.projectSmallList = {
  yes: 20,
  no: 10
}

//项目是否为普通商品
business.projectIsNormalGoods = {
  yes: 1,
  no: 0
};

//项目是否需要房间
business.projectNeedRoom = {
  yes: 0,
  no: 1
};

// 项目是否为待定项目
business.projectIsPendList = {
  yes: 20,
  no: 10
}

//服务方式
business.serviceTypeList = [
  {
    value: 10,
    name: '店内'
  },
  {
    value: 20,
    name: '上门'
  }
];

//账单状态
business.billStatus = {
  serve_in: 10, //服务中
  serve_over: 20 //服务完成
};

//消费的支付状态
business.consumePayStatus = {
  pay_no: 10, //未支付
  pay_yes: 20, //已支付
  pay_lock: 30 //支付中
};

//消费的状态
business.consumeStatus = {
  status_yes: 10, //正常
  status_no: 20 //退单
};

//项目状态
business.projectStatus = {
  status_yes: 10, //正常
  status_no: 20, //禁用
  status_delete: 90 //删除
};

//项目是否可预约
business.projectBooking = {
  yes: 10,
  no: 20
};

//房间状态
business.roomStatus = {
  status_yes: 10, //正常
  status_no: 20, //禁用
  status_delete: 90 //删除
};

//房间的工作状态
business.roomWorkStatus = {
  normal: 10,
  clean: 20,
  fault: 30
};

//房间的预约状态
business.roomBookingStatus = {
  yes: 20,
  no: 10
};

business.roomWorkStatusFree = 1; //空闲房间状态 特殊
business.roomWorkStatusBusy = 2; //忙碌房间状态 特殊
business.roomWorkStatusWaitService = 3; //忙碌房间状态 特殊

//房间的工作状态  条件
business.roomWorkStatusConditionObj = {
  'busy': {'key': 'busy', 'name': '忙碌'},
  'wait': {'key': 'wait', 'name': '待起钟'},
  'occupy': {'key': 'occupy', 'name': '占房'},
  'booking': {'key': 'booking', 'name': '预约'},
  'clean': {'key': 'clean', 'name': '打扫'},
  'fault': {'key': 'fault', 'name': '维修'},
  'free': {'key': 'free', 'name': '空闲'},
}
business.roomWorkStatusConditionList = [
  {'id': business.roomWorkStatusConditionObj.busy.key, 'value': '忙碌'},
  {'id': business.roomWorkStatusConditionObj.wait.key, 'value': '待起钟'},
  {'id': business.roomWorkStatusConditionObj.occupy.key, 'value': '占房'},
  {'id': business.roomWorkStatusConditionObj.booking.key, 'value': '预约'},
  {'id': business.roomWorkStatusConditionObj.clean.key, 'value': '打扫'},
  {'id': business.roomWorkStatusConditionObj.fault.key, 'value': '维修'},
  {'id': business.roomWorkStatusConditionObj.free.key, 'value': '空闲'},
];

//手牌状态
business.handNumberStatus = {
  status_yes: 10, //正常
  status_no: 20, //禁用
  status_delete: 90 //删除
};

//员工类型
business.employeeTypeList = {
  normal: 10, //普通员工
  employee: 20 //技师
};

//技师状态
business.employeeStatus = {
  status_yes: 10, //正常
  status_no: 20, //禁用
  status_delete: 90 //删除
};

//技师的工作状态
business.employeeWorkStatus = {
  normal: 10, //上岗
  rest: 20, //调休
  suspend: 30 //停牌
};

//忙碌的技师工作状态 1 特殊
business.employeeBuyWorkStatus = 1;

//技师的工作状态  条件
business.employeeWorkStatusList = [
  {'id': business.employeeWorkStatus.normal, 'value': '上班'},
  {'id': business.employeeBuyWorkStatus, 'value': '忙碌'},
  {'id': business.employeeWorkStatus.rest, 'value': '休息'},
  {'id': business.employeeWorkStatus.suspend, 'value': '暂停'},
];

//支付方式的标记
business.payTypeTag = {
  tag_normal: 0, //普通账户
  tag_cash: 1, //现金 (默认现金方式)
  tag_card: 2, //实体会员卡帐户
  tag_group: 4, //团购
  tag_wechat_code: 5, //微信扫码支付
  tag_alipay_code: 6, //支付宝扫码支付
  tag_voucher: 7, //抵扣券
  tag_card_import: 9, //旧卡导入
  tag_shop: 10, //线上商城支付
  tag_ticket: 11, //卡券
  tag_point: 13, // 积分支付
  tag_meituan: 14, // 美团团购
};

//技师性别
business.employee_sex_list = [
  {'id': 1, 'value': '男'},
  {'id': 2, 'value': '女'}
];

business.employeeSexList = {
  man: 1,
  woman: 2
};

// 别名设置
business.settingTypeList = {
  serviceType: 10,
  iotScene: 20,
  clockType: 30
}

//钟类型
business.clock_type_list = [
  {value: '10', name: '轮钟'},
  {value: '40', name: '点钟'},
  {value: '30', name: '加钟'},
  {value: '20', name: '跳钟'}
];

// 钟类型：用value为key，name为value
business.clock_type_obj = {
  10: '轮钟',
  20: '跳钟',
  30: '加钟',
  40: '点钟'
}

//钟类型
business.clock_type_keys = {
  'round': 10, // 轮钟
  'call': 40, // 点钟
  'add': 30, // 加钟
  'jump': 20, // 跳钟
}

business.clockTypeList = {
  round: 10,
  jump: 20,
  add: 30,
  call: 40
};

//卡的卡类值
business.cardCategoryTypeList = {
  storeValue: 2, // 储值卡
  order: 3, // 计次卡
  time: 4, // 时段卡
  limit_project: 5 // 专享卡
};

// 卡类的折扣类型
business.cardCategoryDiscountTypeList = {
  no: 10, // 消费不打折
  all: 20, // 整单打折
  relation: 30, // 关联折扣方案打折
}

//呼叫服务数据的状态
business.callServeStatusList = {
  call: 10,  //呼叫
  pending: 20, //待处理
  confirm: 30, //已确认
  cancel: 40, //取消
  invalid: 50, // 作废
  delete: 90 //已删除
};

//技师的考勤状态
business.employeeAttendanceStatusList = {
  work: 10, //上班
  overtime: 20, //加班
  rest: 30 //休息
};

//技师的工作状态
business.employeeOperationStatusList = {
  free: 10, //空闲
  busy: 20, //忙碌
  suspend: 30 //暂停
};

//技师的预约状态
business.employeeBookingStatusList = {
  no: 10, //没有预约
  yes: 20 //预约
};

//技师的待起钟状态
business.employeeAppointOrderStatusList = {
  yes: 20,
  no: 10
};

// 技师的排钟状态
business.employeeClockStatusList = {
  normal: 10, // 正常
  prior: 20 // 优先
};

//小程序下单的数据的状态
business.appointOrderStatusList = {
  pending: 10,
  serve: 20,
  cancel: 30,
  delete: 90
};

// 小程序的下单类型
business.appointOrderTypeList = {
  manually: 10, // 手动
  auto: 20, // 自动
}

//技师的工作状态  条件
business.employeeStatusConditionList = [
  {'value': 'attendance-status-' + business.employeeAttendanceStatusList.work, 'string': '上班'},
  {'value': 'attendance-status-' + business.employeeAttendanceStatusList.overtime, 'string': '加班'},
  {'value': 'attendance-status-' + business.employeeAttendanceStatusList.rest, 'string': '休息'},
  {'value': 'operation-status-' + business.employeeOperationStatusList.free, 'string': '空闲'},
  {'value': 'operation-status-' + business.employeeOperationStatusList.busy, 'string': '忙碌'},
  {'value': 'operation-status-' + business.employeeOperationStatusList.suspend, 'string': '暂停'},
];

business.employeeStatusList = {
  'yes': 10,
  'no': 20,
  'delete': 90
};

//技师是否为营销人员
business.employeeSellList = {
  'yes': 20, //是
  'no': 10 //否
};

//会员卡的状态类型
business.cardStatusList = {
  yes: 10,
  no: 20,
  delete: 90
};

//取整规则
business.roundingRuleList = {
  nearest: 1, //四舍五入
  down: 2, //去尾
  up: 3 //进一
};

//门店的取整方式
business.userRoundingRule = 1;

business.showNotShiftEmployeeList = {
  yes: 1,
  no: 2
};

//收银提醒的分类
business.frontRemindCategoryList = {
  member: 10, //客户提醒
  user: 20, // 门店提醒
};

//收银提醒 客户提醒的类型
business.frontRemindMemberTypeList = {
  birthday: 10, //生日
  consume: 20, //休眠
  no_message: 30, // 短信余额不足
};

//是否提醒数据
business.frontRemindNoticeList = {
  yes: 20,
  no: 10
};

//技师的排钟方式
business.employeeClockTypeList = {
  tag: 1, //大轮循环 标签排钟
  no_tag: 2, //轮次循环 打尾牌
  no_tag_no_sort: 3, //轮次循环(固定)
  tag_with_num: 4, // 轮次多标签
};

// 轮次排钟，技师展示方式
business.frontEmployeeShow = 1; // 下移：目前的方式
business.frontEmployeeShowList = {
  next: 1, // 轮次排钟下移
  after: 2, // 轮次排钟后移
};

// 服务是否通知前台
business.serveFrontNotice = 2; // 默认：不通知
business.serveFrontNoticeList = {
  yes: 1, // 是
  no: 2, // 否
};

// 服务房间是否提示 默认：不通知
business.callServeRoomShowList = {
  yes: 1, // 是
  no: 2, // 否
};

// 房态是否显示服务信息(老房态) 默认：不显示
business.roomShowServeList = {
  yes: 1, // 显示
  no: 2, // 不显示
}

// 前台是否展示排钟客人数量
business.frontShowAppointManQuantity = 2;// 默认不显示
business.frontShowAppointManQuantityList = {
  yes: 1,
  no: 2
}

// 技师是否为保洁人员
business.employeeIsCleaningList = {
  'yes': 20, //是
  'no': 10 //否
};

// 项目：是否同账单计算入场时间
business.projectCalcBillList = {
  yes: 10,
  no: 20,
}

// 房间是否显示排钟按钮(1是 2否)
business.pcRoomShowAppointCreate = 1; // 默认为1
business.pcRoomShowAppointCreateList = {
  yes: 1,
  no: 2
}

// 门柜锁是否支持二维码开锁
business.doorQrcode = 2; // 默认 不支持
business.doorQrcodeList = {
  yes: 1,
  no: 2
}

// 技师排钟超时xx分钟为其中是否闪烁
business.employeeAppointFlash = -1;

// 服务中手牌右上角显示的内容
business.serviceBillShowContent = 1;
business.serviceBillShowContentList = {
  time: 1, // 到钟时间
  pre: 2, // 已预结
}

// 是否使用加班消费记录
business.overtimeConsumeSetting = 2;
business.overtimeConsumeSettingList = {
  yes: 1,
  no: 2
}

// 是否停止计时
business.clockConsumeEndList = {
  yes: 20,
  no: 10
}

// 会员卡余额的使用
business.cardMoneyTypeList = {
  normal: 1, // 正常不区分直接用
  amount: 2, // 优先使用实收
  donation: 3, // 优先使用赠送
  manual: 4, // 手动选择
}

business.front_room_and_bottom_employee = ''

// 店务管家到期时间
business.serviceDue = {
  'show_value': false, //该窗口是否打开 ，默认关闭
  'store_due': '', //店务管家到期时间

  show: () => {
    if (business.serviceDue.show_value === false) {
      //打开结账明细界面
      common.dispatch(setServiceFeeShow(true))
    }
  }
}

business.begin_bill_type = 1; // 默认是手牌

business.front_cancel_booking = false; //收银是否可以取消预约，默认不可以

// business.front_update_price = false; //收银是否可以修改消费单价，默认不可以

// 20181014 作废
// business.front_discount = false; //收银是否可以优惠，默认不可以

business.front_update_sell_employee = false; //收银是否可以修改营销人员，默认不可以

//收银取消预约的权限
business.front_cancel_booking_list = {
  'yes': true,
  'no': false
};

// 实时营运报告房间
business.front_report_room_list = {
  'yes': 1,
  'no': 2
};

// 实时营运报告技师
business.front_report_employee_list = {
  'yes': 1,
  'no': 2
};

// 实时营运报告待结金额
business.front_report_wait_money_list = {
  'yes': 1,
  'no': 2
};

// 实时营运报告待结客流
business.front_report_wait_person_list = {
  'yes': 1,
  'no': 2
};

// 实时营运报告已结客流
business.front_report_finish_person_list = {
  'yes': 1,
  'no': 2
};

// 实时营运报告总客流
business.front_report_total_person_list = {
  'yes': 1,
  'no': 2
};

// 实时营运报告预约人数
business.front_report_booking_list = {
  'yes': 1,
  'no': 2
};

// 实时营运报告已结金额服务收入
business.front_report_finish_order_money_list = {
  'yes': 1,
  'no': 2
};

// 实时营运报告已结金额售卡收入
business.front_report_finish_card_money_list = {
  'yes': 1,
  'no': 2
};


// 收银首页底部是否展示动态消费数据
business.front_footer_show_consume_data_list = {
  yes: 1,
  no: 2
}

business.front_footer_show_consume_data = business.front_footer_show_consume_data_list.yes;

// 收银底部 加钟技师 是否闪动
business.front_bottom_employee_add_clock_flicker_list = {
  yes: 1,
  no: 2
}

business.frontBottomEmployeeAddClockFlicker = business.front_bottom_employee_add_clock_flicker_list.yes;

// 服务外技师添加消费起钟时间
business.service_out_add_consume_begin_time_list = {
  now: 1,
  last: 2
}

business.service_out_add_consume_begin_time = business.service_out_add_consume_begin_time_list.now;

// 20181014 作废
// //收银优惠的权限
// business.front_discount_list = {
//     'yes': true,
//     'no': false
// };

//收银修改营销人员的权限
business.front_update_sell_employee_list = {
  'yes': true,
  'no': false
};

// //计划还没有到钟的技师提醒数据
// business.employeeRemindList = [];

//在线预约的数据
business.userBookingOnlineData = [];

//增加品项的时候是否默认为加钟
business.projectAdd = false; //默认为false

//营业开始和结束时间
business.openTime = ''; // 营业开始时间
business.closeTime = ''; // 营业结束时间

//营业日
business.businessDate = '';

//小程序叫钟的数据
business.appointOrder = [];
business.appointOrderListByEmployeeId = {}; // 按照技师分组的排钟数据，技师ID为key，值为排钟数据组成的数组
business.appointOrderListByRoomId = {}; // 房间id为key，排钟为数组

//账单类型
business.billTypeList = [];

//是否显示非当班人员
business.show_not_shift_employee = business.showNotShiftEmployeeList.yes;

//结账打印小票数量
business.checkoutPrintNum = 1;

//会员操作打印小票数量
business.cardPrintNum = 1;

//自动起钟时间（分钟）
business.autoBeginService = 0;

// 中间技师的排钟列表的状态
business.mainCenterAppointOrderStatusList = [
  {'type': 0, 'name': '全部信息'},
  {'type': 10, 'name': '进行中'},
  {'type': 20, 'name': '待起钟'},
  {'type': 30, 'name': '已预排'},
  {'type': 40, 'name': '点钟'},
  {'type': 50, 'name': '等待'},
  {'type': 60, 'name': '已取消'},
  {'type': 70, 'name': '我提交的'},
  {'type': 80, 'name': '已起钟'},
]

//是否使用余额结算
business.category_use_money = {
  yes: 10,
  no: 20
};

//是否允许空卡打折
business.allow_no_money_discount = {
  yes: 1,
  no: 0
};

// 消费是否允许打折
business.consumeAllowDiscountObj = {
  yes: 1,
  no: 0
}

//预约的类型
business.bookingType = {
  online: 20, //在线预约
  store: 10 //在店预约
};

//预约的状态
business.bookingStatus = {
  waitConsume: 10, //预约  待消费
  service: 20 //到店 已消费
};

//预约的支付状态
business.bookingPaymentStatus = {
  yes: 20, //已支付
  no: 10 //未支付
};

//商户信息
business.userInfo = null;

// 员工
business.employees = [];

// 营销人员
business.sellEmployees = [];

// 正常状态的技师
business.normalEmployeeTypeEmployeeList = [];

// 底部技师的统计汇总数据
business.employeeTotalObj = {};

// 员工数据，用员工ID为key，员工数据为Value
business.employeeObjByEmployeeId = {}

business.employeeObjByNumber = {}

business.handleEmployeeObjByEmployeeId = {} // 处理数据之后的员工信息

//
// //技师基础数据 不用这个了，直接赋值给employees即可
// business.employeeBasicList = [];

//技师星级
business.employee_star_level_list = [];

//项目
business.projects = [];
business.projectNormalList = [];

// 项目数据：id为key，项目数据为value
business.projectObjById = {}

// 项目数据：number为key，项目数据为value
business.projectObjByNumber = {}

// 标签项目关联数据
business.tag_project_list = [];

//品项类型
business.project_type_list = [];

//房间
business.rooms = [];
business.roomNormalList = [];
business.roomUseList = []; // 首页中间展示处理过状态的房间

// 房间对象：用房间ID作为key，房间数据为value
business.roomObjById = {}

// 房间，用编号作为key，房间数据为value
business.roomObjByNumber = {}

// 房间的基础数据
business.roomBasicList = []; // 不带状态

business.roomBasicObjById = {}; // 房间基础数据，不带状态

//房间类型
business.room_type_list = [];

//房间区域类型
business.room_area_list = [];

//手牌
business.hand_numbers = [];
business.normal_hand_numbers = [];

// 手牌，用id作为key，数据作为value
business.handNumberObjById = {}

// 手牌，用编号作为key，数据作为value
business.handNumberObjByNumber = {}

// 手牌：用code作为key
business.handNumberObjByCode = {}

// 实体卡，用code作为key
business.codeObjByCode = {}

// 实体卡，用door_clock作为key
business.codeObjByDoorClock = {}

//账单
business.bills = [];

// 根据批次跟组的账单数据：批次ID为key，bill数组为value
business.billListGroupBatchId = {}

business.billListGroupRoomId =  {} // 房间id为key的服务中的账单

business.roomIdsGroupBatch = {} // 按照批次分组的房间

business.batchIdsGroupRoom = {} // 按照房间分组的批次

// 账单下的所有消费数据
business.billAllConsumeList = [];

// 账单下的所有消费数据，根据技师ID分组
business.billAllConsumeListGroupEmployeeId = {}

// 账单下的所有的消费数据，用消费ID作为key
business.billAllConsumeObjById = {}

// 所有消费数据根据房间分组
business.allConsumeListGroupRoomId = {}; // 房间ID为key, 消费数组为value

// 所有消费设计到的房间ID
business.allConsumeRoomIds = [];

// 所有消费设计到的房间数据对象 {'房间ID': {'room_id': xxx}, ...}
business.allConsumeRoomObjByRoomId = {}
business.allConsumeRoomObjByRoomIdArr = []; // 里面就一个元素"business.allConsumeRoomObjByRoomId"

// 账单 --- 用手牌为key的账单的对象
business.billObjByHandId = {}

// 账单 --- 用账单ID作为key
business.billObjById = {}
business.billObjByIdArr = [] // 是将"business.billObjById"放到这个数组里，第一个元素，因为计算属性那边检测不到对象的变化，放到数组里就可以

//技师的标签
business.employee_tag_list = [];

// 使用中的开房记录
business.roomOpenList = [];

business.roomOpenListGroupByRoomId = {}

// 门店的技师预约记录
business.employeeBookingList = [];

//在店会员
//business.member_in_store = [
//    {id: 1, number: '123', name: 'summer', sex: '女士'},
//    {id: 2, number: '123', name: 'summer', sex: '女士'},
//    {id: 3, number: '123', name: 'summer', sex: '女士'}
//];

business.phraseList = []; // 常用语
business.initPhrase = false; // 常用语是否初始化过

//预约客户
business.customer_booking = [];

//没有服务结束时间的消费信息 数组
business.consumesNoServiceEnd = [];

// 未落钟的消费，以技师ID分组
business.noEndConsumeListGroupEmployeeId = {}
business.noEndConsumeListGroupEmployeeIdArr = [] // 里面就一个元素，就是business.noEndConsumeListGroupEmployeeId

//技师工作记录
business.works = [];

//支付方式类型
business.pay_type_type_list = [];

//支付方式
business.pay_types = [];

//已支付但未下钟且未到钟的消费信息
// business.busyConsumePayYes = [];

//收银的提醒规则
business.frontRemindList = [];

//收银提醒数据
business.frontWaitRemindData = [];

// 实体卡
business.codeList = [];

// 班次信息
business.shiftList = [];

// 班次，用id作为key
business.shiftObjById = {}

// 跨房间消费是否显示
business.billMoreRoomShowConsumeList = {
  yes: 1,
  no: 0,
}

business.billMoreRoomShowConsume = 0

// pc排钟是否只显示本标签下分配过的项目 (1: 是、0: 否)，默认是
business.pcAppointShowTagProject = 1

// 结账是否默认显示0元商品 (1: 是; 2: 否) 默认否
business.pcCheckoutShowZeroGoods = 2
business.pcCheckoutShowZeroGoodsList = {
  yes: 1,
  no: 2
}

// 是否可以排钟排起(yes是;no否)
business.appointWait = "no"
business.appointWaitObj = {
  yes: "yes",
  no: "no"
}

// 房间显示金额
business.roomShowMoney = 1;
business.roomShowMoneyObj = {
  all: 1,
  payNo: 2,
  payYes: 3,
}

// 账单显示的内容 (1: 消费金额; 2: 消费金额+入场时间) 默认消费金额
business.billShowContentList = {
  money: 1, // 消费金额
  timeMoney: 2, // 消费金额+入场时间
}

// PC是否声音提示服务信息 (1: 是; 2: 否) 默认否
business.pcCallServiceNoticeVoice = 1
business.pcCallServiceNoticeVoiceList = {
  yes: 1,
  no: 2
}

// PC账单排序 (1: 手牌; 2: 开单时间) 默认手牌
business.pcBillSort = 1;
business.pcBillSortList = {
  hand: 1,
  bill: 2,
}

// 门店是否有门柜锁
business.hasDoorLock = 0; // 默认没有
business.hasDoorLockList = {
  yes: 1,
  no: 0,
}

// 结账时是否关门
business.payCloseDoorLock = 1; // 默认是关门的
business.payCloseDoorLockList = {
  yes: 1,
  no: 2
}

// 门柜锁类型
business.doorLockType = 1; // 默认为A
business.doorLockTypeList = {
  A: 1, // 帝派门锁
  B: 2, // 爱沙门锁
  C: 3, // 爱沙v2门锁
}

// 是否支持单手牌关房
business.roomCloseCanOneHandRule = 1; // 默认是单手牌关房
business.roomCloseCanOneHandRuleList = {
  yes: 1,
  no: 2,
}

// 空手牌是否可以拼为团体
business.noBillCanGroup = 1; // 默认可以
business.noBillCanGroupList = {
  yes: 1,
  no: 2,
}

// 前台显示的房间内容
business.financeRoomContent = 3; // 默认房间号
business.financeRoomContentList = {
  number: 1,
  name: 2,
  number_name: 3, // 房间号+房间名
}

// 开单过程中显示的房间内容
business.beginBillRoomContent = 1; // 默认房间号
business.beginBillRoomContentList = {
  number: 1,
  name: 2,
}

// 消费清单显示的房间内容
business.pcCheckoutRoomContent = 1; // 默认房间号
business.pcCheckoutRoomContentList = {
  number: 1,
  name: 2,
}

// 门店的离线版本：是边缘门店下有效
business.offlineVersion = '';

// 预约的版本
business.bookingVersion = 1; // 默认是老版
business.bookingVersionList = {
  v1: 1, // 老版
  v2: 2, // 新版
}

// 支付版本
// 1：现有的版本；2：走单独的支付模块的版本
business.payVersion = 1;

// 手牌是否显示备注，默认不显示
business.handNumberShowRemarkList = {
  yes: 1,
  no: 2,
}

// 手牌下是否启用开房
business.handNumberEnableRoomOpenList = {
  yes: 1,
  no: 2,
}

// 消费的开房状态
business.consumeRoomUseStatus = {
  'no': 10, // 未开房
  'ing': 20,  // 开房使用中
  'close': 30, // 已退房
}

business.front_report_room = business.front_report_room_list.no;
business.front_report_employee = business.front_report_employee_list.no;
business.front_report_wait_money = business.front_report_wait_money_list.no;
business.front_report_wait_person = business.front_report_wait_person_list.no;
business.front_report_finish_person = business.front_report_finish_person_list.no;
business.front_report_total_person = business.front_report_total_person_list.no;
business.front_report_booking = business.front_report_booking_list.no;
business.front_report_finish_order_money = business.front_report_finish_order_money_list.no;
business.front_report_finish_card_money = business.front_report_finish_card_money_list.no;

// 员工的隐藏模块
business.hiddenList = [];

// 门店预约时间
business.booking_time = ''; //门店预约时间

business.hand_number_show_remark = business.handNumberShowRemarkList.no;
business.handNumberEnableRoomOpen = business.handNumberEnableRoomOpenList.no;

business.billNoticeStay = 0; // 账单超过xxx分钟提醒  小于等于0不提醒
business.billNoticeMoney = 0; // 手牌消费达xxx金额提醒  小于等于0不提醒
business.billNoticeNoConsume = 0; // 手牌xxx分钟未消费提醒  小于等于0不提醒

business.searchBillNoticeStay = false; // 是否搜索 账单超时提醒数据
business.searchBillNoticeMoney = false; // 是否搜索 账单高消费
business.searchBillNoticeNoConsume = false; // 是否搜索 账单未消费

business.store_wait_serve_booking_list = []
business.reserve_list = []

business.employee_clock_type = business.employeeClockTypeList.no_tag; //技师排钟方式 默认为 轮次打尾牌

business.mainShowMenu = 1; // 中间内容显示的内容，默认是1
business.mainActiveObj = false; // 中间内容的选中项
business.mainShowMenuObj = {
  hand: 1, // 手牌
  room: 2, // 房间
  network: 3, // 网络订单
  appoint: 4, // 排钟列表
  device: 5, // 设备状态
  link: 6, // 联动配置
}

business.bookingInfoList = []

business.ipadCode = ''
business.reportList = []
business.customerBooking = []
business.mainNowType = ''
business.modalShowType = ''

// 处理房间的数据，现根据类型，再根据房间的编号排序
business.handleRoomListForInit = (roomTypeList, roomList) => {
  // 现根据类型排序，再根据id

  // 按照id排序
  var tempList = [...roomList]
  if (tempList) {
    tempList.sort(function (a, b) {
      return a.number - b.number;
    })
  }

  // 类型，后端给的顺序
  var typeList = [...roomTypeList]

  var arr = [];
  if (!typeList) {
    arr = tempList
  } else {
    for (let i = 0; i < typeList.length; i++) {
      for (let j = 0; j < tempList.length; j++) {
        if (tempList[j]['type'] == typeList[i]['id']) {
          arr.push(tempList[j])
        }
      }
    }
  }

  return arr;
}

//根据技师ID查找正在服务的消费信息 数组
business.getConsumeListByEmployeeId = function (employeeId, billAllConsumeListGroupEmployeeId) {
  var consumeList = [];

  if (employeeId in billAllConsumeListGroupEmployeeId) {
    consumeList = billAllConsumeListGroupEmployeeId[employeeId]
  }

  return consumeList;
}

//处理营业时间: 根据时间，获取当前营业日，对应的这个时间的：年-月-日 时:分:秒
business.handBusinessTime = (businessDate, time) => {
  businessDate = businessDate.replace(/-/g, '/');

  var date = businessDate + ' 00:00:00'
  var timestamp2 = Date.parse(new Date(date));
  timestamp2 = timestamp2 / 1000;
  var timestamp = parseInt(timestamp2) + parseInt(time)
  return business.formatDate(timestamp)
}

//将指定时间戳换成时间格式字符串
business.formatDate = (timestamp) => {
  var obj = business.formatDateObj(timestamp)

  return obj.year + "-" + obj.month + "-" + obj.day + " " + obj.hour + ":" + obj.minute + ":" + obj.second;
}

//将指定时间戳换成时间格式字符串
business.formatDateObj = (timestamp) => {
  var nows = new Date(parseInt(timestamp) * 1000)
  var now = new Date(nows);
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();

  month = '' + month  //转为字符串
  if (month.length === 1) {
    month = '0' + month
  }

  day = '' + day    //转为字符串
  if (day.length === 1) {
    day = '0' + day
  }

  hour = '' + hour    //转为字符串
  if (hour.length === 1) {
    hour = '0' + hour
  }

  minute = '' + minute    //转为字符串
  if (minute.length === 1) {
    minute = '0' + minute
  }

  second = '' + second    //转为字符串
  if (second.length === 1) {
    second = '0' + second
  }

  return {
    year: year,
    month: month,
    day: day,
    hour: hour,
    minute: minute,
    second: second
  }
}

//将指定时间戳换成 时间格式字符串去获取
business.handleDateForDateAndDay = (date, days) => {
  date = date.replace(/-/g, '/');
  let tempTime = parseInt(new Date(date + " 00:00:00").getTime() / 1000)
  tempTime = tempTime + days * 24 * 60 * 60;

  var obj = business.formatDateObj(tempTime)

  return obj.year + '-' + obj.month + '-' + obj.day
}

business.functions = {
  // 从https跳转到http的前端地址
  'locationToHttpUrl': function () {
    let tempUlr = window.location.href

    // 如果是https开头的，把这个https替换成http的即可
    // 将前面的5个字符删掉，然后调换为http
    if (tempUlr.indexOf("https") === 0) {
      // https开头的，则将https删掉
      tempUlr = tempUlr.substring(5);

      // 将http替换到前面
      tempUlr = 'http' + tempUlr;
    }

    window.location.href = tempUlr;
  },

  // 处理营销人员
  'handleAndGetSellEmployeeList': function (employees) {
    var data = [];

    if (!employees) {
      return []
    }

    // for (var i = 0; i < employees.length; i++) {
    //   //状态不为正常的则跳过
    //   if (employees[i]['status'] != business.employeeStatusList.yes) {
    //     continue;
    //   }
    //
    //   //非营销人员跳过
    //   if (employees[i]['sell'] != business.employeeSellList.yes) {
    //     continue;
    //   }
    //
    //   data.push(employees[i]);
    // }

    // 202011: 黄春晓要求：营销人员为：非保洁人员，按照营销人员、技师、其他组内工号从小到大排序

    // 按照营销人员、技师、其他人员
    let data1 = [];
    let data2 = [];
    let data3 = [];

    for (let i = 0; i < employees.length; i++) {
      let tempEmployee = employees[i];

      // 状态不为正常的则跳过
      if (tempEmployee['status'] != business.employeeStatusList.yes) {
        continue;
      }

      // 保洁人员排除
      if (tempEmployee['is_cleaning'] == business.employeeIsCleaningList.yes) {
        // 保洁人员，跳过
        continue;
      }

      // 营销
      if (tempEmployee['sell'] == business.employeeSellList.yes) {
        data1.push(tempEmployee);
        continue;
      }

      // 技师
      if (tempEmployee['type'] == business.employeeTypeList.employee) {
        data2.push(tempEmployee);
        continue;
      }

      data3.push(tempEmployee)
    }

    // 组内人员排序技师编号排序
    data1.sort((a, b) => {
      return parseInt(a.number) - parseInt(b.number);
    })

    data2.sort((a, b) => {
      return parseInt(a.number) - parseInt(b.number);
    })

    data3.sort((a, b) => {
      return parseInt(a.number) - parseInt(b.number);
    })

    // 组装成一起返回
    for (let i = 0; i < data1.length; i++) {
      data.push(data1[i])
    }

    for (let i = 0; i < data2.length; i++) {
      data.push(data2[i])
    }

    for (let i = 0; i < data3.length; i++) {
      data.push(data3[i])
    }

    return data;
  },

  // 根据手牌号 打印 鞋柜开锁二维码
  'doorQrcodeByHand': function (number, errorNotice, successCb) {
    successCb = successCb || function () {
    }
    errorNotice = errorNotice || false; // 是否进行报错提示，默认不提示

    if (business.begin_bill_type != business.beginBillTypeList.handNumber) {
      if (errorNotice) {
        common.toast("鞋柜锁在手牌开单模式下有效");
      }
      return;
    }

    // 没有启用门柜锁，不用处理
    if (business.hasDoorLock == business.hasDoorLockList.no) {
      // 没有门柜锁，直接返回
      if (errorNotice) {
        common.toast("门店未启用门柜锁");
      }
      return;
    }

    // 不支持鞋柜二维码的不处理
    if (business.doorQrcode != business.doorQrcodeList.yes) {
      if (errorNotice) {
        common.toast("门店不支持二维码鞋柜");
      }
      return;
    }

    if (business.doorLockType == business.doorLockTypeList.A) {
      // 帝派
      // 获取打印的二维码
      common.jQueryAjax({
        type: "POST",
        url: business.qrcodeRF008 + '?card=' + number, // 用柜号，手牌号就是柜号
        data: {},
        success: function (result) {
          result = result.toString();

          // 失败的时候，result，是error开头的数据；否则就是成功，返回的是一个字符串，可以用来生成二维码，用来扫码开门的

          // 提取数字字母
          result = result.replace(/[^0-9a-zA-Z]/ig, "");

          if (result.indexOf('error') === 0) {
            // 失败
            common.toast('鞋柜二维码失败:' + result)
            console.log('鞋柜二维码失败:' + result)

            return;
          }

          // 打印
          business.functions.printShowQr(number, result, successCb);
        },
        error: function (xmlHttpRequest, textStatus, errorThrown) {
          common.toast("exe未打开")
          console.log("exe未打开: 鞋柜")
          console.log(xmlHttpRequest)
          console.log(textStatus)
          console.log(errorThrown)
        }
      });
    } else if (business.doorLockType == business.doorLockTypeList.C) {
      // 爱莎v2:
      business.functions.aishaV2GetOpenCode(number).then((result) => {
        // 打印
        business.functions.printShowQr(number, result, successCb);
      })
    } else {
      if (errorNotice) {
        common.toast("不支持门柜锁打印二维码");
      }
    }
  },

  // 打印鞋柜二维码
  // number: 手牌号
  // result: 二维码内容
  // successCb: 打印成功后的回调
  'printShowQr': (number, result, successCb) => {
    result = result.toString();
    successCb = successCb || function() {}

    if (!result) {
      common.toast("鞋柜二维码为空，无法打印");
      return;
    }

    // 打印二维码
    common.axios('get', common.apiUrl('print/qr'), {
      'key': result
    }).then((resultData) => {
      let qrcode = resultData['qrcode'];

      // 打印内容 ["fontFamily" => $fontStyle, "text" => $divider, "fontSize" => $infoPrintConfig['fontSize'], "lineHeight" => $infoPrintConfig['height']]
      let printData = []
      printData.push({
        'fontFamily': '宋体',
        'text': '手牌号:' + number,
        'fontSize': 9, // 只能是数字，否则无法打印
        'lineHeight': 13,
      })

      printData.push({
        'fontFamily': '宋体',
        'text': '请凭此码扫码开柜取鞋',
        'fontSize': 9, // 只能是数字，否则无法打印
        'lineHeight': 13,
      })

      printData.push({
        'fontFamily': '宋体',
        'text': '温馨提示：此码5分钟内有效',
        'fontSize': 9, // 只能是数字，否则无法打印
        'lineHeight': 13,
      })

      printData.push({
        'fontFamily': '宋体',
        'text': '超时请联系店内工作人员',
        'fontSize': 9, // 只能是数字，否则无法打印
        'lineHeight': 13,
      })

      let nowDate = biz.handleTime(biz.getNowTime())
      let getTimeStr = "取票时间:" + nowDate.month + "/" + nowDate.day + " " + nowDate.hour + ":" + nowDate.minute
      getTimeStr = getTimeStr.toString()

      printData.push({
        'fontFamily': '宋体',
        'text': getTimeStr,
        'fontSize': 9, // 只能是数字，否则无法打印
        'lineHeight': 13,
      })

      printData = JSON.stringify(printData);

      // 将小票内容处理-超过多少行，就分开小票打印
      print.printToMorePage(printData, ['printWidth=' + print.printWidth], qrcode)

      successCb();

    }).catch((errorObj) => {
      // 失败
      common.toast(errorObj.error);
    })
  },

  // 根据读卡器读出来的内容，查找对应的门柜锁的数据，并将门柜锁的数据返回
  'getDoorLockNumberByCode': function (code) {
    var info = biz.indexOfReturnInObj(code, business.codeObjByCode);

    // 作为code存在，则将门柜锁返回
    if (info !== false) {
      return info['door_clock'];
    }

    // code作为code不存在，那么可能是进行rf008读取的，用door_clock，直接去找

    var info2 = biz.indexOfReturnInObj(code, business.codeObjByDoorClock);

    if (info2 !== false) {
      return info2['door_clock'];
    }

    return false;
  },

  // 开锁 --- 仅为开牌刷卡的时候使用
  'setRF008': function (code, callback, failBack, errorBack) {
    callback = callback || function () {
    } // 开锁成功后要做的事情
    failBack = failBack || function () {
    } // 开锁失败要做的事情
    errorBack = errorBack || function () {
    } // 门柜锁调用失败要做的事情

    // 开锁之前找一下手牌，找不到，不允许
    var hand = business.getHandByCode(code)
    if (hand === false) {
      failBack();
      return;
    }

    if (hand.status == business.handNumberStatus.status_delete) {
      common.toast('客人卡对应手牌已被删除')
      failBack();
      return;
    }

    // 20211014 1、由配置决定， 有没有门柜锁，且走的是哪套
    if (business.hasDoorLock == business.hasDoorLockList.no) {
      // 没有门柜锁，直接返回
      callback();
      return;
    }

    // 有门柜锁决定，走哪一套
    if (business.doorLockType == business.doorLockTypeList.B) {
      // B： 爱沙门锁：单独请求一个API

      let tempDoorLockNumber = business.functions.getDoorLockNumberByCode(code);

      if (tempDoorLockNumber === false) {
        common.toast("门柜锁未设置");
        failBack();
        return;
      }

      common.axios('post', business.doorLockBUrl + 'lock/door/on', {
        'userId': business.userInfo['user_id'],
        'number': tempDoorLockNumber,
      }).then((response) => {
        common.handleApiResponse(response, 'no').then((resultData) => {

          callback();

        }).catch((errorObj) => {
          common.toast(errorObj.error);

          failBack();
        })

      }).catch((errorObj) => {
        // 失败
        common.toast("网络有误");

        errorBack();
      })

      return;
    } else {

      // 开锁成功这里的回调，追加一个记录
      let openSuccess = () => {
        callback();

        // 记录开锁
        business.functions.recordDoorHand(code, business.functions.getDoorHandOnApi());
      }

      if (business.doorLockType == business.doorLockTypeList.A) {
        // 帝派
        common.jQueryAjax({
          type: "POST",
          url: business.setRF008,
          dataType: "json",
          success: function (result) {
            result = result.toString()
            if (business.functions.checkRfActionSuccess(result) === "True") {

              // 开锁成功的事情
              openSuccess();

            } else {
              common.toast("[帝派]门柜锁开启失败:" + result);
              failBack();
            }
          },
          error: function (xmlHttpRequest, textStatus, errorThrown) {
            common.toast("读卡器有误[门柜锁开启]");
            errorBack();
          }
        });
      } else if (business.doorLockType == business.doorLockTypeList.C) {
        // 爱莎V2
        business.functions.aishaV2DoorSet(code, openSuccess, failBack, errorBack);
      }
    }
  },

  // 关锁 --- 仅为结账时使用 - 带有二维码
  'clearRF008Qrcode': function (code, callback, failBack, errorBack) {
    callback = callback || function () {
    } // 开锁成功的事情
    failBack = failBack || function () {
    } // 开锁失败
    errorBack = errorBack || function () {
    } // 调用开锁失败的

    // 开锁之前找一下手牌，找不到，不允许
    var hand = business.getHandByCode(code)
    if (hand === false) {
      failBack();
      return;
    }

    // 20211014 1、由配置决定， 有没有门柜锁，且走的是哪套
    if (business.hasDoorLock == business.hasDoorLockList.no) {
      // 没有门柜锁，直接返回
      callback();
      return;
    }

    // 有门柜锁决定，走哪一套
    if (business.doorLockType == business.doorLockTypeList.B) {
      // B： 爱沙门锁：单独请求一个API

      let tempDoorLockNumber = business.functions.getDoorLockNumberByCode(code);

      if (tempDoorLockNumber === false) {
        common.toast("门柜锁未设置");
        failBack();
        return;
      }

      common.axios('post', business.doorLockBUrl + 'lock/door/off', {
        'userId': business.userInfo['user_id'],
        'number': tempDoorLockNumber,
      }).then((response) => {
        common.handleApiResponse(response, 'no').then((resultData) => {

          callback();

        }).catch((errorObj) => {
          common.toast(errorObj.error);

          failBack();
        })

      }).catch((errorObj) => {
        // 失败
        common.toast("网络有误");

        errorBack();
      })

      return;
    } else {

      // 关锁成功的回调
      let successCb = (result) => {
        // 成功
        callback(result)

        // 记录关锁
        business.functions.recordDoorHand(code, business.functions.getDoorHandOffApi())
      }

      if (business.doorLockType == business.doorLockTypeList.A) {
        // 帝派
        // 原来的这套

        // 根据门店是否支持二维码进行处理
        if (business.doorQrcode == business.doorQrcodeList.yes) {
          // 支持二维码
          common.jQueryAjax({
            type: "POST",
            url: business.clearRF008Qrcode,
            success: function (result) {
              result = result.toString();

              // 失败的时候，result，是error开头的数据；否则就是成功，返回的是一个字符串，可以用来生成二维码，用来扫码开门的

              // 提取数字字母
              result = result.replace(/[^0-9a-zA-Z]/ig, "");

              if (result.indexOf('error') === 0) {
                // 失败
                common.toast('关锁失败:' + result)
                console.log('关锁失败:' + result)
                failBack();
              } else {
                // 成功
                successCb(result)
              }
            },
            error: function (xmlHttpRequest, textStatus, errorThrown) {
              console.log("关锁调用失败")
              console.log(xmlHttpRequest)
              console.log(textStatus)
              console.log(errorThrown)
              errorBack()
            }
          });
        } else {
          // 不支持二维码
          common.jQueryAjax({
            type: "POST",
            url: business.clearRF008,
            dataType: "json",
            success: function (result) {
              result = result.toString()
              if (business.functions.checkRfActionSuccess(result) === "True") {
                successCb('')
              } else {
                common.toast("关锁失败:" + result)
                console.log("关锁失败:" + result)
                failBack();
              }
            },
            error: function (xmlHttpRequest, textStatus, errorThrown) {
              errorBack()
            }
          });
        }

      } else if (business.doorLockType == business.doorLockTypeList.C) {
        // 爱莎v2
        // 原来的这套

        // 根据门店是否支持二维码进行处理
        if (business.doorQrcode == business.doorQrcodeList.yes) {
          // 支持二维码
          business.functions.aishaV2DoorClearAndQr(hand['number'], code, successCb, failBack, errorBack)
        } else {
          // 不支持二维码
          business.functions.aishaV2DoorClear(code, successCb, failBack, errorBack)
        }

      }
    }

  },


  // 记录手牌门柜锁
  'recordDoorHand': function (code, url, successBack) {
    successBack = successBack || function () {
    }
    var hand = business.functions.getInfoByCode(code)

    if (!hand) {
      return;
    }

    common.axios('post', url, {
      'handId': hand['id']
    }).then((resultData) => {
      successBack(hand['id'])
    }).catch((errorObj) => {
      // 失败
      common.toast(errorObj.error);
    }).finally(() => {

    })
  },

  // 开启门柜锁的API
  'getDoorHandOnApi': function () {
    // 记录开锁
    var tempUrl = common.apiUrl('FINANCE/door-hand/on')

    return tempUrl
  },

  // 关闭门柜锁的API
  'getDoorHandOffApi': function () {
    // 记录开锁
    var tempUrl = common.apiUrl('FINANCE/door-hand/off');

    return tempUrl
  },

  //展示会员信息
  'showMemberInfo': function (memberId) {
    if (!memberId || memberId.length <= 0 || memberId <= 0) {
      common.toast('非法操作');
      return;
    }

    common.dispatch(setMemberShowType('showMember'));
    common.dispatch(setMemberShowMemberId(memberId));
  },

  // 给会员充值
  'rechargeForMember': function (memberId) {
    if (!memberId || memberId.length <= 0 || memberId <= 0) {
      common.alert('非法操作');
      return;
    }

    common.dispatch(setMemberShowType('showMember'));
    common.dispatch(setMemberShowMemberId(memberId));
    common.dispatch(setMemberShowBackMessage('payCardRefresh'));
  },

  // 删除消费
  'deleteConsume': function (billId, consumeId, employeeBack) {
    common.loadingStart()
    common.axios('post', common.apiUrl('FINANCE/bill/delete-consume'), {
      "consumeId": consumeId,
      "billId": billId,
      "employeeBack": employeeBack
    }).then((resultData) => {
      var successMessage = '删除成功';

      if("message" in resultData) {
        // 有message，则对应提示
        successMessage = resultData['message']
      }

      common.toast(successMessage)

      // 是否已执行
      let action = 1; // 默认已执行
      if("action" in resultData) {
        action = resultData['action']
      }

      if (action) { // 已经执行的，则立即通知
        // 触发实时通知立即处理
        noticeMessage.triggerHandleMessage();
      }
    }).catch((errorObj) => {
      // 失败
      common.toast(errorObj.error);
    }).finally(() => {
      common.loadingStop();
    })
  },


  // 创建订单的API
  'getOrderCreateApi': function () {
    var apiUrl;

    // 20211204: 根据支付版本定，走的是哪一套
    if (business.payVersion == 2) {
      // 走单独的支付模块
      apiUrl = common.apiUrl('FINANCE/order/new-create');
    } else {
      // 原来的版本
      apiUrl = common.apiUrl('FINANCE/order/create-v3');
    }

    return apiUrl
  },

  // 获取订单支付的API
  'getOrderPayApi': function (isCloud) {
    var payApiUrl; // 支付的API

    // 20211204: 根据支付版本定，走的是哪一套
    if (business.payVersion == 2) {
      // 走单独的支付模块
      payApiUrl = common.apiUrl('FINANCE/order/pay');
    } else {
      // 原来的版本

      // 检测 有  除  现金和普通账户 以外的 支付方式存在，则走云端的，否则，走店端的
      payApiUrl = common.apiUrl('FINANCE/bill/pay-v3');

      if (isCloud === true) {
        payApiUrl = common.apiUrl('bill/pay-v3');
      }
    }

    return payApiUrl
  },

  // 客显的通知
  'customNotice': function(data) {
    // data是json字符串
    // var data = {"api": "checkout", "node": padCode, "content": padData};
    // app.functions.websocketSend(data);

    var tempObj = false;

    try {
      tempObj = JSON.parse(data)
    } catch (e) {
      console.log("客显通知格式有误:" + e.toString());
      return;
    }

    if(tempObj === false){
      console.log("客显通知格式有误:false");
      return;
    }

    // 用mqtt通知

    // 主题
    let topic = '/custom/msg/' + tempObj.node;

    business.functions.mqttSend(topic, JSON.stringify(tempObj));
  },



  // mqtt发送数据
  // topic: 主题
  // message: 消息，字符串
  'mqttSend': function(topic, message) {
    try {

      /**
       * 发布主题presence, 消息内容为Hello mqtt,订阅和推送一样自发自收
       * client.publish('presence', 'Hello mqtt')
       */
      mqttClient.client.publish(topic, message);
    } catch (e) {
      console.log("mqtt send error:" + e.toString());
    }
  },


  // 根据code查找手牌卡
  'getInfoByCode': function (code) {
    // 先根据code找
    code = code.toString().toLowerCase()
    var tempCode = business.functions.handleCardCode(code)

    var info = false;

    if (tempCode.length === 8) {
      info = biz.indexOfReturnInObj(tempCode, business.handNumberObjByCode)
    }

    if (info !== false) {
      return info;
    }

    // 如果code找不到数据，则将code当成门柜锁进行获取
    var codeInfo = biz.indexOfReturnInObj(code, business.codeObjByDoorClock)

    // 门柜锁不存
    if (codeInfo === false) {
      return false;
    }

    // 门柜锁存在，则将code取出使用
    tempCode = codeInfo['code']

    info = biz.indexOfReturnInObj(tempCode, business.handNumberObjByCode)

    if (info === false) {
      return false;
    }

    return info;
  },

  // 处理新设备读卡内容
  'handleCardCode': function (code) {
    // 只获取数字字母下划线
    code = code.replace(/[^a-fA-F0-9]/g, '')

    code = code.toString()

    // 获取后8位
    code = code.substring(code.length - 8)

    return code
  },

  // 获取项目对应钟类型下的服务时长---为新增消费准备
  'getServiceTimeForCreateConsume': function (project, clockType) {
    if (clockType == business.clockTypeList.add) {
      // 加钟，且项目的加钟服务是时长大于等于0，则采用加钟服务时长
      if (typeof project['add_service_time'] !== "undefined") {
        if (project['add_service_time'] >= 0) {
          return project['add_service_time']
        }
      }
    }

    return project['service_time']
  },

  // 将房间根据类型、编号排序   {'obj': obj, 'arr': newArr}
  // obj: 房间ID为key的对象 -- 用来计算使用
  // arr: 直接前端展示使用
  sortRoomList: function (arr, typeList) {
    //先根据房间类型排序，再根据房间编号排序
    arr.sort(function (a, b) {
      if (a.type === b.type) {
        return a.number - b.number;
      } else {
        return a.type - b.type;
      }
    });

    let newArr = [];
    let obj = {};

    for (let i = 0; i < typeList.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        let temp = arr[j]

        if (temp['type'] == typeList[i]['id']) {
          obj[temp['id']] = temp
          newArr.push(temp)
        }
      }
    }

    for (let k = 0; k < arr.length; k++) {
      let temp = arr[k]

      if (temp['id'] in obj) {
        continue
      }

      obj[temp['id']] = temp
      newArr.push(temp)
    }

    return {
      'obj': obj,
      'arr': newArr
    };
  },

  calcPriceForClockConsume: function(starTime, project) {
    let nowTime = biz.getNowTime() // 当前时间戳

    let time = nowTime - starTime; // 时间差
    if(time < 0) {
      time = 0;
    }

    // 计费单位
    let secondUnit = 0;

    if(project['fee_unit'] == 20) {
      // 分钟
      secondUnit = 60;
    } else {
      // 默认小时
      secondUnit = 60 * 60;
    }

    // 首记时长：不得低于这个时间
    var defaultTime = project['default_time'] * secondUnit;
    if(time < defaultTime) {
      time = defaultTime
    }

    // 不得低于最小时长
    var minTime = project['min_time'] * secondUnit;
    if(time < minTime) {
      time = minTime;
    }

    // 计算出，计费时间单位的数字 --- 用来计算价格
    var unitNum = Math.floor(time / (secondUnit));

    // 距离整小时之后的，时间差，判断是否在免记时长内
    var remainTime = time - unitNum * secondUnit;

    // 免记时长: 只有是小时的，才有效
    if(project['fee_unit'] == 10) {
      // 不在免记时长，则小时+1
      if(remainTime > (project['free_time'] * 60)) {
        unitNum = unitNum + 1;
      }
    }

    // 计算价格：首记价格+后面单价*数量
    var price = project['default_price']

    if(unitNum > project['default_time']) {
      let tempUnitNum = unitNum - project['default_time']

      var money = biz.calcMul(tempUnitNum, project['price_unit']) // 浮点的乘法
      price = biz.calcPlusOrMinus(price, money)  // 浮点的加法
    }

    return price;
  },


  // 开启门柜锁-Show
  'openDoorClockAction': function () {
    common.dispatch(setModalShowType('doorClock'))

    common.dispatch(setDoorClockType(business.doorClockTypeList.open))
    common.dispatch(setDoorClockHandNumberList([]))
  },


  // 关闭门柜锁-Show
  'closeDoorClockAction': function (handNumberList) {
    common.dispatch(setModalShowType('doorClock'))

    // 初始化
    common.dispatch(setDoorClockType(business.doorClockTypeList.close))
    common.dispatch(setDoorClockHandNumberList(handNumberList))
  },

  // 等待关闭的门柜锁 的API
  'getDoorHandWaitOffApi': function() {
    // 记录开锁
    var tempUrl = common.apiUrl('FINANCE/door-hand/wait-off')

    return tempUrl
  },

  // 保存手机号
  'saveMobile': function (mobile, name) {
    mobile = mobile || ''
    name = name || ''

    mobile = mobile.replace(/\s+/g, "");

    if (!mobile) {
      return;
    }

    mobile = mobile.toString()

    if (mobile.length != 11) {
      return;
    }

    if (!biz.isMobile(mobile)) {
      return;
    }

    // todo 20230601

    // 将会员手机号存入前端
    common.saveMobile(mobile, name)
    // if (window.self === window.top) {
    //   return;
    // }
    //
    // window.parent.postMessage({
    //   'action': 'saveMobile',
    //   'data': {'mobile': mobile, 'name': name},
    //   "isOfflineMode": app.isOfflineMode()
    // }, '*');
  },

  // 处理获取订单计算信息的API
  'getOrderInfoApi': function (ajaxPayTypeList) {
    var orderApiUrl = ''

    // 20211204：根据支付版本定
    if (business.payVersion == 2) {
      // 走单独的支付模块
      orderApiUrl = common.apiUrl('FINANCE/order/info-v2');
    } else {
      // 原来的模块
      // 检测 有  除  现金和普通账户 以外的 支付方式存在，则走云端的，否则，走店端的
      orderApiUrl = common.apiUrl('FINANCE/order');

      var isCloud = false; // 是否要走云端
      for (let i = 0; i < ajaxPayTypeList.length; i++) {
        if (typeof ajaxPayTypeList[i]['tag'] === "undefined") {
          continue;
        }

        // 非  现金和普通账号
        if ((ajaxPayTypeList[i]['tag'] != business.payTypeTag.tag_cash) && (ajaxPayTypeList[i]['tag'] != business.payTypeTag.tag_normal)) {
          isCloud = true;
          break;
        }
      }
      if (isCloud === true) {
        orderApiUrl = common.apiUrl('order');
      }
    }

    return orderApiUrl
  },

  // 解除支付锁定
  'unbindPayLock': function (bill) {
    // 检测账单下是否有支付锁定的消费
    if (!bill) {
      common.toast("账单不存在");
      return;
    }

    let billId = bill['id'];
    let consumeList = bill['consume'];

    if (consumeList.length <= 0) {
      common.toast("账单下无消费数据");
      return;
    }

    let check = false; // 默认没有支付锁定的消费
    for (let i = 0; i < consumeList.length; i++) {
      if (consumeList[i]['pay'] == business.consumePayStatus.pay_lock) {
        check = true;
        break;
      }
    }

    if (check === false) {
      common.toast("账单下无支付锁定的消费");
      return;
    }

    common.loadingStart()

    common.axios('post', common.apiUrl('FINANCE/bill/unbind-pay-lock'), {
      "billId": billId,
    }).then((resultData) => {
      common.toast('解除支付锁定成功');

      // 触发实时通知立即处理
      noticeMessage.triggerHandleMessage();
    }).catch((errorObj) => {
      // 失败
      common.toast(errorObj.error);
    }).finally(() => {
      common.loadingStop()
    })
  },

  // 设置手牌打开包厢门
  'checkAndSetRoomNameToHand': function(roomId) {

    // 查找房间：如果设置了 锁，才能进行操作
    var room = biz.indexOfReturnInObj(roomId, business.roomObjById);

    if(!room) {
      common.toast("房间不存在");
      return;
    }

    // 检测是否启用了锁，没有启用的不动
    if(typeof room['use_lock'] === "undefined") {
      return;
    }

    if(room['use_lock'] !== "yes") {
      return;
    }

    var roomNumber = room['number'];

    business.functions.setRoomNameToHand(roomNumber);
  },

  // 设置房间号到包厢门里 仅供"checkAndSetRoomNameToHand"使用
  'setRoomNameToHand': function(roomNumber) {
    // 如果房间编号的长度超过4位，则报错
    if(roomNumber.length > 4) {
      common.toast("手牌打开包厢锁功能中：房间编号只支持4位以内的编号");
      return;
    }

    // 不满4位，设置为4位展示
    roomNumber = biz.leftZeroPad(roomNumber, 4);

    common.jQueryAjax({
      type: "POST",
      url: business.setRoomNameToHand + "?code=" + roomNumber,
      dataType: "json",
      success: function (result) {
        result = result.toString() // ok

        if (result === 'ok') {
          // 设置成功
        } else {
          common.toast("设置包厢门失败:" + result)
          console.log("设置包厢门失败:" + result)
        }
      },
      error: function (xmlHttpRequest, textStatus, errorThrown) {
        common.toast("设置包厢门失败: 请检测exe");
      }
    });
  },


  // 验证显示比例
  'checkShowPerc': function (tempValue) {
    if (tempValue < 60) {
      common.toast("不能低于60");
      return false;
    }

    if (tempValue > 200) {
      common.toast("不能高于200");
      return false;
    }

    tempValue = tempValue.toString()

    // 去空格
    tempValue = tempValue.replace(/\s*/g, "")

    if (!(/^[0-9]+$/.test(tempValue))) {
      common.toast("只能是数字");
      return false;
    }

    return parseInt(tempValue);
  },


  // 获取超时的时间
  getLoseTime: function (employeeId) {
    var remaining_time = 0;
    var nowTime = biz.getNowTime()

    var noEndConsumeListGroupEmployeeId = business.noEndConsumeListGroupEmployeeId

    let consumeList = []; // 技师名下未落钟的消费数据
    if (employeeId in noEndConsumeListGroupEmployeeId) {
      consumeList = noEndConsumeListGroupEmployeeId[employeeId]
    }

    for (let k = 0; k < consumeList.length; k++) {
      var tempConsume = consumeList[k];

      if (parseInt(tempConsume['service_end']) != 0) {
        continue;
      }

      if (!tempConsume['service_time']) {
        continue;
      }

      var temp_remaining_time = biz.formatFloat((parseFloat(tempConsume['quantity'] * tempConsume['service_time'] * 60) - parseFloat(nowTime - tempConsume['service_begin'])), 0);

      if (temp_remaining_time >= 0) {
        continue;
      }

      if (remaining_time >= 0) {
        remaining_time = temp_remaining_time
        continue;
      }

      if (temp_remaining_time <= remaining_time) {
        continue;
      }

      remaining_time = temp_remaining_time
    }

    // 处理为分钟
    remaining_time = Math.ceil(remaining_time / 60)

    return remaining_time
  },

  // 处理房间关联的备注、涉及的所有的团体
  // tempItem: 是room
  // tempRoomId: 房间id
  // showBillRemark: 是否展示账单备注
  // checkRoomShowHand: 手牌号: 展示这个房间里的手牌，以及团体中的所有手牌
  handleRemarkAndHandAndOtherRoomForRoom: function(tempItem, tempRoomId, billListGroupRoomId, batchIdsGroupRoom, showBillRemark, checkRoomShowHand, roomIdsGroupBatch, billListGroupBatchId) {
    let tempBillList = (typeof billListGroupRoomId[tempItem['id']] === "undefined") ? [] : billListGroupRoomId[tempItem['id']]

    // 检测备注
    let remarkList = [];
    let handNumberStrList = [];
    let handIdList = [];
    let otherRoomGroup = 0; // 是否和其他房间在一个团体
    let groupRoomIds = []; // 团体的房间ids

    let handNumberStrListInRoom = []; // 在房间里的手牌

    for (let ppp = 0; ppp < tempBillList.length; ppp++) {
      // 备注
      if (showBillRemark === true) {
        if (typeof tempBillList[ppp].remark !== "undefined" && tempBillList[ppp].remark) {
          let tempRemark = tempBillList[ppp].remark;
          if (remarkList.indexOf(tempRemark) === -1) {
            remarkList.push(tempRemark)
          }
        }
      }

      // 手牌号: 展示这个房间里的手牌，以及团体中的所有手牌
      if (checkRoomShowHand === true) {
        let tempHandId = tempBillList[ppp]['hand_id'];

        if (typeof business.handNumberObjById[tempHandId] !== "undefined") {
          let tempHandNumberStr = business.handNumberObjById[tempHandId]['number']

          if (handNumberStrListInRoom.indexOf(tempHandNumberStr) === -1) {
            handNumberStrListInRoom.push(tempHandNumberStr)
          }

          if (handNumberStrList.indexOf(tempHandNumberStr) === -1) {
            handNumberStrList.push(tempHandNumberStr)
          }

          if(handIdList.indexOf(tempHandId) === -1) {
            handIdList.push(tempHandId)
          }
        }
      }
    }

    // 检测这个房间的账单，有没有再其他房间的批次，有则标记为团体，没有则不标记
    // 房间下是否有批次
    if (typeof batchIdsGroupRoom[tempRoomId] !== "undefined") {
      let tempBatchIds = batchIdsGroupRoom[tempRoomId]

      if (tempBatchIds.length > 0) {
        for (let qq = 0; qq < tempBatchIds.length; qq++) {
          let tempBatchId = tempBatchIds[qq]

          if (typeof roomIdsGroupBatch[tempBatchId] == "undefined") {
            continue;
          }

          let tempRoomIdsByBatch = roomIdsGroupBatch[tempBatchId]

          if (tempRoomIdsByBatch.length > 1) {
            otherRoomGroup = 1;
          }

          for (let n = 0; n < tempRoomIdsByBatch.length; n++) {
            let tempBatchRoomId = tempRoomIdsByBatch[n]

            if(tempBatchRoomId == tempRoomId) {
              continue;
            }

            if(groupRoomIds.indexOf(tempBatchRoomId) !== -1) {
              continue;
            }

            groupRoomIds.push(tempBatchRoomId)
          }

          // 手牌号: 展示这个房间里的手牌，以及团体中的所有手牌
          if (checkRoomShowHand === true) {
            // 处理批次下的手牌号
            if (typeof billListGroupBatchId[tempBatchId] !== "undefined") {
              let tempBillList = billListGroupBatchId[tempBatchId];

              for (let qq = 0; qq < tempBillList.length; qq++) {
                let tempHandId = tempBillList[qq]['hand_id'];

                if (typeof business.handNumberObjById[tempHandId] !== "undefined") {
                  let tempHandNumberStr = business.handNumberObjById[tempHandId]['number']

                  if (handNumberStrList.indexOf(tempHandNumberStr) === -1) {
                    handNumberStrList.push(tempHandNumberStr)
                  }

                  if(handIdList.indexOf(tempHandId) === -1) {
                    handIdList.push(tempHandId);
                  }
                }
              }
            }
          }
        }

      }
    }

    return {
      remarkList: remarkList,
      handNumberStrListInRoom: handNumberStrListInRoom,
      handNumberStrList: handNumberStrList,
      handIdList: handIdList,
      groupRoomIds: groupRoomIds,
      otherRoomGroup: otherRoomGroup,
    }
  },

  // 爱沙v2门锁的发牌
  aishaV2DoorSet: (code, successCb, failBack, errorBack) => {
    successCb = successCb || function () {
    } // 开锁成功后要做的事情
    failBack = failBack || function () {
    } // 开锁失败要做的事情
    errorBack = errorBack || function () {
    } // 门柜锁调用失败要做的事情

    common.jQueryAjax({
      type: "POST",
      url: business.setRF003,
      dataType: "json",
      success: function (result) {
        result = result.toString()

        if (business.functions.checkRfActionSuccess(result) === "True") {
          // 开锁成功的事情
          successCb();

        } else {
          common.toast("[爱莎v2]门柜锁开启失败:" + result);
          failBack();
        }
      },
      error: function (xmlHttpRequest, textStatus, errorThrown) {
        common.toast("读卡器有误[门柜锁开启]");
        errorBack();
      }
    });
  },

  // 爱沙v2门锁的消牌
  aishaV2DoorClear: (code, successCb, failBack, errorBack) => {
    successCb = successCb || function () {
    } // 开锁成功的事情
    failBack = failBack || function () {
    } // 开锁失败
    errorBack = errorBack || function () {
    } // 调用开锁失败的

    common.jQueryAjax({
      type: "POST",
      url: business.clearRF003,
      dataType: "json",
      success: function (result) {
        result = result.toString()
        if (business.functions.checkRfActionSuccess(result) === "True") {
          successCb('')
        } else {
          common.toast("关锁失败:" + result)
          console.log("关锁失败:" + result)
          failBack();
        }
      },
      error: function (xmlHttpRequest, textStatus, errorThrown) {
        errorBack()
      }
    });
  },

  // 爱莎v2门锁消牌并获取二维码
  aishaV2DoorClearAndQr: (number, code, successCb, failBack, errorBack) => {
    // 看硬件上怎么用，有直接的API就用；没有就先消牌，再调用二维码
    successCb = successCb || function () {
    } // 开锁成功的事情
    failBack = failBack || function () {
    } // 开锁失败
    errorBack = errorBack || function () {
    } // 调用开锁失败的

    // 成功回调里，要把二维码返回出去进行打印使用

    var tempNumber = number;
    try {
      tempNumber = number.toString()
      tempNumber = tempNumber.trim()
      tempNumber = parseInt(tempNumber)
    } catch (e) {
      common.toast("手牌号有误:" + number + ":error:" + e.toString())
      console.log("手牌号有误:" + number + ":error:" + e.toString())
      failBack();
      return;
    }

    // 20241102： 不足4位的用4位补上，爱莎v2的要4位的
    tempNumber = tempNumber.toString()
    let tempLen = 4 - tempNumber.length
    if (tempLen > 0) {
      for(let tempI = 0; tempI < tempLen; tempI++) {
        tempNumber = "0" + tempNumber;
      }
    }

    common.jQueryAjax({
      type: "POST",
      url: "http://" + "127.0.0.1:2048/clearRF003Qrcode" + "?hand=" + tempNumber,
      success: function (result) {
        result = result.toString();

        // 失败的时候，result，是error开头的数据；否则就是成功，返回的是一个字符串，可以用来生成二维码，用来扫码开门的

        // 提取数字字母
        result = result.replace(/[^0-9a-zA-Z]/ig, "");

        if (result.indexOf('error') === 0) {
          // 失败
          common.toast('关锁失败:' + result)
          console.log('关锁失败:' + result)
          failBack();
        } else {
          // 成功
          successCb(result)
        }
      },
      error: function (xmlHttpRequest, textStatus, errorThrown) {
        console.log("关锁调用失败")
        console.log(xmlHttpRequest)
        console.log(textStatus)
        console.log(errorThrown)
        errorBack()
      }
    });

  },

  // 爱莎v2获取二维码
  // number: 手牌号
  aishaV2GetOpenCode: (number) => {
    return new Promise((resolve, reject) => {
      var tempNumber = number;
      try {
        tempNumber = number.toString()
        tempNumber = tempNumber.trim()
        tempNumber = parseInt(tempNumber)
      } catch (e) {
        common.toast("手牌号有误:" + number + ":error:" + e.toString())
        console.log("手牌号有误:" + number + ":error:" + e.toString())
        reject();
        return;
      }

      // 20241102： 不足4位的用4位补上，爱莎v2的要4位的
      tempNumber = tempNumber.toString()
      let tempLen = 4 - tempNumber.length
      if (tempLen > 0) {
        for(let tempI = 0; tempI < tempLen; tempI++) {
          tempNumber = "0" + tempNumber;
        }
      }

      common.jQueryAjax({
        type: "POST",
        url: "http://" + "127.0.0.1:2048/qrcodeRF003" + '?hand=' + tempNumber, // 箱号，就是手牌号
        data: {},
        success: function (result) {
          result = result.toString();

          // 失败的时候，result，是error开头的数据；否则就是成功，返回的是一个字符串，可以用来生成二维码，用来扫码开门的

          // 提取数字字母
          result = result.replace(/[^0-9a-zA-Z]/ig, "");

          if (result.indexOf('error') === 0) {
            // 失败
            common.toast('鞋柜二维码失败:' + result)
            console.log('鞋柜二维码失败:' + result)

            reject();

            return;
          }

          // 打印
          resolve(result)
        },
        error: function (xmlHttpRequest, textStatus, errorThrown) {
          common.toast("exe未打开")
          console.log("exe未打开: 鞋柜")
          console.log(xmlHttpRequest)
          console.log(textStatus)
          console.log(errorThrown)

          reject()
        }
      });
    })
  },

  // 读取rf手牌
  readRFHand: (cb, errorCb, noCb) => {
    cb = cb || function() {} // 读手牌出来的内容
    noCb = noCb || function() {} // 不支持的门柜锁
    errorCb = errorCb || function() {} // 调用失败

    let tempUrl = ""
    if (business.doorLockType == business.doorLockTypeList.A) {
      // 帝派
      tempUrl = business.readRf008
    } else if (business.doorLockType == business.doorLockTypeList.C) {
      // 爱莎
      tempUrl = "http://" + "127.0.0.1:2048/readRF003"
    }

    if (!tempUrl) {
      console.log("不支持该款项的门柜锁:" + business.doorLockType)
      noCb()
      return;
    }

    common.jQueryAjax({
      type: "POST",
      url: tempUrl,
      data: {},
      dataType: "json",
      success: function (result) {
        result = result.toString()

        if (result.indexOf('error') === 0) {
          // 失败
          cb("");
          return;
        }

        // 提取数字字母
        result = result.replace(/[^0-9a-zA-Z]/ig, "");

        cb(result);
      },
      error: function (xmlHttpRequest, textStatus, errorThrown) {
        // 调用失败
        errorCb()
      }
    });
  },

  // 判断rf的操作是否成功
  checkRfActionSuccess: (result) => {
    // 如果是 帝派的是True
    // 爱莎v2，error开头的是错误的
    if (business.doorLockType == business.doorLockTypeList.A) {
      if(result === "True") {
        return "True"
      }
    } else if (business.doorLockType == business.doorLockTypeList.C) {
      if(result.indexOf("error") !== 0) {
        // 非error开头的，就是成功的
        return "True"
      }
    }

    return result
  }
}

// 根据code获取正常可使用的手牌
business.getHandByCode = function (code) {
  code = code.toString()
  code = code.toLowerCase(); // 转为小写

  if (!code) {
    common.toast('读卡器读取内容有误');
    return false;
  }

  // 20180918 - 房间开单模式，不允许使用客人卡
  if (business.begin_bill_type == business.beginBillTypeList.room) {
    common.toast('房间开单模式，不允许使用客人卡');
    return false;
  }

  // 获取手牌
  var hand = business.functions.getInfoByCode(code)

  if (!hand) {
    common.toast('客人卡不存在');
    return false;
  }

  return hand;
}


//正常状态的技师
business.handleNormalEmployeeTypeList = function () {
  var arr = [];

  for (var i = 0; i < business.employees.length; i++) {
    if (business.employees[i]['status'] == business.employeeStatus.status_yes && business.employees[i]['type'] == business.employeeTypeList.employee) {
      var temp = this.employees[i];
      if (temp['sex'] == '1') {
        temp = {
          ...temp,
          'sex_string': '男'
        }
      } else if (temp['sex'] == '2') {
        temp = {
          ...temp,
          'sex_string': '女'
        }
      } else {
        temp = {
          ...temp,
          'sex_string': '未知'
        }
      }
      arr.push(temp);
    }
  }

  return arr;
}

// 门柜锁的指令
business.doorClockTypeList = {
  'open': 10,
  'close': 20,
}

// 右键菜单的类型
business.showRightMenuObj = {
  busy: 'busy',
  booking: 'booking',
  free: 'free',
  noConsume: 'noConsume',
  clean: 'clean',
  fault: 'fault',
  employeeClock: 'employeeClock',
  bottomEmployeeClock: 'bottomEmployeeClock',
  bottomEmployeeNoTagClock: 'bottomEmployeeNoTagClock',
  bottomEmployeeClockRecover: 'bottomEmployeeClockRecover',
  defaultBooking: 'defaultBooking',
}

business.rightMenuType = ''// 右键菜单类型

// 右键菜单
business.showRightMenu = (rightMenuType, e) => {

  // clientX/Y 获取到的是触发点相对于浏览器可视区域左上角距离
  const clickX = e.clientX
  const clickY = e.clientY
  // window.innerWidth/innerHeight 获取的是当前浏览器窗口的视口宽度/高度
  const screenW = window.innerWidth
  const screenH = window.innerHeight

  // 获取自定义菜单的宽度/高度
  const rootW = 160
  const rootH = 750

  // right为true，说明鼠标点击的位置到浏览器的右边界的宽度可以放下菜单。否则，菜单放到左边。
  // bottom为true，说明鼠标点击位置到浏览器的下边界的高度可以放下菜单。否则，菜单放到上边。
  const right = (screenW - clickX) > rootW
  const left = !right
  const bottom = (screenH - clickY) > rootH
  const top = !bottom

  let positionData = {}

  if (right) {
      positionData['left'] = clickX + 'px'
  }

  if (left) {
      positionData['left'] = clickX - rootW + 'px'
  }

  if (bottom) {
      positionData['top'] = clickY  + 'px'
  }
  if (top) {

      // 顶部为负数则固定展示bottom
      if(clickY - rootH < 0){
          positionData['bottom']  = '10px'
      }else{
          positionData['top'] = (clickY - rootH)  + 'px'
      }

  }


  // 显示右键菜单
  common.dispatch(setModalShowType('rightMenu'))
  common.dispatch(setModalShowTypeScreen(positionData))

  common.dispatch(setRightMenuType(rightMenuType))

  /**
   * busy: 忙碌账单的菜单
   * booking: 预约客户的菜单
   * free: 空闲的菜单
   * noConsume: 待服务
   * clean: 打扫
   * fault: 维修
   * employeeClock: 技师排钟
   * bottomEmployeeClock: 底部技师排钟 大轮循环
   * bottomEmployeeNoTagClock: 底部技师-轮次排钟
   * bottomEmployeeClockRecover: 底部技师复牌
   * defaultBooking: 在线预约的右键菜单
   */

  // 阻止默认事件
  if(e) {
    try {
      e.target.click(); // 触发点击事件
      e.preventDefault()
    } catch (e) {
      console.log(e.toString())
    }
  }
}

// 忙碌中的房间
business.billRoomId = {}

// 技师前端分的状态
business.employeeFrontStatusObj = {
  free: 'free', // 空闲
  busy: 'busy', // 忙碌
  rest: 'rest', // 休息
  suspend: 'suspend', // 暂停
  appoint: 'appoint', // 预约
  overtime: 'overtime', // 加班
  waitingService: 'waitingService', // 待服务
  workSuspend: 'workSuspend', // 停牌
}

// 手动执行的优惠方案
business.manualPricePlanList = [];

// 处理技师的等待时间
business.getEmployeeBusyWaitTime = function (one, handleEmployeeObjByEmployeeId) {
  if (!one) {
    return false;
  }

  if (!one['employee_id']) {
    return false;
  }

  let employee = biz.indexOfReturnInObj(one['employee_id'], handleEmployeeObjByEmployeeId);

  if (!employee) {
    return false;
  }

  // 等待时间
  let waitTime = 0;

  if (typeof employee.time != "undefined") {
    waitTime = employee.time ? employee.time : 0;
  }

  return waitTime
}

// 房间是否为功能房
business.roomIsFunctionalObj = {
  yes: 'yes',
  no: 'no'
}

// 系统使用模式
business.systemUseTypeObj = {
  v1: 1,
  newRoom: 2,
}
business.systemUseType = 1;

business.roomShowHandSetting = 2
business.roomShowHandSettingObj= {
  yes: 1,
  no: 2
}

// 房间展示是否分页[新版收银]
business.roomShowPageV4FinanceList = {
  no: 1,
  yes: 2
}

// 服务中的技师是否显示钟类型
business.busyEmployeeShowClockObj = {
  yes: 1,
  no: 2
}

// 会员拆单是否自动出现手机号和验证码
business.memberSplitOrderObj = {
  yes : 1,
  no: 2
}

export default business
